import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React from 'react';
import CategoryTree from 'feature/components/CategoryTree';

const FeatureCategory = () => {
  return (
    <WorkdeskLayout pageName="feature-category" title="类别管理">
      <CategoryTree mode='manage' />
    </WorkdeskLayout>
  );
};

export default FeatureCategory;
