import callApi, { FetchApiFunc } from 'app/services/api';
import { Strategy } from './models';
import { PredictAggregation } from 'ai/models';
import { TableState } from 'app/components/Table/hooks';

export const fetchStrategyList: FetchApiFunc<Array<Strategy>> = () => {
  return callApi({ endpoint: '/strategies' });
};

interface FetchStrategySignalsParams extends TableState {
  strategy_id: string;
}

export const fetchStrategySignals = async (params: FetchStrategySignalsParams) => {
  return callApi<PredictAggregation[]>({
    endpoint: `/strategies/signals/${params.strategy_id}`,
    method: 'GET',
    data: params,
  });
};
