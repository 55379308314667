import { AccountTradeRecord } from 'account/models';
import { useAppDispatch } from 'app/hooks';
import { createAccountTradeRecordThunk, fetchAccountDetailThunk } from 'account/slice';
import moment from 'moment';
import React from 'react';
import { DateEntry } from 'app/components/Table/hooks';
import {
  FormInstance,
  ModalForm,
  ModalFormProps,
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import Button from 'antd/lib/button';

interface Props {
  mode: 'CREATE' | 'UPDATE';
  accountID: string;
  record?: AccountTradeRecord;
  trigger?: ModalFormProps['trigger'];
}

const TradeRecordForm: React.FC<Props> = ({ mode, accountID, record, trigger }) => {
  const formRef = React.useRef<FormInstance>();
  const dispatch = useAppDispatch();

  return (
    <ModalForm<AccountTradeRecord>
      title={mode === 'CREATE' ? '新建记录' : '编辑记录'}
      formRef={formRef}
      onFinish={async (values) => {
        if (mode === 'CREATE') {
          dispatch(
            createAccountTradeRecordThunk({
              ...values,
              account_id: accountID,
              datetime: moment((values.datetime as DateEntry).$d).format('YYYY-MM-DD HH:mm:ss'),
            })
          ).then((res) => {
            dispatch(fetchAccountDetailThunk(accountID));
          });
        } else {
          // const payload = { ...account, ...values };
          // dispatch(updateAccountThunk(payload));
        }
        formRef.current?.resetFields();
        return true;
      }}
      initialValues={record}
      trigger={
        trigger ? (
          trigger
        ) : (
          <Button type="primary">{mode === 'CREATE' ? '新建记录' : '编辑记录'}</Button>
        )
      }
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {
          formRef.current?.resetFields();
        },
      }}
    >
      <ProFormDateTimePicker name="datetime" label="时间" />
      <ProFormSelect
        name="type"
        label="类型"
        options={[
          { label: '买入', value: 'B' },
          { label: '卖出', value: 'S' },
        ]}
      />
      <ProFormText name="ts_code" label="代码" />
      <ProFormDigit name="price" label="价格" />
      <ProFormDigit name="shares" label="股数" />
    </ModalForm>
  );
};
export default TradeRecordForm;