import { Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import { Classify, THSClassifyType } from 'classify/models';
import { ECharts, EChartsOption, SeriesOption, YAXisComponentOption } from 'echarts';
import React, { FunctionComponent, useMemo } from 'react';
import { DOWN_COLOR, UP_COLOR } from '../CandleChart/constants';
import { StockLimitDailyStat } from './models';

const formatData = (
  data: StockLimitDailyStat['classify_distribution'],
  limitType: 'up' | 'down'
) => {
  const sorted = [...data].sort((a, b) => ((a.up + a.count) > (b.up + b.count) ? -1 : 1));
  const result = sorted.slice(0, 30);

  return result.map((c) => ({ name: c.name, value: c[limitType], code: c.code })).reverse();
};

const getOptions = (
  data: StockLimitDailyStat['classify_distribution'],
  classifyType: Classify['type'],
  classifyLevel: Classify['level']
): EChartsOption => {
  let title: string;
  if (classifyType === 'N') {
    title = `${THSClassifyType[classifyType]}(前30)`;
  } else {
    title = `${classifyLevel}级行业(前30)`;
  }
  const result: EChartsOption = {
    title: {
      text: title,
      left: '30%',
    },
    xAxis: {},
    grid: {
      left: '25%',
    }
  };

  const series: SeriesOption[] = [];
  const yAxis: YAXisComponentOption[] = [];

  ['up', 'down'].forEach((type, _idx) => {
    const _data = formatData(
      classifyType === 'N'
        ? data.filter((c) => c.type === classifyType)
        : data.filter((c) => c.type === classifyType && c.level === classifyLevel),
      type as 'up' | 'down'
    );
    if (_idx === 0) {
      yAxis.push({
        type: 'category',
        data: _data.map((item) => item['name']),
        axisLabel: {
          interval: 0,
        },
      });
    }
    series.push({
      name: _idx === 0 ? '买入' : '卖出',
      type: 'bar',
      data: _data,
      stack: 'total',
      label: {
        show: true,
      },
      itemStyle: {
        color: _idx === 0 ? UP_COLOR : DOWN_COLOR,
      },
    });
  });

  result.series = series;
  result.yAxis = yAxis;

  return result;
};

const StockLimitClassifyDistribution: FunctionComponent<{
  data: StockLimitDailyStat['classify_distribution'];
  classifyType: Classify['type'];
  classifyLevel?: Classify['level'];
}> = ({ data, classifyType, classifyLevel }) => {
  const options = useMemo(
    () => getOptions(data, classifyType, classifyLevel),
    [data, classifyType, classifyLevel]
  );

  if (!options) {
    return <Skeleton active />;
  }

  const eventHandler = (chart: ECharts) => {
    chart.off('click');
    chart.on('click', 'series', (params) => {
      if (params.seriesName) {
        const code = (params.data as { code: string })['code'];
        if (code !== '其他') {
          window.open(`/classify/ths/${code}/latest`, 'blank');
        }
      }
    });
  };
  return (
    <MQCharts
      options={options}
      className="stock-limit-classify-dist-chart"
      eventHandler={eventHandler}
    />
  );
};

export default StockLimitClassifyDistribution;
