import { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { Stock } from "stock/models";
import { RootState } from "app/store";
import { Collection } from 'collection/models';
import { useContextDispatch } from "app/hooks";
import { addCollectionItemThunk, fetchCollectionsThunk } from "collection/slice";

export const useInCollections = (code: Stock['code']) => {
  const dispatch = useContextDispatch();

  const collections = useSelector((state: RootState) => state.collection.collections.items);
  const isLogin = useSelector((state: RootState) => state.auth.isLogin);

  useEffect(() => {
    if (isLogin) {
      dispatch(fetchCollectionsThunk());
    }
  }, [dispatch, isLogin]);

  return {
    inCollections: collections && collections.filter(collection => collection.items.includes(code)),
    collections,
  };
}

export const useSelectCollectionModal = (code: Stock['code'], collections: Collection[]) => {
  const dispatch = useContextDispatch();
  const [visiable, setVisiable] = useState<boolean>(false);

  const open = useCallback(() => {
    setVisiable(true);
  }, []);

  const close = useCallback(() => {
    setVisiable(false);
  }, []);

  const addToCollections = useCallback((collections?: Array<Collection['id']>) => {
    dispatch(addCollectionItemThunk({ item: code, collections }));
  }, [code, dispatch]);

  const onAddClick = useCallback(() => {
    if (collections.length === 0) {
      addToCollections();
    } else {
      open();
    }
  }, [open, addToCollections, collections])

  return {
    state: {
      visiable,
    },
    actions: {
      open,
      close,
      addToCollections,
      onAddClick,
    }
  }
};

export const useCollectionOptions = () => {
  const collections = useSelector((state: RootState) => state.collection.collections.items);

  const options = useMemo(() => collections.map(c => ({
    label: c.name,
    value: c.id
  })), [collections])

  return options;
}