import React from 'react';
import moment from 'moment';

import Table, { ColumnProps } from 'app/components/Table';

import { BuyTag, SellTag } from 'signal/components/TypeTag';
import { SignalType } from 'signal/models';
import { DataWithPagination } from 'app/services/api';
import { ProTableProps } from '@ant-design/pro-table';
import StockPriceChange from 'stock/components/StockPriceChange';
import { SignalWithCollection } from 'collection/models';
import { fetchCollectionSignalThunk } from 'collection/slice';
import CollectionTag from '../CollectionTag';
import { openNewStockPage } from 'stock/utils';
import { StockPageTab } from 'stock/pages/Stock';


const columns: ColumnProps<SignalWithCollection>[] = [
  {
    title: '策略',
    dataIndex: 'strategy',
    render: (_, record) => record.strategy.name,
    search: false,
  },
  {
    title: '股票',
    dataIndex: 'stock',
    key: 'code',
    search: false,
    render: (_, record) => `${record.stock.name} ${record.stock.code}`,
  },
  {
    title: '类型',
    dataIndex: 'type',
    render: (_, record) => record.type === SignalType.BUY ? <BuyTag /> : <SellTag />,
    search: false,
  },
  {
    title: '强度',
    dataIndex: 'strength',
    search: false,
  },
  {
    title: '收盘价',
    key: 'daily_data.close',
    search: false,
    render: (_, record) => (<span>{record.daily_data?.close}</span>),
  },
  {
    title: '涨跌幅',
    dataIndex: 'price_chg',
    search: false,
    render: (_, record) => {
      return <StockPriceChange
        price_chg={record.daily_data?.price_chg}
        pct_chg={record.daily_data?.pct_chg}
      />
    },
    sorter: (a, b) => a.daily_data.pct_chg - b.daily_data.pct_chg,
  },
  {
    title: '发生日期',
    dataIndex: 'occurred_at',
    key: 'date',
    valueType: 'date',
    search: false,
    render: (_, record) => moment(record.occurred_at).format('YYYY-MM-DD'),
    sorter: (a, b) => a.occurred_at - b.occurred_at,
  },
  {
    title: '所属组合',
    dataIndex: 'collections',
    search: false,
    render: (_, record) => {
      return (
        <>
          {record.collections.map(c => (
            <CollectionTag key={c.id} {...c} />
          ))}
        </>
      )
    }
  }
];

export interface SignalTableProps extends ProTableProps<SignalWithCollection, any> {
  state: DataWithPagination<SignalWithCollection>,
}

export default function SignalTable({ state, ...rest }: SignalTableProps) {
  const data = state && state.items;
  const pagination = state && state.pagination;

  return (
    <Table
      {...rest}
      columns={columns}
      data={data}
      rowKey="id"
      search={false}
      fetchAction={fetchCollectionSignalThunk}
      paginationConfig={pagination}
      options={false}
      onRow={record => {
        return {
          onClick: event => {
            openNewStockPage(record.stock.code, StockPageTab.signals)
            event.preventDefault();
            event.stopPropagation();
          }, // 点击行
        };
      }}
    />
  );
}