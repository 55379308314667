import { Card, Skeleton } from 'antd';
import { useContextDispatch } from 'app/hooks';
import { DateRangeParams } from 'app/services/api';
import { RootState } from 'app/store';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStockLimitRangeStatThunk } from './slice';
import StockLimitRangeStatChart from './StockLimitRangeStatChart';

import './index.scss';


const StockLimitRangeStat: FunctionComponent<{params?: DateRangeParams}> = ({ params }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchStockLimitRangeStatThunk(params));
  }, [dispatch, params]);

  const data = useSelector((state: RootState) => state.stocks.limitRangeStat.data);

  if (!data) {
    return <Skeleton active />;
  }

  return (
    <Card
      title={`涨跌停区间统计: [${data.start}, ${data.end}]`}
      className="stock-limit-range-stat"
    >
      <StockLimitRangeStatChart data={data['records']} />
    </Card>
  );
}

export default StockLimitRangeStat;