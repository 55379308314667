import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tag } from 'antd';

import './index.scss';
import StockPriceChange from '../../../stock/components/StockPriceChange';
import { BuyTag, SellTag, SignalTag } from 'signal/components/TypeTag';
import { StockLimitTag } from '../../../stock/components/LimitTag';
import { AmountFormatter, AmountUnit } from 'market/utils';
import { MLModelPredict } from 'strategy/models';
import IndustrySectorLink from 'classify/components/IndustrySectorLink';
import Table, { ColumnProps } from 'app/components/Table';
import { fetchMLModelPredictList } from './api';
import moment from 'moment';
import SearchInput from 'stock/components/SearchInput';

export enum MLModelName {
  XGB35_All = 'XGB35_All',
  LSTM_1 = 'LSTM_1',
  XGB35 = 'XGB35',
}

// 参考文档:https://iv1h7cm1z3.feishu.cn/wiki/QG3tw7G8sikxWQkRVf9cAi3KnMh
const MODEL_CONFIG = {
  [MLModelName.XGB35]: {
    sortBy: 'record.stock.detail.trade.turnover_rate_f',
    highlight: (record: MLModelPredict) =>
      // record.stock.detail.trade.turnover_rate_f >= 10 && record.stock.detail.trade.major_pct >= 0.3,
      false
  },
  [MLModelName.XGB35_All]: {
    sortBy: 'record.stock.detail.trade.major_pct',
    highlight: (record: MLModelPredict) =>
      record.stock.detail.trade.major_pct > 0.2 &&
      record.stock.detail.trade.pct_chg_10 >= 10 &&
      record.prob >= 0.8,
  },
  [MLModelName.LSTM_1]: {
    sortBy: 'record.stock.detail.trade.turnover_rate_f',
    highlight: (record: MLModelPredict) =>
      record.stock.detail.trade.turnover_rate_f >= 13 &&
      record.prob >= 0.8 &&
      record.stock.detail.trade.pct_chg_20 <= 20,
  },
};

// columeRender方法接收一个modelName参数，返回一个ColumnProps数组
const renderColumns = (modelName: MLModelName): ColumnProps<MLModelPredict>[] => {
  const config = MODEL_CONFIG[modelName];

  const columns: ColumnProps<MLModelPredict>[] = [
    {
      title: '日期',
      dataIndex: 'date',
      render: (_, record) => moment(record.date).format('YYYY-MM-DD'),
      valueType: 'dateRange',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: '股票',
      dataIndex: 'code',
      key: 'code',
      renderFormItem(item, config, form) {
        return (
          <SearchInput
            onSelect={(value) => {
              form.setFieldsValue({ [item.dataIndex as string]: value.split(' ')[0] });
            }}
            search={false}
          />
        );
      },
      render: (_, record) => (
        <Link to={`/stocks/${record.stock.info.code}`} target="_blank">
          {record.stock.info.name}
          <br />
          {record.stock.info.code}
          <StockLimitTag stock={record.stock} />
          <br />
          <Tag>
            <IndustrySectorLink {...record.stock.industry_sector} />
          </Tag>
          <br />
          <>
            {record.stock.detail.signals.map((signal, idx) => (
              <SignalTag key={idx} signal={signal} />
            ))}
          </>
        </Link>
      ),
      sorter: (a, b) => a.stock.detail.signals.length - b.stock.detail.signals.length,
    },
    {
      title: '概率',
      dataIndex: 'prob',
      render: (_, record) => record.prob,
      sorter: (a, b) => a.prob - b.prob,
      valueType: 'digit',
    },
    {
      title: () => <>收益期望</>,
      key: 'expected',
      render: (_, record) =>
        record.expected ? <StockPriceChange pct_chg={record.expected} /> : '数据缺失',
      sorter: (a, b) => a.expected - b.expected,
      showSorterTooltip: false,
      search: false,
    },
    {
      title: '模型',
      dataIndex: 'model_name',
      render: (_, r) => (
        <>
          {r.model.name}
          <br />
          版本: {r.model.version}
          <br />
          准确率：
          <StockPriceChange pct_chg={r.model.score * 100} />
          <br />
          平均损失：
          <StockPriceChange pct_chg={r.model.mean_loss} />
        </>
      ),
      search: false,
      sorter: (a, b) => a.model.score - b.model.score,
      valueEnum: {
        ALL: { text: '全部', status: 'ALL' },
        XGB35: { text: 'XGB35', status: 'XGB35' },
        XGB35_All: { text: 'XGB35_All', status: 'XGB35All' },
        LSTM_1: { text: 'LSTM_1', status: 'LSTM_1' },
      },
    },
    {
      title: '涨跌幅',
      key: 'stock.detail.trade.pct_chg',
      dataIndex: 'stock.detail.trade.pct_chg',
      render: (_, record) => {
        return (
          <StockPriceChange
            // price_chg={record.stock.detail.trade.price_chg}
            pct_chg={record.stock.detail.trade.pct_chg}
          />
        );
      },
      sorter: true,
      search: false,
    },
    {
      key: 'chg_5',
      dataIndex: 'stock.detail.trade.pct_chg_5',
      title: '5日涨跌幅',
      sorter: true,
      render: (_, r) => {
        return <StockPriceChange pct_chg={r.stock.detail.trade.pct_chg_5} />;
      },
      search: false,
    },
    {
      key: 'chg_10',
      dataIndex: 'stock.detail.trade.pct_chg_10',
      title: '10日涨跌幅',
      sorter: true,
      render: (_, r) => {
        return <StockPriceChange pct_chg={r.stock.detail.trade.pct_chg_10} />;
      },
      search: false,
    },
    {
      key: 'chg_20',
      dataIndex: 'stock.detail.trade.pct_chg_20',
      title: '20日涨跌幅',
      sorter: true,
      render: (_, r) => {
        return <StockPriceChange pct_chg={r.stock.detail.trade.pct_chg_20} />;
      },
      search: false,
    },
    {
      title: '成交额',
      key: 'stock.detail.trade.amount',
      dataIndex: 'stock.detail.trade.amount',
      render: (_, r) =>
        r.stock.detail.trade.amount
          ? new AmountFormatter(r.stock.detail.trade.amount, AmountUnit.Thounsand).parseTo(
              AmountUnit.HundredMillion
            )
          : '数据缺失',
      sorter: (a, b) => a.stock.detail.trade.amount - b.stock.detail.trade.amount,
      search: false,
    },
    {
      title: '自由流通换手',
      key: 'stock.detail.trade.turnover_rate_f',
      dataIndex: 'stock.detail.trade.turnover_rate_f',
      render: (_, r) =>
        r.stock.detail.trade.turnover_rate_f
          ? `${r.stock.detail.trade.turnover_rate_f.toFixed(2)}%`
          : '数据缺失',
      sorter: (a, b) => a.stock.detail.trade.turnover_rate_f - b.stock.detail.trade.turnover_rate_f,
      search: false,
    },
    {
      title: '大单占比',
      key: 'stock.detail.trade.major_pct',
      dataIndex: 'stock.detail.trade.major_pct',
      render: (_, record) => {
        return record.stock.detail.trade.major_pct !== null
          ? `${(record.stock.detail.trade.major_pct * 100).toFixed(2)}%`
          : '数据缺失';
      },
      sorter: (a, b) => a.stock.detail.trade.major_pct - b.stock.detail.trade.major_pct,
      search: false,
    },
    // {
    //   title: '信号',
    //   key: 'signals',
    //   render: (_, record) => {
    //     return record.stock.detail.signals.map((signal, idx) => (
    //       <SignalTag key={idx} signal={signal} />
    //     ));
    //   },
    //   sorter: (a, b) => a.stock.detail.signals.length - b.stock.detail.signals.length,
    //   search: false,
    // },
    {
      title: '总市值',
      dataIndex: 'stock.detail.basic.total_mv',
      sorter: true,
      render: (_, r) =>
        r.stock.detail.basic &&
        new AmountFormatter(r.stock.detail.basic?.total_mv, AmountUnit.Wan).parseTo(
          AmountUnit.HundredMillion
        ),
      search: false,
    },
    {
      title: '自由流通市值',
      dataIndex: 'stock.detail.basic.free_mv',
      sorter: true,
      render: (_, r) =>
        r.stock.detail.basic &&
        new AmountFormatter(r.stock.detail.basic?.free_mv, AmountUnit.Wan).parseTo(
          AmountUnit.HundredMillion
        ),
      search: false,
    },
    {
      title: '结果',
      key: 'result',
      render: (_, record) => record.max_chg !== null ? (
        <>
          {record.label ? <BuyTag text="达标" /> : <SellTag text="未达标" />}
          <br />
          最大涨幅：
          <StockPriceChange pct_chg={record.max_chg} />
          <br />
          T+3收盘卖出：
          <StockPriceChange pct_chg={record.loss} />
        </>
      ) : '暂无结果',
      search: false,
    },
    // {
    //   title: '当前价',
    //   dataIndex: 'close',
    //   key: 'close',
    //   render: (_, record) => record.stock.detail.trade.close,
    // },
    // {
    //   title: '操作',
    //   key: 'operations',
    //   render: (_, record) => <AddToCollection code={record.stock.info.code} />,
    //   search: false,
    // },
  ];

  return columns.map((column) => {
    if (column.key && config.sortBy.includes(column.key as string)) {
      return {
        ...column,
        defaultSortOrder: 'descend',
      };
    } else {
      return column;
    }
  });
};

export const MLModelPredictTable: FunctionComponent<{ modelName: MLModelName }> = ({
  modelName,
}) => {
  const config = MODEL_CONFIG[modelName];
  return (
    <Table
      rowClassName={(record) => (config.highlight(record) ? 'highlight' : '')}
      className="ml-model-predict-table"
      columns={renderColumns(modelName)}
      // data={data}
      fetchAction={(params) => {
        // params中扩展filter_model_name: model
        if (params.filter) {
          params.filter.model_name = modelName;
        } else {
          params.filter = { model_name: modelName };
        }
        return fetchMLModelPredictList(params);
      }}
      pagination={{ pageSize: 10 }}
      useRedux={false}
      sortInLocal={true}
      options={false}
      rowKey={(record) => `${record.info.code}_${record.date}_${record.model.version}`}
    />
  );
};
