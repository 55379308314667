import React from 'react';
import { Indicator } from 'market/models';
import ReactDomServer from 'react-dom/server';
import * as constants from 'stock/components/CandleChart/constants';
import classNames from 'classnames';

export const formatData = (data: Indicator[]) => {
  const dataset = data.sort((a, b) => (a.date > b.date ? 1 : -1));
  return {
    indexes: dataset.map((data) => data.date),
    candle: dataset.map((data) => [data.open, data.close, data.low, data.high, data.pct_chg]),
    signal: dataset.map((data) => data.signal),
    upBound: dataset.map((data) => data.up_bound),
    downBound: dataset.map((data) => data.down_bound),
    adRatio: dataset.map((data) => data.ad_ratio),
    active: dataset.map((data) => data.active),
    buy: dataset.map((data) => data.buy),
    sell: dataset.map((data) => data.sell),
    zhangting_count: dataset.map((data) => data.zhangting_count),
    continuous_zhangting_stock_count: dataset.map((data) => data.continuous_zhangting_stock_count),
    max_continuous_zhangting_count: dataset.map((data) => data.max_continuous_zhangting_count),
  };
};

export const makeAreaData = (formatedData: ReturnType<typeof formatData>) => {
  return formatedData.active.reduce((acc: any[], value, index) => {
      if (value) {
        acc.push([
          { xAxis: index, itemStyle: { color: 'rgba(240, 128, 128, 0.3)' } },
          { xAxis: index + 1 },
        ]); // 更浅的浅红色
      } else {
        acc.push([
          { xAxis: index, itemStyle: { color: 'rgba(144, 238, 144, 0.3)' } },
          { xAxis: index + 1 },
        ]); // 更浅的浅绿色
      }
      return acc;
    }, []);
};

export const makeMarkPoint = (formatedData: ReturnType<typeof formatData>) => {
  const result: any[] = [];

  formatedData.buy.forEach((value, index) => {
    if (value) {
      result.push({
        name: '买入',
        xAxis: index,
        yAxis: formatedData.candle[index][2] as number - 14,
        symbol: 'triangle',
        symbolSize: 8,
        itemStyle: {
          color: constants.UP_COLOR,
        },
      });
    }
  });

  formatedData.sell.forEach((value, index) => {
    if (value) {
      result.push({
        name: '卖出',
        xAxis: index,
        yAxis: formatedData.candle[index][3] as number + 14,
        symbol: 'triangle',
        symbolRotate: 180, // 旋转三角形
        symbolSize: 8,
        itemStyle: {
          color: constants.DOWN_COLOR,
        },
      });
    }
  });

  return result;
};

export const getOptions = (data?: Indicator[]) => {
  const formatedData = formatData(data || []);
  const markAreaData = makeAreaData(formatedData);
  const markPointData = makeMarkPoint(formatedData);

  return {
    grid: [
      { bottom: '30%', top: '2%' }, // 第一个图表（K 线图）的布局
      { top: '73%', height: '20%' }, // 第二个图表（adRatioMA5 线图）的布局
    ],
    xAxis: [
      {
        gridIndex: 0,
        type: 'category',
        data: formatedData.indexes,
        show: false,
      },
      {
        gridIndex: 1,
        type: 'category',
        data: formatedData.indexes,
        show: true,
      },
    ],
    tooltip: {
      trigger: 'axis', // 当鼠标悬停时触发 tooltip 的方式，'axis' 表示基于坐标轴，'item' 表示基于图表元素
      axisPointer: {
        type: 'cross', // 十字准线指示器
      },
      formatter: (params: any) => {
        return ReactDomServer.renderToStaticMarkup(
          <div className="candlechart-tooltip">
            <div className="tooltip-date">{params[0].axisValueLabel}</div>
            {params.map((series: any) => {
              let value;
              if (series.seriesType === 'candlestick') {
                const valueType = ['开盘价', '收盘价', '最低价', '最高价', '涨跌幅'];
                value = (
                  <div className="tooltip-series-values">
                    {series.data.slice(1).map((v: number, idx: number) => {
                      return (
                        <div className="value-item" key={idx}>
                          <span className="name">{valueType[idx]}:</span>
                          {valueType[idx] === '涨跌幅' && (
                            <span
                              className={classNames('value', {
                                up: v >= 0,
                                down: v < 0,
                              })}
                            >
                              {v.toFixed(2)}%
                            </span>
                          )}
                          {valueType[idx] !== '涨跌幅' && <span className="value">{v}</span>}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (series.seriesName.includes('占比')) {
                value = (
                  <div className="tooltip-series-value">
                    {Array.isArray(series.value) ? series.value[1] : series.value}%
                  </div>
                );
              } else {
                value = <div className="tooltip-series-value">{series.value}</div>;
              }
              return (
                <div className="tooltip-series" key={series.seriesIndex}>
                  <div
                    className="tooltip-series-marker"
                    style={{ backgroundColor: series.color }}
                  />
                  <div className="tooltip-series-name">{series.seriesName}:</div>
                  {value}
                </div>
              );
            })}
          </div>
        );
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777',
      },
    },
    yAxis: [
      {
        gridIndex: 0,
        type: 'value',
        scale: true,
      },
      {
        gridIndex: 1,
        type: 'value',
        scale: true,
        axisLine: { show: false },
      },
      {
        gridIndex: 0,
        type: 'value',
        scale: true,
        axisLine: { show: false },
      },
    ],
    series: [
      {
        name: '日K线',
        type: 'candlestick',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: formatedData.candle,
        itemStyle: {
          color: constants.UP_COLOR,
          color0: constants.DOWN_COLOR,
        },
        markPoint: {
          data: markPointData,
       },
        markArea: {
          silent: true,
          data: markAreaData,
        },
      },
      // {
      //   name: '涨停数',
      //   type: 'line',
      //   xAxisIndex: 0,
      //   yAxisIndex: 2,
      //   symbol: 'none',
      //   data: formatedData.zhangting_count,
      // },
      // {
      //   name: '连板个股数',
      //   type: 'line',
      //   xAxisIndex: 0,
      //   yAxisIndex: 2,
      //   symbol: 'none',
      //   data: formatedData.continuous_zhangting_stock_count,
      // },
      // {
      //   name: '最大连板数',
      //   type: 'line',
      //   xAxisIndex: 0,
      //   yAxisIndex: 2,
      //   symbol: 'none',
      //   data: formatedData.max_continuous_zhangting_count,
      // },
      {
        name: 'Signal',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: formatedData.signal,
        symbol: 'none',
        markArea: {
          silent: true,
          data: markAreaData,
        },
      },
      // {
      //   name: 'Up Bound',
      //   type: 'line',
      //   xAxisIndex: 1,
      //   yAxisIndex: 1,
      //   data: formatedData.upBound,
      // },
      {
        name: 'Down Bound',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbol: 'none',
        data: formatedData.downBound,
      },
      {
        name: 'AD Ratio',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: formatedData.adRatio,
        itemStyle: {
          color: (params: any) => {
            const item = formatedData.adRatio[params.dataIndex];
            return item >= 0 ? constants.UP_COLOR : constants.DOWN_COLOR;
          },
        },
      },
    ],
  };
};
