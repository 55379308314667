import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';
import {
  createAccount,
  createAccountAmountRecord,
  createAccountTradeRecord,
  deleteAccount,
  deleteAccountAmountRecord,
  deleteAccountTradeRecord,
  fetchAccountDetail,
  fetchAccountList,
  updateAccount,
  updateAccountHistories,
} from './api';
import { Account, AccountAmountRecord, AccountTradeRecord } from './models';
import { createSlice } from '@reduxjs/toolkit';

export const fetchAccountListThunk = createAppAsyncThunk<Account[]>(
  'account/fetchAccountList',
  async () => {
    return (await fetchAccountList()).data;
  }
);

export const deleteAccountThunk = createAppAsyncThunk<Account, string>(
  'account/deleteAccount',
  async (id: string) => {
    return (await deleteAccount(id)).data;
  }
);

export const createAccountThunk = createAppAsyncThunk<Account, Account>(
  'account/createAccount',
  async (account: Account) => {
    return (await createAccount(account)).data;
  }
);

export const updateAccountThunk = createAppAsyncThunk<Account, Account>(
  'account/updateAccount',
  async (account: Account) => {
    return (await updateAccount(account)).data;
  }
);

export const fetchAccountDetailThunk = createAppAsyncThunk<Account, string>(
  'account/fetchAccountDetail',
  async (id: string) => {
    return (await fetchAccountDetail(id)).data;
  }
);

export const createAccountAmountRecordThunk = createAppAsyncThunk<
  AccountAmountRecord,
  AccountAmountRecord
>('account/createAccountAmountRecord', async (record: AccountAmountRecord) => {
  return (await createAccountAmountRecord(record)).data;
});

export const deleteAccountAmountRecordThunk = createAppAsyncThunk<
  AccountAmountRecord,
  { accountID: string; recordID: string }
>('account/deleteAccountAmountRecord', async ({ accountID, recordID }) => {
  return (await deleteAccountAmountRecord(accountID, recordID)).data;
});

export const createAccountTradeRecordThunk = createAppAsyncThunk<
  AccountTradeRecord,
  AccountTradeRecord
>('account/createAccountTradeRecord', async (record: AccountTradeRecord) => {
  return (await createAccountTradeRecord(record)).data;
});

export const deleteAccountTradeRecordThunk = createAppAsyncThunk<
  AccountTradeRecord,
  { accountID: string; recordID: string }
>('account/deleteAccountTradeRecord', async ({ accountID, recordID }) => {
  return (await deleteAccountTradeRecord(accountID, recordID)).data;
});

export const updateAccountHistoriesThunk = createAppAsyncThunk<Account, string>(
  'account/updateAccountHistories',
  async (id: string) => {
    return (await updateAccountHistories(id)).data;
  }
);

interface AccountState {
  accounts: Account[];
  details: Record<string, Account>;
}

const initialState: AccountState = {
  accounts: [],
  details: {},
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountListThunk.fulfilled, (state, action) => {
      state.accounts = action.payload;
    });
    builder.addCase(deleteAccountThunk.fulfilled, (state, action) => {
      state.accounts = state.accounts.filter((a) => a.id !== action.payload.id);
    });
    builder.addCase(createAccountThunk.fulfilled, (state, action) => {
      state.accounts.push(action.payload);
    });
    builder.addCase(updateAccountThunk.fulfilled, (state, action) => {
      const index = state.accounts.findIndex((a) => a.id === action.payload.id);
      state.accounts[index] = {
        ...state.accounts[index],
        ...action.payload,
      };
    });
    builder.addCase(fetchAccountDetailThunk.fulfilled, (state, action) => {
      state.details[action.meta.arg] = action.payload;
    });
    builder.addCase(createAccountAmountRecordThunk.fulfilled, (state, action) => {
      state.details[action.payload.account_id].amount_records.push(action.payload);
    });
    builder.addCase(deleteAccountAmountRecordThunk.fulfilled, (state, action) => {
      const account = state.details[action.meta.arg.accountID];
      account.amount_records = account.amount_records.filter((r) => r.id !== action.payload.id);
    });
    builder.addCase(createAccountTradeRecordThunk.fulfilled, (state, action) => {
      state.details[action.payload.account_id].trade_records.push(action.payload);
    });
    builder.addCase(deleteAccountTradeRecordThunk.fulfilled, (state, action) => {
      const account = state.details[action.meta.arg.accountID];
      account.trade_records = account.trade_records.filter((r) => r.id !== action.payload.id);
    });
    builder.addCase(updateAccountHistoriesThunk.fulfilled, (state, action) => {
      state.details[action.meta.arg] = action.payload;
    });
  },
});

export default accountSlice.reducer;
