import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-components';
import { deleteModelSpec, fetchModelSepcs, fetchModelSpecFormSpec } from 'ai/api';
import { AIModelSpec, AIModelTarget, ModelSpecFormSpec } from 'ai/models';
import { Button, Modal, Skeleton, Tag } from 'antd';
import Table from 'app/components/Table';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ModelList = () => {
  const tableRef = React.useRef<ActionType>();
  const [formSpec, setFormSpec] = useState<ModelSpecFormSpec>();
  useEffect(() => {
    fetchModelSpecFormSpec().then((result) => {
      setFormSpec(result.data);
    });
  }, []);

  if (!formSpec) {
    return <Skeleton />;
  }
  return (
    <WorkdeskLayout title="模型定义" pageName="ai-model-spec-list">
      <Table
        actionRef={tableRef}
        className="model-table"
        fetchAction={() => {
          return fetchModelSepcs();
        }}
        rowKey={(record) => record.id}
        useRedux={false}
        sortInLocal={true}
        options={false}
        pagination={{ pageSize: 10 }}
        search={false}
        toolBarRender={() => [
          <Button type="primary" href="/ai/model_specs/create">
            创建模型
          </Button>,
        ]}
        columns={[
          {
            title: '序号',
            render: (text, record, index) => index + 1,
            search: false,
            width: 50,
          },
          {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            search: false,
            render: (_, r) => (
              <Link to={`/ai/model_specs/${r.id}/detail`}>
                {r.name}
              </Link>
            ),
          },
          {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            search: false,
          },
          {
            title: '最新版本',
            search: false,
            render: (_, r) => {
              if (!r.latest_model) {
                return '-';
              }
              const latestModel = r.latest_model;

              let detail: Record<string, number> = {};
              if (Array.isArray(latestModel.detail)) {
                // 分别统计了0.8和0.9的指标
                detail = latestModel.detail.filter(d => d.threshold === 0.9)[0];
              } else {
                detail = latestModel.detail;
              }

              return (
                <>
                版本: {r.latest_model.version}
                <br />
                AUC: {detail.val_auc}
                <br />
                Precision: {(detail.val_pre / 100).toFixed(2)}
                <br />
                正例个数: {detail.val_positive_count}
                {/* <br />
                平均误判损失: {
                  detail.val_fp_loss ? detail.val_fp_loss.toFixed(2) : detail.val_loss.toFixed(2)
                } % */}
                <br />
                阈值: {detail.threshold}
                </>
              )
            },
            sorter: (a, b) => {
              // 比较detail的val_pre
              const getMetric = (modelSpec: AIModelSpec) => {
                if (!modelSpec.latest_model) {
                  return -1;
                }

                if (modelSpec.status !== 'ACTIVE') {
                  return -1;
                }

                const detail = modelSpec.latest_model.detail;

                if (Array.isArray(detail)) {
                  return detail.filter(d => d.threshold === 0.9)[0].val_pre;
                } else {
                  return detail.val_pre;
                }
              };

              return getMetric(a) - getMetric(b);
            },
            defaultSortOrder: 'descend',
          },
          {
            title: '近期表现',
            search: false,
            render: (_, r) => {
              if (!r.recent_stats.start_date) {
                return '-';
              }
              const detail = r.recent_stats.detail[0.9];
              return (
                <>
                近期统计:
                <br />
                {r.recent_stats.start_date} - {r.recent_stats.end_date}
                <br />
                AUC: {r.recent_stats.auc}
                <br />
                Precision: {detail.tp_pct}
                <br />
                正例个数: {detail.positive_count}
                <br />
                误判平均损失: {detail.fp_avg_loss} %
                </>
              )
            },
            sorter: (a, b) => {
              // 比较recent_stats.auc的值，如果没有recent_stats，则返回-1
              if (!a.recent_stats.start_date) {
                return -1;
              }
              if (!b.recent_stats.start_date) {
                return 1;
              }
              return a.recent_stats.detail[0.9].tp_pct - b.recent_stats.detail[0.9].tp_pct;
            },
          },
          {
            title: '版本数',
            dataIndex: 'model_count',
            key: 'model_count',
            search: false,
            render: (_, r) => (
              <Link to={`/ai/model_specs/${r.id}/detail`}>
                {r.model_count}
              </Link>
            ),
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            search: false,
            render: (_, r) => {
              if (r.status === 'ACTIVE') {
                return <Tag color="green">启用</Tag>;
              } else {
                return <Tag color="red">禁用</Tag>;
              }
            },
          },
          {
            title: '数据集',
            dataIndex: 'trainset',
            key: 'trainset',
            search: false,
            render: (_, r) => (
              <Link to={`/ai/trainsets/${r.trainset.id}/detail`}>
                {r.trainset.name}
              </Link>
            ),
          },
          {
            title: '目标',
            dataIndex: 'target',
            key: 'target',
            search: false,
            render: (_, r) => (
              <>
                {formSpec.target.map((target) => {
                  return (
                    <div>
                      {target.name}: {r.target?.[target.key as keyof AIModelTarget]}
                    </div>
                  );
                })}
              </>
            ),
          },
          {
            title: '参数',
            dataIndex: 'params',
            key: 'params',
            search: false,
            render: (_, r) => {
              const hyperParams = formSpec.params.find(
                (p) => p.base === r.params.base
              )?.hyper_params;
              if (!hyperParams) {
                return null;
              }
              return (
                <>
                  模型基础: {r.params.base}
                  <br />
                  超参:
                  <br />
                  {hyperParams.map((p) => {
                    return (
                      <div>
                        {p.name}: {r.params.hyper_params[p.key]}
                      </div>
                    );
                  })}
                </>
              );
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            search: false,
            render: (_, r) => new Date(r.created_at).toLocaleString(),
          },
          {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            search: false,
            render: (_, r) => (
              <>
                <Link to={`/ai/model_specs/${r.id}/edit`}>
                  <Button type="primary" size="small">
                    <EditOutlined />
                  </Button>
                </Link>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: `确认删除-${r.name}`,
                      content: '删除后不可恢复',
                      onOk: async () => {
                        const response = await deleteModelSpec(r.id);
                        if (response.isOK) {
                          tableRef.current?.reloadAndRest?.();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </>
            ),
          },
        ]}
      />
    </WorkdeskLayout>
  );
};

export default ModelList;
