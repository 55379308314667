import React, { FunctionComponent } from 'react';

import Table, { ColumnProps } from 'app/components/Table';
import moment from 'moment';
import { PredictAggregation } from 'ai/models';
import { fetchModelPredictsAggregation } from 'ai/api';
import AIModePredictCard from 'ai/components/ModelPredictCard';

import './index.scss';
import { StockDailyColumns } from 'stock/components/StockTableComponents';

const COLUMNS: ColumnProps<PredictAggregation>[] = [
  {
    title: '日期',
    dataIndex: 'date',
    render: (_, record) => moment(record.date).format('YYYY-MM-DD'),
    valueType: 'dateRange',
    sorter: (a, b) => a.date - b.date,
    initialValue: [moment().subtract(1, 'month'), moment()],
    defaultSortOrder: 'descend',
    width: 120,
    fixed: 'left',
  },
  {
    title: '预测',
    dataIndex: 'prob',
    render: (_, r) => {
      // probs根据ai_model.name排序
      r.probs.sort((a, b) => {
        if (a.ai_model.name > b.ai_model.name) {
          return 1;
        }
        if (a.ai_model.name < b.ai_model.name) {
          return -1;
        }
        return 0;
      });
      return (
        <div className="predicts">
          {r.probs.map((prob) => (
            <AIModePredictCard predict={prob} />
          ))}
        </div>
      );
    },
    // 根据模型预测数量排序, 或者是根据最大概率排序
    sorter: (a, b) =>
      a.probs.length !== b.probs.length
        ? a.probs.length - b.probs.length
        : a.probs[0].prob - b.probs[0].prob,
    initialValue: 0,
  },
];

interface Props {
  code?: string;
}

const StockPredictTable: FunctionComponent<Props> = ({ code }) => {
  return (
    <Table
      className="ai-modelpredict-aggregation-table"
      columns={COLUMNS}
      // data={data}
      fetchAction={(params) => {
        // 如果params中有filter, 则添加code到filter中, 否则添加filter
        // 默认展示近一个月的数据
        if (params['filter'] && code) {
          params['filter']['code'] = code;
        } else {
          params['filter'] = { code };
        }
        return fetchModelPredictsAggregation(params);
      }}
      paginationConfig={{ per_page: 10 }}
      useRedux={false}
      sortInLocal={true}
      options={false}
      rowKey={(record) => `${record.info.code}_${record.date}`}
    />
  );
};

export default StockPredictTable;