import { TablePaginationConfig } from 'antd/lib/table';
import { Pagination } from 'app/services/api';

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 20,
};

export function formatPagination(
  pagination?: Partial<Pagination>
): TablePaginationConfig | false {
  if (!pagination) return DEFAULT_PAGINATION;

  const result: TablePaginationConfig = {
    pageSize: pagination.per_page,
    showSizeChanger: false,
  };

  if (pagination.total_count) {
    result.total = pagination.total_count;
  }

  return result;
}
