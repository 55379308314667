import React, { FunctionComponent } from 'react';

import { ClassifyWatchingItem } from 'classify/models';
import Table, { ColumnProps } from 'app/components/Table';
import { AmountFormatter, AmountUnit } from 'market/utils';
import { Link } from 'react-router-dom';
import { getClassifyDetailRoute } from 'classify/utils';
import moment from 'moment';
import StockPriceChange from 'stock/components/StockPriceChange';

const COLUMNS: ColumnProps<ClassifyWatchingItem>[] = [
  {
    key: 'code',
    dataIndex: 'code',
    title: '板块',
    render: (_, r) => (
      <Link to={getClassifyDetailRoute(r.code)} target="_blank">
        {r.level ? `${r.name}[${r.level}级]` : r.name}
      </Link>
    ),
  },
  {
    key: 'count',
    dataIndex: 'count',
    title: '成分股数',
    sorter: (a, b) => a.count - b.count
  },
  // {
  //   key: 'strategy',
  //   dataIndex: 'strategy',
  //   title: '策略',
  // },
  {
    key: 'start_date',
    dataIndex: 'start_date',
    title: '关注起始',
    sorter: (a, b) => moment(a.start_date).unix() - moment(b.start_date).unix()
  },
  {
    key: 'total',
    dataIndex: 'total_mv',
    title: '总市值',
    sorter: (a, b) => a.total_mv - b.total_mv,
    render: (_, r) =>
      new AmountFormatter(r.total_mv, AmountUnit.Wan).parseTo(AmountUnit.HundredMillion),
  },
  {
    key: 'free_mv',
    dataIndex: 'free_mv',
    title: '自由流通市值',
    sorter: (a, b) => a.free_mv - b.free_mv,
    render: (_, r) =>
      new AmountFormatter(r.free_mv, AmountUnit.Wan).parseTo(AmountUnit.HundredMillion),
  },
  {
    key: 'amount',
    dataIndex: 'amount',
    title: '成交额',
    sorter: (a, b) => a.amount - b.amount,
    render: (_, r) =>
      new AmountFormatter(r.amount, AmountUnit.Wan).parseTo(AmountUnit.HundredMillion),
  },
  {
    key: 'major_net_buy',
    dataIndex: 'major_net_buy',
    title: '主力净买',
    sorter: (a, b) => a.major_net_buy - b.major_net_buy,
    render: (_, r) => {
      const className = r.major_net_buy > 0 ? 'value-up' : 'value-down';
      return (
        <span className={className}>
          {new AmountFormatter(r.major_net_buy, AmountUnit.Wan).parseTo(AmountUnit.HundredMillion)}
        </span>
      );
    },
  },
  {
    key: 'chg_latest',
    dataIndex: 'chg_latest',
    title: '涨跌幅',
    sorter: (a, b) => a.chg_latest - b.chg_latest,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.chg_latest} />;
    },
  },
  {
    key: 'rs',
    dataIndex: 'rs',
    title: '相对强度',
    sorter: (a, b) => a.rs - b.rs,
    render: (_, r) => r.rs.toFixed(2),
  },
  {
    key: 'rs_signal',
    dataIndex: 'rs_signal',
    title: 'RS信号',
    sorter: (a, b) => a.rs_signal - b.rs_signal,
    render: (_, r) => r.rs_signal,
    defaultSortOrder: 'descend'
  },
  {
    key: 'chg_5',
    dataIndex: 'chg_5',
    title: '5日涨跌幅',
    sorter: (a, b) => a.chg_5 - b.chg_5,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.chg_5} />;
    },
  },
  {
    key: 'chg_10',
    dataIndex: 'chg_10',
    title: '10日涨跌幅',
    sorter: (a, b) => a.chg_10 - b.chg_10,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.chg_10} />;
    },
  },
  {
    key: 'chg_20',
    dataIndex: 'chg_20',
    title: '20日涨跌幅',
    sorter: (a, b) => a.chg_20 - b.chg_20,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.chg_20} />;
    },
  },
];

const ClassifyWatchingTable: FunctionComponent<{ data: ClassifyWatchingItem[] }> = ({ data }) => {
  return (
    <Table
      rowKey="code"
      columns={COLUMNS}
      dataSource={data}
      search={false}
      toolBarRender={false}
      paginationConfig={{ per_page: 10 }}
    />
  );
};

export default ClassifyWatchingTable;
