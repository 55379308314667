import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StockGroupViewerProps } from '.';
import { StockGroupItem } from './models';
import { fetchStockDataThunk, initGroup, StockGroupViewerState, viewGroupItem } from './slice';
import { makeGroupItemKey } from './utils';

export const useStockGroupViewerState = <T extends StockGroupItem>(
  group: StockGroupViewerProps<T>['group'],
  curKey?: string
) => {
  const dispatch = useContextDispatch();
  const state = useSelector((state: RootState) => state.analysis.groupViewer);

  useEffect(() => {
    dispatch(initGroup({ group, curKey }));
    let curIdx = 0;
    if (curKey) {
      curIdx = group.findIndex((x: StockGroupItem) => makeGroupItemKey(x) === curKey);
    }
    const groupItem = group[curIdx];

    if (curKey && !(curKey in state.dataset)) {
      dispatch(fetchStockDataThunk({ groupItem, idx: curIdx }));
    }
  }, [dispatch, group, curKey]);

  const setNextItem = (nextIdx: StockGroupViewerState['curIdx']) => {
    if (nextIdx < 0) {
      return;
    }

    const nextItem = group[nextIdx];
    const itemKey = makeGroupItemKey(nextItem);

    if (!(itemKey in state.dataset)) {
      dispatch(fetchStockDataThunk({ groupItem: nextItem, idx: nextIdx }));
    } else {
      dispatch(viewGroupItem(nextIdx));
    }
  };

  const prevStock = () => {
    const prevIdx = state.curIdx - 1;
    setNextItem(prevIdx);
  };

  const nextStock = () => {
    const nextIdx = state.curIdx + 1;
    setNextItem(nextIdx);
  };

  return {
    state,
    actions: {
      prevStock,
      nextStock,
    },
  };
};
