import React from 'react';
import ReactDomServer from 'react-dom/server';
import { StockCandleData } from 'stock/models';
import * as constants from 'stock/components/CandleChart/constants';
import {
  CandlestickSeriesOption,
  EChartsOption,
  MarkPointComponentOption,
  SeriesOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from "echarts";

import { EMA, SMA } from 'technicalindicators';
import { Indicator, makeIndicators } from 'stock/components/CandleChart/indicators';
import { ChartComp } from 'app/components/MQCharts';
import moment from 'moment';
import { StockData, StockGroupItem } from './models';
import { AmountFormatter, AmountUnit } from 'market/utils';
import classNames from 'classnames';
import { Signal, SignalType } from 'signal/models';
import { makeSignals } from '../CandleChart/utils';

const TOTAL_HEIGHT = 600;
const COMP_HEIGHT = 100;
const DATA_ZOOM_HEIGHT = 100;


export interface ChartOptions {
  dataZoomStart?: number; // K线图显示多少天
}

export interface FormatedData {
  indexes: StockCandleData['date'][],
  values: any[],
  volumes: [string, number, number][],
  amount: [string, number, number][],
  majorPct: [string, number, number][],
}

export const formatData = (stockData: StockData): FormatedData => {
  const { daily, tickAnalysis } = stockData;

  return {
    indexes: daily.map(_data => _data.date),
    values: daily.map(_data => [_data.open, _data.close, _data.low, _data.high, _data.pct_chg]),
    volumes: daily.map((_data, idx) => [_data.date, _data.vol, _data.open >= _data.close ? -1 : 1]),
    amount: daily.map((_data, idx) => [_data.date, _data.amount, _data.open >= _data.close ? -1 : 1]),
    majorPct: daily.map(_data => {
      const _taData = tickAnalysis.find(ta => ta.date === _data.date);

      // 可能有脏数据
      return [_data.date, _taData ? _taData.components.major_pct : 0]
    }),
  }
};

const makeMA = (data: FormatedData, maConfig: number[]): SeriesOption[] => {
  const closeData = data.values.map(d => d[2]);

  return maConfig.map(ma => {
    const maData = SMA.calculate({ period: ma, values: closeData }).map((v) => v.toFixed(2));
    const fillData = Array(closeData.length - maData.length).fill('-');

    return {
      symbol: 'none',
      name: `MA ${ma}`,
      type: 'line',
      data: fillData.concat(maData),
      smooth: true,
      lineStyle: {
        opacity: 0.8,
        width: 1,
      },
    }
  });
};

const makeBaseCandle = (
  data: FormatedData,
  maConfig?: number[],
  markIdx?: number,
  signals?: Signal[]
): ChartComp => {
  let series: SeriesOption[] = [];

  const xAxis = {
    data: data.indexes,
    scale: true,
    boundaryGap: false,
    axisLine: { onZero: false },
    axisTick: { show: false },
    splitLine: { show: false },
    // axisLabel: {show: false},
    splitNumber: 20,
    min: 'dataMin',
    max: 'dataMax',
    axisPointer: {
      z: 100,
    },
  };

  const yAxis = {
    scale: true,
    splitArea: {
      show: true,
    },
  };

  const baseCandle: CandlestickSeriesOption = {
    name: '日K线',
    type: 'candlestick',
    data: data.values,
    itemStyle: {
      color: constants.UP_COLOR,
      color0: constants.DOWN_COLOR,
    },
  };

  if (markIdx) {
    const y = data.values[markIdx][2];
    baseCandle['markLine'] = {
      data: [
        {
          symbol: 'none',
          lineStyle: {
            type: 'solid',
            color: 'rgba(255, 173, 177, 0.5)',
            width: 10,
          },
          label: {
            position: 'end',
            formatter: '信号发生',
            fontSize: 14,
            color: constants.UP_COLOR,
            fontWeight: 'bolder',
          },
          xAxis: markIdx,
        },
      ],
    };
    // baseCandle["markArea"] = {
    //   itemStyle: {
    //     color:'rgba(255, 173, 177, 0.4)',
    //   },
    //   data: [
    //     [
    //       {
    //         xAxis: markIdx - 1,
    //       },
    //       {
    //         xAxis: markIdx + 1,
    //       },
    //     ],
    //   ],
    // };
    // baseCandle['markPoint'] = {
    //   data: [
    //     {
    //       name: '信号发生点',
    //       symbol: 'arrow',
    //       symbolSize: 40,
    //       symbolOffset: [0, '20%'],
    //       coord: [markIdx, y],
    //       value: '信号',
    //       label: {
    //         fontSize: 12,
    //         color: '#FFF',
    //       },
    //       itemStyle: {
    //         color: constants.UP_COLOR,
    //       }
    //     }
    //   ]
    // }
  }

  if (signals) {
    baseCandle['markPoint'] = {
      data: makeSignals(data, signals),
    };
  }

  series.push(baseCandle as SeriesOption);

  if (maConfig && maConfig.length > 0) {
    series = series.concat(makeMA(data, maConfig));
  }

  return {
    xAxis,
    yAxis,
    series,
  };
};

const makeGrid = (option: EChartsOption) => {
  const result = [];

  const otherCompCount = (option.xAxis as XAXisComponentOption[]).length - 1;

  const gridCommon = {
    left: '3%',
    right: '3%',
  };

  const baseCandleHeight = TOTAL_HEIGHT - (otherCompCount * COMP_HEIGHT) - DATA_ZOOM_HEIGHT;

  const baseCandleGrid = {
    ...gridCommon,
    height: `${baseCandleHeight - 20}`,
  };

  result.push(baseCandleGrid);

  Array(otherCompCount).fill(null).forEach((_, idx) => {
    result.push({
      ...gridCommon,
      height: `${COMP_HEIGHT}`,
      top: `${baseCandleHeight + idx * COMP_HEIGHT + 60}`,
    })
  })

  return result;
};

const makeDataZoom = (option: EChartsOption, middle: number, start: number = 40) => {
  const xAxisIndex = (option.xAxis as XAXisComponentOption[]).map((_, idx) => idx);

  return [
    {
      type: "inside",
      xAxisIndex,
      start: middle - start,
      end: middle + 35,
    },
    {
      show: true,
      xAxisIndex,
      type: "slider",
      top: 560,
      start: middle - start,
      end: middle + 35,
    },
  ];
};

export const getOptions = (data: StockData, occurredAt: StockGroupItem['occurredAt'], chartOptions?: ChartOptions) => {
  if (!data) {
    return null;
  }

  const signals = data.signals?.items;

  let result: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      position: (pos, params, el, elRect, size) => {
        if ((pos[0] < size.viewSize[0] / 2)) {
          return {
            'right': 30,
            'top': 10,
          };
        } else {
          return {
            'left': 30,
            'top': 10,
          };
        }
      },
      formatter: (params: any) => {
        return ReactDomServer.renderToStaticMarkup(<div className='candlechart-tooltip'>
          <div className='tooltip-date'>{params[0].axisValueLabel}</div>
          {params.map((series: any) => {
            let value;
            if (series.seriesName === '成交额') {
              value = (
                <div className="tooltip-series-value">
                  {new AmountFormatter(series.value[1], AmountUnit.Thounsand).parseTo(AmountUnit.HundredMillion)}
                </div>
              );
            } else if (series.seriesType === 'candlestick') {
              const valueType = ['开盘价', '收盘价', '最低价', '最高价', '涨跌幅'];
              value = (
                <div className="tooltip-series-values">
                  {series.data.slice(1).map((v: number, idx: number) => {
                    return (
                      <div className="value-item" key={idx}>
                        <span className="name">{valueType[idx]}:</span>
                        {valueType[idx] === '涨跌幅' && (
                          <span
                            className={classNames('value', {
                              up: v >= 0,
                              down: v < 0,
                            })}
                          >
                            {v.toFixed(2)}%
                          </span>
                        )}
                        {valueType[idx] !== '涨跌幅' && <span className="value">{v}</span>}
                      </div>
                    );
                  })}
                </div>
              );
            } else if (series.seriesName.includes('占比')) {
              value = (
                <div className="tooltip-series-value">
                  {Array.isArray(series.value) ? series.value[1] : series.value}%
                </div>
              );
            } else {
              value = <div className="tooltip-series-value">{series.value}</div>;
            }
            return (
              <div className="tooltip-series" key={series.seriesIndex}>
                <div className="tooltip-series-marker" style={{ backgroundColor: series.color }} />
                <div className="tooltip-series-name">{series.seriesName}:</div>
                {value}
              </div>
            );
          })}
        </div>);
      }
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777'
      }
    },
    xAxis: [],
    yAxis: [],
    series: [],
    visualMap: [],
  };

  const formatedData = formatData(data);
  let comps: ChartComp[] = [];

  // 需要标记的x轴位置
  const x = moment(occurredAt).format(constants.STOCK_DATE_FORMAT);
  const markIdx = formatedData.indexes.findIndex(v => v === x);
  comps.push(makeBaseCandle(formatedData, [5, 20, 60, 120, 250], markIdx, signals));
  const indicatorComps = makeIndicators(formatedData, [Indicator.AMOUNT, Indicator.MAJOR_TREND]);

  if (indicatorComps) {
    comps = comps.concat(indicatorComps);
  }

  comps.forEach(comp => {
    (result.xAxis as XAXisComponentOption[]).push(comp.xAxis);
    (result.yAxis as YAXisComponentOption[]).push(comp.yAxis);
    result.series = (result.series as SeriesOption[]).concat(comp.series);
    if (comp.visualMap) {
      result.visualMap = (result.visualMap as VisualMapComponentOption[]).concat(
        comp.visualMap as VisualMapComponentOption
      );
    }
  });

  return {
    animation: false,
    ...result,
    dataZoom: makeDataZoom(
      result,
      Math.round((markIdx / formatedData.indexes.length) * 100),
      chartOptions?.dataZoomStart
    ),
    grid: makeGrid(result),
  };
}

export const makeGroupItemKey = (groupItem: StockGroupItem) => {
  return `${groupItem.code}_${groupItem.occurredAt}`;
}

export const parseKeyToGroupItem = (itemKey: string) => {
  const [code, occurredAt] = itemKey.split('_');
  return { code, occurredAt };
};