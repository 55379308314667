import React, { useRef } from 'react';
import classnames from 'classnames';

import {
  EChartsOption,
  SeriesOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from "echarts";

import { useEcharts } from './hooks';
import './index.scss';

export interface MQChartsProps {
  className?: string,
  options: EChartsOption,
  eventHandler?: Function,
}

export interface ChartComp {
  xAxis: XAXisComponentOption,
  yAxis: YAXisComponentOption,
  series: SeriesOption[] | SeriesOption,
  visualMap?: VisualMapComponentOption[],
}

export default function MQCharts(props: MQChartsProps) {
  const { options, className, eventHandler } = props;

  const domRef = useRef(null);
  useEcharts(domRef, options, eventHandler);

  return <div className={classnames('mq-charts', className)} ref={domRef} />;
}