import React, { FunctionComponent, useEffect } from 'react';
import { PageHeaderProps } from '@ant-design/pro-components';
import { PageHeader as AntPageHeader } from '@ant-design/pro-components';

import './index.scss';

interface Props extends PageHeaderProps {
  titleText?: string;
}

const PageHeader: FunctionComponent<Props> = (props) => {
  useEffect(() => {
    document.title = props.titleText || (props.title as string);
  }, [props.title, props.titleText]);

  return <AntPageHeader {...props} className="mq-page-header" />;
};

export default PageHeader;
