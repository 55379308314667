import React, { FunctionComponent } from 'react';

import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import CollectionTab from 'collection/components/CollectionTab';

import './index.scss';

const List: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName='collection-management' title='自选管理'>
      <CollectionTab />
    </WorkdeskLayout>
  )
};

export default List;