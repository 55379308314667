import { ProForm, ProFormItem, ProFormText, ProFormTextArea } from '@ant-design/pro-components';
import { CreateTrainsetParams, createTrainset, fetchTrainset, updateTrainset } from 'ai/api';
import { Button, Skeleton } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FeatureSelector from 'feature/components/FeatureSelector';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface TrainsetFormProps {
  mode: 'CREATE' | 'EDIT';
  id?: string;
}

const TrainsetForm: FunctionComponent<TrainsetFormProps> = ({ mode, id }) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [initialValues, setInitialValues] = React.useState<CreateTrainsetParams>();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }
      const result = (await fetchTrainset(id)).data;
      result.feature_ids = result.features.map((feature) => feature.id);
      setInitialValues(result);
    };
    if (mode === 'EDIT' && id) {
      init();
    }
  }, [mode, id]);

  if (mode === 'EDIT' && initialValues === undefined) {
    return <Skeleton />;
  }

  return (
    <ProForm<CreateTrainsetParams>
      initialValues={initialValues}
      formRef={formRef}
      submitter={{
        render: (_, dom) => {
          return [
            <Button
              type="primary"
              onClick={() => {
                formRef.current?.submit();
              }}
            >
              提交
            </Button>,
            <Button>
              <Link to="/ai/trainsets">取消</Link>
            </Button>
          ];
        }
      }}
      onFinish={async (values) => {
        let result = null;
        if (mode === 'CREATE') {
          result = await createTrainset(values);
        }
        if (mode === 'EDIT' && id) {
          result = await updateTrainset(id, values);
        }
        if (result && result.isOK) {
          history.push('/ai/trainsets');
        }
      }}
    >
      <ProFormText name="name" label="名称" />
      <ProFormTextArea name="description" label="描述" />
      <ProFormItem name="feature_ids" label="特征">
        <FeatureSelector
          onChange={(selectedIds) => {
            formRef.current?.setFieldsValue({ feature_ids: selectedIds });
          }}
          selectedIds={initialValues?.feature_ids}
        />
      </ProFormItem>
    </ProForm>
  );
};

export default TrainsetForm;
