import { fetchPredictsRangeStatistic } from 'ai/api';
import { AIModelPredictRangeCount } from 'ai/models';
import { Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { predictRangeCountChartOptions } from './options';
import { fetchIndicators } from 'market/api';
import { Indicator } from 'market/models';

interface RangeStatisticProps {
  count?: number;
}

/**
 * 区间统计
 */
const RangeStatistic: FunctionComponent<RangeStatisticProps> = ({ count = 60}) => {
  const [rangeStatistic, setRangeStatistic] = useState<AIModelPredictRangeCount>();
  const [marketIndicators, setMarketIndicators] = useState<Indicator[]>();

  useEffect(() => {
    Promise.all([fetchPredictsRangeStatistic({ count }), fetchIndicators({ count })]).then(([rangeStatistic, indicators]) => {
      setRangeStatistic(rangeStatistic.data);
      setMarketIndicators(indicators.data);
    });
  }, [count]);

  if (!rangeStatistic || !marketIndicators) {
    return <Skeleton active />;
  }

  return (
    <div className="range-statistic">
      <MQCharts
        options={predictRangeCountChartOptions(rangeStatistic, marketIndicators)}
        className="predict-range-count-chart"
      />
    </div>
  );
}

export default RangeStatistic;