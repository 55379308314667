import { fetchModelPredicts } from 'ai/api';
import { AIModel } from 'ai/models';
import Table from 'app/components/Table';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';

interface Props {
  modelId: AIModel['id'];
}

const PredictsTable: FunctionComponent<Props> = ({ modelId }) => {
  return (
    <Table
      fetchAction={(params) => {
        // 可能是版本不对，本地和远程不同
        // params.filter.date如果存在且是对象，则需要moment实例化后转成字符串
        if (params.filter?.date && typeof params.filter.date === 'object') {
          params.filter.date = params.filter.date
            .map((d: Moment) => moment(d).format('YYYY-MM-DD'))
            .join(',');
        }
        return fetchModelPredicts(modelId, params);
      }}
      paginationConfig={{ per_page: 10}}
      useRedux={false}
      options={false}
      columns={[
        {
          title: '序号',
          render: (text, record, index) => index + 1,
          search: false,
          width: 50,
        },
        {
          title: '日期',
          dataIndex: 'date',
          key: 'date',
          valueType: 'dateRange',
          render: (_, r) => moment(r.date).format('YYYY-MM-DD'),
        },
        {
          title: '股票',
          dataIndex: 'code',
        },
        {
          title: '预测概率',
          dataIndex: 'prob',
          key: 'prob',
          render: (_, r) => r.prob.toFixed(3),
        },
        {
          title: '最大涨幅',
          dataIndex: 'max_chg',
          key: 'max_chg',
          search: false,
        },
        {
          title: '收盘卖出',
          dataIndex: 'loss',
          key: 'loss',
          search: false,
        },
        {
          title: '预测结果',
          dataIndex: 'label',
          key: 'label',
          search: false,
        },
      ]}
    />
  )
};

export default PredictsTable;