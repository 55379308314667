import { Account } from 'account/models';
import Card from 'antd/es/card/Card';
import MQCharts from 'app/components/MQCharts';
import React from 'react';
import { getHistoriesAmountChartOptions, getHistoriesNetValueChartOptions } from './historiesChartOptions';
import Tabs from 'antd/lib/tabs';

interface Props {
  histories: Account['histories'];
}

const AccountHistories: React.FC<Props> = ({ histories }) => {
  return (
    <Card title="历史记录" className="account-histories" size="small">
      <Tabs>
        <Tabs.TabPane tab="净值" key="net_value">
          <MQCharts
            className="account-histories-chart"
            options={getHistoriesNetValueChartOptions(histories)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="总资产" key="amount">
          <MQCharts
            className="account-histories-chart"
            options={getHistoriesAmountChartOptions(histories)}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default AccountHistories;
