/**
 * 行业板块链接
 * source: THS
 * type: I
 * level: 3
 */
import React, { FunctionComponent } from 'react';
import { Classify } from 'classify/models';
import { Link } from 'react-router-dom';


type Props = Pick<Classify, 'code' | 'name'>;

const IndustrySectorLink: FunctionComponent<Props> = ({ code, name}) => {
  const url = `/classify/ths/${code}`;
  return <Link to={url} target='_blank'>{name}</Link>
}

export default IndustrySectorLink;