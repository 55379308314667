import React, { FunctionComponent } from 'react';

import { Modal, Form, Checkbox, List, Row } from 'antd';

import { Collection } from 'collection/models';
import { useCollectionOptions, useSelectCollectionModal } from './hooks';

interface SelectCollectionsModalProps {
  visible: boolean,
  actions: ReturnType<typeof useSelectCollectionModal>['actions'],
}

const SelectCollectionsModal: FunctionComponent<SelectCollectionsModalProps> = (props) => {
  const { visible, actions } = props;
  const [form] = Form.useForm();
  const collectionOptions = useCollectionOptions();

  return (
    <Modal
      title='选择自选分组'
      visible={visible}
      okText='确定'
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            actions.addToCollections(values.collections as Array<Collection['id']>);
            actions.close();
          })
      }}
      cancelText='取消'
      onCancel={actions.close}
    >
      <Form
        form={form}
        layout="vertical"
        name="new-collection-form"
      >
        <Form.Item
          name="collections"
        >
          <Checkbox.Group>
            <List
              dataSource={collectionOptions}
              renderItem={(item) => {
                return (
                  <Row>
                    <Checkbox
                      value={item.value}
                    > {item.label}</Checkbox>
                  </Row>
                );
              }}
            />
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectCollectionsModal;