import React, { FunctionComponent, useState } from 'react';
import AIModelPredictAggTable from './AggregationTable';
import Tabs from 'antd/lib/tabs';
import MQCharts from 'app/components/MQCharts';
import { predictsConceptChartOptions, predictsIndustryChartOptions, predictsLimitChartOptions } from './options';
import { PredictAggregation } from 'ai/models';
import { ECharts } from 'echarts';

/**
 * 按天的预测记录
 */
const DailyPredicts: FunctionComponent<{}> = () => {
  const [predicts, setPredicts] = useState<PredictAggregation[]>([]);

  const eventHandler = (chart: ECharts) => {
    chart.off('click');
    chart.on('click', 'series', (params) => {
      if (params.seriesType === 'bar') {
        const code = (params.data as { code: string }).code;
        if (code) {
          window.open(`/classify/ths/${code}/latest`, 'blank');
        }
      }
    });
  };
  return (
    <div className='daily-predicts'>
      <Tabs defaultActiveKey="table">
        <Tabs.TabPane tab="详情" key="table">
          <AIModelPredictAggTable setData={setPredicts} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="概念" key="concept">
          <MQCharts
            options={predictsConceptChartOptions(predicts, 30)}
            className="predicts-classify-chart"
            eventHandler={eventHandler}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="行业" key="industry">
          <MQCharts
            options={predictsIndustryChartOptions(predicts, 30)}
            className="predicts-classify-chart"
            eventHandler={eventHandler}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="周期" key="limit">
          <MQCharts
            options={predictsLimitChartOptions(predicts)}
            className="predicts-limit-chart"
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default DailyPredicts;