import React, { FunctionComponent, useMemo } from 'react';
import AIModelPredictAggTable, { COLUMNS } from 'ai/pages/PredictAggregation/AggregationTable';
import { SIGNAL_COLUMN } from 'signal/pages/List';
import { TableState } from 'app/components/Table/hooks';
import { fetchAccountSignals } from 'account/api';
import { StockDailyColumns } from 'stock/components/StockTableComponents';

const AccountSignalsTable: FunctionComponent<{ accountID: string }> = ({ accountID }) => {
  const columns = useMemo(() => {
    const columns = SIGNAL_COLUMN.concat(COLUMNS, StockDailyColumns as any);
    // 移除所有的defaultSortOrder
    columns.forEach((column) => {
      delete column.defaultSortOrder;
    });
    // 找到日期列，设置默认排序
    const dateColumn = columns.find((column) => column.dataIndex === 'date');
    if (dateColumn) {
      dateColumn.defaultSortOrder = 'descend';
    }
    return columns;
  }, []);
  return (
    <AIModelPredictAggTable
      tableProps={{
        columns,
        search: false,
      }}
      apiFunction={(params: TableState) => {
        return fetchAccountSignals({
          ...params,
          accountID: accountID,
        });
      }}
    />
  );
};

export default AccountSignalsTable;
