import { Tabs } from 'antd';
import React from 'react';
import StockLimitDetail from './StockLimitDetail';
import StockLimitDailyStat from './StockLimitDailyStat';
import StockLimitTrender from './StockLimitTrender';
import StockLimitRangeStat from './StockLimitRangeStat';

const StockLimit = () => {
  return (
    <Tabs>
      <Tabs.TabPane key="1" tab="当日涨停板">
        <StockLimitDetail />
        <StockLimitDailyStat />
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab="涨停板趋势">
        <StockLimitTrender count={60} />
        <StockLimitRangeStat />
      </Tabs.TabPane>
    </Tabs>
  );
};
export default StockLimit;
