import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import { RootState } from 'app/store';
import SignalTable from 'collection/components/SignalTable';

import { Tabs } from 'antd';
import StockTable from 'collection/components/StockTable';
import CollectionPane from 'collection/components/CollectionTab/CollectionPane';
import { useCollectionsData } from 'collection/hooks';

import {
  StarOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

const { TabPane } = Tabs;

interface DashboardProps {}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const collectionSignalState = useSelector((state: RootState) => state.collection.signals);
  const collections = useCollectionsData();
  const location = useLocation();
  const history = useHistory();

  const activeTab = (location.hash && location.hash.split('#')[1]) || 'signal';

  const setTab = useCallback((tabKey) => {
    history.push(`${location.pathname}#${tabKey}`);
  }, []);

  return (
    <WorkdeskLayout pageName='collection-dashboard' title='自选概览'>
      <Tabs type="card" activeKey={activeTab} onChange={setTab}>
        <TabPane tab="信号" key="signal">
          <SignalTable state={collectionSignalState} />
        </TabPane>
        <TabPane tab="全部" key="all">
          <StockTable />
        </TabPane>
        {collections.map(collection => {
          const tab = (<span>
            <StarOutlined />
            {collection.name}
          </span>);
          return (
            <TabPane key={collection.name} tab={tab} closable={false}>
              <CollectionPane collection={collection} management={false} />
            </TabPane>
          )
        })}
      </Tabs>
    </WorkdeskLayout>
  );
};

export default Dashboard;