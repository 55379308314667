import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

import { StockCandleData } from 'stock/models';

import './index.scss';

interface StockPriceChangeProps {
  price_chg?: StockCandleData['price_chg'];
  pct_chg: StockCandleData['pct_chg'];
}

const StockPriceChange: FunctionComponent<StockPriceChangeProps> = ({ price_chg, pct_chg }) => {
  if (!pct_chg || typeof pct_chg === 'string') {
    pct_chg = 0;
  }

  const _className = classNames(
    'stock-price-change',
    pct_chg > 0 ? 'price-up' : pct_chg === 0 ? 'price-hold' : 'price-down'
  );

  return (
    <span className={_className}>
      {price_chg && (
        `${price_chg}(${pct_chg.toFixed(2)}%)`
      )}
      {!price_chg && (
        `${pct_chg.toFixed(2)}%`
      )}
    </span>
  )
};

export default StockPriceChange;