import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { defaultConfig } from "app/services/api";
import Axios from "axios";
import { fetchAnalysisResult } from "./api";
import { ActiveStep, AnalysisResult, Group, ResultSet } from "./models";
import { formatRawInput } from "./utils";

export const GROUP_SIZE = [10, 20, 50];

export interface CrossComparisonState {
  activeStep: ActiveStep;
  resultSet: ResultSet;
  group: Group;
  resultInfo?: AnalysisResult;
  groupItemKey?: string;
}

const initialState: CrossComparisonState = {
  activeStep: ActiveStep.ComparsionData,
  resultSet: [],
  group: {
    groupIdx: 0,
    selectedKeys: [],
    groupSize: GROUP_SIZE[0],
    hasPrev: false,
    hasNext: false,
    dataset: {},
  },
};

const fetchResultsetThunk = createAsyncThunk(
  "crossComparison/fetchResultset",
  async (resultID: string) => {
    const resultInfo = (await fetchAnalysisResult(resultID)).data;

    const resultSet = (await Axios.get(resultInfo.url, defaultConfig)).data;

    return { resultInfo, rawResultset: resultSet };
  }
);

const crossComparisonSlice = createSlice({
  name: "analysis/crossComparison",
  initialState,
  reducers: {
    setGroup: (state, { payload }) => {
      state.group = {
        ...state.group,
        ...payload,
      };
    },
    closeStockDetail: (state) => {
      state.activeStep = ActiveStep.ComparsionData;
      delete state['groupItemKey'];
    },
    startGroupViewer: (state, { payload }) => {
      state.activeStep = ActiveStep.StockDetail;
      state.groupItemKey = payload;
    }
  },
  extraReducers: (build) => {
    build.addCase(fetchResultsetThunk.fulfilled, (state, { payload }) => {
      const resultSet = formatRawInput(payload.rawResultset);
      if (resultSet.length > 0) {
        state.resultInfo = payload.resultInfo;
        state.resultSet = resultSet;
        state.activeStep = ActiveStep.ComparsionData;
      } else {
        message.error("分析范围不能为空");
      }
    });
  },
});

export { fetchResultsetThunk };
export const { setGroup, closeStockDetail, startGroupViewer } = crossComparisonSlice.actions;

export default crossComparisonSlice.reducer;
