import React, { FunctionComponent, useEffect, useMemo } from 'react';

import { Classify } from 'classify/models';
import MQCharts from 'app/components/MQCharts';
import { useContextDispatch } from 'app/hooks';
import { fetchTHSDailyIndicatorsThunk } from 'classify/slice/detail';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { Skeleton } from 'antd';
import { getOptions } from './options';

import './index.scss';

const ClassifyDailyIndicatorChart: FunctionComponent<{ code: Classify['code'] }> = ({ code }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(
      fetchTHSDailyIndicatorsThunk({ code, dateRange: { count: 200 }, includeStragtegy: true })
    );
  }, [dispatch, code]);

  const indicators = useSelector((state: RootState) => state.classify.details[code].indicators);
  const strategy = useSelector((state: RootState) => state.classify.details[code].strategy);

  const options = useMemo(() => getOptions(indicators, strategy), [indicators, strategy]);

  if (!indicators || !options) {
    return <Skeleton active />;
  }


  return (
    <div className='classify-daily-indicator'>
      <MQCharts options={options} className='classify-daily-indicator-chart' />
    </div>
  )
};

export default ClassifyDailyIndicatorChart;