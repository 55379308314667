import React from 'react';
import { Tabs } from 'antd';

import { useCollectionsData, useTabState } from 'collection/hooks';
import CollectionPane from './CollectionPane';
import NewCollectionModal from 'collection/components/NewCollectionModal';

import './index.scss';
import { useModalState } from 'app/hooks';

const { TabPane } = Tabs;

export default function CollectionTab() {
  const collections = useCollectionsData();

  const newCollectionState = useModalState();
  const tabState = useTabState(collections, newCollectionState);

  return (
    <>
      <Tabs
        className='collections-tabs'
        type="editable-card"
        size="large"
        activeKey={tabState.activeKey}
        onChange={tabState.actions.activate}
        onEdit={tabState.actions.edit}
      >
        {collections.map(collection => (
          <TabPane key={collection.id} tab={collection.name} closable={false}>
            <CollectionPane collection={collection} backToFirst={tabState.actions.backToFirst} />
          </TabPane>
        ))}
      </Tabs>
      <NewCollectionModal
        visible={newCollectionState.visiable}
        close={newCollectionState.actions.close}
      />
    </>
  );
}