import { ResultItem, ResultSet } from "./models";

/**
 * 格式化原始输入
 * csv格式的字符串变为对象数组ResultSet
 *
 * @param source
 * @param delimiter
 * @returns
 */
export const formatRawInput = (source: String, delimiter = ","): ResultSet => {
  const rows = source.split('\n').filter(x => x.length > 0);

  const resultSet = rows.map(row => {
    const parts = row.split(',');
    let positive = undefined;
    if (parts.length === 4) {
      positive = parts.pop();
    }
    const [code, name, occurredAt] = parts;

    return {
      code,
      name,
      occurredAt,
      positive: positive === undefined ? positive : positive === "True" || positive === "1",
    };
  });

  return resultSet;
};

export const findResultItem = (
  resultSet: ResultSet,
  resultKey: string
): ResultItem => {
  const [code, occurredAt] = resultKey.split("_");
  const resultItem = resultSet.find(
    (x) => x.code === code && x.occurredAt === occurredAt
  );
  return resultItem || { code, occurredAt, name: "_" };
};

export const makeResultItemKey = ({code, occurredAt}: ResultItem): string => {
  return `${code}_${occurredAt}`;
};