import TrainsetForm from 'ai/components/TrainsetForm';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React from 'react';

const CreateTrainset = () => {
  return (
    <WorkdeskLayout pageName="create-trainset" title="创建训练集">
      <TrainsetForm mode='CREATE' />
    </WorkdeskLayout>
  );
};

export default CreateTrainset;
