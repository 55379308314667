export interface AuthUserProps {
  userid: string,
  username: string,
  token: string,
  is_admin: boolean;
}

const STORAGE_KEY = 'auth_user';

export const remember = ({ userid, username, token }: AuthUserProps) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ userid, username, token }));
}

export const logout = () => {
  localStorage.removeItem(STORAGE_KEY);
}

export const isRemember = () => {
  return !!localStorage.getItem(STORAGE_KEY);
}

export function getAuthUser(): AuthUserProps | null {
  const userStr = localStorage.getItem(STORAGE_KEY);
  return userStr ? JSON.parse(userStr) : null;
}