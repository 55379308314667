import React, { FunctionComponent } from 'react';
import { Tag } from 'antd';
import { Signal, SignalType } from 'signal/models';

// 买入标签，支持text和children，如果没有children，就显示text
export const BuyTag: FunctionComponent<{ text?: string }> = ({ text = '买入', children }) => (
  <Tag color="#f50">{children || text}</Tag>
);


// 卖出标签，支持text和children，如果没有children，就显示text
export const SellTag: FunctionComponent<{ text?: string }> = ({ text = '卖出', children }) => (
  <Tag color="#87d068">{children || text}</Tag>
);

export const SignalTag: FunctionComponent<{ signal: Pick<Signal, 'type' | 'strategy'> }> = ({
  signal,
}) => <Tag color={signal.type === SignalType.BUY ? '#F50' : '#87d068'}>{signal.strategy.name}</Tag>;
