import { Card, Skeleton, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import {
  ClassifyHit,
  ClassifyTopType,
  ClassifyTopTypeText,
  THSClassifyType,
} from 'classify/models';
import { fetchLatestTHSTopListThunk } from './slice';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StockPriceChange from 'stock/components/StockPriceChange';

import './index.scss';
import { AmountFormatter, AmountUnit } from 'market/utils';
import WatchingTag from '../WatchingTag';

interface TopHitTableProps {
  data: ClassifyHit[];
  topType: ClassifyTopType;
}

const TopHitTableColumns: ColumnProps<ClassifyHit>[] = [
  {
    title: '板块名',
    dataIndex: 'name',
    width: '20%',
    render: (_, r) => (
      <Link to={`/classify/ths/${r['code']}`} target="_blank">
        {r.level ? `${r.name}[${r.level}级]` : r.name}
        <WatchingTag item={r.is_watching} />
      </Link>
    ),
  },
  {
    title: '成分数',
    dataIndex: 'count',
    render: (_, r) => (
      <Link to={`/classify/ths/${r['code']}/members`} target="_blank">
        {r['member_count']}
      </Link>
    ),
  },
  // {
  //   title: '信号数',
  //   dataIndex: 'signal_count',
  //   render: (_, r) => {
  //     if (r['signal_count'] === 0) return '-';
  //     return (
  //       <Link to={`/classify/ths/${r['code']}/members`} target="_blank">
  //         {r['signal_count']}
  //       </Link>
  //     );
  //   },
  // },
  {
    title: '涨跌幅',
    dataIndex: 'pct_chg',
    render: (_, r) => <StockPriceChange pct_chg={r['pct_chg']} />,
  },
  {
    title: '相对强度',
    dataIndex: 'rs',
    render: (_, r) => r.rs.toFixed(2),
  },
  {
    title: '成交额',
    dataIndex: 'amount',
    render: (_, r) => {
      return new AmountFormatter(r['amount'], AmountUnit.Wan).parseTo(AmountUnit.HundredMillion);
    },
  },
  {
    title: '主力净买',
    dataIndex: 'major_net_buy',
    render: (_, r) => {
      const className = r['major_net_buy'] > 0 ? 'value-up' : 'value-down';
      return (
        <span className={className}>
          {new AmountFormatter(r['major_net_buy'], AmountUnit.Wan).parseTo(
            AmountUnit.HundredMillion
          )}
        </span>
      );
    },
  },
  // {
  //   title: '大单占比',
  //   render: (_, r) => {
  //     return `${(r['major_pct'] * 100).toFixed(2)}%`;
  //   },
  // },
  {
    title: '涨停',
    render: (_, r) => {
      const pct = r['zhangting_pct'];
      if (!pct) {
        return '-';
      }
      const count = Math.round(pct * r['member_count']);
      return (
        <span className="value-up">
          {count}({(pct * 100).toFixed(1)}%)
        </span>
      );
    },
  },
  {
    title: '跌停',
    render: (_, r) => {
      const pct = r['dieting_pct'];
      if (!pct) {
        return '-';
      }
      const count = Math.round(pct * r['member_count']);
      return (
        <span className="value-down">
          {count}({(pct * 100).toFixed(1)}%)
        </span>
      );
    },
  },
];

const TopListTable: FunctionComponent<TopHitTableProps> = ({ data, topType }) => {
  return (
    <Table
      rowKey="code"
      columns={TopHitTableColumns}
      dataSource={data}
      size="small"
      pagination={false}
    />
  );
};

const THSTopList: FunctionComponent<{ topType?: ClassifyTopType }> = ({
  topType = ClassifyTopType.HIT,
}) => {
  const dispatch = useContextDispatch();
  const data = useSelector((state: RootState) => state.classify.thsLatestTop[topType]);

  useEffect(() => {
    dispatch(fetchLatestTHSTopListThunk({ type: topType }));
  }, [dispatch, topType]);

  if (!data || data['I'].length === 0) {
    return <Skeleton active />;
  }

  return (
    <Card
      title={`同花顺板块-${ClassifyTopTypeText[topType]}-前十: ${data['I'][0].date}`}
      className="ths-top-list"
      size="small"
    >
      {(Object.keys(THSClassifyType) as Array<keyof typeof THSClassifyType>).map((type) => {
        return (
          <div key={type} className="ths-type">
            <Card
              key={type}
              title={THSClassifyType[type as keyof typeof THSClassifyType]}
              className={classNames('top-list-card')}
              size="small"
            >
              <TopListTable key={type} data={data[type]} topType={topType} />
            </Card>
          </div>
        );
      })}
    </Card>
  );
};

export default THSTopList;
