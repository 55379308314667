import { Card, Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import { fetchIndicators } from 'market/api';
import { Indicator } from 'market/models';
import { CompareStatistic } from 'market/utils';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getOptions } from './chartOptions';

import './index.scss';

interface IndicatorDashboardProps {
  count: number;
}

const IndicatorDashboard: FunctionComponent<IndicatorDashboardProps> = ({ count }) => {
  const [indicators, setIndicators] = useState<Indicator[]>([]);

  useEffect(() => {
    fetchIndicators({ count }).then((res) => {
      setIndicators(res.data);
    });
  }, [count]);

  if (indicators.length === 0) {
    return <Skeleton active />;
  }

  const latestIndicator = indicators[indicators.length - 1];
  const preIndicator = indicators[indicators.length - 2];

  return (
    <div className="dashboard-section indicator">
      <Card title={`情绪:${latestIndicator.date}`} className="latest" size="small">
        <CompareStatistic
          a={latestIndicator.ad_ratio as number}
          b={preIndicator.ad_ratio as number}
          title="涨跌比[-1, 1]"
        />
        <CompareStatistic
          a={latestIndicator.position as number}
          b={preIndicator.position as number}
          title='建议仓位(%)'
        />
        <CompareStatistic
          a={latestIndicator.zhangting_count as number}
          b={preIndicator.zhangting_count as number}
          title="涨停个股数"
        />
        <CompareStatistic
          title="连板个股数"
          a={latestIndicator.continuous_zhangting_stock_count as number}
          b={preIndicator.continuous_zhangting_stock_count as number}
        />
        <CompareStatistic
          a={latestIndicator.max_continuous_zhangting_count as number}
          b={preIndicator.max_continuous_zhangting_count as number}
          title="最大涨停数"
        />
        <CompareStatistic
          a={latestIndicator.increase_pct_median as number}
          b={preIndicator.increase_pct_median as number}
          title="涨幅中位数(%)"
        />
        <CompareStatistic
          a={latestIndicator.major_net_buy  as number}
          b={preIndicator.major_net_buy as number}
          title="主力净买(亿)"
        />
      </Card>
      <Card title="趋势" className="trender" size="small">
        <MQCharts options={getOptions(indicators)} className="indicator-trender-chart" />
      </Card>
    </div>
  );
};

export default IndicatorDashboard;
