import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ClassifyHit, ClassifyTopType, THSClassifyType, THSTopList } from 'classify/models';
import * as classifyApi from 'classify/api';
import { DataWithState } from 'app/models';

export type TrenderData = Record<string, ClassifyHit[]>;
export type StatisticItem = Pick<ClassifyHit, 'code' | 'name' | 'is_watching' | 'level'> & { times: number };
export type StatisticData = Record<string, Array<StatisticItem>>;

export type THSTopTrenderData = {
  trender: Record<keyof typeof ClassifyTopType, Record<keyof typeof THSClassifyType, TrenderData>>;
  statistic: Record<
    keyof typeof ClassifyTopType,
    Record<keyof typeof THSClassifyType, StatisticData>
  >;
};

const initialState: DataWithState<THSTopTrenderData> = {
  loaded: false,
};

export const fetchTHSTopTrenderThunk = createAsyncThunk(
  'classify/fetchTHSTopTrenderList',
  async (params?: classifyApi.ClassifyTopListParams) => {
    return (await classifyApi.fetchTHSTopList(params)).data;
  }
);

const formatTrenderData = (data: THSTopList, topType: ClassifyTopType) => {
  const grouped: THSTopTrenderData['trender'][keyof typeof ClassifyTopType] = {
    N: {},
    I: {},
  };

  const rankField = topType.toLowerCase() as keyof ClassifyHit;

  ['N', 'I'].forEach((type) => {
    const _data = data.filter((item) => item.type === type);
    const dates = Array.from(new Set(_data.map((item) => item.date))).sort((a, b) => (a > b ? 1 : -1));

    // 检查前N天是否出现过
    const checkNewRange = 9;

    dates.forEach((date, idx) => {
      const _dateData = _data
        .filter((item) => item.date === date)
        .sort((a, b) => ((a[rankField] as number) - (b[rankField] as number))).reverse();

        _dateData.forEach((item) => {
          item.new = true; // 默认为新条目
          item.repeat = 0; // 默认没出现过
          const checkRange = Math.min(checkNewRange, idx);

          if (idx >= 1) {
            for (let i = 1; i <= checkRange; i++) {
              const preDate = dates[idx - i];
              const preData = _data.filter((d) => d.date === preDate);
              if (preData.find((d) => d.code === item.code)) {
                item.new = false; // 发现在前N天内出现过
                if (item.repeat === 0) {
                  item.repeat = 2; // 第二次出现
                } else {
                  item.repeat += 1;
                }
              }
            }
          }
        });
      grouped[type as 'I' | 'N'][date] = _dateData;
    });
  });

  return grouped;
};

const formatStatisticData = (data: THSTopTrenderData['trender'][keyof typeof ClassifyTopType]) => {
  const result: THSTopTrenderData['statistic'][keyof typeof ClassifyTopType] = {
    N: {},
    I: {},
  };

  Object.keys(THSClassifyType).forEach((type) => {
    const _data = data[type as keyof typeof THSClassifyType];
    const _result: Record<
      string,
      Record<string, { name: string; times: number; is_watching: ClassifyHit['is_watching']; level: ClassifyHit['level'] }>
    > = {
      20: {}, // 近20个交易日
      10: {}, // 近10个交易日
      5: {}, // 近5个交易日
    };

    Object.keys(_data).forEach((date, idx) => {
      _data[date].forEach((item) => {
        if (!(item.code in _result[20])) {
          _result[20][item.code] = { name: item.name, times: 1, is_watching: item.is_watching, level: item.level };
        } else {
          _result[20][item.code].times += 1;
        }

        if (idx >= 10) {
          if (!(item.code in _result[10])) {
            _result[10][item.code] = { name: item.name, times: 1, is_watching: item.is_watching, level: item.level };
          } else {
            _result[10][item.code].times += 1;
          }
        }

        if (idx >= 15) {
          if (!(item.code in _result[5])) {
            _result[5][item.code] = { name: item.name, times: 1, is_watching: item.is_watching, level: item.level };
          } else {
            _result[5][item.code].times += 1;
          }
        }
      });
    });

    const statisticData: StatisticData = {};

    Object.keys(_result).forEach((c) => {
      statisticData[c] = Object.keys(_result[c])
        .map((code) => {
          return {
            ..._result[c][code],
            code,
          };
        })
        .sort((a, b) => (a.times > b.times ? -1 : 1));
    });

    result[type as keyof typeof THSClassifyType] = statisticData;
  });

  return result;
};

const slice = createSlice({
  name: 'classify/thsTopTrender',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTHSTopTrenderThunk.fulfilled, (state, { payload, meta }) => {
      const topType = meta.arg?.type || ClassifyTopType.HIT;

      const trenderData = formatTrenderData(payload, topType);
      if (!state.data) {
        state.data = {
          trender: {
            HIT: {
              N: {},
              I: {},
            },
            SCORE: {
              N: {},
              I: {},
            },
            RS: {
              N: {},
              I: {},
            }
          },
          statistic: {
            HIT: {
              N: {},
              I: {},
            },
            SCORE: {
              N: {},
              I: {},
            },
            RS: {
              N: {},
              I: {},
            }
          },
        };
      }
      state.data.trender[topType] = trenderData;
      state.data.statistic[topType] = formatStatisticData(trenderData);
      state.loaded = true;
    });
  },
});

export default slice.reducer;
