import { SignalType } from 'signal/models';
import { Stock, StockWithDailyDetail } from 'stock/models';
import { RSDailyData } from 'strategy/models';

// 板块数据来源
export enum ClassifySource {
  // 同花顺
  THS = 'THS',
  // 申万
  SW = 'SW',
}

export const ClassifySourceText = {
  [ClassifySource.THS]: '同花顺',
  [ClassifySource.SW]: '申万',
};
// 同花顺板块分类
export enum THSClassifyType {
  I = '行业板块', // 行业板块
  N = '概念板块', // 概念板块
}

export interface WatchingSignal {
    signal_type: SignalType;
    signal_date: string;
}

// 板块
export interface Classify {
  code: string;
  name: string;
  member_count: number;
  source: ClassifySource;
  type: keyof typeof THSClassifyType;
  members: Stock['code'][];
  level?: number;
  rs: number;
  rs_signal: number;
  is_watching: WatchingSignal;
}

export interface ClassifyDailyIndicator {
  date: string;
  hit_rank: number; // 活跃度排名
  hit: number; // 活跃度
  score_rank: number; // 表现排名
  score: number; // 表现分
  dieting_pct: number; // 跌停占比
  zhangting_pct: number; // 涨停占比
  major_pct: number; // 大单占比
  turnover_rate_f: number; // 自由流通换手率
  pct_chg: number; // 平均涨跌幅
  zhangting_count: number; // 涨停数量
  dieting_count: number; // 跌停数量
  signal_count: number; // 信号个数
  open: number; // 开盘
  close: number; // 收盘
  low: number; // 最低
  high: number; // 最高
  total_mv: number; // 总市值
  free_mv: number; // 自由流通市值
  amount: number; // 成交额
  major_net_buy: number; // 主力净流入
  rs: number; //相对强度
  rs_signal: number; //相对强度信号
}

// 板块热度
export type ClassifyHit = Pick<Classify, 'code' | 'name' | 'member_count' | 'type' | 'is_watching' | 'level'> &
  ClassifyDailyIndicator & {
    hit: number;
    hit_rank: number;
    score: number;
    score_rank: number;
    new: boolean;
    repeat: number;
  };

// 同花顺板块热度榜
export type THSTopListData = {
  [key in keyof typeof THSClassifyType]: ClassifyHit[];
};

export type THSTopList = ClassifyHit[];

export type Member = StockWithDailyDetail;
export type Members = Member[];

export interface ClassifyDetail {
  info: Classify;
  latest: ClassifyDailyIndicator;
  members?: Members;
  indicators?: ClassifyDailyIndicator[];
  strategy?: ClassifyStrategyData;
}

export enum ClassifyTopType {
  HIT = 'HIT',
  SCORE = 'SCORE',
  RS = 'RS',
  RS_SIGNAL = 'RS_SIGNAL',
}

export const ClassifyTopTypeText = {
  [ClassifyTopType.HIT]: '活跃度',
  [ClassifyTopType.SCORE]: '整体表现',
  [ClassifyTopType.RS]: '相对强度',
  [ClassifyTopType.RS_SIGNAL]: '相对强度信号线',
};

export type ClassifySignal = Pick<Classify, 'code' | 'name' | 'level'> & {
  date: string;
  strategy: string;
  type: SignalType;
};

export type ClassifyWatchingItem = Pick<Classify, 'code' | 'name' | 'level'> &
  Pick<
    ClassifyDailyIndicator,
    'total_mv' | 'free_mv' | 'amount' | 'major_net_buy' | 'rs' | 'rs_signal'
  > & {
    count: Classify['member_count'];
    strategy: ClassifySignal['strategy'];
    start_date: ClassifySignal['date'];
    chg_latest: number; // 最近涨跌幅
    chg_5: number; // 5日涨跌幅
    chg_10: number; // 10日涨跌幅
    chg_20: number; // 20日涨跌幅
  };

export interface ClassifyStrategyData {
  rs: RSDailyData[];
}
