import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DataWithState } from 'app/models';
import { DateRangeParams } from 'app/services/api';
import { fetchStockLimitRangeStat } from './api';
import { StockLimitRangeStat } from './model';

export const fetchStockLimitRangeStatThunk = createAsyncThunk(
  'stocks/fetchLimitRangeStat',
  async (params?: DateRangeParams) => {
    return (await fetchStockLimitRangeStat(params)).data;
  }
);

export type StockLimitRangeStatState = DataWithState<StockLimitRangeStat>;

const initialState: StockLimitRangeStatState = {
  loaded: false,
};

export const StockLimitRangeStatSlice = createSlice({
  name: 'stocks/limitRangeStat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStockLimitRangeStatThunk.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.loaded = true;
    });
  },
});

export default StockLimitRangeStatSlice.reducer;