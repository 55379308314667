import { ProCard } from '@ant-design/pro-components';
import { fetchModelDetail } from 'ai/api';
import { AIModel } from 'ai/models';
import { Skeleton } from 'antd';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './index.scss';
import PredictsTable from './PredictsTable';

const ModelDetail = () => {
  const modelId = useParams<{ id: string }>().id;
  const [modelDetail, setModelDetail] = useState<AIModel>();
  useEffect(() => {
    fetchModelDetail(modelId).then((result) => {
      setModelDetail(result.data);
      window.document.title = `模型详情-${result.data.name}`;
    });
  }, [modelId]);

  if (!modelDetail) {
    return <Skeleton active />;
  }

  const specDetail = modelDetail.spec;
  const detail = Array.isArray(modelDetail.detail)
    ? modelDetail.detail.filter((d) => d.threshold === 0.9)[0]
    : modelDetail.detail;
  return (
    <SinglePageLayout pageName="ai-model-detail">
      <h1>
        模型详情 - {modelDetail.name} - {modelDetail.version}
      </h1>
      <ProCard size="small" title="信息" bordered className="model-info">
        <ProCard size="small" title="基本信息" bordered>
          名称:<br/>
          {modelDetail.name}<br/>
          版本:<br/>
          {modelDetail.version}<br/>
          创建日期:<br/>
          {moment(modelDetail.created_at).format('YYYY-MM-DD HH:mm:ss')}
        </ProCard>
        <ProCard size="small" title="定义信息" bordered>
          目标:<br/>
          期限-{modelDetail.spec.target.time_limit} 收益目标-{modelDetail.spec.target.chg_pct}%<br/>
          <br/>
          模型:<br/>
          基础: {specDetail.params.base}
          <br />
          {
            <>
              超参:
              <br />
              {
                // 遍历specDetail.params.hyper_params
                Object.entries(specDetail.params.hyper_params).map(([key, value]) => {
                  return (
                    <div>
                      {key}: {value}
                    </div>
                  );
                })
              }
            </>
          }
        </ProCard>
        <ProCard size="small" title="详情" bordered>
          {
            // 遍历modelDetail.detail

            Object.entries(detail).map(([key, value]) => {
              return (
                <div>
                  {key}: {value}
                </div>
              );
            })
          }
        </ProCard>
      </ProCard>
      <ProCard size="small" title="预测记录">
        <PredictsTable modelId={modelId} />
      </ProCard>
    </SinglePageLayout>
  );
};

export default ModelDetail;
