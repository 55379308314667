import React, { FunctionComponent } from 'react';
import MQCharts from '../MQCharts';
import KChartSetting from './Setting';
import { useKChartHook } from './hooks';
import { KChartConfig } from './models';

import './index.scss';

export interface KChartProps {
  config?: KChartConfig;
}

const KChart: FunctionComponent<KChartProps> = ({ config }) => {
  const { id, state, actions, options } = useKChartHook(config);

  return (
    <div className="k-chart" key={id}>
      <KChartSetting />
      {options && <MQCharts options={options} className="k-chart-instance" />}
    </div>
  );
};

export default KChart;
