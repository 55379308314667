import React, { FunctionComponent, useCallback } from 'react';

import { Card, Space, Button } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { clearBrowserHistory, removeBrowserItem } from './slice';
import StockTable from 'stock/components/StockTable'
import AddToCollection from 'collection/components/AddToCollection';
import { StockWithDailyDetail } from 'stock/models';
import { useContextDispatch } from 'app/hooks';

const BrowserHistory: FunctionComponent<{}> = (props) => {
  const history = useSelector((state: RootState) => state.browserHistory);
  const dispatch = useContextDispatch();
  const clearHistory = useCallback(() => {
    dispatch(clearBrowserHistory());
  }, []);

  const removeItem = useCallback((code) => {
    dispatch(removeBrowserItem(code));
  }, []);

  const actionRender = useCallback(
    (text: any, record: StockWithDailyDetail) => (
      <Space size="middle">
        <AddToCollection code={record.info.code} size="small" />
        <Button
          danger
          type="primary"
          size="small"
          onClick={() => {
            removeItem(record.info.code);
          }}
        >
          删除
        </Button>
      </Space>
    ),
    []
  );

  return (
    <Card
      title="浏览历史"
      className="browser-history"
      extra={
        <Button danger type='primary' onClick={clearHistory}>
          清空历史
        </Button>
      }
    >
      <StockTable codes={history} actionRender={actionRender} />
    </Card>
  );
};

export default BrowserHistory;
