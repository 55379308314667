import React from 'react';
import ModelSpecForm from 'ai/components/ModelSpecForm';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import { useParams } from 'react-router-dom';

const EditModelSpec = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <WorkdeskLayout pageName="update-model-spec" title="编辑模型定义">
      <ModelSpecForm mode="EDIT" id={id} />
    </WorkdeskLayout>
  );
};

export default EditModelSpec;
