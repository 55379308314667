import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'app/config';
import { getAuthUser } from 'auth/service';
import { BaseModel, ModelField } from 'app/models';
import { message } from 'antd';
export interface ResponseProps {
  code: number;
  status: 'OK' | 'ERROR';
  msg: string;
  data: object | null;
  meta: ModelField[] | [];
}

export interface ApiProps {
  endpoint: string;
  method?: AxiosRequestConfig['method'];
  data?: Record<string, any>;
  options?: object;
  headers?: AxiosRequestConfig['headers'];
}

export interface Pagination {
  page: number;
  per_page: number;
  next_page: number;
  total_count: number;
  total_pages: number;
}

export interface DataWithPagination<T> {
  items: Array<T>;
  pagination?: Pagination;
}

export interface PaginationParams {
  page?: number;
  per_page?: number;
}

export interface DateRangeParams {
  start?: string;
  end?: string;
  count?: number;
}

export class ApiResult<T extends BaseModel> {
  msg: string;
  data: T;
  isOK: boolean;
  meta: ModelField[];

  constructor({ status, msg, data, meta }: ResponseProps) {
    this.isOK = status === 'OK';
    this.data = this.processData(data);
    this.msg = msg;
    this.meta = meta;
  }

  processData = (data: any) => {
    return data;
  };
}

/**
 * 将一个嵌套对象展平为查询字符串的形式
 * @param obj 要展平的对象
 * @param result 存储结果的 URLSearchParams 实例，默认为空
 * @param prefix 当前对象在嵌套结构中的键名前缀，用于构造展平后的键名，默认为空
 * @returns 展平后的查询字符串
 */
const flatObjectToQueryString = (obj: Record<string, any>, result: URLSearchParams = new URLSearchParams(), prefix: string = ''): string => {
  for (const key in obj) {
    const value = obj[key];

    // 忽略 undefined 和 null 值
    if (value === undefined || value === null) {
      continue;
    }

    // 构造展平后的键名
    const flatKey = prefix ? `${prefix}_${key}` : key;

    // 如果值是对象，递归展平嵌套的对象
    if (typeof value === 'object' && !Array.isArray(value)) {
      flatObjectToQueryString(value, result, flatKey);
    } else {
      // 否则，添加展平后的键值对
      result.append(flatKey, String(value));
    }
  }

  return result.toString();
}

export type FetchApiFunc<T extends BaseModel> = (params?: any) => Promise<ApiResult<T>>;

const baseUrl = config.isDev ? 'http://localhost:8080' : 'https://api.morequant.com';

const _fetch = axios;

export const defaultConfig: AxiosRequestConfig = {
  baseURL: baseUrl,
  adapter: require('axios/lib/adapters/http'),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export default async function callApi<T extends BaseModel>({
  endpoint,
  data,
  options = {},
  method = 'GET',
}: ApiProps): Promise<ApiResult<T>> {
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      } else {
        if (typeof data[key] === 'boolean') {
          data[key] = data[key] ? 1 : 0;
        }
      }
    });
  }

  if (method === 'GET' && data) {
    endpoint += '?' + flatObjectToQueryString(data);
  }

  try {
    const params: AxiosRequestConfig = Object.assign(defaultConfig, {
      url: endpoint,
      data: data,
      method: method,
    });

    const authUser = getAuthUser();

    if (authUser) {
      params.headers['Authorization'] = `Bearer ${authUser.token}`;
    }

    const response: AxiosResponse = await _fetch(params);
    const result = new ApiResult<T>(response.data);
    if (result.isOK && result.msg) {
      message.success(result.msg);
    }
    return result;
  } catch (e) {
    if (e.response) {
      // http code非200
      const errorMsg = `${e.response.data.msg || '服务器错误，调用接口失败'}, 错误码： ${
        e.response.data.code
      }`;
      message.error(errorMsg);
      return new ApiResult(e.response.data);
    } else {
      // Like Network Error
      message.error(`服务器错误，调用接口失败, 错误码: 500`);
      return new ApiResult({
        status: 'ERROR',
        msg: e.message,
        code: 500,
        data: null,
        meta: [],
      });
    }
  }
}
