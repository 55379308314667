import { combineReducers } from '@reduxjs/toolkit';
import thsLatestTopReducer from 'classify/components/THSTopList/slice';
import thsTopTrenderReducer from 'classify/components/THSTopTrender/slice';
import thsLatestSignals from 'classify/components/THSLatestSignals/slice';
import detailReducer from './detail';

export default combineReducers({
  thsLatestTop: thsLatestTopReducer,
  thsTopTrender: thsTopTrenderReducer,
  thsLatestSignals: thsLatestSignals,
  details: detailReducer,
});
