import callApi, { ApiResult } from "app/services/api";
import { AnalysisResult, Group } from "./models";

interface fetchComparsionDataParams {
  forward?: Number; // 往前，天
  backward?: Number; // 往后, 天
  range_data: String[][]; // 对比区间
}


export const fetchComparsionData = async function (params: fetchComparsionDataParams) {
  return await callApi({ endpoint: '/stocks/comparison', method: 'POST', data: params }) as ApiResult<Group['dataset']>;
}

export const fetchAnalysisResult = async function (resultID: string) {
  return (await callApi({
    endpoint: `/analysis/result/${resultID}`,
    method: "GET",
  })) as ApiResult<AnalysisResult>;
};