import React from "react";
import { ColumnProps } from "app/components/Table";
import { StockDailyDetail } from "stock/models";
import StockPriceChange from "stock/components/StockPriceChange";
import { AmountFormatter, AmountUnit } from "market/utils";
import { Tag } from "antd";
import { SignalTag } from "signal/components/TypeTag";

export const StockDailyColumns: ColumnProps<StockDailyDetail>[] = [{
    title: '涨跌幅',
    key: 'detail.chg_pct',
    dataIndex: 'detail.chg_pct',
    width: 100,
    render: (_, record) => {
      return (
        <StockPriceChange
          pct_chg={record.detail.chg_pct}
        />
      );
    },
    sorter: true,
    search: false,
  },
  {
    key: 'zhangting',
    title: '涨停情况',
    sorter: false,
    render: (_, r) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {r.detail.zhangting_count > 1 && <Tag color="red">{r.detail.zhangting_count}连板</Tag>}
          {r.detail.zhangting_10 > 0 && <Tag color="red">10天{r.detail.zhangting_10}板</Tag>}
          {r.detail.zhangting_20 > 0 && <Tag color="red">20天{r.detail.zhangting_20}板</Tag>}
        </div>
      );
    },
    search: false,
  },
  {
    key: 'chg_5',
    dataIndex: 'detail.close_chg_5',
    title: '5日涨跌幅',
    sorter: true,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.detail.close_chg_5} />;
    },
    search: false,
  },
  {
    key: 'chg_10',
    dataIndex: 'detail.close_chg_10',
    title: '10日涨跌幅',
    sorter: true,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.detail.close_chg_10} />;
    },
    search: false,
  },
  {
    key: 'chg_20',
    dataIndex: 'detail.close_chg_20',
    title: '20日涨跌幅',
    sorter: true,
    render: (_, r) => {
      return <StockPriceChange pct_chg={r.detail.close_chg_20} />;
    },
    search: false,
  },
  {
    title: '自由流通市值',
    dataIndex: 'detail.free_mv_yi',
    sorter: true,
    render: (_, r) =>
      new AmountFormatter(r.detail.free_mv_yi, AmountUnit.HundredMillion).toString(),
    search: false,
  },
  {
    title: '成交额',
    key: 'detail.amount',
    dataIndex: 'detail.amount',
    render: (_, r) =>
      r.detail.amount_yi
        ? new AmountFormatter(r.detail.amount_yi, AmountUnit.HundredMillion).toString()
        : '数据缺失',
    sorter: (a, b) => a.detail.amount_yi - b.detail.amount_yi,
    search: false,
  },
  {
    title: '成交额排名',
    key: 'detail.amount_rank',
    dataIndex: 'detail.amount_rank',
    render: (_, r) => {
      if (r.detail.amount_rank <= 50) {
        return <Tag color="red">{r.detail.amount_rank}</Tag>;
      } else {
        return <Tag>{r.detail.amount_rank}</Tag>;
      }
    },
    sorter: (a, b) => a.detail.amount_rank - b.detail.amount_rank,
    search: false,
  },
  {
    title: '主力净买',
    key: 'detail.major_net_buy',
    dataIndex: 'detail.major_net_buy',
    render: (_, r) => {
      const value = new AmountFormatter(r.detail.major_net_buy, AmountUnit.HundredMillion).toString();
      if (r.detail.major_net_buy > 0) {
        return <Tag color="red">{value}</Tag>;
      }
      return <Tag color='green'>{value}</Tag>;
    },
    sorter: (a, b) => a.detail.major_net_buy - b.detail.major_net_buy,
    search: false,
  },
  {
    title: '自由流通换手',
    key: 'detail.turnover_rate_f',
    dataIndex: 'detail.turnover_rate_f',
    render: (_, r) =>
      r.detail.tr_f
        ? `${r.detail.tr_f.toFixed(2)}%`
        : '数据缺失',
    sorter: (a, b) => a.detail.tr_f - b.detail.tr_f,
    search: false,
  },
  {
    title: '大单占比',
    key: 'detail.major_pct',
    dataIndex: 'detail.major_pct',
    render: (_, record) => {
      return (record.detail.major_buy_pct !== null && record.detail.major_buy_pct !== "")
        ? `${record.detail.major_buy_pct.toFixed(2)}%`
        : '数据缺失';
    },
    sorter: (a, b) => a.detail.major_buy_pct - b.detail.major_buy_pct,
    search: false,
  },
  {
    title: '信号',
    key: 'detail.signals',
    dataIndex: 'detail.signals',
    render: (_, record) => {
      return record.detail.signals.map((s) => {
        return (
          <SignalTag key={s.strategy.id} signal={s} />
        );
      });
    },
  }
];
