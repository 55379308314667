import React from 'react';

import { Result, Button } from 'antd';

import SinglePageLayout from 'app/layouts/SinglePageLayout';
import { history } from 'app/routes';


export default function NotFound() {
  return (
    <SinglePageLayout pageName='not-found'>
      <Result
        style={{margin: 'auto'}}
        status="404"
        title="404"
        subTitle="找不到页面"
        extra={
          <Button type="primary" onClick={history.goBack}>
            返回
        </Button>
        }
      />
    </SinglePageLayout>
  );
}