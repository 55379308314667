import React from 'react';
import { AccountAmountRecord } from 'account/models';
import {
  FormInstance,
  ModalForm,
  ModalFormProps,
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormSelect,
} from '@ant-design/pro-components';
import Button from 'antd/lib/button';
import { useAppDispatch } from 'app/hooks';
import { createAccountAmountRecordThunk, fetchAccountDetailThunk } from 'account/slice';
import moment from 'moment';
import { DateEntry } from 'app/components/Table/hooks';

interface Props {
  mode: 'CREATE' | 'UPDATE';
  accountID: string;
  record?: AccountAmountRecord;
  trigger?: ModalFormProps['trigger'];
}

const AmountRecordForm: React.FC<Props> = ({ mode, accountID, record, trigger }) => {
  const formRef = React.useRef<FormInstance>();
  const dispatch = useAppDispatch();

  return (
    <ModalForm<AccountAmountRecord>
      title={mode === 'CREATE' ? '新建记录' : '编辑记录'}
      formRef={formRef}
      onFinish={async (values) => {
        console.log(values);
        if (mode === 'CREATE') {
          dispatch(
            createAccountAmountRecordThunk({
              ...values,
              account_id: accountID,
              datetime: moment((values.datetime as DateEntry).$d).format('YYYY-MM-DD HH:mm:ss'),
            })
          ).then((res) => {
            dispatch(fetchAccountDetailThunk(accountID));
          });
        } else {
          // const payload = { ...account, ...values };
          // dispatch(updateAccountThunk(payload));
        }
        formRef.current?.resetFields();
        return true;
      }}
      initialValues={record}
      trigger={
        trigger ? (
          trigger
        ) : (
          <Button type="primary">{mode === 'CREATE' ? '新建记录' : '编辑记录'}</Button>
        )
      }
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {
          formRef.current?.resetFields();
        },
      }}
    >
      <ProFormDigit name="amount" label="金额" rules={[{ required: true, message: '金额' }]} />
      <ProFormSelect
        name="type"
        label="类型"
        options={[
          { label: '存入', value: 'D' },
          { label: '取出', value: 'W' },
        ]}
        rules={[{ required: true, message: '类型' }]}
      />
      <ProFormDateTimePicker
        name="datetime"
        label="入账时间"
      />
    </ModalForm>
  );
};

export default AmountRecordForm;
