import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { autoLogin } from "auth/slice";

export const useAutoLogin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(autoLogin());
  }, [dispatch]);
}
