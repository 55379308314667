import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DataWithPagination } from "app/services/api";
import { Signal } from "signal/models"
import { fetchSignalListThunk as fetchSignals } from 'signal/slice';
import {
  CandleType,
  StockCandleData,
  Stock,
  StockTickAnalysis,
  StockDailyDetail,
  StockClassifies,
  StockAIModelPredict,
} from './models';
import * as stockApi from './api';
import { RootState } from 'app/store';
import { fetchTHSMemberLatestDataThunk } from 'classify/slice/detail';
import { Classify } from 'classify/models';
import { fetchStockCandleModelPredicts } from 'ai/api';

export interface StockData {
  info: Stock;
  latest: StockDailyDetail;
  signals: DataWithPagination<Signal>;
  [CandleType.DAILY]: StockCandleData[];
  tickAnalysis: StockTickAnalysis[];
  classifies: StockClassifies;
  industrySector: Pick<Classify, 'code' | 'name'>;
  predicts: StockAIModelPredict[];
}

export type StockState = Record<Stock['code'], StockData>;

const initialState: StockState = {};

export const fetchCandleDataThunk = createAsyncThunk('stock/fetchCandleData', async (params: stockApi.CandleDataParams) => {
  const res = await stockApi.fetchCandleData(params);

  return {
    code: params.code,
    data: res.data,
  }
});

export const fetchTickAnalysisThunk = createAsyncThunk('stock/fetchTickAnalysis', async (params: stockApi.TickAnalysisParams) => {
  const res = await stockApi.fetchTickAnalysis(params);

  return {
    code: params.code,
    data: res.data,
  }
});

export const fetchStockListThunk = createAsyncThunk('stock/fetchList', async() => {
  const res = await stockApi.fetchStockList();

  return res.data;
});

export const fetchLatestDataThunk = createAsyncThunk('stock/fetch_latest_data', async (codes: stockApi.StockLatestDataParams) => {
  const res = await stockApi.fetchLatestData(codes);

  return res.data;
});

export const fetchStockClassifiesThunk = createAsyncThunk(
  'stock/fetchClassifies',
  async (code: Stock['code']) => {
    return (await stockApi.fetchClassifies(code)).data;
  }
);

export const fetchStockDetailThunk = createAsyncThunk(
  'stock/fetchDetail',
  async (code: Stock['code']) => {
    return (await stockApi.fetchStockDetail(code)).data;
  },
  {
    condition(code, { getState }) {
      const stockStore = (getState() as RootState).stock;
      // 不重复抓取
      if ((code in stockStore) && ('basic' in stockStore[code])) {
        return false;
      }
    }
  }
);

export const fetchStockAIModelPredicts = createAsyncThunk(
  'stock/fetchAIModelPredicts',
  async (code: Stock['code']) => {
    return (await fetchStockCandleModelPredicts(code)).data;
  }
);

export const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSignals.fulfilled, (state, { payload }) => {
      if (payload.code) {
        state[payload.code] = {
          ...state[payload.code],
          signals: payload.data
        };
      }
    });
    builder.addCase(fetchCandleDataThunk.fulfilled, (state, { payload }) => {
      state[payload.code] = {
        ...state[payload.code],
        ...payload.data,
      }
    });
    builder.addCase(fetchTickAnalysisThunk.fulfilled, (state, { payload }) => {
      state[payload.code] = {
        ...state[payload.code],
        tickAnalysis: (payload.data as StockTickAnalysis[]),
      }
    });
    builder.addCase(fetchStockListThunk.fulfilled, (state, { payload }) => {
      payload.forEach(stock => {
        state[stock.code] = {
          ...state[stock.code],
          info: stock,
        }
      });
      return state;
    });
    builder.addCase(fetchLatestDataThunk.fulfilled, (state, { payload }) => {
      payload.forEach(stock => {
        state[stock.info.code] = {
          ...state[stock.info.code],
          info: stock.info,
          latest: stock.detail,
        }
      });
      return state;
    });
    builder.addCase(fetchStockDetailThunk.fulfilled, (state, { meta, payload }) => {
      const code = meta.arg;

      state[code] = {
        ...state[code],
        info: payload.info,
        latest: payload.detail,
      }
    });
    builder.addCase(fetchStockClassifiesThunk.fulfilled, (state, action) => {
      const code = action.meta.arg;

      state[code] = {
        ...state[code],
        classifies: action.payload,
      };
    });
    builder.addCase(fetchTHSMemberLatestDataThunk.fulfilled, (state, { payload }) => {
      payload.forEach(stock => {
        state[stock.info.code] = {
          ...state[stock.info.code],
          info: stock.info,
          latest: stock.detail,
        }
      });
      return state;
    });
    builder.addCase(fetchStockAIModelPredicts.fulfilled, (state, { meta, payload }) => {
      const code = meta.arg;

      state[code].predicts = payload;
    });
  }
});

export default stockSlice.reducer;