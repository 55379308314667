import React, { FunctionComponent } from 'react';
import moment from 'moment';

import Table, { ColumnProps } from 'app/components/Table';

import { BuyTag, SellTag } from 'signal/components/TypeTag';
import { Signal, SignalType } from 'signal/models';
import { Pagination } from 'app/services/api';
import StockPriceChange from 'stock/components/StockPriceChange';
import { ProTableProps } from '@ant-design/pro-table';

interface SignalTableProps extends ProTableProps<Signal, any> {
  state: {
    signals: Signal[];
    pagination?: Pagination;
  };
  operatorRender?: ColumnProps<Signal>['render'];
}

const makeColumns = (
  operatorRender?: SignalTableProps['operatorRender']
): ColumnProps<Signal>[] => {
  const columns: ColumnProps<Signal>[] = [
    {
      title: '策略名',
      dataIndex: 'strategy.name',
      render: (_, record) => record.strategy.name,
      search: false,
    },
    {
      title: '股票',
      dataIndex: 'stock',
      key: 'code',
      render: (_, record) => `${record.stock.name} ${record.stock.code}`,
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (_, record) => (record.type === SignalType.BUY ? <BuyTag /> : <SellTag />),
      search: false,
    },
    {
      title: '强度',
      dataIndex: 'strength',
      search: false,
    },
    {
      title: '收盘价',
      key: 'daily_data.close',
      search: false,
      render: (_, record) => <span>{record.daily_data?.close}</span>,
    },
    {
      title: '涨跌幅',
      dataIndex: 'price_chg',
      search: false,
      render: (_, record) => {
        return (
          <StockPriceChange
            price_chg={record.daily_data?.price_chg}
            pct_chg={record.daily_data?.pct_chg}
          />
        );
      },
    },
    {
      title: '发生日期',
      dataIndex: 'occurred_at',
      key: 'date',
      valueType: 'date',
      render: (_, record) => moment(record.occurred_at).format('YYYY-MM-DD'),
    },
  ];

  if (operatorRender) {
    columns.push({
      title: '操作',
      render: operatorRender,
    });
  }

  return columns;
};

const SignalTable: FunctionComponent<SignalTableProps> = ({
  state,
  operatorRender,
  ...rest
}) => {
  return (
    <Table
      {...rest}
      rowKey='id'
      columns={makeColumns(operatorRender)}
      data={state.signals}
      paginationConfig={state.pagination}
      options={false}
      search={false}
    />
  );
};

export default SignalTable;
