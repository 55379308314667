import React from 'react';
import ReactDomServer from 'react-dom/server';
import { StockAIModelPredict, StockCandleData } from 'stock/models';
import * as constants from './constants';
import {
  CandlestickSeriesOption,
  EChartsOption,
  SeriesOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import _ from 'lodash';

import { SMA } from 'technicalindicators';
import { StockData } from 'stock/slice';
import { Indicator, makeIndicators } from './indicators';
import { ChartComp } from 'app/components/MQCharts';
import { AmountFormatter, AmountUnit } from 'market/utils';
import classNames from 'classnames';
import { Signal, SignalType } from 'signal/models';
import moment from 'moment';

export interface FormatedData {
  indexes: StockCandleData['date'][],
  values: any[],
  volumes: [string, number, number][],
  amount: [string, number, number][],
  majorPct: [string, number, number][],
}

export const formatData = (stockData: StockData): FormatedData => {
  const { daily, tickAnalysis } = stockData;

  return {
    indexes: daily.map(_data => _data.date),
    values: daily.map(_data => [_data.open, _data.close, _data.low, _data.high, _data.pct_chg]),
    volumes: daily.map((_data, idx) => [_data.date, _data.vol, _data.open >= _data.close ? -1 : 1]),
    amount: daily.map((_data, idx) => [_data.date, _data.amount, _data.open >= _data.close ? -1 : 1]),
    majorPct: daily.map(_data => {
      const _taData = tickAnalysis.find(ta => ta.date === _data.date);

      // 可能有脏数据
      return [_data.date, _taData ? _taData.components.major_pct : 0, _data.open >= _data.close ? -1 : 1]
    }),
  }
};

// 构造信号
export const makeSignals = (formatedData: FormatedData, signals: Signal[]) => {
  // signal按照日期分组
  const groupedSignals = _.groupBy(signals, 'occurred_at');

  const results: any[] = [];

  Object.keys(groupedSignals).forEach(date => {
    const signals = groupedSignals[date];
    // date是毫秒级的时间戳，需要转换成字符串
    const dateStr = moment(Number(date)).format(constants.STOCK_DATE_FORMAT);
    const indexIdx = formatedData.indexes.findIndex(v => v === dateStr);

    if (indexIdx !== -1) {
      const y = formatedData.values[indexIdx][2];
      signals.forEach((signal, idx) => {
        const offset = `${100 + idx * 140}%`;
        const markOptions = {
          symbol: 'rect',
          symbolSize: 18,
          symbolOffset: [0, offset],
          tooltip: {
            trigger: 'item',
            position: 'top', // 显示位置
            formatter: (params: any) => {
              return `策略：${signal.strategy.name}<br/> 强度: ${signal.strength}`;
            },
          },
          value: signal.strength,
          coord: [dateStr, y],
          label: {
            fontSize: 8,
            color: '#FFF',
          },
          itemStyle: {
            color:
              signal.type === SignalType.BUY
                ? constants.UP_COLOR
                : signal.type === SignalType.SELL
                ? constants.DOWN_COLOR
                : 'grey',
          },
        };

        results.push(markOptions);
      });
    }
  });

  return results;
};

// 构造高质量预测
const makePredicts = (formatedData: FormatedData, predicts: StockAIModelPredict[]) => {
  // 先把predicts根据date进行分组
  const groupedPreidcts = _.groupBy(predicts, 'date');

  const results: any[] = [];

  Object.keys(groupedPreidcts).forEach(date => {
    const predicts = groupedPreidcts[date];
    const indexIdx = formatedData.indexes.findIndex(v => v === date);

    if (indexIdx !== -1) {
      const y = formatedData.values[indexIdx][2];
      predicts.sort((a, b) => b.prob - a.prob);
      predicts.forEach((predict,  idx) => {
        const offset = `${100 + idx * 140}%`;
        const markOptions = {
          modelId: predict.model.id,
          modelName: predict.model.name,
          symbol: 'rect',
          symbolSize: 12,
          symbolOffset: [0, offset],
          value: predict.prob.toFixed(2) * 100,
          coord: [date, y],
          label: {
            fontSize: 8,
            color: '#FFF',
          },
          itemStyle: {
            color:
              predict.label === 1
                ? constants.UP_COLOR
                : predict.label === 0
                ? constants.DOWN_COLOR
                : 'grey',
          },
        };

          results.push(markOptions);
      });
    }
  });

  return results;
};

const makeMA = (data: FormatedData, maConfig: number[]): SeriesOption[] => {
  const closeData = data.values.map(d => d[2]);

  return maConfig.map(ma => {
    const maData = SMA.calculate({period: ma, values : closeData}).map(v => v.toFixed(2));
    const fillData = Array(closeData.length - maData.length).fill('-');

    return {
      symbol: 'none',
      name: `MA ${ma}`,
      type: 'line',
      data: fillData.concat(maData),
      smooth: true,
      lineStyle: {
        opacity: 0.8,
        width: 1,
      },
    }
  });
};

const makeBaseCandle = (data: FormatedData, predicts?: StockAIModelPredict[], signals?: Signal[], maConfig?: number[]): ChartComp => {
  let series: SeriesOption[] = [];

  const xAxis = {
    data: data.indexes,
    scale: true,
    boundaryGap: false,
    axisLine: { onZero: false },
    axisTick: {show: false},
    splitLine: { show: false },
    axisLabel: {show: true},
    splitNumber: 20,
    min: 'dataMin',
    max: 'dataMax',
    axisPointer: {
      z: 100
    }
  };

  const yAxis = {
    scale: true,
    splitArea: {
      show: true
    }
  };

  const baseCandle: CandlestickSeriesOption = {
    name: '日K线',
    type: 'candlestick',
    data: data.values,
    itemStyle: {
      color: constants.UP_COLOR,
      color0: constants.DOWN_COLOR,
    },
    markPoint: {
      data: [
        {
          name: '最高价',
          type: 'max',
          valueDim: 'highest',
          symbol: 'diamond',
          symbolSize: 30,
          itemStyle: {
            color: 'orange',
          },
        },
        {
          name: '最低价',
          type: 'min',
          valueDim: 'lowest',
          symbolOffset: [0, '80%'],
          symbolSize: 30,
          itemStyle: {
            color: 'orange',
          },
        },
      ],
    },
  };



  // if (predicts && baseCandle['markPoint']) {
  //   baseCandle['markPoint']['data'] = Array.prototype.concat(
  //     baseCandle['markPoint']['data'],
  //     makePredicts(data, predicts)
  //   );
  // }

  console.log(signals)
  if (signals && baseCandle['markPoint']) {
    baseCandle['markPoint']['data'] = Array.prototype.concat(
      baseCandle['markPoint']['data'],
      makeSignals(data, signals)
    );
  }

  series.push(baseCandle as SeriesOption);

  if (maConfig && maConfig.length > 0) {
    series = series.concat(makeMA(data, maConfig));
  }

  return {
    xAxis,
    yAxis,
    series,
  };
};

const makeGrid = (option: EChartsOption) => {
  const result = [];

  const otherCompCount = (option.xAxis as XAXisComponentOption[]).length - 1;

  const gridCommon = {
    left: '3%',
    right: '3%',
  };

  const baseCandleHeight = 100 - constants.DATA_ZOOM_HEIGHT - (otherCompCount * constants.COMP_HEIGHT + 1) - 10;

  const baseCandleGrid = {
    ...gridCommon,
    height: `${baseCandleHeight}%`,
  };

  result.push(baseCandleGrid);

  Array(otherCompCount).fill(null).forEach((_, idx) => {
    result.push({
      ...gridCommon,
      height: `${constants.COMP_HEIGHT}%`,
      top: `${baseCandleHeight + (idx + 1) * constants.COMP_HEIGHT}%`
    })
  })

  return result;
};

const makeDataZoom = (option: EChartsOption, indexes: FormatedData['indexes']) => {
  const xAxisIndex = (option.xAxis as XAXisComponentOption[]).map((_, idx) => idx);
  const startIdx = Math.max(0, indexes.length - 120);
  const startValue = indexes[startIdx];

  return [
    {
      type: 'inside',
      xAxisIndex,
      startValue,
    },
    {
      show: true,
      xAxisIndex,
      type: 'slider',
      top: `${100 - constants.DATA_ZOOM_HEIGHT + 5}%`,
      startValue,
    },
  ]
};

export const getOptions = (data: StockData) => {
  if (!data || !data.tickAnalysis || !data.daily) return null;

  const predicts = data.predicts || [];
  const signals = data.signals?.items || [];

  let result: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      position: (pos, params, el, elRect, size) => {
        if ((pos[0] < size.viewSize[0] / 2)) {
          return {
            'right': 30,
            'top': 10,
          };
        } else {
          return {
            'left': 30,
            'top': 10,
          };
        }
      },
      formatter: (params: any) => {
        return ReactDomServer.renderToStaticMarkup(<div className='candlechart-tooltip'>
          <div className='tooltip-date'>{params[0].axisValueLabel}</div>
          {params.map((series: any) => {
            let value;
            if (series.seriesName === '成交额') {
              value = (
                <div className="tooltip-series-value">
                  {new AmountFormatter(series.value[1], AmountUnit.Thounsand).parseTo(AmountUnit.HundredMillion)}
                </div>
              );
            } else if (series.seriesType === 'candlestick') {
              const valueType = ['开盘价', '收盘价', '最低价', '最高价', '涨跌幅'];
              value = (
                <div className="tooltip-series-values">
                  {series.data.slice(1).map((v: number, idx: number) => {
                    return (
                      <div className="value-item" key={idx}>
                        <span className="name">{valueType[idx]}:</span>
                        {valueType[idx] === '涨跌幅' && (
                          <span
                            className={classNames('value', {
                              up: v >= 0,
                              down: v < 0,
                            })}
                          >
                            {v.toFixed(2)}%
                          </span>
                        )}
                        {valueType[idx] !== '涨跌幅' && <span className="value">{v}</span>}
                      </div>
                    );
                  })}
                </div>
              );
            } else if (series.seriesName.includes('占比')) {
              value = (
                <div className="tooltip-series-value">
                  {Array.isArray(series.value) ? series.value[1] : series.value}%
                </div>
              );
            } else {
              value = <div className="tooltip-series-value">{series.value}</div>;
            }
            return (
              <div className="tooltip-series" key={series.seriesIndex}>
                <div className="tooltip-series-marker" style={{ backgroundColor: series.color }} />
                <div className="tooltip-series-name">{series.seriesName}:</div>
                {value}
              </div>
            );
          })}
        </div>);
      }
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777'
      }
    },
    xAxis: [],
    yAxis: [],
    series: [],
    visualMap: [],
  };

  const formatedData = formatData(data);
  let comps: ChartComp[] = [];

  comps.push(makeBaseCandle(formatedData, predicts, signals, [5, 20, 60, 120, 250]));
  const indicatorComps = makeIndicators(formatedData, [Indicator.AMOUNT, Indicator.MAJOR_TREND]);

  if (indicatorComps) {
    comps = comps.concat(indicatorComps);
  }

  comps.forEach(comp => {
    (result.xAxis as XAXisComponentOption[]).push(comp.xAxis);
    (result.yAxis as YAXisComponentOption[]).push(comp.yAxis);
    result.series = (result.series as SeriesOption[]).concat(comp.series);
    if (comp.visualMap) {
      result.visualMap = (result.visualMap as VisualMapComponentOption[]).concat(
        comp.visualMap as VisualMapComponentOption
      );
    }
  });

  return {
    animation: false,
    ...result,
    dataZoom: makeDataZoom(result, formatedData.indexes),
    grid: makeGrid(result),
  }
}