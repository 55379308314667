import { Card, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { StatisticData, StatisticItem } from './slice';
import WatchingTag from '../WatchingTag';

const COLUMNS: ColumnProps<StatisticItem>[] = [
  {
    title: '名称',
    render: (_, r) => (
      <Link to={`/classify/ths/${r['code']}`} target="_blank">
        {r.level ? `${r.name}[${r.level}级]` : r.name}
        <WatchingTag item={r.is_watching} />
      </Link>
    ),
  },
  {
    title: '前十次数',
    dataIndex: 'times',
  },
];

const TopStatistic: FunctionComponent<{ data: StatisticData }> = ({ data }) => {
  return (
    <div className='top-statistic'>
      {Object.keys(data).map((c) => {
        return (
          <Card title={`近${c}日表现最佳`} size='small' className='top-statistic-card' key={c}>
            <Table rowKey='code' columns={COLUMNS} dataSource={data[c].slice(0, 10)} pagination={false} size='small' />
          </Card>
        );
      })}
    </div>
  );
};

export default TopStatistic;
