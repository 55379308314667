import { createContext } from 'react';

import { store } from 'app/store';

interface ContextProps {
  dispatch: typeof store.dispatch,
}

const AppContext = createContext<ContextProps>(null as any);

export default AppContext;