import { useCallback, useEffect, useReducer } from 'react';

export interface GroupState<T> {
  curGroup: number;
  groupSize: number;
  totalCount: number;
  groupCount: number;
  items: Array<T>;
  curItems: Array<T>;
  hasNext: boolean;
  hasPrev: boolean;
}

const initState = <T>(groupSize: number = 5, items: Array<T>): GroupState<T> => {
  const totalCount = items.length;
  const groupCount = Math.ceil(totalCount / groupSize);
  const curItems = items.slice(0, Math.min(groupSize, totalCount));
  const hasNext = groupCount > 1;
  const hasPrev = false;
  return {
    groupSize,
    curGroup: 0,
    totalCount,
    groupCount,
    items: items,
    curItems,
    hasNext,
    hasPrev,
  };
};

type Action<T> =
  | { type: 'nextGroup' }
  | { type: 'prevGroup' }
  | { type: 'setItems'; payload: { groupSize?: number; items: Array<T> } }
  | { type: 'setGroup'; groupIdx: number };

const updateState = <T>(state: GroupState<T>, nextGroup: number): GroupState<T> => {
  const hasPrev = nextGroup - 1 > 0;
  const hasNext = nextGroup + 1 < state.groupCount;
  const startIdx = (nextGroup - 1) * state.groupSize;
  const endIdx = Math.min(nextGroup * state.groupSize, state.totalCount);
  const curItems = state.items.slice(startIdx, endIdx);
  return {
    ...state,
    curGroup: nextGroup,
    curItems,
    hasNext,
    hasPrev,
  };
};

const reducer = <T>(state: GroupState<T>, action: Action<T>): GroupState<T> => {
  let nextGroup;
  switch (action.type) {
    case 'nextGroup':
      nextGroup = state.curGroup + 1;
      return updateState(state, nextGroup);
    case 'prevGroup':
      nextGroup = state.curGroup - 1;
      return updateState(state, nextGroup);
    case 'setGroup':
      nextGroup = action.groupIdx;
      return updateState(state, nextGroup);
    case 'setItems':
      return initState(action.payload.groupSize, action.payload.items);
    default:
      return state;
  }
};

export const useGroup = <T>(codes: Array<T>) => {
  const [groupState, dispatch] = useReducer(reducer, initState(5, codes));

  const nextGroup = useCallback(() => {
    dispatch({ type: 'nextGroup' });
  }, []);

  const prevGroup = useCallback(() => {
    dispatch({ type: 'prevGroup' });
  }, []);

  const setGroup = useCallback((group: number) => {
    dispatch({ type: 'setGroup', groupIdx: group });
  }, []);

  const setItems = useCallback(
    ({ items, groupSize }: { items: Array<string>; groupSize?: number }) => {
      dispatch({ type: 'setItems', payload: { items, groupSize } });
    },
    []
  );

  useEffect(() => {
    dispatch({ type: 'setItems', payload: { items: codes } });
  }, [codes]);

  return {
    state: groupState,
    actions: {
      nextGroup,
      prevGroup,
      setItems,
      setGroup,
    },
  };
};
