import React, { FunctionComponent, useCallback } from "react";

import SinglePageLayout from "app/layouts/SinglePageLayout";
import { ActiveStep, ResultItem } from "./models";

import GroupCompare from "./GroupCompare";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { useParams } from "react-router-dom";
import { useCrossComparison } from "./hooks";

import "./index.scss";
import StockGroupViewer from "stock/components/GroupViewer";
import { useContextDispatch } from "app/hooks";
import { closeStockDetail } from "./slice";
import { PositiveTag } from "./components";

const GroupViewerHeader: FunctionComponent<{ groupItem: ResultItem }> = ({groupItem}) => {
  return <PositiveTag positive={groupItem.positive} />
}

const CrossComparison: FunctionComponent<{}> = () => {
    const { resultID } = useParams<{ resultID: string }>();
    const state = useSelector((state: RootState) => state.analysis.crossComparison);
    const dispatch = useContextDispatch();

    const onBack = useCallback(() => {
      dispatch(closeStockDetail());
    }, [dispatch]);

    useCrossComparison(resultID);

    return (
      <SinglePageLayout pageName="cross-comparsion">
        {state.activeStep === ActiveStep.ComparsionData && <GroupCompare />}
        {state.activeStep === ActiveStep.StockDetail && (
          <StockGroupViewer
            group={state.resultSet}
            curKey={state.groupItemKey}
            onBack={onBack}
            header={GroupViewerHeader}
          />
        )}
      </SinglePageLayout>
    );
};

export default CrossComparison;
