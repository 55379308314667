import React, { FunctionComponent, useMemo } from 'react';

import { KChartConfig, TargetType } from 'app/components/KChart/models';
import { Stock } from 'stock/models';
import { GroupState, useGroup } from './hooks';
import KChart from 'app/components/KChart';
import Pagination from 'antd/es/pagination';

import './index.scss';

interface StockGroupKChartProps {
  config: KChartConfig;
  codes: Stock['code'][];
}

export const makeKChartConfig = (config: KChartConfig, groupState: GroupState<any>): KChartConfig => {
  return {
    ...config,
    refs: groupState.curItems.map((c) => ({
      code: c,
      type: TargetType.S,
    })),
  };
};

const StockGroupKChart: FunctionComponent<StockGroupKChartProps> = ({ config, codes }) => {
  const group = useGroup(codes);
  const chartConfig = useMemo(() => makeKChartConfig(config, group.state), [config, group.state]);

  return (
    <div className="stock-group-k-chart">
      <Pagination
        className='stock-group-control'
        total={group.state.totalCount}
        pageSize={5}
        onChange={(groupIdx) => group.actions.setGroup(groupIdx)}
      />
      <KChart config={chartConfig} />
    </div>
  );
};

export default StockGroupKChart;
