import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectProps } from 'antd/es/select';

import { RootState } from 'app/store';
import { Stock } from 'stock/models';


export const useOptions = (): [SelectProps<object>['options'], (search: string) => void] => {
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const stockList = useSelector((state: RootState) =>
    Object.keys(state.stock).map((code) => ({ code, name: state.stock[code].info.name }))
  );

  const onSearch = useCallback((searchText: string) => {
    if (!stockList) return;

    const options: SelectProps<object>['options'] = [];
    let compareKey: keyof Pick<Stock, 'code'| 'name'>;

    if (/^\d+$/.test(searchText)) {
      compareKey = 'code'
    } else {
      compareKey = 'name'
    }

    stockList.forEach(stock => {
      if (stock[compareKey].match(searchText)) {
        options.push({label: `${stock.code} ${stock.name}`, value: `${stock.code} ${stock.name}`})
      }
    });

    setOptions(options);
  }, [stockList]);

  return [options, onSearch];
};