import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/store';
import { FeatureSpecTarget } from 'feature/models';
import { fetchDailyFeatureStoreInfoThunk } from 'feature/slice';
import React, { FunctionComponent, useEffect } from 'react';
import { Button, Skeleton } from 'antd';
import Statistic from 'antd/es/statistic/Statistic';
import { CloudUploadOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';

import './index.scss';
import { ProCard } from '@ant-design/pro-components';
import { syncDailyFeatureSpecs } from 'feature/api';

const { Divider } = ProCard;

interface StoreDashboardProps {
  target: FeatureSpecTarget;
}

const StoreDashboard: FunctionComponent<StoreDashboardProps> = ({ target }) => {
  const dispatch = useAppDispatch();
  const storeInfo = useAppSelector((state: RootState) => state.feature.storeInfo[target]);
  const [syncing, setSyncing] = React.useState(false);

  useEffect(() => {
    dispatch(fetchDailyFeatureStoreInfoThunk(target));
  }, [target]);

  if (!storeInfo) {
    return <Skeleton active />;
  }

  return (
    <div className="feautre-store-dashboard">
      <ProCard.Group
        title="特征库信息"
        className="store-info"
        direction="row"
        loading={!storeInfo}
        extra={
          <>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              onClick={() => {
                dispatch(fetchDailyFeatureStoreInfoThunk(target));
              }}
            >
              刷新
            </Button>

            <Button
              type="primary"
              icon={<CloudUploadOutlined />}
              loading={syncing}
              onClick={async () => {
                setSyncing(true);
                await syncDailyFeatureSpecs(target);
                setSyncing(false);
              }}
            >
              入库
            </Button>
          </>
        }
      >
        <ProCard>
          <Statistic title="总行数" value={storeInfo.total} />
        </ProCard>
        <Divider />
        <ProCard>
          <Statistic title="定义特征数" value={storeInfo.specs.length} />
        </ProCard>
        <Divider />
        <ProCard>
          <Statistic
            title="启用特征数"
            value={storeInfo.specs.filter((spec) => spec.status === 'ACTIVE').length}
          />
        </ProCard>
        <Divider />
        <ProCard>
          <Statistic
            title="入库特征数"
            value={storeInfo.features.length}
          />
        </ProCard>
        <Divider />
        <ProCard>
          <Statistic title="开始日期" value={storeInfo.start_date} />
        </ProCard>
        <Divider />
        <ProCard>
          <Statistic title="结束日期" value={storeInfo.end_date} />
        </ProCard>
      </ProCard.Group>
    </div>
  );
};

export default StoreDashboard;
