import React from 'react';
import { Modal } from 'antd';

import { WarningOutlined } from '@ant-design/icons';
import { ModalFuncProps } from 'antd/lib/modal';

interface confirmDeleteParams {
  target: string;
  onOK: ModalFuncProps['onOk'];
}

export const confirmDelete = ({ target, onOK }: confirmDeleteParams) => {
  Modal.confirm({
    title: '删除确认',
    icon: <WarningOutlined />,
    content: `是否删除 ${target}`,
    okText: '确认',
    okType: 'danger',
    cancelText: '取消',
    onOk: onOK,
  });
};

export const scrollToElem = (contianerId: string, targetId: string) => {
  const conatainerElem = document.getElementById(contianerId);
  const targetElem = document.getElementById(targetId);
  if (targetElem === null) return;
  const targetOffsetY = targetElem?.offsetTop;
  conatainerElem?.scroll({
    left: 0,
    top: targetOffsetY - 80,
    behavior: 'smooth',
  });
};

export const hasIntersection = (arr1: any[], arr2: any[]): boolean => {
  return arr1.some((item) => arr2.includes(item));
};