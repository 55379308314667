import React, { FunctionComponent, useEffect } from 'react';

import { Card, Col, Row, Skeleton, Statistic } from 'antd';
import { RootState } from 'app/store';
import classNames from 'classnames';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { Stock } from 'stock/models';

import './index.scss';
import { AmountFormatter, AmountUnit } from 'market/utils';
import { SignalTag } from 'signal/components/TypeTag';
import { useContextDispatch } from 'app/hooks';
import { fetchStockDetailThunk } from 'stock/slice';

const StockDailyData: FunctionComponent<{ code: Stock['code'] }> = ({ code }) => {
  const dispatch = useContextDispatch();
  const stock = useSelector((state: RootState) => state.stock[code]);

  useEffect(() => {
    dispatch(fetchStockDetailThunk(code));
  }, [dispatch, code]);

  if (!stock || !stock.latest) {
    return <Skeleton active />;
  }

  const data = stock.latest;

  return (
    <div className="stock-daily-data">
      <Row>
        <Card key="trade" size="small">
          <Statistic key="date" className="info-item" title="最近交易日" value={data.date} />
          <Statistic key="close" className="info-item" title="最新价格" value={data.trade.close} />
          <Statistic
            key="pct-chg"
            title="涨跌幅"
            value={data.trade.pct_chg.toFixed(2)}
            prefix={data.trade.pct_chg >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            className={classNames('info-item', data.trade.pct_chg >= 0 ? 'value-up' : 'value-down')}
            suffix="%"
          />
          <Statistic
            className="info-item"
            key="amount"
            title="成交额"
            value={new AmountFormatter(data.trade.amount, AmountUnit.Thounsand).parseTo(
              AmountUnit.HundredMillion
            )}
          />
          <Statistic
            className={classNames('info-item', data.trade.amount_rank <= 50 ? 'value-up' : '')}
            key="amount_rank"
            title="成交额排名"
            value={data.trade.amount_rank}
          />
          <Statistic
            className={classNames('info-item', data.trade.major_net_buy >= 0 ? 'value-up' : 'value-down')}
            key="major_net_buy"
            title="主力净买"
            value={new AmountFormatter(data.trade.major_net_buy, AmountUnit.Wan).parseTo(AmountUnit.HundredMillion)}
          />
          <Statistic
            className="info-item"
            key="vol"
            title="成交量"
            value={new AmountFormatter(data.trade.vol, AmountUnit.Yuan).parseTo(
              AmountUnit.Wan
            )}
            precision={0}
            suffix="手"
          />
          <Statistic
            className="info-item"
            key="turnover-rate"
            title="换手率"
            value={data.trade.turnover_rate.toFixed(2)}
            suffix="%"
          />
          <Statistic
            key="turnover-rate-f"
            className="info-item"
            title="自由流通换手率"
            value={data.trade.turnover_rate_f.toFixed(2)}
            suffix="%"
          />
          <Statistic
            key="major-pct"
            className="info-item"
            title="大单占比"
            value={data.trade.major_pct * 100}
            precision={2}
            suffix="%"
          />
        </Card>
        {data.basic && (
          <Card key="baisc" size="small">
            <Statistic
              key="total-mv"
              className="info-item"
              title="总市值"
              value={new AmountFormatter(data.basic.total_mv, AmountUnit.Wan).parseTo(
                AmountUnit.HundredMillion
              )}
            />
            <Statistic
              key="circ-mv"
              className="info-item"
              title="流通市值"
              value={new AmountFormatter(data.basic.circ_mv, AmountUnit.Wan).parseTo(
                AmountUnit.HundredMillion
              )}
            />
            <Statistic
              key="free-mv"
              className="info-item"
              title="自由流通市值"
              value={new AmountFormatter(data.basic.free_mv, AmountUnit.Wan).parseTo(
                AmountUnit.HundredMillion
              )}
            />
            <Statistic
              key="pe"
              className="info-item"
              title="PE"
              precision={2}
              value={data.basic?.pe ? data.basic.pe : '亏损'}
            />
            <Statistic
              key="pe-ttm"
              className="info-item"
              title="PE-TTM"
              precision={2}
              value={data.basic?.pe_ttm ? data.basic.pe : '亏损'}
            />
            <Statistic
              key="ps"
              className="info-item"
              title="PS"
              precision={2}
              value={data.basic?.ps}
            />
            <Statistic
              key="pb"
              className="info-item"
              title="PB"
              precision={2}
              value={data.basic?.pb}
            />
          </Card>
        )}
      </Row>
      <Row gutter={1}>
        {data.signals && data.signals.length > 0 && (
          <Card key="signals" size="small">
            信号 :
            {data.signals.map((signal) => (
              <SignalTag key={signal.strategy.id} signal={signal} />
            ))}
          </Card>
        )}
      </Row>
    </div>
  );
};

export default StockDailyData;
