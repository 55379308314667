import React, { FunctionComponent, useEffect, useState } from 'react';

import Table, { ColumnProps } from 'app/components/Table';
import moment from 'moment';

import { DailyFeature, FeatureSpecTarget } from 'feature/models';
import { Skeleton } from 'antd';
import { fetchDailyFeatures } from 'feature/api';

interface Props {
  code: string;
  target: FeatureSpecTarget;
}

const DailyFeatureTable: FunctionComponent<Props> = ({ code, target }) => {
  const [columns, setColumns] = useState<ColumnProps<DailyFeature>[]>();
  const [data, setData] = useState<DailyFeature[]>();

  useEffect(() => {
    fetchDailyFeatures({
      code,
      target,
      start_date: moment().subtract(10, 'day').format('YYYYMMDD'),
      end_date: moment().format('YYYYMMDD'),
    }).then((res) => {
      // pre特征不展示
      const metas = res.meta.filter(m => !m.dataIndex.includes('pre'))
      const columns = metas.map((meta) => {
        return {
          title: meta.title || meta.dataIndex,
          dataIndex: meta.dataIndex,
          fixed: meta.dataIndex === 'date' ? 'left' : undefined,
          width: meta.dataIndex === 'date' ? 100 : undefined,
          render: (_, record) => {
            switch (meta.type) {
              case 'datetime':
                return moment(record[meta.dataIndex]).format('YYYY-MM-DD');
              default:
                if (Object.keys(record).indexOf(meta.dataIndex) === -1) {
                  return '-';
                }
                return record[meta.dataIndex];
            }
          },
        } as ColumnProps<DailyFeature>;
      });
      setColumns(columns);
      setData(res.data);
    });
  }, [code, target]);

  if (!columns) {
    return <Skeleton active />;
  }

  return (
    <Table
      className="daily-features-table"
      columns={columns}
      data={data}
      paginationConfig={{ per_page: 10 }}
      useRedux={false}
      search={false}
      sortInLocal={true}
      options={false}
    />
  );
};

export default DailyFeatureTable;