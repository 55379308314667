import React from 'react';

import ProTable, { ProColumns, ProTableProps } from '@ant-design/pro-table';

import { formatPagination } from './utils';
import { AppThunk } from 'app/store';
import { PaginationParams, Pagination, ApiResult, DataWithPagination } from 'app/services/api';

import './index.scss';
import { TableState, useRefreshTableState } from './hooks';
import classNames from 'classnames';

export type ProTableParams = PaginationParams & { filter?: Record<string, string> };

export type ColumnProps<T> = ProColumns<T>;
export interface TableProps<RecordType> extends ProTableProps<RecordType, any> {
  columns: ColumnProps<RecordType>[];
  data?: RecordType[];
  fetchAction?:
    | ((params: TableState) => AppThunk)
    | ((params: TableState) => Promise<ApiResult<RecordType[]>>)
    | ((params: TableState) => Promise<ApiResult<DataWithPagination<RecordType>>>);
  useRedux?: boolean;
  sortInLocal?: boolean;
  paginationConfig?: Pick<Pagination, 'per_page'>;
}


const Table: <RecordType extends object = any>(props: TableProps<RecordType>) => JSX.Element = function(props) {
  const {
    columns,
    data,
    fetchAction,
    request,
    paginationConfig,
    useRedux = true,
    sortInLocal = true,
    className,
    ...rest
  } = props;

  // 可以直接传request
  const customRequest = useRefreshTableState(data, fetchAction, useRedux, sortInLocal);

  return (
    <ProTable
      params={rest.params}
      request={request || customRequest}
      columns={columns}
      dataSource={data}
      pagination={formatPagination(paginationConfig)}
      className={classNames('mq-table', className)}
      scroll={{ x: 'max-content' }}
      {...rest}
    />
  );
}

export default Table;