import React, { FunctionComponent } from 'react';
import { FormItemSpec } from './models';
import { ProFormDigit, ProFormText } from '@ant-design/pro-components';

export const renderFormItem = (spec: FormItemSpec) => {
  switch (spec.type) {
    case 'number':
      return (
        <ProFormDigit
          key={spec.key}
          name={spec.key}
          label={spec.name}
          fieldProps={{ type: spec.type }}
          initialValue={spec.default}
          min={-100}
        />
      );
    case 'string':
      return <ProFormText key={spec.key} name={spec.key} label={spec.name} />;
    default:
      return <ProFormText key={spec.key} name={spec.key} label={spec.name} />;
  }
};