import callApi from 'app/services/api';
import {
  DailyFeature,
  DailyFeatureSpec,
  DailyFeatureStoreInfo,
  FeatureCategory,
  FeatureCategoryTree,
  FeatureSpecTarget,
} from './models';
import { TableState } from 'app/components/Table/hooks';
import { DataWithMetaState } from 'app/store';

// 获取某类型的特征库信息，包括前十条后十条数据，特征定义列表，以及总行数
export const fetchDailyFeatureStoreInfo = async (target: FeatureSpecTarget) => {
  return callApi<DailyFeatureStoreInfo>({
    endpoint: '/features/daily_store_info',
    method: 'GET',
    data: { target },
  });
};

// 获取某类型的特征定义列表
export const fetchDailyFeatureSpecs = async (params: TableState) => {
  return callApi<DailyFeatureSpec[]>({
    endpoint: '/features/daily_specs',
    method: 'GET',
    data: params,
  });
};

// 获取单个特征定义
export const fetchDailyFeatureSpec = async (id: DailyFeatureSpec['id']) => {
  return callApi<DailyFeatureSpec>({
    endpoint: `/features/daily_specs/${id}`,
    method: 'GET',
  });
};

// 创建某类型的特征定义
export const createDailyFeatureSpec = async (spec: DailyFeatureSpec) => {
  return callApi<DailyFeatureSpec>({
    endpoint: '/features/daily_specs',
    method: 'POST',
    data: spec,
  });
};

// 更新某类型的特征定义
export const updateDailyFeatureSpec = async (spec: DailyFeatureSpec) => {
  return callApi<DailyFeatureSpec>({
    endpoint: `/features/daily_specs/${spec.id}`,
    method: 'PUT',
    data: spec,
  });
};

// 删除某类型的特征定义
export const deleteDailyFeatureSpec = async (spec: DailyFeatureSpec) => {
  return callApi<DailyFeatureSpec>({
    endpoint: `/features/daily_specs/${spec.id}`,
    method: 'DELETE',
  });
};

// 同步某个特征定义
export const syncDailyFeatureSpec = async (spec: DailyFeatureSpec) => {
  return callApi<DailyFeatureSpec>({
    endpoint: `/features/daily_specs/${spec.id}/sync`,
    method: 'POST',
  });
};

// 同步某个类型的所有特征定义
export const syncDailyFeatureSpecs = async (target: FeatureSpecTarget) => {
  return callApi<DailyFeatureSpec[]>({
    endpoint: `/features/daily_specs/sync`,
    method: 'POST',
    data: { target },
  });
};

// 增加特征类别节点
export const createFeatureCategory = async (
  name: string,
  parentId: FeatureCategory['parent_id']
) => {
  return callApi<FeatureCategory>({
    endpoint: '/features/category/create',
    method: 'POST',
    data: { name, parent_id: parentId },
  });
};

// 更新特征类别节点
export const updateFeatureCategory = async (node: FeatureCategory) => {
  return callApi<FeatureCategory>({
    endpoint: `/features/category/${node.id}`,
    method: 'PUT',
    data: node,
  });
};

// 删除特征类别节点
export const deleteFeatureCategory = async (nodeId: FeatureCategory['id']) => {
  return callApi<FeatureCategory>({
    endpoint: `/features/category/${nodeId}`,
    method: 'DELETE',
  });
};

// 移动特征类别节点
export const moveFeatureCategory = async (
  nodeId: FeatureCategory['id'],
  parentId: FeatureCategory['parent_id'],
  idx: FeatureCategory['idx']
) => {
  return callApi<FeatureCategory>({
    endpoint: `/features/category/${nodeId}/move`,
    method: 'POST',
    data: { parent_id: parentId, idx },
  });
};

// 获取特征类别树
export const fetchFeatureCategoryTree = async () => {
  return callApi<FeatureCategory[]>({
    endpoint: '/features/categories',
    method: 'GET',
  });
};

// 所有特征类别以及下面的特征定义
export const fetchFeatureCategoryTreeWithSpecs = async () => {
  return callApi<FeatureCategoryTree>({
    endpoint: '/features/categories_with_specs',
    method: 'GET',
  });
};

// 获取日频特征
export interface DailyFeatureAPIParams {
  target: FeatureSpecTarget;
  start_date: string;
  end_date: string;
  code: string;
}
export const fetchDailyFeatures = async (params: DailyFeatureAPIParams) => {
  return callApi<DailyFeature[]>({
    endpoint: '/features/daily_features',
    method: 'GET',
    data: params,
  })
};
