import React, { FunctionComponent } from 'react';
import _ from 'lodash';

import { Card } from 'antd';
import { FormSectionProps } from '../interfaces';

const BizSection: FunctionComponent<FormSectionProps> = (props) => {
  const { label, formKey, children, value, actions, path } = props;

  return (
    <Card type='inner' className={`biz-section ${formKey}`} title={`${label} Section`}>
      { React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            value: typeof value === 'object' ? _.get(value, child.props.formKey) : value,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              const _path = path ? [...path, formKey] : formKey;
              actions?.setValue(_path, value);
            }
          });
        }
      })}
    </Card>
  );
}

export default BizSection;