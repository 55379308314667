import React, { FunctionComponent, useRef } from 'react';
import { useTree } from './hooks';
import {
  ProForm,
  ProFormInstance,
  ProFormText,
  ProFormTreeSelect,
} from '@ant-design/pro-components';
import { TreeNode } from './models';
import Modal from 'antd/lib/modal/Modal';

interface TreeFormProps {
  state: ReturnType<typeof useTree>['state'];
  handlers: ReturnType<typeof useTree>['handlers'];
}

const TreeForm: FunctionComponent<TreeFormProps> = ({ state, handlers }) => {
  const formRef = useRef<ProFormInstance>();
  return (
    <div className="tree-form">
      <Modal
        open={state.operateConfig.formOpen}
        title={state.operateConfig.mode === 'CREATE' ? '新建' : '编辑'}
        onCancel={() => {
          handlers.endOperate();
        }}
        okText={state.operateConfig.mode === 'CREATE' ? '创建' : '保存'}
        cancelText="取消"
        onOk={() => {
          formRef.current?.submit();
        }}
      >
        <ProForm<{ name: string; parentId: TreeNode['parent_id'] }>
          initialValues={{
            name:
              state.operateConfig.mode === 'UPDATE' && state.operateConfig.selectedNode
                ? state.nodes.find((node) => node.id === state.operateConfig.selectedNode)?.name
                : '',
            parentId: state.operateConfig.mode === 'CREATE' && state.operateConfig.selectedNode,
          }}
          submitter={false}
          formRef={formRef}
          onFinish={async (values) => {
            if (state.operateConfig.mode === 'CREATE') {
              await handlers.createNode(values.name, values.parentId);
            } else {
              await handlers.updateNode({
                id: state.operateConfig.selectedNode as TreeNode['id'],
                name: values.name,
              });
            }
            formRef.current?.resetFields();
            handlers.endOperate();
          }}
        >
          <ProFormText name="name" label="名称" />
          {state.operateConfig.mode === 'CREATE' && state.operateConfig.selectedNode && (
            <ProFormTreeSelect
              label="父级"
              name="parentId"
              disabled
              fieldProps={{
                multiple: false,
                treeData: state.treeData,
                placeholder: '请选择',
                treeDefaultExpandAll: true,
              }}
            />
          )}
        </ProForm>
      </Modal>
    </div>
  );
};

export default TreeForm;
