import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ClassifyDetail, ClassifySource } from 'classify/models';
import * as classifyApi from 'classify/api';
import { RootState } from 'app/store';
import { DateRangeParams } from 'app/services/api';

export interface ClassifyDetailState {
  [code: string]: ClassifyDetail;
}

const initialState: ClassifyDetailState = {};

export const fetchTHSInfoThunk = createAsyncThunk('classify/fetchTHSInfo', async (code: string) => {
  return (await classifyApi.fetchTHSClassifyInfo(code)).data;
});

export const fetchTHSDailyIndicatorsThunk = createAsyncThunk(
  'classify/fetchTHSDailyIndicators',
  async ({
    code,
    dateRange,
    includeStragtegy,
  }: {
    code: string;
    dateRange?: DateRangeParams;
    includeStragtegy?: boolean;
  }) => {
    return (await classifyApi.fetchTHSDailyIndicators(code, dateRange, includeStragtegy)).data;
  },
  {
    condition({ code }, { getState }) {
      const detail = (getState() as RootState).classify.details[code];
      if ('indicators' in detail) {
        return false;
      }
    },
  }
);

export const fetchTHSMemberLatestDataThunk = createAsyncThunk(
  'classify/fetchTHSMemberLatestData',
  async (code: string) => {
    return (await classifyApi.fetchTHSMemberLatestData(code)).data;
  },
  {
    condition(code, { getState }) {
      const detail = (getState() as RootState).classify.details[code];
      if ('members' in detail) {
        return false;
      }
    },
  }
);

const slice = createSlice({
  name: 'classifyDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTHSInfoThunk.fulfilled, (state, { payload }) => {
      const code = payload.info.code;
      payload['info']['source'] = ClassifySource.THS;
      if (code in state) {
        state[code]['info'] = payload.info;
      } else {
        state[code] = {
          info: payload.info,
          latest: payload.latest,
        };
      }
    });
    builder.addCase(fetchTHSDailyIndicatorsThunk.fulfilled, (state, { payload, meta }) => {
      state[meta.arg.code].indicators = payload.indicators;
      state[meta.arg.code].strategy = payload.strategy;
    });
    builder.addCase(fetchTHSMemberLatestDataThunk.fulfilled, (state, { payload, meta }) => {
      state[meta.arg].members = payload;
    });
  },
});

export default slice.reducer;
