import { ProForm, ProFormItem, ProFormText, ProFormTextArea } from '@ant-design/pro-components';
import { CreateTrainsetParams, fetchTrainset } from 'ai/api';
import { Button, Skeleton } from 'antd';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import FeatureSelector from 'feature/components/FeatureSelector';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

const TrainsetDetail = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [initialValues, setInitialValues] = React.useState<CreateTrainsetParams>();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }
      const result = (await fetchTrainset(id)).data;
      result.feature_ids = result.features.map((feature) => feature.id);
      setInitialValues(result);
    };
    init();
  }, [id]);

  if (!initialValues) {
    return <Skeleton />;
  }

  return (
    <WorkdeskLayout pageName="trainset-detail" title="查看训练集">
      <ProForm<CreateTrainsetParams> initialValues={initialValues} disabled submitter={false}>
        <ProFormText name="name" label="名称" />
        <ProFormTextArea name="description" label="描述" />
        <ProFormItem name="feature_ids" label="特征">
          <FeatureSelector selectedIds={initialValues?.feature_ids} readonly />
        </ProFormItem>
      </ProForm>
      <Button type="primary" style={{ marginRight: '1rem' }}>
        <Link to={`/ai/trainsets/${id}/edit`}>编辑</Link>
      </Button>
      <Button onClick={() => history.goBack()}>返回</Button>
    </WorkdeskLayout>
  );
};

export default TrainsetDetail;
