import React, { FunctionComponent } from 'react';
import { Card, Button, List } from 'antd';
import { FormSectionProps } from '../interfaces';

const StructSection: FunctionComponent<FormSectionProps> = (props) => {
  const { formKey, label, value, actions, children, path } = props;
  const currentPath = path ? [...path, formKey] : [formKey];

  return (
    <Card
      type='inner'
      className={`struct-section ${formKey}`}
      title={`${label} Section`}
      extra={<Button type='primary' onClick={() => {
        actions?.addItem(currentPath)
      }}>添加</Button>}
    >
      <List
        dataSource={value}
        renderItem={(item, idx) => {
          const itemPath = [...currentPath, idx];
          return (
            <List.Item actions={[<Button type='primary' danger onClick={() => { actions?.removeItem(currentPath, idx) }}>删除</Button>]}>
              {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, {
                    value: value[idx][child.props.formKey],
                    path: itemPath,
                    actions,
                  })
                }
              })}
            </List.Item>
          )
        }}
      />
    </Card>
  );
}

export default StructSection;