import { useLocation } from "react-router-dom";
import { useContext, useState, useCallback } from "react";
import AppContext from "./context";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";

export const useQuery = () => {
  const searchString = useLocation().search;
  if (searchString.length === 0) return {};

  const search = searchString.substring(1);
  return JSON.parse(
    '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
  );
};

export const useContextDispatch = () => {
  return useContext(AppContext).dispatch;
}

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useModalState = () => {
  const [visiable, setVisiable] = useState<boolean>(false);

  const close = useCallback(() => {
    setVisiable(false);
  }, []);

  const open = useCallback(() => {
    setVisiable(true);
  }, []);

  return {
    visiable,
    actions: {
      open,
      close,
    }
  }
};
