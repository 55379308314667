import { useCallback, useEffect, useState } from "react"

import { fetchCollectionsThunk, createCollectionThunk, deleteCollectionThunk, removeCollectionItemThunk, addCollectionItemThunk, updateCollectionThunk } from "collection/slice";
import { Collection, StockWithCollection } from "collection/models";
import { useContextDispatch, useModalState } from "app/hooks";
import { addItemParams, createCollectionParams, removeCollectionItemParams } from "collection/api";
import { confirmDelete } from "app/utils";
import { TabsProps } from "antd/lib/tabs";
import { useSelector } from "react-redux";
import _ from 'lodash';
import { RootState } from "app/store";
import { useStockData } from "stock/hooks";

export const useCollectionsData = () => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchCollectionsThunk());
  }, [dispatch]);

  return useSelector((state: RootState) => state.collection.collections.items);
};

export const useTabState = (collections: Collection[], newCollectionModalState: ReturnType<typeof useModalState>) => {
  const [activeKey, setActiveKey] = useState(collections[0]?.id)

  const add = useCallback(() => {
    newCollectionModalState.actions.open();
  }, [newCollectionModalState.actions]);

  const backToFirst = useCallback(() => {
    setActiveKey(collections[0]?.id);
  }, [collections]);

  const edit: TabsProps['onEdit'] = useCallback((targetKey, action) => {
    if (action === 'add') {
      add();
    }
  }, [add]);

  return {
    activeKey,
    actions: {
      activate: setActiveKey,
      addCollection: add,
      edit,
      backToFirst,
    }
  }
};

export const useCollectionActions = () => {
  const dispatch = useContextDispatch();

  const deleteCollection = useCallback((collection: Collection, resolved?: () => void) => {
    confirmDelete({
      target: collection.name,
      onOK: () => {
        dispatch(deleteCollectionThunk(collection.id)).then(resolved);
      }
    })
  }, [dispatch]);

  const removeItem = useCallback((params: removeCollectionItemParams) => {
    confirmDelete({
      target: params.item,
      onOK: () => {
        dispatch(removeCollectionItemThunk(params));
      }
    })
  }, [dispatch]);

  const addItem = useCallback((params: addItemParams) => {
    dispatch(addCollectionItemThunk(params));
  }, [dispatch]);

  const editCollection = useCallback((id: Collection['id'], params: createCollectionParams) => {
    dispatch(updateCollectionThunk({ id, params }));
  }, [dispatch]);

  const createCollection = useCallback((params: createCollectionParams) => {
    dispatch(createCollectionThunk(params));
  }, [dispatch]);

  return {
    createCollection,
    deleteCollection,
    editCollection,
    removeItem,
    addItem,
  }
};

export const useStockWithCollection = (): StockWithCollection[] => {
  const collections = useSelector((state: RootState) => state.collection.collections.items);
  const allCodes = _.uniq(collections.flatMap(c => c.items));

  const stockData = useStockData(allCodes);

  return stockData.map((stock) => ({
    ...stock,
    collections: collections
      .filter((c) => c.items.includes(stock.info.code))
      .map((c) => ({ id: c.id, name: c.name })),
  }));
};