import MQCharts from "app/components/MQCharts";
import React, { FunctionComponent, PropsWithChildren, useMemo } from "react";
import { ChartOptions, getOptions, makeGroupItemKey } from "./utils";

import './index.scss';
import { Button, Skeleton } from "antd";
import PageHeader from "app/components/PageHeader";
import { useStockGroupViewerState } from "./hooks";
import { StockGroupItem } from "./models";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import AddToCollection from "collection/components/AddToCollection";
import StockClassifies from "../Classifies";
import StockDailyData from "../DailyData";

export interface StockGroupViewerProps<T> {
  group: T[],
  curKey?: string,
  onBack?: () => void,
  header?: FunctionComponent<{ groupItem: T }>,
  chartOptions?: ChartOptions,
}

const StockGroupViewer = <T extends StockGroupItem,>({
  group,
  curKey,
  onBack,
  header,
  chartOptions,
}: PropsWithChildren<StockGroupViewerProps<T>>) => {
  const { state, actions } = useStockGroupViewerState<T>(group, curKey);
  const isLogin = useSelector((state: RootState) => state.auth.isLogin);

  const options = useMemo(() => {
    if (state.curItem) {
      return getOptions(state.dataset[makeGroupItemKey(state.curItem)], state.curItem?.occurredAt, chartOptions);
    } else {
      return null;
    }
  }, [state.curItem, state.dataset, chartOptions]);

  if (!state.curItem) {
    return <Skeleton active />;
  }

  const titleText = `个股分析-${state.curItem.code}-${state.curItem.name}-${state.curIdx + 1}/
            ${state.total}`;
  return (
    <div className="cross-comparison-stock-detail">
      <PageHeader
        onBack={onBack}
        titleText={titleText}
        title={
          <>
            {titleText}
            {header && React.createElement(header, { groupItem: state.curItem as T })}
          </>
        }
        extra={[
          isLogin && <AddToCollection key='addToCollection' code={state.curItem.code} />,
          <Button
            key="prev-btn"
            type="primary"
            className="prev-btn"
            onClick={actions.prevStock}
            style={{ visibility: state.hasPrev ? 'visible' : 'hidden' }}
          >
            上一个
          </Button>,
          <Button
            key="next-btn"
            type="primary"
            className="next-btn"
            onClick={actions.nextStock}
            style={{ visibility: state.hasNext ? 'visible' : 'hidden' }}
          >
            下一个
          </Button>,
        ]}
      />
      <StockDailyData code={state.curItem.code} />
      <StockClassifies code={state.curItem.code} />
      {options && <MQCharts options={options} className="candle-chart" />}
    </div>
  );
};

export default StockGroupViewer;
