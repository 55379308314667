import React, { ReactNode } from 'react';

import SinglePageLayout from 'app/layouts/SinglePageLayout';
import Logo from 'app/components/Logo';

import './index.scss';

type Props = {
  pageName: string,
  children: ReactNode,
}

export default function AuthPageLayout({ pageName, children } : Props) {
  return (
    <SinglePageLayout pageName={pageName} className='auth-page'>
      <div className='center-section'>
        <Logo />
        {children}
      </div>
    </SinglePageLayout>
  );
}