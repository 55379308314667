import React, { FunctionComponent } from 'react';
import { Col, Row } from 'antd';

import TargetSelector from './TargetSelector/indes';

import './index.scss';
import ComparisonChart from './ComparisonChart';
import PageHeader from 'app/components/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';


const GroupCompare: FunctionComponent<{}> = () => {
  const resultInfo = useSelector((state: RootState) => state.analysis.crossComparison.resultInfo?.description);

  return (
    <div className="group-compare">
      <PageHeader title={`分组分析-${resultInfo}`} />
      <Row>
        <Col span={18}>
          <ComparisonChart />
        </Col>
        <Col span={5}>
          <TargetSelector />
        </Col>
      </Row>
    </div>
  );
};

export default GroupCompare;