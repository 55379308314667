import { ActionType, ProCard } from '@ant-design/pro-components';
import { deleteModel, fetchModelSpecDetail, trainModel } from 'ai/api';
import { AIModelSpecDetail, AIModelTarget } from 'ai/models';
import { Button, Skeleton, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import './index.scss';
import moment from 'moment';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import ModelSpecStatsCard from 'ai/components/ModelSpecStatsCard';
import Modal from 'antd/lib/modal';

const ModelSpecDetail = () => {
  // 从url中获取id
  const id = useParams<{ id: string }>().id;
  const [specDetail, setSpecDetail] = useState<AIModelSpecDetail>();
  const tableRef = React.useRef<ActionType>();

  useEffect(() => {
    fetchModelSpecDetail(id).then((result) => {
      setSpecDetail(result.data);
      window.document.title = `模型定义详情-${result.data.name}`;
    });
  }, [id]);

  if (!specDetail) {
    return <Skeleton active />;
  }

  const hyperParams = specDetail.form_spec.params.find(
    (p) => p.base === specDetail.params.base
  )?.hyper_params;

  return (
    <WorkdeskLayout pageName="ai-model-spec-detail">
      <h1>
        模型定义详情 - {specDetail.name}
        <Button
          type="primary"
          onClick={() => {
            trainModel(specDetail.id);
          }}
        >
          触发训练
        </Button>
      </h1>
      <ProCard size="small" title="基本信息" bordered className="spec-info">
        <ProCard size="small" title="名称" bordered>
          {specDetail?.name}
        </ProCard>
        <ProCard size="small" title="描述" bordered>
          {specDetail?.description}
        </ProCard>
        <ProCard size="small" title="目标" bordered>
          {specDetail?.form_spec.target.map((target) => {
            return (
              <div>
                {target.name}: {specDetail.target?.[target.key as keyof AIModelTarget]}
              </div>
            );
          })}
        </ProCard>
        <ProCard size="small" title="参数" bordered>
          模型基础: {specDetail.params.base}
          <br />
          {hyperParams && (
            <>
              超参:
              <br />
              {hyperParams.map((p) => {
                return (
                  <div>
                    {p.name}: {specDetail.params.hyper_params[p.key]}
                  </div>
                );
              })}
            </>
          )}
        </ProCard>
        <ProCard size="small" title="数据集" bordered>
          <Link to={`/ai/trainsets/${specDetail.trainset.id}/detail`}>
            {specDetail.trainset.name}
          </Link>
        </ProCard>
      </ProCard>
      <ModelSpecStatsCard modelSpecId={specDetail.id} />
      <ProCard size="small" title="版本列表" bordered>
        共计: {specDetail.models.length}
        <Table
          dataSource={specDetail.models}
          columns={[
            {
              title: '序号',
              dataIndex: 'id',
              render: (_, r, idx) => idx + 1,
            },
            {
              title: '创建时间',
              dataIndex: 'created_at',
              render: (_, r) => {
                return moment(r.created_at).format('YYYY-MM-DD HH:mm:ss');
              },
              defaultSortOrder: 'descend',
              sorter: (a, b) => a.created_at - b.created_at,
            },
            {
              title: '版本号',
              dataIndex: 'version',
            },
            {
              title: '超参',
              dataIndex: 'params',
              render: (_, r) => {
                if (!r.params) {
                  return '-';
                }
                return Object.keys(r.params).map((key) => {
                  return (
                    <div>
                      {key}: {r.params[key]}
                    </div>
                  );
                });
              },
            },
            {
              title: '详情',
              dataIndex: 'detail',
              render: (_, r) => {
                // detail是JSON，遍历展示
                let detail: Record<string, number>;

                if (Array.isArray(r.detail)) {
                  detail = r.detail.filter(d => d.threshold === 0.9)[0];
                } else {
                  detail = r.detail;
                }

                return (
                  <>
                    {Object.keys(detail).map((key) => {
                      return (
                        <div>
                          {key}: {detail[key]}
                        </div>
                      );
                    })}
                  </>
                );
              }
            },
            {
              title: '实际情况',
              render: (_, r) => {
                if (Object.keys(r.stats).length === 0) {
                  return '-'
                }

                const stats = r.stats.detail.filter(d => d.prob_threshold === 0.8)[0];

                return (
                  <>
                    AUC: {r.stats.auc}
                    <br />
                    Positive Count: {stats.positive_count}
                    <br />
                    TP Count: {stats.tp_count ? stats.tp_count : '-'}
                    <br />
                    Profit Count: {stats.profit_count ? stats.profit_count : '-'}
                  </>
                );
              }
            },
            {
              title: '操作',
              render: (_, r) => {
                return (
                  <>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: `确认删除-${r.version}`,
                      content: '删除后不可恢复',
                      onOk: async () => {
                        const response = await deleteModel(r.id);
                        if (response.isOK) {
                          const restModels = specDetail.models.filter((m) => m.id !== r.id);
                          setSpecDetail({
                            ...specDetail,
                            models: restModels,
                          });
                        }
                      },
                    });
                  }}
                >
                  删除
                </Button>
                    <Button type="link" size="small">
                      <Link to={`/ai/model/${r.id}`}>查看预测</Link>
                    </Button>
                  </>
                );
              }
            }
          ]}
          pagination={{ pageSize: 10 }}
        />
      </ProCard>
    </WorkdeskLayout>
  );
};

export default ModelSpecDetail;
