import React, { ReactNode } from 'react';

import { Layout } from 'antd';

import SideMenu from './SideMenu';

import Footer from 'app/components/Footer';
import Logo from 'app/components/LogoIcon';
import Breadcrumb from 'app/components/Breadcrumb';

import './index.scss';
import PageHeader from 'app/components/PageHeader';

const { Content, Sider } = Layout;

interface Props {
  pageName?: string,
  children: ReactNode,
  className?: string,
  title?: string,
}

export default function WorkdeskLayout({ pageName, children, className, title }: Props) {
  const _className = ['workdesk-layout', pageName, className].join(' ');
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <Layout style={{ minHeight: '100vh' }} className={_className}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        className='side-menu'
        width={140}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <Logo />
        <SideMenu />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 76 : 140 }}>
        <Content style={{ margin: '1rem' }}>
          <Breadcrumb />
          {title && <PageHeader title={title} />}
          <Content className='main-content'>
            {children}
          </Content>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}