import { Card, Skeleton, Statistic } from 'antd';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSignalCountByStrategyThunk } from 'signal/slice';
import ClassifyDistributionPie from './ClassifyDistribution';

import  './index.scss';
import { BuyTag, SellTag } from '../TypeTag';

interface SignalCountStatisticProps {
  date?: string;
}

const SignalCountStatistic: FunctionComponent<SignalCountStatisticProps> = ({ date }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchSignalCountByStrategyThunk(date));
  }, [dispatch, date]);

  const data = useSelector((state: RootState) => state.signal.countByStrategy);

  if (!data) {
    return <Skeleton active />;
  }

  return (
    <Card title={`信号统计: ${data.date}`} className='signal-count-statistic'>
      {data.detail.map((s) => {
        const listUrl = `/signals/group_viewer?strategy_id=${s.id}&date=${data.date}`;
        return (
          <Card size="small" title={s.name} key={s.id} className="signal-strategy-card">
            <Statistic
              key={s.id}
              title="总计"
              className="strategy-count"
              valueRender={() => (
                <div className='count-wrapper'>
                  {s.count}
                  <BuyTag>
                    <Link to={`${listUrl}&type=BUY`} target="_blank">
                      {s.buy_count}
                    </Link>
                  </BuyTag>
                  <SellTag>
                    <Link to={`${listUrl}&type=SELL`} target="_blank">
                      {s.sell_count}
                    </Link>
                  </SellTag>
                </div>
              )}
            />
            <div className="signal-classify-dist-charts">
              <ClassifyDistributionPie
                data={s.classifyDistribution}
                classifyType="I"
                classifyLevel={2}
              />
              <ClassifyDistributionPie
                data={s.classifyDistribution}
                classifyType="I"
                classifyLevel={3}
              />
              <ClassifyDistributionPie data={s.classifyDistribution} classifyType="N" />
            </div>
          </Card>
        );
      })}
    </Card>
  );
};

export default SignalCountStatistic;