import classNames from 'classnames';
import { range } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { TrenderData } from './slice';
import { Tag } from 'antd';
import Badge from 'antd/lib/badge';

const TopTrenderTable: FunctionComponent<{ data: TrenderData }> = ({ data }) => {
  const [code, setCode] = useState<string | null>(null);

  return (
    <div className="top-trender">
      <div className="top-trender-rank">
        <div className="date header">排名</div>
        <div className="items">
          {range(1, 11).map((rank) => (
            <div className="item" key={rank}>
              {rank}
            </div>
          ))}
        </div>
      </div>
      <div className="content">
        {Object.keys(data).map((date) => {
          return (
            <div className="top-trender-daily" key={date}>
              <div className="date header">{moment(date, 'YYYY-MM-DD').format('MM-DD')}</div>
              <div className="items">
                {data[date].map((c) => {
                  return (
                    <div
                      className={classNames({
                        item: true,
                        active: code && c.code === code,
                        watching: c.is_watching && c.is_watching.signal_type === 'BUY',
                      })}
                      key={c.code}
                      onMouseEnter={(e) => {
                        setCode(c.code);
                      }}
                      onMouseLeave={(e) => setCode(null)}
                      onClick={(e) => {
                        window.open(`/classify/ths/${c.code}`, 'blank');
                      }}
                    >
                      <Badge count={c.repeat}>{c.name}</Badge>
                      {c.is_watching && c.is_watching.signal_type === 'BUY' && (
                        <span>{c.is_watching.signal_date.slice(5)}</span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TopTrenderTable;
