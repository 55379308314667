import React from 'react';

import './index.scss';
import { useFeatureSelector } from './hooks';
import { Button, Skeleton, Tree } from 'antd';

export interface FeatureSelectorProps {
  onChange?: (selectedIds: string[]) => void;
  selectedIds?: string[];
  readonly?: boolean;
}

const FeatureSelector: React.FC<FeatureSelectorProps> = ({ onChange, selectedIds, readonly }) => {
  const { state, handlers } = useFeatureSelector(onChange, selectedIds);

  if (!state.ready) {
    return <Skeleton />;
  }

  return (
    <div className="feature-selector">
      {!readonly && (
        <div className="candidate-tree">
          共计: {state.totalFeatures}个特征, 剩余:{' '}
          {state.totalFeatures - state.selectedFeatures.length}个特征
          <Tree
            defaultExpandAll
            treeData={state.candidateTree}
            multiple
            showIcon
            disabled={readonly}
            selectedKeys={state.selectedCandidateFeatures}
            onSelect={(selectedKeys) => {
              handlers.selectCandidateFeatures(selectedKeys as string[]);
            }}
          />
        </div>
      )}
      {!readonly && (
        <div className="operations">
          <Button onClick={() => handlers.addFeatures()} disabled={readonly}>
            {'添加>>'}
          </Button>
          <Button onClick={() => handlers.removeFeatures()} disabled={readonly}>
            {'移除<<'}
          </Button>
        </div>
      )}
      <div className="current-tree">
        已选: {state.selectedFeatures.length}个特征
        <Tree
          defaultExpandAll
          treeData={state.currentTree}
          multiple
          disabled={readonly}
          showIcon
          selectedKeys={state.selectedCurrentFeatures}
          onSelect={(selectedKeys) => {
            handlers.selectCurrentFeatures(selectedKeys as string[]);
          }}
        />
      </div>
    </div>
  );
};

export default FeatureSelector;
