import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DataWithPagination } from "app/services/api";
import moment from "moment";
import { fetchSignalList } from "signal/api";
import { Signal } from "signal/models";
import { fetchCandleData, fetchTickAnalysis } from "stock/api";
import { Stock } from "stock/models";
import { StockData, StockGroupItem } from "./models";
import { makeGroupItemKey } from "./utils";

export interface StockGroupViewerState {
  group: StockGroupItem[]; // 分组数据
  curIdx: number; // 当前组的第几个股票
  curItem?: StockGroupItem; // 当前的GroupItem
  hasPrev: boolean; // 是否有上一个
  hasNext: boolean; // 是否有下一个
  total: number; // 总共多少个
  dataset: Record<Stock['code'], StockData>;
}

const initialState: StockGroupViewerState = {
  group: [],
  curIdx: 0,
  total: 0,
  hasNext: false,
  hasPrev: false,
  dataset: {}
};

export const fetchStockDataThunk = createAsyncThunk(
  'stock/groupViewer/fetchStockData',
  async ({ groupItem, idx }: { groupItem: StockGroupItem; idx: number }) => {
    // 以信号发生日前三个月为起点
    const occurredAt = moment(groupItem.occurredAt);
    const start = occurredAt.subtract(6, 'months').format('YYYYMMDD');
    const end = occurredAt.add(9, 'months').format('YYYYMMDD');
    const payload = {
      code: groupItem.code,
      start,
      end,
    };
    return Promise.all([
      idx,
      groupItem,
      (await fetchCandleData(payload)).data,
      (await fetchTickAnalysis(payload)).data,
      (await fetchSignalList(payload)).data,
    ]);
  }
);


const groupViewerSlice = createSlice({
  name: 'stock/groupViewer',
  initialState,
  reducers: {
    initGroup: (state, { payload }) => {
      state.group = payload.group;
      state.total = payload.group.length;
      let curIdx = 0;
      if (payload.curKey) {
        curIdx = payload.group.findIndex(
          (x: StockGroupItem) => makeGroupItemKey(x) === payload.curKey
        );
      }
      state.curIdx = curIdx;
      state.curItem = payload.group[curIdx];
      state.hasPrev = curIdx > 0;
      state.hasNext = curIdx < payload.group.length - 1;
    },
    viewGroupItem: (state, { payload }) => {
      const curIdx = payload;
      if (curIdx < 0) {
        return;
      }

      state.curIdx = curIdx;
      state.curItem = state.group[curIdx];
      state.hasPrev = curIdx > 0;
      state.hasNext = curIdx < state.total - 1;
    }
  },
  extraReducers: (build) => {
    build.addCase(fetchStockDataThunk.fulfilled, (state, { payload }) => {
      const [nextIdx, groupItem, candleData, tickAnalysisData, signals] = payload;

      state.curIdx = nextIdx;
      state.curItem = groupItem;
      state.hasPrev = nextIdx > 0;
      state.hasNext = nextIdx < state.total - 1;
      state.dataset[makeGroupItemKey(groupItem)] = {
        tickAnalysis: tickAnalysisData,
        signals: signals as DataWithPagination<Signal>,
        ...candleData,
      }
    })
  }
});

export const { initGroup, viewGroupItem } = groupViewerSlice.actions;

export default groupViewerSlice.reducer;