import { EditOutlined } from '@ant-design/icons';
import {
  ModalForm,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTreeSelect,
} from '@ant-design/pro-components';
import { Button, FormInstance } from 'antd';
import {
  createDailyFeatureSpec,
  updateDailyFeatureSpec,
} from 'feature/api';
import { DailyFeatureSpec, FeatureCategory } from 'feature/models';
import React, { FunctionComponent, useEffect } from 'react';
import { useAppSelector } from 'app/hooks';
import { fetchFeatureCategoriesThunk } from 'feature/slice';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { TreeProps } from 'antd/lib/tree';

// DailyFeatureSpecForm是一个带有category属性的DailyFeatureSpec, 但是在mode为CREATE时，可能只有categry
type DailyFeatureSpecForm =
  | (DailyFeatureSpec & { category: { label: string; value: string } })
  | { category: { label: string; value: string } };


interface SpecFormProps {
  onFinish?: () => void;
  category?: Pick<FeatureCategory, 'id' | 'name'>;
  mode?: 'CREATE' | 'EDIT';
  spec?: DailyFeatureSpec;
}

const SpecForm: FunctionComponent<SpecFormProps> = ({
  onFinish,
  category,
  spec,
  mode = 'CREATE',
}) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<FormInstance>();
  const featureCategories = useAppSelector((state) => state.feature.categories);

  let featureSpec: DailyFeatureSpecForm;
  let title: string;
  let trigger;

  useEffect(() => {
    dispatch(fetchFeatureCategoriesThunk());
  }, [spec, mode, category]);

  if (featureCategories.nodes.length === 0) {
    return null;
  }

  if (mode === 'EDIT') {
    title = `编辑特征-名称:${spec?.name}`;
    trigger = (
      <Button type="primary" size="small">
        <EditOutlined />
      </Button>
    );
    const curCategory = featureCategories.nodes.find((item) => item.id === spec?.category_id);
    featureSpec = {
      ...spec,
      category: {
        label: curCategory?.name as string,
        value: curCategory?.id as string,
      },
    };
  } else {
    title = `新建特征-类别:${category && category.name}`;
    trigger = <Button type="primary">新建</Button>;
    featureSpec = {
      category: {
        label: category?.name as string,
        value: category?.id as string,
      },
      status: 'ACTIVE',
    }
  }

  return (
    <ModalForm<DailyFeatureSpec & { category: { label: string; value: string } }>
      title={title}
      formRef={formRef}
      initialValues={featureSpec}
      trigger={trigger}
      onFinish={async (values) => {
        let response;
        if (mode === 'EDIT') {
          values.id = spec?.id as string;
          values.category_id = values.category.value;
          // 移除values中的category
          const { category, ...rest } = values;
          response = await updateDailyFeatureSpec(rest);
        } else {
          values.category_id = category?.id as string;
          response = await createDailyFeatureSpec(values);
        }
        if (response.isOK) {
          formRef.current?.resetFields();
          onFinish && onFinish();
          return true;
        } else {
          return false;
        }
      }}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {
          formRef.current?.resetFields();
        },
      }}
    >
      <ProFormText name="name" label="名称" />
      <ProFormText name="label" label="标签" />
      <ProFormText name="description" label="描述" />
      {mode === 'EDIT' && (
        <ProFormTreeSelect
          name="category"
          placeholder="Please select"
          allowClear
          width={330}
          secondary
          label="类别"
          // tree-select args
          fieldProps={{
            // Redux中的数据是immutable的，所以需要cloneDeep
            treeData: cloneDeep(featureCategories.treeNodes),
            showArrow: false,
            filterTreeNode: true,
            showSearch: true,
            popupMatchSelectWidth: false,
            labelInValue: true,
            autoClearSearchValue: true,
            multiple: false,
            treeNodeFilterProp: 'title',
            fieldNames: {
              label: 'title',
            },
          }}
        />
      )}
      <ProFormTextArea name="transformer" label="代码" />
      <ProFormSelect
        name="status"
        label="状态"
        options={[
          { label: '启用', value: 'ACTIVE' },
          { label: '禁用', value: 'INACTIVE' },
        ]}
      />
    </ModalForm>
  );
};

export default SpecForm;
