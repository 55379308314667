import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React, { FunctionComponent } from 'react';

import SearchInput from 'stock/components/SearchInput';
import BrowserHistory from 'stock/components/BrowserHistory';

import './index.scss';
import { addBrowserItem } from 'stock/components/BrowserHistory/slice';
import { openNewStockPage } from 'stock/utils';
import { useContextDispatch } from 'app/hooks';
import PageHeader from 'app/components/PageHeader';

interface DashboardProps { }

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const dispatch = useContextDispatch();

  const onSelectStock = (value: string) => {
    const code = value.split(' ')[0];
    openNewStockPage(code);
    dispatch(addBrowserItem(code));
  };

  return (
    <WorkdeskLayout pageName='stock-dashboard'>
      <PageHeader title='股票行情'/>
      <SearchInput onSelect={onSelectStock} />
      <BrowserHistory />
    </WorkdeskLayout>
  );
}

export default Dashboard;