import { Button, Select } from "antd";
import { RootState } from "app/store";
import classNames from "classnames";
import React, { FunctionComponent, useEffect } from "react";
import { useSelector } from "react-redux";
import { PositiveTag } from "../../components";
import { GROUP_SIZE } from "../../slice";
import { useGroupCompareActions } from "../hooks";

import "./index.scss";

const { Option } = Select;

const TargetSelector: FunctionComponent<{}> = () => {
  const curGroup = useSelector((state: RootState) => state.analysis.crossComparison.group);
  const resultSet = useSelector((state: RootState) => state.analysis.crossComparison.resultSet);
  const actions = useGroupCompareActions();

  useEffect(() => {
    actions.prepareGroup(resultSet, curGroup.groupIdx);
  }, [resultSet, curGroup.groupSize]);

  return (
    <div className="target-selector">
      <div className="selector-options">
        <div className="group-size item">
          <span>每组个数: </span>
          <Select
            defaultValue={curGroup.groupSize}
            style={{ width: 60 }}
            onChange={actions.setGroupSize}
          >
            {GROUP_SIZE.map((x) => (
              <Option key={x} value={x}>
                {x}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="selector-status">
        <div className="total-count">区间总数: {resultSet.length}</div>
        <div className="current-selected-count">当前已选: {curGroup.selectedKeys.length}</div>
      </div>
      <div id="target-items" className="target-items">
        {resultSet.map((item) => {
          const { code, name, occurredAt, positive } = item;
          const key = `${code}_${occurredAt}`;
          const selected = curGroup.selectedKeys.includes(key);

          return (
            <div
              key={key}
              className={classNames("range", {
                selected,
              })}
              id={key}
            >
              <div className="range-item" onClick={() => actions.selectItem(key, !selected)}>
                <PositiveTag positive={positive} />
                {name}
                <span className="range-item start">{occurredAt}</span>
              </div>
              <Button
                type="primary"
                size="small"
                className="check-detail-btn"
                onClick={() => actions.checkStockDetail(key)}
              >
                详情
              </Button>
            </div>
          );
        })}
      </div>
      <div className="target-actions">
        {curGroup.hasPrev && (
          <Button type="primary" className="prev-group-btn" onClick={actions.prevGroup}>
            上一组
          </Button>
        )}
        {curGroup.hasNext && (
          <Button type="primary" className="next-group-btn" onClick={actions.nextGroup}>
            下一组
          </Button>
        )}
      </div>
    </div>
  );
};

export default TargetSelector;
