import React from 'react';
import { AimOutlined, FolderOutlined } from '@ant-design/icons';
import { DataNode, TreeProps } from 'antd/lib/tree';
import { TreeNode } from 'app/components/Tree/models';
import { FeatureCategory, FeatureCategoryTree } from 'feature/models';
import { hasIntersection } from 'app/utils';
import { getParents } from 'app/components/Tree/utils';
import _ from 'lodash';

const featuresToTreeNodes = (features: FeatureCategory['features']): DataNode[] => {
  return features.map((feature) => ({
    key: feature.id,
    title: feature.name,
    value: feature.id,
    idx: feature.idx,
    isFeature: true,
    icon: <AimOutlined />,
    selectable: true,
  })).sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    return 1;
  });
};

// 把node数组转换成tree结构
export const generateTree = (
  nodes: FeatureCategoryTree,
  parent_id: TreeNode['parent_id'] = null
): TreeProps['treeData'] => {
  return nodes
    .filter((node) => node.parent_id === parent_id)
    .map((node) => ({
      key: node.id,
      title: node.name,
      value: node.id,
      idx: node.idx,
      selectable: false,
      icon: <FolderOutlined />,
      children: generateTree(nodes, node.id)?.concat(featuresToTreeNodes(node.features)),
    }))
    .sort((a, b) => a.idx - b.idx);
};

export const generateCandidateTree = (
  featureTree: FeatureCategoryTree,
  selectedFeatures: string[]
): TreeProps['treeData'] => {
  const nodes = featureTree.map((category) => {
    const features = category.features.filter((feature) => !selectedFeatures.includes(feature.id));
    return { ...category, features };
  });

  return generateTree(nodes);
};

export const generateCurrentTree = (
  featureTree: FeatureCategoryTree,
  selectedFeatures: string[]
): TreeProps['treeData'] => {
  // 只保留featureTree中，features的id存在于selectedFeatures的节点
  const leafs = featureTree.filter((node) => {
    const featureIds = node.features.map((f) => f.id);
    // featureIds与selectedFeatures有交集
    return hasIntersection(featureIds, selectedFeatures);
  });

  let nodes = leafs
    .flatMap((leaf) => getParents<FeatureCategory>(featureTree, leaf.id))
    .map((category) => {
      const features = category.features.filter((feature) => selectedFeatures.includes(feature.id));
      return { ...category, features };
    });

  nodes = _.uniqBy(nodes, 'id');
  return generateTree(nodes as FeatureCategoryTree);
};
