import callApi from 'app/services/api';
import { ClassifySignal, ClassifyWatchingItem, THSClassifyType } from 'classify/models';
import { Signal } from 'signal/models';

export interface THSLatestSignals {
  date?: Signal['occurred_at'];
  signals: ClassifySignal[];
  watching: ClassifyWatchingItem[];
}

export interface THSSignalsParams {
  type: keyof typeof THSClassifyType;
  level?: number;
}

export const fetchTHSSignals = (params: THSSignalsParams) => {
  return callApi<THSLatestSignals>({
    endpoint: `/classify/ths/signals/latest`,
    data: params,
  });
};
