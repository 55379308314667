import { ProCard } from '@ant-design/pro-components';
import { fetchModelSpecStats } from 'ai/api';
import { AIModelSpecStats } from 'ai/models';
import { Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import React, { useEffect, useState } from 'react';
import { makeModelSpecStatsChartOptions } from './chatOptions';

import './index.scss';

interface ModelSpecStatsCardProps {
  modelSpecId: string;
}

const ModelSpecStatsCard: React.FC<ModelSpecStatsCardProps> = ({ modelSpecId }) => {
  const [stats, setStats] = useState<AIModelSpecStats>();

  useEffect(() => {
    fetchModelSpecStats(modelSpecId).then((result) => {
      setStats(result.data);
    });
  }, [modelSpecId]);

  if (!stats) {
    return <Skeleton active />;
  }

  return (
    <ProCard
      className="model-spec-stats-card"
      bordered
      size="small"
      title="实际表现"
      split="horizontal"
    >
      <ProCard className="overview" title="概述">
        开始日期: {stats.start_date}
        <br />
        结束日期：{stats.end_date}
        <br />
        平均AUC： {stats.auc}
        <div className="detail">
          {[0.8, 0.9].map((prob) => {
            const detailStats = stats.detail[prob];
            if (!detailStats) {
              return null;
            }
            return (
              <ProCard title={prob} className="prob-threshold-card" bordered>
                Positive Count: {detailStats.positive_count}
                <br />
                Precision: {detailStats.tp_pct}
                <br />
                误判平均损失: {detailStats.fp_avg_loss}%
                <br />
              </ProCard>
            );
          })}
        </div>
      </ProCard>
      {Object.keys(stats.detail).length > 0 && (
        <ProCard className="detail" title="详细">
          <MQCharts
            options={makeModelSpecStatsChartOptions(stats.detail)}
            className="spec-stats-detail-chart"
          />
        </ProCard>
      )}
    </ProCard>
  );
};

export default ModelSpecStatsCard;
