import React, { FunctionComponent, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Card, Skeleton } from 'antd';

import {
  ClassifyTopType,
  ClassifyTopTypeText,
  THSClassifyType,
} from 'classify/models';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import { fetchTHSTopTrenderThunk } from './slice';

import TopTrenderTable from './TopTrenderTable';

import './index.scss';
import TopStatistic from './TopStatistic';

const THSTopTrender: FunctionComponent<{ topType: ClassifyTopType, count?: number }> = ({ topType, count = 20 }) => {
  const dispatch = useContextDispatch();
  const state = useSelector((state: RootState) => state.classify.thsTopTrender);

  useEffect(() => {
    dispatch(fetchTHSTopTrenderThunk({ type: topType, count }));
  }, [dispatch, topType, count]);

  if (!state.loaded) {
    return <Skeleton active />;
  }

  return (
    <Card
      title={`同花顺板块-${ClassifyTopTypeText[topType]}-趋势`}
      className="ths-top-trender"
      size="small"
    >
      {Object.keys(THSClassifyType).map((type) => {
        if (!state.data) return null;

        return (
          <Card
            key={`${topType}-${type}`}
            title={THSClassifyType[type as keyof typeof THSClassifyType]}
            className={'top-trender-card'}
            size="small"
          >
            <TopStatistic
              key={`${topType}-${type}-statistic`}
              data={state.data['statistic'][topType][type as keyof typeof THSClassifyType]}
            />
            <TopTrenderTable
              key={`${topType}-${type}-table`}
              data={state.data['trender'][topType][type as keyof typeof THSClassifyType]}
            />
          </Card>
        );
      })}
    </Card>
  );
};

export default THSTopTrender;
