import { DateRangeParams } from "app/services/api";
import { THSClassifyType } from "classify/models";

// 标的类型
export enum TargetType {
  S = 'S', // 股票
  I = 'I', // 指数
  C = 'C' // 板块
}

// 标的
export interface Target {
  code: string,
  name?: string,
  type: TargetType
}

// KChart的配置项
export interface KChartConfig {
  range: DateRangeParams,
  target: Target & {
    indicators?: string[];
  };
  refs?: Target[];
}

// KChart的配置可选项
export interface KChartOption {
  stock: {
    items: {
      [code: string]: string,
    },
    inds: {
      [s: string]: string,
    }
  },
  sector: {
    items: {
      [code: string]: {
        name: string,
        type: THSClassifyType,
        level: number,
      }
    },
    inds: {
      [s: string]: string,
    }
  }
  index: {
    items: {
      [code: string]: string,
    },
    inds: {
      [s: string]: string,
    }
  }
}

export interface KChartIndData {
  symbol: string;
  name: string;
  data: Array<{ date: string; value: string }>;
}

export interface KData {
  low: number;
  high: number;
  open: number;
  close: number;
  date: string;
}

export type KChartRefData = Target & { k: KData[] };
export type KChartTargetData = KChartRefData & {
  indicators: KChartIndData[];
};

// KChart数据结构
export interface KChartData {
  range: {
    start: string;
    end: string;
  };
  target: KChartTargetData;
  refs: Array<KChartRefData>;
}