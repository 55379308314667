import callApi, { DateRangeParams } from 'app/services/api';
import {
  CandleType,
  Stock,
  StockCandleData,
  StockClassifies,
  StockTickAnalysis,
  StockWithDailyDetail,
  ZhangtingStock,
  ZhangtingTrender,
} from './models';


export interface CandleDataParams extends Partial<DateRangeParams> {
  code: string,
  type?: CandleType,
}

export interface TickAnalysisParams extends Partial<DateRangeParams> {
  code: string,
}

export const fetchCandleData = async function (params: CandleDataParams) {
  const code = params.code;

  return callApi<{ [key in  CandleType]: StockCandleData[] }>({
    endpoint: `/stock/${code}/candle_data`,
    data: params,
  });
}

export const fetchTickAnalysis = async function (params: TickAnalysisParams) {
  const code = params.code;

  return callApi<StockTickAnalysis[]>({ endpoint: `/stock/${code}/tick_analysis`, data: params });
}

export const fetchStockList = async function () {
  return callApi<Stock[]>({ endpoint: '/stocks' })
}

export type StockLatestDataParams = Array<Stock['code']>;

export const fetchLatestData = async function (codes: StockLatestDataParams) {
  return callApi<StockWithDailyDetail[]>({
    endpoint: '/stocks/latest_data', data: {
      codes: codes.join(',')
    }
  })
};

export const fetchClassifies = async (code: Stock['code']) => {
  return callApi<StockClassifies>({ endpoint: `/stock/${code}/classifies` });
};

export const fetchStockDetail = async (code: Stock['code']) => {
  return callApi<StockWithDailyDetail>({ endpoint: `/stock/${code}/detail`});
}

export const fetchZhangtingStocks = async () => {
  return callApi<ZhangtingStock[]>({ endpoint: '/stocks/zhangting_stocks' });
};

export const fetchZhangtingTrenders = async (count: number) => {
  return callApi<ZhangtingTrender>({ endpoint: '/stocks/zhangting_trender', data: { count } });
};