import { useState, useEffect, useMemo } from 'react';
import { TreeApi, TreeNode, Tree } from './models';
import { arrayToTree } from './utils';

interface OperateConfig {
  selectedNode: TreeNode['id'] | null;
  formOpen: boolean;
  mode: 'CREATE' | 'UPDATE' | null;
}

export function useTree(apiSpec: TreeApi) {
  const [tree, setTree] = useState<Tree>([]);
  const [operateConfig, setOperateConfig] = useState<OperateConfig>({
    selectedNode: null,
    formOpen: false,
    mode: null,
  });

  useEffect(() => {
    // 在组件挂载时获取树的数据
    const init = async () => {
      const tree = (await apiSpec.fetchTree()).data;
      setTree(tree);
    };

    init();
  }, [apiSpec]);

  const handlers = {
    createNode: async (name: string, parentId: TreeNode['parent_id']) => {
      const newNode = (await apiSpec.createNode(name, parentId)).data;
      // 更新树的状态
      setTree((prevTree: Tree) => [...prevTree, newNode]);
    },
    updateNode: async (node: TreeNode) => {
      const updatedNode = (await apiSpec.updateNode(node)).data;
      // 更新树的状态
      setTree((prevTree: Tree) =>
        prevTree.map((n: TreeNode) => (n.id === updatedNode.id ? updatedNode : n))
      );
    },
    deleteNode: async (nodeId: TreeNode['id']) => {
      const result = await apiSpec.deleteNode(nodeId);
      if (result.isOK) {
        // 更新树的状态
        setTree((prevTree: Tree) => prevTree.filter((n: TreeNode) => n.id !== nodeId));
      }
    },
    moveNode: async (nodeId: TreeNode['id'], parentId: TreeNode['parent_id'], idx: TreeNode['idx']) => {
      const movedNode = (await apiSpec.moveNode(nodeId, parentId, idx)).data;
      // 更新树的状态
      setTree((prevTree: Tree) => {
        const newTree = [...prevTree];
        const node = newTree.find((n: TreeNode) => n.id === movedNode.id);
        // 如果移动前和移动后的父节点相同，说明是同级移动，需要更新自身以及兄弟节点的idx
        if (node && node.parent_id === movedNode.parent_id) {
          const siblings = newTree.filter((n: TreeNode) => n.parent_id === movedNode.parent_id);
          siblings.forEach((n: TreeNode) => {
            if (n.id !== movedNode.id) {
              if (n.idx >= movedNode.idx) {
                n.idx += 1;
              }
            } else {
              n.idx = movedNode.idx;
            }
          });
        } else {
          // 如果移动前和移动后的父节点不同，则之前的父节点和之后的父节点都需要更新
          const prevParent = newTree.find((n: TreeNode) => n.id === node?.parent_id);
          const nextParent = newTree.find((n: TreeNode) => n.id === movedNode.parent_id);
          if (prevParent) {
            newTree
              .filter((n: TreeNode) => n.parent_id === prevParent.id)
              .forEach((n: TreeNode) => {
                if (n.id !== movedNode.id) {
                  if (n.idx >= node!.idx) {
                    n.idx -= 1;
                  }
                }
              });
          }
          if (nextParent) {
            newTree
              .filter((n: TreeNode) => n.parent_id === nextParent.id)
              .forEach((n: TreeNode) => {
                if (n.id !== movedNode.id) {
                  if (n.idx >= movedNode.idx) {
                    n.idx += 1;
                  }
                }
              });
          }
        }
        return newTree.map((n: TreeNode) => (n.id === movedNode.id ? movedNode : n));
      });
    },
    startOperate: (config: OperateConfig) => {
      setOperateConfig(config);
    },
    endOperate: () => {
      setOperateConfig({
        selectedNode: null,
        formOpen: false,
        mode: null,
      });
    },
  };

  const treeData = useMemo(() => {
    return arrayToTree(tree);
  }, [tree]);

  return {
    state: {
      nodes: tree,
      treeData,
      operateConfig,
    },
    handlers,
  };
}
