import { TableProps } from "app/components/Table";
import { PaginationParams } from "app/services/api";
import { useCallback } from "react";
import { fetchAnalysisResults } from "./api";
import { AnalysisResult } from "../CrossComparison/models";

export const useResultTableActions = () => {
  const request: TableProps<AnalysisResult>["request"] = useCallback(async (params, ...rest) => {
    const _params: PaginationParams = {
      page: params.current,
      per_page: params.pageSize,
    };

    const payload = await fetchAnalysisResults(_params);

    return {
      data: payload.data.items,
      success: payload.isOK,
      total: payload.data.pagination?.total_count,
    };
  }, []);

  return {
    request,
  }
};
