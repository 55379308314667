import React, { FunctionComponent } from 'react';
import { Tabs } from 'antd';

import './index.scss';
import { MLModelName, MLModelPredictTable } from './modelTables';

const MLModelPredictPane: FunctionComponent<{}> = () => {
  // const dispatch = useContextDispatch();

  // useEffect(() => {
  //   dispatch(fetchMLModelPredictListThunk());
  // }, [dispatch]);

  // const data = useSelector((state: RootState) => state.strategy.mlModelPredicts.list);

  return (
    <div className="ml-model-predict-pane">
      <Tabs defaultActiveKey="XGB35_All" type="card">
        {Object.keys(MLModelName).map((model) => (
          <Tabs.TabPane tab={model} key={model}>
            <MLModelPredictTable modelName={model as MLModelName} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default MLModelPredictPane;
