import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MLModelPredict } from 'strategy/models';
import { fetchMLModelPredictLatest, fetchMLModelPredictList } from './api';
import { TableState } from 'app/components/Table/hooks';

export type MLModelPredictState = {
  list: MLModelPredict[],
  latest: MLModelPredict[],
}

const initialState: MLModelPredictState = { list: [], latest: [] };

export const fetchMLModelPredictLatestThunk = createAsyncThunk(
  'strategy/ml_model_predicts/latest',
  async () => {
    return (await fetchMLModelPredictLatest()).data;
  }
);

export const fetchMLModelPredictListThunk = createAsyncThunk(
  'strategy/ml_model_predicts/list',
  async (params?: TableState) => {
    return (await fetchMLModelPredictList(params)).data;
  }
);
/**
 * 计算预测的分数
 * 如果r.model.score不存在，记为0
 * r.score = r.prob * r.model.score * 100 + r.model.mean_loss
 */
const calculateScore = (r: MLModelPredict) => {
  return {
    ...r,
    score: r.model.score ? r.prob * r.model.score * 100 + r.model.mean_loss : 0,
  };
};


const slice = createSlice({
  name: 'mlModelPredicts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMLModelPredictLatestThunk.fulfilled, (state, { payload }) => {
      state.latest = payload.map(calculateScore);
    });
    builder.addCase(fetchMLModelPredictListThunk.fulfilled, (state, { payload }) => {
      state.list = payload.map(calculateScore);
    });
  },
});

export default slice.reducer;