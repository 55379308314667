import { Stock, StockCandleData, StockTickAnalysis } from 'stock/models';
import { Strategy } from 'strategy/models';
import { BaseModel } from 'app/models';
import { Classify } from 'classify/models';

export enum SignalType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface Signal extends BaseModel {
  code: string;
  occurred_at: number;
  type: SignalType;
  strategy: Strategy;
  stock: Stock;
  strength: number;
  daily_data: Pick<StockCandleData, 'close' | 'price_chg' | 'pct_chg'>;
  tick_data: Pick<StockTickAnalysis['components'], 'major_pct'>;
}

export type CountByClassify = Pick<Classify, 'code' | 'name' | 'type' | 'level'> & {
  count: number;
  buy_count: number;
  sell_count: number;
};

export interface CountByStrategy extends Strategy {
  count: number;
  buy_count: number;
  sell_count: number;
  classifyDistribution: CountByClassify[];
}
