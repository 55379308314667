import React, { FunctionComponent } from "react";
import { Tag } from "antd";

export const PositiveTag: FunctionComponent<{ positive: boolean | undefined }> = ({ positive }) => {
  if (positive === undefined) return null;

  if (positive === true) {
    return <Tag color="#87d068">有效</Tag>;
  } else {
    return <Tag color="#f50">无效</Tag>;
  }
};