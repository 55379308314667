import callApi, { DataWithPagination, FetchApiFunc, PaginationParams } from "app/services/api";
import { AnalysisResult } from "../CrossComparison/models";

export const fetchAnalysisResults: FetchApiFunc<DataWithPagination<AnalysisResult>> = (
    params: PaginationParams
) => {
    return callApi({
        endpoint: "/analysis/results",
        method: "GET",
    });
};
