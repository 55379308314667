import React, { FunctionComponent, useEffect } from 'react';

import { useContextDispatch } from 'app/hooks';
import { fetchHSGTThunk } from 'market/slice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

import { Skeleton, Card, Row, Col, Statistic } from 'antd';
import classNames from 'classnames';

import './index.scss';

import TimeSeriesChart, { TimeSeriesChartProps } from '../TimeSeriesChart';
import { AmountFormatter, AmountUnit } from 'market/utils';
import { ModelMeta } from 'app/models';


const chartFieldsConfig: TimeSeriesChartProps['fieldsConfig'] = [
  {
    dataIndex: 'south_money',
    series: {
      type: 'bar',
    }
  },
  {
    dataIndex: 'north_money',
    series: {
      type: 'bar',
    }
  },
];

const HSGTDashboard: FunctionComponent<{count?: number}> = ({ count }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchHSGTThunk({ count }));
  }, [dispatch]);

  const state = useSelector((state: RootState) => state.market.hsgt);

  if (!state) {
    return <Skeleton active />;
  }

  const data = state.data;
  const meta = new ModelMeta(state.meta);

  const latest = data[0];

  return (
    <div className="dashboard-section hsgt">
      <Card title={'沪深港通:' + latest.date} className="latest" size="small">
        <Statistic
          title={meta.getTitle('north_money')}
          value={new AmountFormatter(latest.north_money, AmountUnit.Million).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
          className={classNames(latest.north_money > 0 ? 'value-up' : 'value-down')}
        />
        <Statistic
          title={meta.getTitle('south_money')}
          value={new AmountFormatter(latest.south_money, AmountUnit.Million).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
          className={classNames(latest.south_money > 0 ? 'value-up' : 'value-down')}
        />
      </Card>
      <Card title="成交额趋势" className="trender" size='small'>
        <TimeSeriesChart
          className="hsgt-trender-chart"
          data={data}
          meta={meta}
          fieldsConfig={chartFieldsConfig}
          unit={AmountUnit.Million}
        />
      </Card>
    </div>
  );
}

export default HSGTDashboard;