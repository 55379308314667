import { Account } from 'account/models';
import { EChartsOption } from 'echarts';

export const getHistoriesNetValueChartOptions = (histories: Account['histories']): EChartsOption => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['净值'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: histories.map((h) => h.date),
    },
    yAxis: {
      type: 'value',
      scale: true,
    },
    series: [
      {
        name: '净值',
        type: 'line',
        data: histories.map((h) => h.net_value),
      },
    ],
  };
};

export const getHistoriesAmountChartOptions = (histories: Account['histories']): EChartsOption => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['总资产'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: histories.map((h) => h.date),
    },
    yAxis: {
      type: 'value',
      scale: true,
    },
    series: [
      {
        name: '总资产',
        type: 'line',
        data: histories.map((h) => h.amount),
      },
    ],
  };
};
