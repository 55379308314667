import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React, { FunctionComponent } from 'react';

import './index.scss';
import Tabs from 'antd/lib/tabs';
import DailyPredicts from './DailyPredicts';
import RangeStatistic from './RangeStatistic';

const AIModelPredicts: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName="ai-model-predicts" title="模型预测">
      <Tabs defaultActiveKey="daily-predicts">
        <Tabs.TabPane tab="按天" key="daily-predicts">
          <DailyPredicts />
        </Tabs.TabPane>
        <Tabs.TabPane tab="统计" key="range-statistic">
          <RangeStatistic count={70} />
        </Tabs.TabPane>
      </Tabs>
    </WorkdeskLayout>
  );
};

export default AIModelPredicts;
