import { Card, Skeleton, Statistic } from 'antd';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StockLimitClassifyDistribution from './ClassifyDistribution';

import './index.scss';
import { fetchStockLimitDailyStatThunk } from './slice';

const StockLimitDailyStat: FunctionComponent<{ date?: string }> = ({date}) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchStockLimitDailyStatThunk(date));
  }, [dispatch, date]);

  const _data = useSelector((state: RootState) => state.stocks.limitDailyStat);

  const key = date || 'latest';

  if (!(key in _data)) {
    return <Skeleton active />;
  }

  const data = _data[key];

  return (
    <Card title={`涨跌停分布: ${data.date}`} className="stock-limit-count-statistic">
      <Statistic
        key="stock-limit-count"
        title="总计"
        value={`${data.count}(涨停: ${data.up}, 跌停:${data.down})`}
      />
      <div className="stock-limit-classify-charts">
        <StockLimitClassifyDistribution data={data.classify_distribution} classifyType={'I'} classifyLevel={2} />
        <StockLimitClassifyDistribution data={data.classify_distribution} classifyType={'I'} classifyLevel={3} />
        <StockLimitClassifyDistribution data={data.classify_distribution} classifyType={'N'} />
      </div>
    </Card>
  );
}

export default StockLimitDailyStat;