import { AnyAction } from '@reduxjs/toolkit';
import ModalForm from 'account/components/AccountForm';
import { Account } from 'account/models';
import { deleteAccountThunk, fetchAccountListThunk } from 'account/slice';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Table, { ColumnProps } from 'app/components/Table';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import { dispatch } from 'app/store';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const AccountListColumns: ColumnProps<Account>[] = [
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
    render: (text, r) => <Link to={`/account/${r.id}`}>{text}</Link>,
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '最新净值',
    dataIndex: 'net_value',
    key: 'net_value',
  },
  {
    title: '总资产',
    dataIndex: 'total',
    key: 'total',
  },
  {
    title: '持仓',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: '最新日期',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: '操作',
    key: 'operation',
    render: (text, r) => {
      return (
        <>
          <ModalForm
            mode="UPDATE"
            account={r}
            trigger={
              <Button type="primary" size="small">
                编辑
              </Button>
            }
          />
          <Button
            type="primary"
            size="small"
            danger
            onClick={() => {
              Modal.confirm({
                title: `确认删除-${r.title}`,
                content: '删除后不可恢复',
                onOk: async () => {
                  dispatch(deleteAccountThunk(r.id) as unknown as AnyAction);
                },
              });
            }}
          >
            删除
          </Button>
        </>
      );
    },
  },
];

const AccountList = () => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector((state) => state.account.accounts);

  useEffect(() => {
    dispatch(fetchAccountListThunk());
  }, [dispatch]);

  return (
    <WorkdeskLayout title="账户列表">
      <ModalForm mode="CREATE" />
      <Table
        columns={AccountListColumns}
        dataSource={accounts}
        toolBarRender={false}
        search={false}
      />
    </WorkdeskLayout>
  );
};

export default AccountList;
