import callApi from 'app/services/api';
import { Account, AccountAmountRecord, AccountTradeRecord } from './models';
import { PredictAggregation } from 'ai/models';
import { TableState } from 'app/components/Table/hooks';

export const fetchAccountList = async () => {
  return callApi<Account[]>({
    endpoint: '/accounts',
    method: 'GET',
  });
};

export const deleteAccount = async (id: string) => {
  return callApi<Account>({
    endpoint: `/accounts/${id}`,
    method: 'DELETE',
  });
};

export const createAccount = async (account: Account) => {
  return callApi<Account>({
    endpoint: '/accounts',
    method: 'POST',
    data: account,
  });
};

export const updateAccount = async (account: Account) => {
  return callApi<Account>({
    endpoint: `/accounts/${account.id}`,
    method: 'PUT',
    data: account,
  });
};

export const fetchAccountDetail = async (id: string) => {
  return callApi<Account>({
    endpoint: `/accounts/${id}`,
    method: 'GET',
  });
};

export const createAccountAmountRecord = async (record: AccountAmountRecord) => {
  return callApi<AccountAmountRecord>({
    endpoint: `/accounts/${record.account_id}/add_amount_record`,
    method: 'POST',
    data: record,
  });
};

export const deleteAccountAmountRecord = async (accountID: string, recordID: string) => {
  return callApi<AccountAmountRecord>({
    endpoint: `/accounts/${accountID}/remove_amount_record/${recordID}`,
    method: 'DELETE',
  });
};

export const createAccountTradeRecord = async (record: AccountTradeRecord) => {
  return callApi<AccountTradeRecord>({
    endpoint: `/accounts/${record.account_id}/add_trade_record`,
    method: 'POST',
    data: record,
  });
};

export const deleteAccountTradeRecord = async (accountID: string, recordID: string) => {
  return callApi<AccountTradeRecord>({
    endpoint: `/accounts/${accountID}/remove_trade_record/${recordID}`,
    method: 'DELETE',
  });
};

export const updateAccountHistories = async (id: string) => {
  return callApi<Account>({
    endpoint: `/accounts/${id}/update_histories`,
    method: 'PUT',
  });
};

interface FetchAccountSignalParams extends TableState {
  accountID: string;
}

export const fetchAccountSignals = async (params: FetchAccountSignalParams) => {
  return callApi<PredictAggregation[]>({
    endpoint: `/accounts/${params.accountID}/signals`,
    method: 'GET',
  });
};