import React from 'react';

import AuthPageLayout from 'auth/pages/Layout';
import LoginForm from 'auth/components/LoginForm';


export default function LoginPage() {
  return (
    <AuthPageLayout pageName='login'>
      <LoginForm />
    </AuthPageLayout>
  );
}