import React from 'react';

import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import TurnoverDashboard from 'market/components/TurnoverDashboard';
import MarginDashboard from 'market/components/MarginDashboard';
import HSGTDashboard from 'market/components/HSGTDashboard';

import './index.scss';

export default function Dashboard() {
  return (
    <WorkdeskLayout pageName='dashboard' title="市场概览">
      <TurnoverDashboard />
      <MarginDashboard />
      <HSGTDashboard />
    </WorkdeskLayout>
  );
}