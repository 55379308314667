import TrainsetForm from 'ai/components/TrainsetForm';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React from 'react';
import { useParams } from 'react-router-dom';

const EditTrainset = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <WorkdeskLayout pageName="update-trainset" title="编辑训练集">
      <TrainsetForm mode="EDIT" id={id} />
    </WorkdeskLayout>
  );
};

export default EditTrainset;
