import { MutableRefObject, RefObject, useEffect, useRef } from "react";
import * as echarts from "echarts";
import { MQChartsProps } from ".";

export const useEcharts = (chartRef: RefObject<HTMLDivElement>, options: MQChartsProps['options'], eventHandler: MQChartsProps['eventHandler']) => {
  const chartInstance: MutableRefObject<echarts.ECharts | null> = useRef(null);

  useEffect(() => {
    const renderedInstance = echarts.getInstanceByDom((chartRef.current as HTMLDivElement));

    if (renderedInstance) {
      chartInstance.current = renderedInstance;
    } else {
      chartInstance.current = echarts.init((chartRef.current as HTMLDivElement));
    }
    chartInstance.current.setOption(options, true);

    // eventHandler需要首先off
    eventHandler && eventHandler(chartInstance.current);
  }, [chartRef, options, eventHandler]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      chartInstance.current && chartInstance.current.resize();
    });
    return () => {
      chartInstance.current && chartInstance.current.dispose();
      chartInstance.current = null;
    };
  }, []);

  return chartInstance.current;
}