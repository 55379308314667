import { useContextDispatch } from "app/hooks";
import { RootState } from "app/store";
import { scrollToElem } from "app/utils";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { fetchComparsionData } from "../api";
import { ResultSet } from "../models";
import { setGroup, startGroupViewer } from "../slice";
import { makeResultItemKey } from "../utils";


export const useGroupCompareActions = () => {
    const dispatch = useContextDispatch();
    const resultSet = useSelector((state: RootState) => state.analysis.crossComparison.resultSet);
    const curGroup = useSelector((state: RootState) => state.analysis.crossComparison.group);

    const prepareGroup = async (resultSet: ResultSet, groupIdx: number) => {
      const groupSize = curGroup.groupSize;
      const start = groupIdx * groupSize;
      const end =
        (groupIdx + 1) * groupSize > resultSet.length
          ? resultSet.length
          : (groupIdx + 1) * groupSize;
      const resultSlice = resultSet.slice(start, end);
      const groupKeys = resultSlice.map((resultItem) => makeResultItemKey(resultItem));

      const params = {
        range_data: resultSlice.map((resultItem) => [resultItem.code, resultItem.occurredAt]),
      };

      const comparsionData = (await fetchComparsionData(params)).data;

      const group = {
        groupIdx,
        selectedKeys: groupKeys,
        dataset: comparsionData,
        hasPrev: groupIdx > 0,
        hasNext: (groupIdx + 1) * groupSize < resultSet.length,
      };

      dispatch(setGroup(group));
      scrollToElem("target-items", groupKeys[0]);
    };

    const nextGroup = async () => {
        const nextGroupIdx = curGroup.groupIdx + 1;

        await prepareGroup(resultSet, nextGroupIdx);
    };

    const prevGroup = async () => {
        const prevGroupIdx = curGroup.groupIdx - 1;

        if (prevGroupIdx < 0) {
            return;
        }

        await prepareGroup(resultSet, prevGroupIdx);
    };

    // 手动选择
    const selectItem = async (value: string, checked: boolean) => {
        const compareKeys = [...curGroup.selectedKeys];

        if (checked) {
            compareKeys.push(value);
        } else {
            const idx = compareKeys.indexOf(value);
            compareKeys.splice(idx, 1);
        }

        const rangeSlice = resultSet.filter((x) =>
            compareKeys.includes(makeResultItemKey(x))
        );

        const params = {
            range_data: rangeSlice.map((_range) => [_range.code, _range.occurredAt]),
        };

        const comparsionData = (await fetchComparsionData(params)).data;

        const payload = {
            selectedKeys: compareKeys,
            dataset: comparsionData,
        }
        dispatch(setGroup(payload));
    };

    const setGroupSize = useCallback((size: number) => {
        dispatch(setGroup({
            groupSize: size,
        }))
    }, [dispatch]);

    const checkStockDetail = useCallback((resultKey) => {
        dispatch(startGroupViewer(resultKey));
      }, [dispatch]);

    return {
        prevGroup,
        nextGroup,
        selectItem,
        setGroupSize,
        checkStockDetail,
        prepareGroup,
    };
};
