import React, { FunctionComponent, useEffect } from 'react';

import { useContextDispatch } from 'app/hooks';
import { fetchMarginTunk } from 'market/slice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import classNames from 'classnames';

import { Skeleton, Card, Row, Col, Statistic } from 'antd';
import { AmountFormatter, AmountUnit } from 'market/utils';

import TimeSeriesChart, { TimeSeriesChartProps } from '../TimeSeriesChart';

import './index.scss';
import { ModelMeta } from 'app/models';

const chartFieldsConfig: TimeSeriesChartProps['fieldsConfig'] = [
  {
    dataIndex: 'rzrqye',
    series: {
      type: 'line',
    }
  },
  {
    dataIndex: 'rzye',
    series: {
      type: 'line',
    }
  },
  {
    dataIndex: 'rqye',
    series: {
      type: 'line',
    }
  },
  {
    dataIndex: 'SSE-rzrqye',
    series: {
      type: 'bar',
      // stack: 'exchange',
    }
  },
  {
    dataIndex: 'SZSE-rzrqye',
    series: {
      type: 'bar',
      // stack: 'exchange',
    }
  },
]

const MarginDashboard: FunctionComponent<{count?: number, small?: boolean}> = ({ count, small }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchMarginTunk({ count }));
  }, [dispatch]);

  const marginState = useSelector((state: RootState) => state.market.margin);

  if (!marginState) {
    return <Skeleton active />;
  }

  const marginData = marginState.data;
  const marginMeta = new ModelMeta(marginState.meta);

  const latest = marginData[0];
  const netBuy = latest.rzmre - latest.rzche; // 净买入额

  return (
    <div className="dashboard-section margin">
      <Card title={'两融数据:' + latest.date} className="latest" size='small'>
        <Statistic
          title={marginMeta.getTitle('rzrqye').split('(')[0]}
          value={new AmountFormatter(latest.rzrqye, AmountUnit.Yuan).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
        />
        <Statistic
          title={marginMeta.getTitle('rzye').split('(')[0]}
          value={new AmountFormatter(latest.rzye, AmountUnit.Yuan).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
        />
        <Statistic
          title="融资净买入额"
          value={new AmountFormatter(netBuy, AmountUnit.Yuan).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
          className={classNames(netBuy > 0 ? 'value-up' : 'value-down')}
        />
        <Statistic
          title={marginMeta.getTitle('rqye').split('(')[0]}
          value={new AmountFormatter(latest.rqye, AmountUnit.Yuan).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
        />
      </Card>
      <Card title="两融趋势" className="trender" size="small">
        <TimeSeriesChart
          data={marginData}
          meta={marginMeta}
          className="margin-trender-chart"
          fieldsConfig={chartFieldsConfig}
          unit={AmountUnit.Yuan}
        />
      </Card>
    </div>
  );
}

export default MarginDashboard;