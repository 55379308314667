import {
  EChartsOption,
  GridComponentOption,
  LegendComponentOption,
  SeriesOption,
  TitleComponentOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { DOWN_COLOR, UP_COLOR } from 'stock/components/CandleChart/constants';
import { SMA } from 'technicalindicators';
import { KChartConfig, KChartData } from './models';

type SeriesConfig = SeriesOption & {
  ma?: number[];
  visualMap?: VisualMapComponentOption;
};

type SubChartConfig = {
  label?: string;
  series: SeriesConfig[];
  grid: GridComponentOption;
};

type ChartConfig = Array<SubChartConfig>;

const cal_chg_pct = (a: number, base: number) => {
  return parseFloat((((a - base) / base) * 100).toFixed(2));
}

const formatKData = (data: KChartData['target']['k'], parseToPCT: boolean, onlyClose: boolean) => {

  if (parseToPCT) {
    if (onlyClose) {
      const base = data[0].close;
      return data.map((d) => [d.date, cal_chg_pct(d.close, base)]);
    }
    const base = data[0].open;
    return data.map((d) => [
      cal_chg_pct(d.open, base),
      cal_chg_pct(d.close, base),
      cal_chg_pct(d.low, base),
      cal_chg_pct(d.high, base),
    ]);
  } else {
    return data.map((d) => [d.open, d.close, d.low, d.high]);
  }
};

const COLOR_LIST = [
  '#2f4554',
  '#d48265',
  '#546570',
  '#749f83',
  '#bda29a',
  '#6e7074',
  '#61a0a8',
  '#91c7ae',
  '#ca8622',
  '#c4ccd3',
  '#c23531',
];

const addAlpha = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};
 // 根据数据转换成子图格式
const getChartConfig = (data: KChartData): ChartConfig => {
  const config: ChartConfig = [];

  const indCount = data.target.indicators.length;
  const refCount = data.refs.length;

  const kSereis: SeriesConfig[] = [
    {
      name: data.target.name,
      data: formatKData(data.target.k, refCount > 0, true),
      type: 'line',
      connectNulls: false,
      // ma: [5, 20, 60],
      itemStyle: {
        color: UP_COLOR,
        // color0: DOWN_COLOR,
      },
    },
  ];

  const colors = COLOR_LIST;
  data.refs.forEach((ref, idx) => {
    const color = addAlpha(colors[idx], 0.8)
    kSereis.push({
      name: ref.name,
      data: formatKData(ref.k, refCount > 0, true),
      type: 'line',
      connectNulls: false,
      itemStyle: {
        color: color,
        // color0: 'rgba(0, 0, 0, 0)',
        // borderColor: color,
        // borderColor0: color,
      },
    });
  });

  config.push({
    series: kSereis,
    grid: {
      left: '5%',
      height: `${100 - indCount * 10 - 10}%`,
    },
  });

  return config;
};

export const getOptions = (state: {
  config: KChartConfig;
  data?: KChartData;
}): EChartsOption | null => {
  if (!state || !state.data) return null;
  const { data } = state;

  let result: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      position: (pos, params, el, elRect, size) => {
        if (pos[0] < size.viewSize[0] / 2) {
          return {
            right: '10%',
            top: 10,
          };
        } else {
          return {
            left: '5%',
            top: '1%',
          };
        }
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777',
      },
    },
  };

  let series: SeriesOption[] = [];
  let legend: LegendComponentOption[] = [];
  let xAxis: XAXisComponentOption[] = [];
  let yAxis: YAXisComponentOption[] = [];
  let grid: GridComponentOption[] = [];
  let title: TitleComponentOption[] = [];
  let visualMap: VisualMapComponentOption[] = [];

  const _config = getChartConfig(data);

  let top: number = 3;
  let seriesIdx: number = 0;

  _config.forEach((sub, idx) => {
    const legendNames: string[] = [];
    sub.series.forEach((s) => {
      const _series = {
        ...s,
        xAxisIndex: idx,
        yAxisIndex: idx,
      };

      series.push(_series);

      // 添加MA
      if (s.ma) {
        const values = (s.data as any[]).map((d) => d[1]) as Array<number>;

        s.ma.forEach((ma) => {
          const maData = SMA.calculate({ period: ma, values }).map((v) => v.toFixed(2));
          const fillData = Array(values.length - maData.length).fill('-');

          series.push({
            symbol: 'none',
            name: `MA ${ma}`,
            type: 'line',
            data: fillData.concat(maData),
            smooth: true,
            xAxisIndex: idx,
            yAxisIndex: idx,
            lineStyle: {
              width: 1,
            },
          });
          legendNames.push(`MA ${ma}`);
          seriesIdx += 1;
        });
      }

      legendNames.push(s.name as string);
      if (s.visualMap) {
        visualMap.push({
          ...s.visualMap,
          seriesIndex: seriesIdx,
        });
      }
      seriesIdx += 1;
    });
    xAxis.push({
      gridIndex: idx,
      type: 'category',
      data: data.target.k.map((k) => k.date),
      boundaryGap: false,
      axisLine: { onZero: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: true },
    });
    yAxis.push({
      scale: true,
      gridIndex: idx,
    });
    legend.push({
      data: legendNames,
      left: '5%',
      top: `${top - 4} %`,
    });
    grid.push({
      ...sub.grid,
      top: `${top}%`,
    });
    title.push({
      top: `${top}%`,
      text: sub.label,
    });
    top += parseInt((sub.grid.height as string).split('%')[0]) + 5;
  });

  result.xAxis = xAxis;
  result.yAxis = yAxis;
  result.series = series;
  result.legend = legend;
  result.grid = grid;
  result.title = title;
  result.visualMap = visualMap;

  return result;
};
