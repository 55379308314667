import { createSlice } from '@reduxjs/toolkit';
import { DailyFeatureStoreInfo, FeatureCategory, FeatureSpecTarget } from './models';
import { fetchDailyFeatureStoreInfo, fetchFeatureCategoryTree } from './api';
import { createAppAsyncThunk } from 'app/redux/createAppAsyncThunk';
import { TreeProps } from 'antd/lib/tree';
import { generateTree } from './utils';

export const fetchDailyFeatureStoreInfoThunk = createAppAsyncThunk(
  'feature/fetchFeatureStoreInfoThunk',
  async (type: FeatureSpecTarget) => {
    return (await fetchDailyFeatureStoreInfo(type)).data;
  }
);

export const fetchFeatureCategoriesThunk = createAppAsyncThunk(
  'feature/fetchFeatureCategoriesThunk',
  async () => {
    return (await fetchFeatureCategoryTree()).data;
  },
  {
    condition: (_, { getState }) => {
      const { feature } = getState();
      return feature.categories.nodes.length === 0;
    },
  }
);

interface FeatureState {
  storeInfo: {
    [key: string]: DailyFeatureStoreInfo | null;
  };
  categories: {
    nodes: FeatureCategory[];
    treeNodes: TreeProps['treeData'];
  };
}

const initialState: FeatureState = {
  storeInfo: {},
  categories: {
    nodes: [],
    treeNodes: [],
  },
};

const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDailyFeatureStoreInfoThunk.pending, (state, action) => {
      state.storeInfo[action.meta.arg] = null;
    });
    builder.addCase(fetchDailyFeatureStoreInfoThunk.fulfilled, (state, action) => {
      // payload中的start_date和end_date需要转化为日期字符串
      action.payload.start_date = action.payload.start_date
        ? new Date(action.payload.start_date).toLocaleDateString()
        : '-';
      action.payload.end_date = action.payload.end_date
        ? new Date(action.payload.end_date).toLocaleDateString()
        : '-';
      state.storeInfo[action.meta.arg] = action.payload;
    });
    builder.addCase(fetchFeatureCategoriesThunk.fulfilled, (state, action) => {
      state.categories.nodes = action.payload;
      state.categories.treeNodes = generateTree(action.payload);
    });
  },
});

export default featureSlice.reducer;
