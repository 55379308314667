import { createSlice } from "@reduxjs/toolkit";
import { Stock } from 'stock/models';
import * as utils from './utils';

export type BrowserHistoryState = Array<Stock['code']>;

const initialState: BrowserHistoryState = utils.getBrowserHistory();

export const browserHistorySlice = createSlice({
  name: 'browserHistory',
  initialState,
  reducers: {
    addBrowserItem: (state, action) => {
      if (state.indexOf(action.payload) === -1) {
        state.unshift(action.payload);
        utils.setBrowserHistory(state);
      }
    },
    removeBrowserItem: (state, action) => {
      const index = state.indexOf(action.payload);
      state.splice(index, 1);
      utils.setBrowserHistory(state);
    },
    clearBrowserHistory: state => {
      utils.setBrowserHistory([]);
      state = [];
      return state;
    }
  }
});

export const { addBrowserItem, clearBrowserHistory, removeBrowserItem } = browserHistorySlice.actions;

export default browserHistorySlice.reducer;