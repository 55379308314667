import { combineReducers, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strategyApi from './api';
import { Strategy } from './models';
import mlModelPredictReducer from './components/MLModelPredictPane/slice';

export interface StrategyState {
  list: Strategy[];
}

const initialState: StrategyState = {
  list: [],
};

export const fetchStrategyListThunk = createAsyncThunk('strategy/fetchList', async () => {
  return await strategyApi.fetchStrategyList();
});

export const strategySlice = createSlice({
  name: 'strategy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStrategyListThunk.fulfilled, (state, { payload }) => {
      state.list = payload.data;
    });
  },
});

export default combineReducers({
  strategies: strategySlice.reducer,
  mlModelPredicts: mlModelPredictReducer,
});
