import React, { useEffect, useMemo, useState } from 'react';

import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import Tabs from 'antd/lib/tabs';
import { Strategy } from 'strategy/models';
import { fetchStrategyList, fetchStrategySignals } from 'strategy/api';
import { Skeleton } from 'antd';
import AIModelPredictAggTable, { COLUMNS } from 'ai/pages/PredictAggregation/AggregationTable';
import { TableState } from 'app/components/Table/hooks';
import SignalCountStatistic from 'signal/components/CountStatistic';
import { PredictAggregation } from 'ai/models';
import { ColumnProps } from 'app/components/Table';
import { StockDailyColumns } from 'stock/components/StockTableComponents';

export const SIGNAL_COLUMN: ColumnProps<PredictAggregation>[] = [
  {
    title: '强度',
    dataIndex: 'strength',
    key: 'strength',
    width: 100,
    valueType: 'digit',
    fixed: 'left',
    sorter: true,
    defaultSortOrder: 'descend',
  }
];

export default function List() {
  const [strategies, setStrategies] = useState<Strategy[]>([]);

  useEffect(() => {
    fetchStrategyList().then((res) => {
      setStrategies(res.data);
    });
  }, []);

  const columns = useMemo(() => {
    const columns = SIGNAL_COLUMN.concat(COLUMNS, StockDailyColumns as any);
    // 移除所有的defaultSortOrder
    columns.forEach((column) => {
      delete column.defaultSortOrder;
      if (column.dataIndex === 'strength') {
        column.defaultSortOrder = 'descend';
      }
    });

    // 把key是detail.signals放到第二列
    const detailSignals = columns.find((column) => column.key === 'detail.signals');
    if (detailSignals) {
      columns.splice(1, 0, columns.splice(columns.indexOf(detailSignals), 1)[0]);
    }
    // detailSignals

    return columns;
  }, []);


  if (strategies.length === 0) {
    return <Skeleton active />;
  }

  return (
    <WorkdeskLayout pageName="信号" title="信号">
      <Tabs defaultActiveKey="statistic">
        <Tabs.TabPane key={'statistic'} tab="统计">
          <SignalCountStatistic />
        </Tabs.TabPane>
        <Tabs.TabPane key="详情" tab="详情">
          <Tabs defaultActiveKey={strategies[0].id}>
            {strategies.map((strategy) => {
              return (
                <Tabs.TabPane key={strategy.id} tab={strategy.name}>
                  <AIModelPredictAggTable
                    tableProps={{
                      columns,
                    }}
                    apiFunction={(params: TableState) => {
                      return fetchStrategySignals({
                        ...params,
                        strategy_id: strategy.id,
                      });
                    }}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Tabs.TabPane>
      </Tabs>
    </WorkdeskLayout>
  );
}
