import { useContextDispatch } from 'app/hooks';
import { TableProps } from '.';
import { ApiResult, Pagination } from 'app/services/api';
import { SortOrder } from 'antd/es/table/interface';
import { AppThunk } from 'app/store';
import _ from 'lodash';
import { useRef } from 'react';
import moment from 'moment';

export type DateEntry = string | {
  $L: string;
  $d: object;
  $x: object;
  $y: number;
  $M: number;
  $D: number;
  $W: number;
  $H: number;
  $m: number;
  $s: number;
  $ms: number;
};

export interface TableState {
  pagination: Pick<Pagination, 'page' | 'per_page'>;
  filter?: Record<string, any>;
  order?: Record<string, SortOrder>;
}

export const useRefreshTableState = (
  data: TableProps<any>['data'],
  fetchAction: TableProps<any>['fetchAction'],
  useRedux: TableProps<any>['useRedux'],
  sortInLocal: TableProps<any>['sortInLocal']
) => {
  const dispatch = useContextDispatch();
  const dataRef = useRef<Array<any>>([]);
  const totalCountRef = useRef();
  const tableState = useRef<TableState>();

  const refreshTableStateInRedux: TableProps<any>['request'] = (params, sort, filter) => {
    Object.keys(params).forEach((key) => (params[key] === undefined ? delete params[key] : {}));

    const { current, pageSize, ...rest } = params;

    const state: TableState = {
      pagination: {
        page: current,
        per_page: pageSize,
      },
    };

    const hasSort = Object.keys(sort).length > 0;

    // 如果是服务端排序
    if (hasSort && !sortInLocal) {
      state['order'] = sort;
    }

    if (Object.keys(rest).length > 0) {
      state['filter'] = rest;
    }

    dispatch((fetchAction as (params: TableState) => AppThunk)(state));

    if (hasSort && sortInLocal && Array.isArray(data)) {
      Object.keys(sort).forEach((field) => {
        data = data?.sort((a, b) => {
          if (sort[field] === 'ascend') {
            return _.get(a, field) - _.get(b, field);
          } else {
            return _.get(b, field) - _.get(a, field);
          }
        });
      });
    }

    return Promise.resolve({ success: true, data: data || [] });
  };

  const refreshTableState: TableProps<any>['request'] = async (params, sort, filter) => {
    if (!fetchAction) {
      return {
        success: false,
        data: [],
      };
    }

    Object.keys(params).forEach((key) => (params[key] === undefined ? delete params[key] : {}));
    // 可能是版本不对，本地和远程不同
    // params.filter.date如果存在且是对象，则需要moment实例化后转成字符串
    if (params.date && Array.isArray(params.date)) {
      params.date = params.date
        .map((item: DateEntry) => {
          if (typeof item === 'object' && item.hasOwnProperty('$d')) {
            return moment(item.$d).format('YYYY-MM-DD');
        }
          return item;
        })
        .join(',');
    }

    const { current, pageSize, ...rest } = params;

    const state: TableState = {
      pagination: {
        page: current,
        per_page: pageSize,
      },
    };

    const hasSort = sort && Object.keys(sort).length > 0;

    // 如果是服务端排序
    if (hasSort && !sortInLocal) {
      state['order'] = sort;
    }

    if (Object.keys(rest).length > 0) {
      state['filter'] = rest;
    }

    if (dataRef.current?.length === 0 || !_.isEqual(tableState.current, state)) {
      // 需要重新拉取数据
      const result = (await (fetchAction as (params: TableState) => Promise<ApiResult<any>>)(state))
        .data;
      if (result?.pagination) {
        dataRef.current = result.items;
        totalCountRef.current = result.pagination.total_count;
      } else {
        dataRef.current = result || [];
      }
      tableState.current = state;
    }

    if (hasSort && sortInLocal && Array.isArray(dataRef.current)) {
      Object.keys(sort).forEach((field) => {
        dataRef.current = dataRef.current && dataRef.current.sort((a: any, b: any) => {
          if (sort[field] === 'ascend') {
            return _.get(a, field) - _.get(b, field);
          } else {
            return _.get(b, field) - _.get(a, field);
          }
        });
      });
    }

    const payload = {
      data: dataRef.current,
      total: totalCountRef.current,
      success: true,
    };

    return Promise.resolve(payload);
  };

  if (useRedux) {
    return refreshTableStateInRedux;
  } else {
    return refreshTableState;
  }
};
