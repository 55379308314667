import React, { FunctionComponent, useEffect, useMemo } from 'react';

import { useContextDispatch } from 'app/hooks';
import { fetchTurnoverThunk } from 'market/slice';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import classNames from 'classnames';

import { Skeleton, Card, Row, Col, Statistic } from 'antd';
import { AmountFormatter, AmountUnit, formatPctChange } from 'market/utils';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import MQCharts from 'app/components/MQCharts';
import { getOptions } from './chartOptions';

import './index.scss';

const TurnoverDashboard: FunctionComponent<{ count?: number }> = ({ count }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchTurnoverThunk({ count }));
  }, [dispatch]);

  const turnoverData = useSelector((state: RootState) => state.market.turnover)?.data;

  const options = useMemo(() => getOptions(turnoverData), [turnoverData]);

  if (!turnoverData || !options) {
    return <Skeleton active />;
  }

  const latestTurnover = turnoverData[0];
  const latestPctChange = formatPctChange(turnoverData[0].total, turnoverData[1].total);

  return (
    <div className="dashboard-section turnover">
      <Card
        title={'成交额:' + latestTurnover.date}
        className="latest"
        size="small"
      >
        <Statistic
          title="总成交额"
          value={new AmountFormatter(latestTurnover.total, AmountUnit.Yuan).parseTo(
            AmountUnit.HundredMillion,
            false
          )}
          precision={2}
          suffix={AmountUnit.HundredMillion}
          className="turnover-latest"
        />
        <Statistic
          title="较前日变化"
          value={latestPctChange.toFixed(2)}
          prefix={latestPctChange > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          suffix="%"
          className={classNames(
            'turnover-pct-change',
            latestPctChange > 0 ? 'value-up' : 'value-down'
          )}
        />
      </Card>
      <Card title="成交额趋势" className="trender" size="small">
        <MQCharts options={options} className="turnover-trender-chart" />
      </Card>
    </div>
  );
};

export default TurnoverDashboard;