import React, { FunctionComponent } from 'react';
import { Input, AutoComplete } from 'antd';
import { useOptions } from './hooks';
import { useStockList } from 'stock/hooks';

/**
 * SearchInput组件的props
 * @param onSelect 选择股票后的回调函数
 * @param search 是否显示搜索框 默认为true
 */
export interface SearchInputProps {
  onSelect?: (value: string) => void;
  search?: boolean;
}

const SearchInput: FunctionComponent<SearchInputProps> = ({ onSelect, search = true }) => {
  useStockList();
  const [options, onSearch] = useOptions();

  return (
    <div className="stock-search-input">
      <AutoComplete
        dropdownMatchSelectWidth={252}
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
      >
        {search && <Input.Search size="large" placeholder="输入股票代码" enterButton />}
      </AutoComplete>
    </div>
  );
};

export default SearchInput;
