import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Skeleton, Table } from 'antd';

import { Stock, StockWithDailyDetail } from 'stock/models';
import { ColumnsType } from 'antd/lib/table';

import './index.scss';
import { FilterInfo, useStockData } from 'stock/hooks';
import { useContextDispatch } from 'app/hooks';
import { fetchLatestDataThunk } from 'stock/slice';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { StockLimitTag } from '../LimitTag';
import { KChartConfig } from 'app/components/KChart/models';
import StockGroupKChart from '../StockGroupKChart';
import { StockDailyColumns } from '../StockTableComponents';

export interface StockTableData extends StockWithDailyDetail {
  key: string;
}

export interface StockTableProps {
  codes: Array<Stock['code']>;
  fetchAction?: AsyncThunkAction<StockWithDailyDetail[], any, {}>;
  actionRender?: (text: any, record: StockWithDailyDetail) => JSX.Element | null;
  filterInfo?: FilterInfo;
  kChartConfig?: KChartConfig;
}

export const StockWithDailyDataColumns: ColumnsType<StockWithDailyDetail> = [
  {
    title: '序号',
    key: 'idx',
    render: (_, __, idx) => idx + 1,
    width: '5%',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <Link to={`/stocks/${record.info.code}`} target="_blank">
        {record.info.name}<br/>
        {record.info.code}
        <StockLimitTag stock={record} />
      </Link>
    ),
  },
  {
    title: '日期',
    dataIndex: 'date',
    render: (_, record) => new Date(record.detail.date).toLocaleDateString(),
  },
];

const makeColumns = (actionRender: StockTableProps['actionRender']) => {
  const columns = [...StockWithDailyDataColumns, ...StockDailyColumns];

  if (actionRender) {
    columns.push({
      title: '操作',
      key: 'action',
      render: actionRender,
    });
  }

  return columns;
};

const StockTable: FunctionComponent<StockTableProps> = ({
  codes,
  actionRender,
  fetchAction,
  filterInfo,
  kChartConfig,
}) => {
  const columns = useMemo(() => makeColumns(actionRender), [actionRender]);

  const dispatch = useContextDispatch();

  useEffect(() => {
    const action = fetchAction || fetchLatestDataThunk(codes);
    dispatch(action);
  }, [dispatch, codes, fetchAction]);

  const data = useStockData(codes, filterInfo);
  const [orderCodes, setOrderCodes] = useState<Array<string>>(codes);

  if (!data) {
    return <Skeleton active />;
  }

  return (
    <div className="stock-table-container">
      {kChartConfig && <StockGroupKChart config={kChartConfig} codes={orderCodes} />}
      <Table
        className="stock-table"
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.info.code}
        onChange={(p, f, s, d) => {
          setOrderCodes(d.currentDataSource.map((item) => item.info.code));
        }}
        // onRow={record => {
        //   return {
        //     onClick: event => {
        //       openNewStockPage(record.code);
        //     }, // 点击行
        //     onDoubleClick: event => {},
        //     onContextMenu: event => {},
        //     onMouseEnter: event => {}, // 鼠标移入行
        //     onMouseLeave: event => {},
        //   };
        // }}
      />
    </div>
  );
};

export default StockTable;
