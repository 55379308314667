import { Card, Skeleton } from 'antd';
import Tag from 'antd/es/tag';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import { THSClassifyType } from 'classify/models';
import { getClassifyDetailRoute } from 'classify/utils';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SignalType } from 'signal/models';
import ClassifyWatchingTable from '../ClassifyWatchingTable';
import { fetchLatestTHSSignalsThunk, makeStateKey } from './slice';

import './index.scss';

type THSLatestSignalsProps = {
  type: keyof typeof THSClassifyType;
  level?: number;
};

const THSLatestSignals: FunctionComponent<THSLatestSignalsProps> = ({ level, type }) => {
  const dispatch = useContextDispatch();
  useEffect(() => {
    dispatch(fetchLatestTHSSignalsThunk({ level, type }));
  }, [level, type]);

  const stateKey = makeStateKey({ level, type });

  const data = useSelector((state: RootState) => state.classify.thsLatestSignals[stateKey]);

  if (!data || !data.date) {
    return <Skeleton active />;
  }

  let title;

  if (type === 'I') {
    if (level) {
      title = `同花顺板块信号-${THSClassifyType[type]}-${level}级-${data.date}`;
    } else {
      title = `同花顺板块信号-${THSClassifyType[type]}-${data.date}`;
    }
  } else {
    title = `同花顺板块信号-${THSClassifyType[type]}-${data.date}`;
  }

  return (
    <Card
      title={title}
      className="ths-latest-signals"
    >
      {data.signals.length > 0 && (
        <Card title="当日信号" size="small" className='signals'>
          {data.signals.map((signal) => {
            return (
              <Tag key={signal.code} color={signal.type === SignalType.BUY ? '#F50' : '#87d068'}>
                <Link to={getClassifyDetailRoute(signal.code)} target="_blank">
                  {signal.level ? `${signal.name}[${signal.level}级]` : signal.name}
                </Link>
              </Tag>
            );
          })}
        </Card>
      )}
      {data.watching.length > 0 && (
        <Card size='small' title='关注列表' className='watching'>
          <ClassifyWatchingTable data={data.watching} />
        </Card>
      )}
    </Card>
  );
};
export default THSLatestSignals;
