/**
 * 批量查看信号
 */
import { Button, Skeleton } from 'antd';
import PageHeader from 'app/components/PageHeader';
import { ColumnProps } from 'app/components/Table';
import { useQuery } from 'app/hooks';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { fetchSignalList } from 'signal/api';
import { BuyTag, SellTag } from 'signal/components/TypeTag';
import { Signal, SignalType } from 'signal/models';
import StockGroupViewer from 'stock/components/GroupViewer';
import { StockGroupItem } from 'stock/components/GroupViewer/models';
import { makeGroupItemKey } from 'stock/components/GroupViewer/utils';
import SignalTable from './Table';

interface StockGroupItemWithSignal extends StockGroupItem {
  type: Signal['type'];
  strategy: Signal['strategy'];
}

const GroupViewerHeader: FunctionComponent<{ groupItem: StockGroupItemWithSignal }> = ({
  groupItem,
}) => {
  if (groupItem.type === SignalType.BUY) {
    return <BuyTag />;
  } else {
    return <SellTag />;
  }
};

const SignalGroupViewer: FunctionComponent<{}> = () => {
  const query = useQuery();
  const [data, setData] = useState<Signal[]>();
  const [isViewStock, setViewStock] = useState<Boolean>(false);

  let stockGroup = useRef<StockGroupItemWithSignal[]>([]);
  let activeKey = useRef<string>();

  const viewStock = useCallback((item: StockGroupItem) => {
    activeKey.current = makeGroupItemKey(item);
    setViewStock(true);
  }, []);

  const closeViewStock = useCallback(() => {
    setViewStock(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        all: 1,
        ...query,
      };
      const _data = (await fetchSignalList(params)).data as Signal[];
      stockGroup.current = _data.map((signal) => ({
        code: signal.stock.code,
        name: signal.stock.name,
        occurredAt: moment(signal.occurred_at).format('YYYY-MM-DD'),
        type: signal.type,
        strategy: signal.strategy,
      }));

      setData(_data);
    };
    fetchData();
  }, []);

  const operatorRender: ColumnProps<Signal>['render'] = useCallback((_, record) => {
    const groupItem: StockGroupItemWithSignal = {
      code: record.stock.code,
      name: record.stock.name,
      occurredAt: moment(record.occurred_at).format('YYYY-MM-DD'),
      type: record.type,
      strategy: record.strategy,
    };
    return (
      <Button type="primary" size="small" onClick={() => viewStock(groupItem)}>
        查看
      </Button>
    );
  }, []);

  return (
    <SinglePageLayout pageName="信号查看器">
      <PageHeader title="信号查看器"></PageHeader>
      {!data && <Skeleton active />}
      {data && (
        <SignalTable
          state={{ signals: data }}
          operatorRender={operatorRender}
          tableStyle={{ display: isViewStock ? 'none' : 'block' }}
        />
      )}
      {isViewStock && (
        <StockGroupViewer<StockGroupItemWithSignal>
          group={stockGroup.current}
          curKey={activeKey.current}
          onBack={closeViewStock}
          header={GroupViewerHeader}
          chartOptions={{ dataZoomStart: 60 }}
        />
      )}
    </SinglePageLayout>
  );
};

export default SignalGroupViewer;
