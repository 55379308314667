import { TreeProps, DataNode } from 'antd/lib/tree';
import { TreeNode } from './models';

export const arrayToTree = (
  nodes: TreeNode[],
  parentId: TreeNode['parent_id'] = null
): TreeProps<DataNode & { idx: number; parentId: TreeNode['parent_id'] }>['treeData'] => {
  return nodes
    .filter((node) => node.parent_id === parentId)
    .map((node) => ({
      key: node.id,
      title: node.name,
      value: node.id,
      idx: node.idx,
      parentId: node.parent_id,
      children: arrayToTree(nodes, node.id),
    }))
    .sort((a, b) => a.idx - b.idx);
};

// 根据当前节点的id，获取其所有的父节点（路径）
export const getParents = <T extends TreeNode>(nodes: T[], id: T['id']) => {
  const node = nodes.find((n) => n.id === id);
  if (!node) {
    return [];
  }
  const parents = [node];
  let parent = nodes.find((n) => n.id === node.parent_id);
  while (parent) {
    parents.push(parent);
    parent = nodes.find((n) => n.id === parent!.parent_id);
  }
  parents.reverse();
  return parents;
};
