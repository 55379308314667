/**
 * 以date为轴的数据Model图形
 */
import React, { FunctionComponent, useMemo } from 'react';

import { BaseModel, ModelField, ModelMeta } from 'app/models';
import { EChartsOption, SeriesOption } from 'echarts';
import { AmountFormatter, AmountUnit } from "market/utils";
import MQCharts from 'app/components/MQCharts';

export interface TimeSeriesChartProps<T = BaseModel> {
  className?: string,
  data: T[],
  meta: ModelMeta,
  fieldsConfig: {
    dataIndex: ModelField['dataIndex'],
    series: SeriesOption[] | SeriesOption,
  }[],
  unit: AmountUnit,
}


export const formatData = (data: TimeSeriesChartProps['data'], fieldKeys: ModelField['dataIndex'][]) => {
  const dataset = data.slice().sort((a, b) => a.date > b.date ? 1 : -1);

  const _dataset = fieldKeys.map(field => [field, dataset.map(_data => [_data.date, _data[field]])]);

  return {
    indexes: dataset.map(_data => _data.date),
    ...Object.fromEntries(_dataset)
  }
};

export const getOptions = (unit: AmountUnit, fieldsConfig: TimeSeriesChartProps['fieldsConfig'], data?: TimeSeriesChartProps['data'], meta?: TimeSeriesChartProps['meta']) => {
  if (!data || !meta) return null;

  const fieldKeys = fieldsConfig.map(field => field.dataIndex);

  const formatedData = formatData(data, fieldKeys);

  let result: EChartsOption = {
    legend: {
      data: fieldKeys.map(field => meta.getTitle(field)),
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params: any) => {
        return params.map((series: any) => {
          const formatedValue = new AmountFormatter(series.data[1], unit).parseTo(AmountUnit.HundredMillion);
          return `${series.marker}    ${series.seriesName}: ${formatedValue}`;
        }).join('<br/>');
      }
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777'
      }
    },
    xAxis: {
      type: 'category',
      data: formatedData.indexes,
      axisPointer: {
        type: 'shadow',
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => {
          return new AmountFormatter(value, unit).parseTo(AmountUnit.HundredMillion)
        }
      },
      axisPointer: {
        label: {
          formatter: (params: any) => {
            return new AmountFormatter(params.value, unit).parseTo(AmountUnit.HundredMillion);
          }
        }
      }
    },
    series: fieldsConfig.map(field => ({
      symbol: 'none',
      name: meta.getTitle(field.dataIndex),
      data: formatedData[field.dataIndex],
      smooth: true,
      ...field.series
    })),
    visualMap: [],
  };

  return result;
};

const TimeSeriesChart: FunctionComponent<TimeSeriesChartProps> = (props) => {
  const { data, meta, fieldsConfig, className, unit } = props;

  const options = useMemo(() => getOptions(unit, fieldsConfig, data, meta), [fieldsConfig, data, meta, unit]);

  if (!options) {
    return null;
  }

  return (
    <MQCharts options={options} className={className} />
  )
}

export default TimeSeriesChart;