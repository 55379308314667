import callApi, { DateRangeParams } from 'app/services/api';
import { HSGT, Indicator, Margin, Turnover } from './models';

export const fetchTurnoverData = async (params?: DateRangeParams) => {
  return callApi<Turnover[]>({ endpoint: '/market/turnover', data: params });
};

export const fetchMarginData = async (params?: DateRangeParams) => {
  return callApi<Margin[]>({ endpoint: '/market/margin', data: params });
};

export const fetchHSGTData = async (params?: DateRangeParams) => {
  return callApi<HSGT[]>({ endpoint: '/market/hsgt', data: params });
};

export const fetchLatestTradeDate = async () => {
  return callApi<{ date: string }>({ endpoint: '/market/latest_tradedate' });
};

export const fetchIndicators = async (params?: DateRangeParams) => {
  return callApi<Indicator[]>({
    endpoint: '/market/indicators',
    data: params,
  });
};
