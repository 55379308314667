import React, { FunctionComponent } from 'react';
import { Tag } from 'antd';
import { StockWithDailyDetail } from 'stock/models';

export const StockLimitTag: FunctionComponent<{ stock: StockWithDailyDetail }> = ({ stock }) => {
  if (!stock.detail || (!stock.detail.is_zhangting && !stock.detail.is_dieting))
    return null;

  let text = '';
  if (stock.detail.is_zhangting) {
    if (stock.detail.zhangting_count > 1) {
      text = `${stock.detail.zhangting_count}连板`;
    } else {
      text = '首板';
    }
  } else {
    text = '跌停';
  }
  return (
    <Tag color={stock.detail.is_zhangting? '#F50' : '#87d068'}>
      {text}
    </Tag>
  );
};
