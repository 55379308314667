import { EChartsOption } from "echarts";
import { Turnover } from "market/models";
import { TurnoverState } from "market/slice";
import { AmountFormatter, AmountUnit } from "market/utils";

export interface FormatedData {
  indexes: Turnover['date'][],
  total: [string, number][],
  sz: [string, number][],
  sh: [string, number][],
};


export const formatData = (data: TurnoverState['data']): FormatedData => {
  const dataset = data.slice().sort((a, b) => a.date > b.date ? 1 : -1);

  return {
    indexes: dataset.map(_data => _data.date),
    total: dataset.map((_data, idx) => [_data.date, _data.total]),
    sh: dataset.map(_data => [_data.date, (_data.components?.find(d => d.code === '000001')?.amount as number)]),
    sz: dataset.map(_data => [_data.date, (_data.components?.find(d => d.code === '399001')?.amount as number)]),
  }
};
export const getOptions = (data?: TurnoverState['data']) => {
  if (!data) return null;

  const formatedData = formatData(data);

  let result: EChartsOption = {
    legend: {
      data: ['总成交额', '上证指数', '深证成指'],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params: any) => {
        return params
          .map((series: any) => {
            const formatedValue = new AmountFormatter(series.data[1], AmountUnit.Yuan).parseTo(
              AmountUnit.HundredMillion
            );
            return `${series.marker}    ${series.seriesName}: ${formatedValue}`;
          })
          .join('<br/>');
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777',
      },
    },
    xAxis: {
      type: 'category',
      data: formatedData.indexes,
      axisPointer: {
        type: 'shadow',
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => {
          return new AmountFormatter(value, AmountUnit.Yuan).parseTo(AmountUnit.HundredMillion);
        },
      },
      axisPointer: {
        label: {
          formatter: (params: any) => {
            return new AmountFormatter(params.value, AmountUnit.Yuan).parseTo(
              AmountUnit.HundredMillion
            );
          },
        },
      },
    },
    series: [
      {
        symbol: 'none',
        name: '总成交额',
        type: 'line',
        data: formatedData.total,
        smooth: true,
        markLine: {
          data: [
            [
              {
                symbol: 'none',
                type: 'average',
                name: 'avg',
                x: '10%',
              },
              {
                x: '90%',
                yAxis: 'average',
                symbol: 'none',
                label: {
                  formatter: (params: any) => {
                    return new AmountFormatter(params.value, AmountUnit.Yuan).parseTo(
                      AmountUnit.HundredMillion
                    );
                  },
                },
              },
            ]
          ],
        },
      },
      {
        // symbol: 'none',
        name: '上证指数',
        type: 'bar',
        stack: 'total',
        data: formatedData.sh,
        // smooth: true,
      },
      {
        // symbol: 'none',
        name: '深证成指',
        type: 'bar',
        stack: 'total',
        data: formatedData.sz,
        // smooth: true,
      },
    ],
    visualMap: [],
  };

  return result;
};