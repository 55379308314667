import { useEffect, useReducer } from 'react';
import { FeatureSelectorProps } from '.';
import { FeatureCategoryTree } from 'feature/models';
import { fetchFeatureCategoryTreeWithSpecs } from 'feature/api';
import { TreeProps } from 'antd';
import { generateCandidateTree, generateCurrentTree } from './utils';
import _ from 'lodash';

interface FeatureSelectorState {
  ready: boolean;
  featureTree: FeatureCategoryTree;
  selectedFeatures: string[];
  candidateTree: TreeProps['treeData'];
  currentTree: TreeProps['treeData'];
  selectedCandidateFeatures: string[];
  selectedCurrentFeatures: string[];
  totalFeatures: number;
}
type Action =
  | { type: 'INIT'; payload: FeatureCategoryTree }
  | { type: 'SELECT_CANDIDATE_FEATURE'; payload: string[] }
  | { type: 'SELECT_CURRENT_FEATURE'; payload: string[] }
  | { type: 'ADD_FEATURES' }
  | { type: 'REMOVE_FEATURES' };

export const useFeatureSelector = (
  onChange: FeatureSelectorProps['onChange'],
  selectedIds: FeatureSelectorProps['selectedIds']
) => {
  const initialState: FeatureSelectorState = {
    ready: false,
    featureTree: [],
    selectedFeatures: selectedIds || [],
    candidateTree: [],
    currentTree: [],
    selectedCandidateFeatures: [],
    selectedCurrentFeatures: [],
    totalFeatures: 0,
  };
  const reducer = (state: FeatureSelectorState, action: Action): FeatureSelectorState => {
    switch (action.type) {
      case 'INIT':
        return {
          ...state,
          featureTree: action.payload,
          candidateTree: generateCandidateTree(action.payload, state.selectedFeatures),
          currentTree: generateCurrentTree(action.payload, state.selectedFeatures),
          ready: true,
          totalFeatures: action.payload.reduce(
            (acc, category) => acc + category.features.length,
            0
          ),
        };
      case 'SELECT_CANDIDATE_FEATURE':
        return {
          ...state,
          selectedCandidateFeatures: action.payload,
        };
      case 'SELECT_CURRENT_FEATURE':
        return {
          ...state,
          selectedCurrentFeatures: action.payload,
        };
      case 'ADD_FEATURES':
        const newSelectedFeatures = _.uniq([
          ...state.selectedFeatures,
          ...state.selectedCandidateFeatures,
        ]);
        onChange && onChange(newSelectedFeatures);
        return {
          ...state,
          selectedFeatures: newSelectedFeatures,
          candidateTree: generateCandidateTree(state.featureTree, newSelectedFeatures),
          currentTree: generateCurrentTree(state.featureTree, newSelectedFeatures),
          selectedCandidateFeatures: [],
        };
      case 'REMOVE_FEATURES':
        const remainingSelectedFeatures = _.uniq(
          state.selectedFeatures.filter(
            (feature) => !state.selectedCurrentFeatures.includes(feature)
          )
        );
        onChange && onChange(remainingSelectedFeatures);
        return {
          ...state,
          selectedFeatures: remainingSelectedFeatures,
          candidateTree: generateCandidateTree(state.featureTree, remainingSelectedFeatures),
          currentTree: generateCurrentTree(state.featureTree, remainingSelectedFeatures),
          selectedCurrentFeatures: [],
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const init = async () => {
      const result = await fetchFeatureCategoryTreeWithSpecs();
      if (result.isOK) {
        dispatch({ type: 'INIT', payload: result.data });
      }
    };
    init();
  }, []);

  return {
    state,
    handlers: {
      selectCandidateFeatures: (ids: string[]) =>
        dispatch({ type: 'SELECT_CANDIDATE_FEATURE', payload: ids }),
      selectCurrentFeatures: (ids: string[]) =>
        dispatch({ type: 'SELECT_CURRENT_FEATURE', payload: ids }),
      addFeatures: () => {
        dispatch({ type: 'ADD_FEATURES' });
      },
      removeFeatures: () => {
        dispatch({ type: 'REMOVE_FEATURES' });
      },
    },
  };
};
