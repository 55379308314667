import { AIModel } from "ai/models";
import { BaseModel } from "app/models";
import { Classify, ClassifySource } from "classify/models";
import { Signal } from "signal/models";

export interface Stock {
  code: string,
  name: string,
}

export type StockClassifies = {
  [key in keyof typeof ClassifySource]: Pick<Classify, 'code' | 'name' | 'type' | 'level' | 'is_watching'>[];
};

export enum PriceType {
  OPEN = 'open',
  HIGH = 'high',
  LOW = 'low',
  CLOSE = 'close'
}
export interface StockCandleData {
  low: number;
  high: number;
  open: number;
  close: number;
  date: string;
  vol: number;
  amount: number;
  pct_chg: number;
  price_chg: number;
  major_pct: number;
  turnover_rate: number;
  turnover_rate_f: number;
  is_zhangting: boolean;
  is_dieting: boolean;
  amount_rank: number;
  major_net_buy: number;
}

export enum CandleType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export interface StockTickAnalysis extends BaseModel {
  date: string,
  components: {
    major_pct: number,
    big_pct: number,
    medium_pct: number,
    small_pct: number,
  }
}

export const TA_META = {
  components: {
    'major_pct': {
      label: '主力资金',
      description: '单笔大于100万',
    },
    'big_pct': {
      label: '大单资金',
      description: '单笔介于20-100万之间'
    },
    'medium_pct': {
      label: '中单资金',
      description: '单笔介于5-20万之间',
    },
    'small_pct': {
      label: '散户资金',
      description: '单笔小于5万'
    }
  }
};

export interface StockBasicIndicator {
  total_mv: number;
  circ_mv: number;
  free_mv: number;
  pb: number;
  ps: number;
  pe: number;
  pe_ttm: number;
}

export interface StockDailyDetail extends Record<string, any> {
  date: number;
  basic: StockBasicIndicator;
  signals: Signal[];
}

export interface StockWithDailyDetail {
  info: Stock;
  detail: StockDailyDetail;
  industry_sector: Classify;
  concept_sectors: Classify[];
};

export interface StockAIModelPredict {
  date: string;
  prob: number;
  label: number | null;
  model: Pick<AIModel, 'id' | 'name'>;
}

export interface ZhangtingStock extends StockWithDailyDetail {
  zhangting_count: number;
}

export interface ZhangtingTrender {
  [date: string]: {
    count: Record<number, number>;
    ratios: Record<number, number>;
  };
}