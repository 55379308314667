import Tabs from 'antd/lib/tabs';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import StoreDashboard from 'feature/components/StoreDashboard';
import { FeatureSpecTarget } from 'feature/models';
import React from 'react';

const { TabPane } = Tabs;

const TabItems = [
  {
    key: 'STOCK',
    name: '股票',
  },
  {
    key: 'SECTOR',
    name: '板块',
  },
  {
    key: 'INDEX',
    name: '指数',
  },
];

const Dashboard = () => {
  return (
    <WorkdeskLayout pageName="dashboard" title="总览">
      <Tabs defaultActiveKey="STOCK">
        {TabItems.map((item) => (
          <TabPane tab={item.name} key={item.key}>
            <StoreDashboard target={item.key as FeatureSpecTarget} />
          </TabPane>
        ))}
      </Tabs>
    </WorkdeskLayout>
  );
};

export default Dashboard;
