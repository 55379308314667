import { AIModelPredictRangeCount, PredictAggregation } from 'ai/models';
import { EChartsOption } from 'echarts';
import { formatData, makeAreaData, makeMarkPoint } from 'market/components/IndicatorDashboard/chartOptions';
import { Indicator } from 'market/models';
import * as constants from 'stock/components/CandleChart/constants';

/**
 * 预测标的的概念分布
 * @param predicts
 * @param topN
 * @returns
 */
export const predictsConceptChartOptions = (predicts: PredictAggregation[], topN?: number): EChartsOption => {
  // 根据predicts的classify字段进行统计，生成柱状图
  if (!predicts || predicts.length === 0) {
    return {};
  }

  const data: Array<{ code: string, name: string, value: number }> = [];

  predicts.forEach((p) => {
    p.concept_sectors.forEach((c) => {
      const { code, name} = c;
      const index = data.findIndex((d) => d.code === code);
      if (index === -1) {
        data.push({ code, name, value: 1 });
      } else {
        data[index].value += 1;
      }
    });
    // 只取相对强度最大的第一个概念
    // const sortedConcepts = p.concept_sectors.sort((a, b) => b.rs - a.rs);
    // // 有可能没有概念板块
    // if (sortedConcepts.length > 0) {
    //   const { code, name } = sortedConcepts[0];
    //   const index = data.findIndex((d) => d.code === code);
    //   if (index === -1) {
    //     data.push({ code, name, value: 1 });
    //   } else {
    //     data[index].value += 1;
    //   }
    // }
  });

  const sortedData = data.sort((a, b) => b.value - a.value);

  if (topN) {
    sortedData.splice(topN);
  }

  return {
    title: {
      text: `概念${topN ? `（前${topN}）` : ''}`,
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    grid: {
      bottom: '20%',
    },
    xAxis: [
      {
        type: 'category',
        data: sortedData.map((d) => d.name),
        axisLabel: {
          interval: 0, // 显示所有标签
          rotate: 45, // 标签倾斜45度
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: '概念',
        type: 'bar',
        data: sortedData,
        label: {
          show: true,
          position: 'top',
        },
      },
    ],
  }
};

export const predictsIndustryChartOptions = (predicts: PredictAggregation[], topN?: number): EChartsOption => {
  // 根据predicts的classify字段进行统计，生成柱状图
  if (!predicts || predicts.length === 0) {
    return {};
  }

  const data: Array<{ code: string, name: string, value: number }> = [];

  predicts.forEach((p) => {
    const { code, name} = p.industry_sector;
    const index = data.findIndex((d) => d.code === code);
    if (index === -1) {
      data.push({ code, name, value: 1 });
    } else {
      data[index].value += 1;
    }
  });

  const sortedData = data.sort((a, b) => b.value - a.value);

  if (topN) {
    sortedData.splice(topN);
  }

  return {
    title: {
      text: `行业分布${topN ? `（前${topN}）` : ''}`,
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    grid: {
      bottom: '20%',
    },
    xAxis: [
      {
        type: 'category',
        data: sortedData.map((d) => d.name),
        axisLabel: {
          interval: 0, // 显示所有标签
          rotate: 45, // 标签倾斜45度
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: '行业',
        type: 'bar',
        data: sortedData,
        label: {
          show: true,
          position: 'top',
        },
      },
    ],
  }
};

export const predictRangeCountChartOptions = (
  rawRangeCount: AIModelPredictRangeCount,
  marketIndicators: Indicator[]
): EChartsOption => {
  // 构建每个区间预测数量的系列数据和模型数量系列数据
  let lineSeries: any[] = [];
  let barSeries: any[] = [];
  let legendData: string[] = [];

  const formatedMarketData = formatData(marketIndicators);
  const markAreaData = makeAreaData(formatedMarketData);
  const markPointData = makeMarkPoint(formatedMarketData);
  // X轴的数据为日期
  const dates = formatedMarketData.indexes;
  // rangeCount中只保留date在dates中的数据
  const rangeCount = rawRangeCount.filter((r) =>
    dates.includes(r.date)
  );

  const candleSeries: any[] = [
    {
      name: '日K线',
      type: 'candlestick',
      xAxisIndex: 0,
      yAxisIndex: 0,
      data: formatedMarketData.candle,
      itemStyle: {
        color: constants.UP_COLOR,
        color0: constants.DOWN_COLOR,
      },
      markPoint: {
        data: markPointData,
      },
      markArea: {
        silent: true,
        data: markAreaData,
      },
    },
  ];
  // 获取所有的limit
  const limits = rangeCount.length > 0 ? Object.keys(rangeCount[dates.length - 1].detail) : [];

  // 为每个limit创建一个line系列
  limits.forEach((limit) => {
    const data = rangeCount.map((item) => item.detail[parseInt(limit)] || 0);
    lineSeries.push({
      name: `${limit}日模型`,
      type: 'line',
      data: data,
      xAxisIndex: 1,
      yAxisIndex: 1,
      showSymbol: false,
    });
    legendData.push(`${limit}日模型`);
  });


  // 添加一个表示总量的line系列
  const totalData = rangeCount.map((item) => item.total);
  lineSeries.push({
    name: '总量',
    type: 'line',
    data: totalData,
    xAxisIndex: 1,
    yAxisIndex: 1,
    showSymbol: false,
  });
  legendData.push('总量');

  // 添加一个表示模型个数的bar系列
  const modelCountData = rangeCount.map((item) => item.models.length);
  barSeries.push({
    name: '模型数',
    type: 'bar',
    data: modelCountData,
    xAxisIndex: 2,
    yAxisIndex: 2,
  });
  legendData.push('模型数');

  barSeries.push({
      name: '标的数',
      type: 'bar',
      data: rangeCount.map((item) => item.stock_count),
      xAxisIndex: 1,
      yAxisIndex: 1,
  });

  legendData.push('标的数');

  // 构建ECharts的配置对象
  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777',
      },
    },
    legend: {
      data: legendData,
    },
    grid: [
      { bottom: '45%' }, // 上半部分的grid
      { top: '60%', height: '15%' }, // 下半部分的grid
      { top: '80%' }, // 下半部分的grid
    ],
    xAxis: [
      {
        gridIndex: 0,
        type: 'category',
        boundaryGap: true,
        data: dates,
        show: false,
      },
      {
        gridIndex: 1,
        type: 'category',
        boundaryGap: true,
        data: dates,
        show: false,
      },
      {
        gridIndex: 2,
        type: 'category',
        boundaryGap: true,
        data: dates,
      },
    ],
    yAxis: [
      { gridIndex: 0, type: 'value', scale: true },
      { gridIndex: 1, type: 'value', name: '信号数量', scale: true, axisTick: { show: false }},
      { gridIndex: 2, type: 'value', name: '模型个数', axisTick: { show: false } },
    ],
    series: [...lineSeries, ...barSeries, ...candleSeries],
  };

  return option;
};

export const predictsLimitChartOptions = (predicts: PredictAggregation[]): EChartsOption => {
  // 根据信号的周期分布
  const probs = predicts.flatMap((p) => p.probs);

  const data = probs.map(p => p.ai_model.spec.target.time_limit);

  // 统计频次
  const countMap: Record<number, number> = {};
  data.forEach((item) => {
      if (countMap[item]) {
          countMap[item] += 1;
      } else {
          countMap[item] = 1;
      }
  });

  // 准备ECharts的数据格式
  const chartData = Object.keys(countMap).map((key) => ({
      name: key,
      value: countMap[+key]
  }));

  // ECharts配置选项
  const option: echarts.EChartsOption = {
    title: {
      text: '周期分布',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
    },
    series: [
      {
        name: '周期分布',
        type: 'pie',
        radius: '55%',
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          normal: {
            show: true,
            formatter: '{b}: {d}%',
          },
        },
      },
    ],
  };

  return option;
};