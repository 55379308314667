import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Collection, SignalWithCollection } from './models';
import * as collectionApi from './api';
import { DataWithPagination } from 'app/services/api';
import { RootState } from 'app/store';
import { LoadingStatus } from 'app/models';

export interface CollectionState {
  signals: DataWithPagination<SignalWithCollection>;
  collections: {
    loading: LoadingStatus,
    items: Array<Collection>,
  };
};

const initialState: CollectionState = {
  signals: { items: [] },
  collections: {
    loading: 'idle',
    items: [],
  },
};

export const fetchCollectionsThunk = createAsyncThunk(
  'collection/fetchList',
  async (_, { getState }) => {
    const collections = (await collectionApi.fetchCollections()).data;

    return collections;
  },
  {
    condition: (_, { getState }) => {
      const { collection } = getState() as RootState;
      if (collection.collections.loading === 'pending') {
        return false;
      }
      if (collection.collections.items.length > 0) {
        return false;
      }
    }
  }
);

export const createCollectionThunk = createAsyncThunk('collection/create', async (params: collectionApi.createCollectionParams) => {
  return (await collectionApi.createCollection(params)).data;
});

export const deleteCollectionThunk = createAsyncThunk('collection/delete', async (id: Collection['id']) => {
  await collectionApi.deleteCollection(id);
  return id;
});

export const updateCollectionThunk = createAsyncThunk('collection/update', async ({ id, params }: { id: Collection['id'], params: collectionApi.createCollectionParams }) => {
  return (await collectionApi.updateCollection(id, params)).data;
});

export const addCollectionItemThunk = createAsyncThunk('collection/addItem', async (params: collectionApi.addItemParams) => {
  return (await collectionApi.addItem(params)).data;
});

export const removeCollectionItemThunk = createAsyncThunk('collection/removeItem', async (params: collectionApi.removeCollectionItemParams) => {
  return (await collectionApi.removeItem(params)).data;
});

export const fetchCollectionSignalThunk = createAsyncThunk('collection/fetchSignal', async (params: collectionApi.fetchCollectionSignalParams) => {
  return (await collectionApi.fetchSignals(params)).data;
});

export const collectionSlice = createSlice({
  name: 'collection',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCollectionsThunk.pending, (state) => {
      state['collections'].loading = 'pending';
    })
    builder.addCase(fetchCollectionsThunk.fulfilled, (state, action) => {
      state['collections'] = {
        loading: 'idle',
        items: action.payload,
      };
    });
    builder.addCase(createCollectionThunk.fulfilled, (state, action) => {
      state['collections'].items.push(action.payload);
    });
    builder.addCase(deleteCollectionThunk.fulfilled, (state, action) => {
      const idx = state['collections'].items.findIndex(collection => collection.id === action.payload);
      state['collections'].items.splice(idx, 1);
    });
    builder.addCase(updateCollectionThunk.fulfilled, (state, action) => {
      const idx = state['collections'].items.findIndex(collection => collection.id === action.payload.id);
      state['collections'].items.splice(idx, 1, action.payload);
    });
    builder.addCase(addCollectionItemThunk.fulfilled, (state, action) => {
      action.payload.forEach(collection => {
        const idx = state['collections'].items.findIndex(_collection => _collection.id === collection.id);
        if (idx > -1) {
          state['collections'].items.splice(idx, 1, collection);
        } else {
          state['collections'].items.push(collection);
        }
      });
    });
    builder.addCase(removeCollectionItemThunk.fulfilled, (state, action) => {
      action.payload.forEach(collection => {
        const idx = state['collections'].items.findIndex(
          (_collection) => _collection.id === collection.id
        );
        state['collections'].items.splice(idx, 1, collection);
      })
    });
    builder.addCase(fetchCollectionSignalThunk.fulfilled, (state, action) => {
      state['signals'] = action.payload;
      return state;
    });
  }
});

export default collectionSlice.reducer;