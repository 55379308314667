import React, { useCallback } from 'react';
import { Button } from 'antd';

import { Collection } from 'collection/models';
import { useCollectionActions } from 'collection/hooks';
import StockTable, { StockTableProps } from 'stock/components/StockTable';
import { useModalState } from 'app/hooks';
import EditCollectionModal from '../EditCollectionModal';

interface CollectionPaneProps {
  collection: Collection,
  backToFirst?: () => void,
  management?: boolean,
}

export default function CollectionPane({ collection, backToFirst, management = true }: CollectionPaneProps) {
  const collectionActions = useCollectionActions();
  const editModalState = useModalState();

  const actionRender: StockTableProps['actionRender'] = useCallback((_, record) => (
    <Button
      danger
      type='primary'
      size='small'
      onClick={() => {
        collectionActions.removeItem({
          item: record.code,
          collections: [collection.id]
        });
      }}
    >
      移除
    </Button>
  ), [collection, collectionActions]);

  return (
    <div className='collection-pane-content'>
      {
        management && (collection.name === '我的自选' ? null : (
          <>
            <Button
              type='primary'
              danger
              onClick={() => {
                 collectionActions.deleteCollection(collection, backToFirst);
              }}
              className='collection-btn'
            >
              删除分组
            </Button>
            <Button
              type='primary'
              onClick={editModalState.actions.open}
              className='collection-btn'
            >
              编辑分组
            </Button>
          </>
        ))
      }
      <StockTable codes={collection.items} actionRender={management ? actionRender : undefined} />
      {
        management && <EditCollectionModal
          collection={collection}
          visible={editModalState.visiable}
          close={editModalState.actions.close}
        />
      }
    </div>
  )
}