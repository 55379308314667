import React, { ReactNode } from 'react';

import { Layout } from 'antd';

import './index.scss';

type SinglePageTypes = {
  pageName: string,
  children: ReactNode,
  className?: string,
}

export default function SinglePageLayout({ pageName, children, className }: SinglePageTypes) {
  const _className = ['single-page-layout', pageName, className].join(' ');

  return (
    <Layout className={_className}>
      {children}
    </Layout>
  );
}