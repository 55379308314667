import callApi, { DataWithPagination, FetchApiFunc } from 'app/services/api';
import { Strategy } from 'strategy/models';
import { CountByClassify, Signal } from './models';
import { TableState } from 'app/components/Table/hooks';

export interface ListParams extends TableState {
  code?: string,
  filter_date?: string,
}

export const fetchSignalList: FetchApiFunc<DataWithPagination<Signal> | Signal[]> = function (
  params: ListParams
) {
  return callApi({ endpoint: '/signals', data: params });
};

export interface SignalCountByStrategyResult {
  date: string;
  detail: Array<{
    id: Strategy['id'],
    name: Strategy['name'],
    count: number,
    buy_count: number,
    sell_count: number,
    classify_distribution: CountByClassify[],
  }>;
}

export const fetchSignalCountByStrategy: FetchApiFunc<SignalCountByStrategyResult> = (
  date?: string
) => {
  return callApi({ endpoint: '/signals/count_by_strategy', data: { date } });
};