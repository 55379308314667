import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { Card, Col, Row, Skeleton, Statistic, Tabs } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import PageHeader from 'app/components/PageHeader';
import { useContextDispatch } from 'app/hooks';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import { RootState } from 'app/store';
import { ClassifySource, ClassifySourceText } from 'classify/models';
import { fetchTHSInfoThunk, fetchTHSMemberLatestDataThunk } from 'classify/slice/detail';
import AddToCollection from 'collection/components/AddToCollection';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import StockTable from 'stock/components/StockTable';
import { StockWithDailyDetail } from 'stock/models';

import './index.scss';
import classNames from 'classnames';
import ClassifyDailyIndicatorChart from 'classify/components/DailyIndicatorChart';
import { AmountFormatter, AmountUnit } from 'market/utils';
import { TargetType } from 'app/components/KChart/models';
import AIModelPredictAggTable from 'ai/pages/PredictAggregation/AggregationTable';
import { fetchClassifyModelPredictAggregation } from 'ai/api';

const { TabPane } = Tabs;

enum TabKey {
  LATEST = 'latest',
  HISTORY = 'history',
  MEMBERS = 'members',
}

const ClassifyDetail: FunctionComponent<{}> = () => {
  const { source, code, tab } = useParams<{ source: ClassifySource; code: string; tab?: TabKey }>();
  const dispatch = useContextDispatch();
  const isLogin = useSelector((state: RootState) => state.auth.isLogin);

  // 获取板块数据数据
  useEffect(() => {
    if (source === ClassifySource.THS.toLowerCase()) {
      dispatch(fetchTHSInfoThunk(code));
    }
  }, [dispatch, source, code]);

  const actionRender = useCallback(
    (text: any, record: StockWithDailyDetail) => (
      <AddToCollection code={record.info.code} size="small" />
    ),
    []
  );

  const [activeTab, setActiveTab] = useState<TabKey>(tab || TabKey.LATEST);

  const detail = useSelector((state: RootState) => state.classify.details[code]);

  if (detail === undefined) {
    return <Skeleton active />;
  }

  const tableProps = {
    codes: detail.info.members,
    fetchAction: fetchTHSMemberLatestDataThunk(code),
    actionRender: isLogin ? actionRender : undefined,
  }

  return (
    <SinglePageLayout pageName="classify-detail">
      <PageHeader title={`板块-${detail.info.name}`} />
      <Row gutter={16}>
        <Col className="common-info">
          <Card className="basic-info" size="small">
            <Statistic className="info-item" title="代码" value={detail.info.code} />
            <Statistic
              className="info-item"
              title="来源"
              value={ClassifySourceText[detail.info.source]}
            />
            <Statistic className="info-item" title="类型" value={detail.info.type} />
            <Statistic className="info-item" title="成分股数" value={detail.info.members.length} />
            {detail.latest && (
              <>
                <Statistic
                  key="total-mv"
                  className="info-item"
                  title="总市值"
                  value={new AmountFormatter(detail.latest.total_mv, AmountUnit.Wan).parseTo(
                    AmountUnit.HundredMillion
                  )}
                />
                <Statistic
                  key="free-mv"
                  className="info-item"
                  title="自由流通市值"
                  value={new AmountFormatter(detail.latest.free_mv, AmountUnit.Wan).parseTo(
                    AmountUnit.HundredMillion
                  )}
                />
              </>
            )}
          </Card>
          {detail.latest && (
            <Card className="latest-data" size="small">
              <Statistic className="info-item" title="最近交易日" value={detail.latest.date} />
              <Statistic
                title="涨跌幅"
                value={detail.latest.pct_chg.toFixed(2)}
                prefix={detail.latest.pct_chg >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                className={classNames(
                  'info-item',
                  detail.latest.pct_chg >= 0 ? 'value-up' : 'value-down'
                )}
                suffix="%"
              />
              <Statistic
                className="info-item value-up"
                title="涨停"
                value={detail.latest.zhangting_count === 0 ? '-' : detail.latest.zhangting_count}
                suffix={
                  detail.latest.zhangting_count === 0 ? '' : `(${detail.latest.zhangting_pct}%)`
                }
              />
              <Statistic
                className="info-item value-down"
                title="跌停"
                value={detail.latest.dieting_count === 0 ? '-' : detail.latest.dieting_count}
                suffix={detail.latest.dieting_count === 0 ? '' : `(${detail.latest.dieting_pct}%)`}
              />
              <Statistic
                className="info-item"
                title="总成交额"
                value={new AmountFormatter(detail.latest.amount, AmountUnit.Wan).parseTo(
                  AmountUnit.HundredMillion
                )}
              />
              <Statistic
                title="主力净买"
                value={new AmountFormatter(detail.latest.major_net_buy, AmountUnit.Wan).parseTo(
                  AmountUnit.HundredMillion
                )}
                className={classNames(
                  'info-item',
                  detail.latest.major_net_buy >= 0 ? 'value-up' : 'value-down'
                )}
              />
              <Statistic
                className="info-item"
                title="自由流通换手"
                value={detail.latest.turnover_rate_f.toFixed(2)}
                suffix="%"
              />
              <Statistic
                className="info-item"
                title="大单占比"
                value={detail.latest.major_pct.toFixed(2)}
                suffix="%"
              />
              <Statistic className="info-item" title="排名" value={detail.latest.hit_rank} />
            </Card>
          )}
        </Col>
      </Row>
      <Tabs type="card" activeKey={activeTab} onChange={(value) => setActiveTab(value as TabKey)}>
        <TabPane tab="当日行情" key="latest">
          <Card size="small" title="涨跌停个股" key="limit-members" className="tab-card">
            <StockTable {...tableProps} filterInfo={['OnlyLimit', 'Latest']} />
          </Card>
          <Card size="small" title="信号个股" key="signal-members" className="tab-card">
            <StockTable {...tableProps} filterInfo={['OnlySignal', 'Latest']} />
          </Card>
          <Card size="small" title="高概率预测个股" key="predict-members" className="tab-card">
            <AIModelPredictAggTable
              apiFunction={() => fetchClassifyModelPredictAggregation(code)}
              tableProps={{
                search: false,
              }}
            />
          </Card>
        </TabPane>
        <TabPane tab="历史趋势" key="history">
          <ClassifyDailyIndicatorChart code={detail.info.code} />
        </TabPane>
        <TabPane tab="所有个股" key="members">
          <StockTable
            key="all-members"
            {...tableProps}
            kChartConfig={{
              range: {
                count: 120,
              },
              target: {
                code: detail.info.code,
                name: detail.info.name,
                type: TargetType.C,
              },
            }}
          />
        </TabPane>
      </Tabs>
    </SinglePageLayout>
  );
};

export default ClassifyDetail;
