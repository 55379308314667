import React from 'react';
import { Account } from 'account/models';
import {
  FormInstance,
  ModalForm,
  ModalFormProps,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import Button from 'antd/lib/button';
import { useAppDispatch } from 'app/hooks';
import { createAccountThunk, updateAccountThunk } from 'account/slice';

interface Props {
  mode: 'CREATE' | 'UPDATE';
  account?: Account;
  trigger?: ModalFormProps['trigger'];
}

const AccountForm: React.FC<Props> = ({ mode, account, trigger }) => {
  const formRef = React.useRef<FormInstance>();
  const dispatch = useAppDispatch();

  return (
    <ModalForm<Account>
      title={mode === 'CREATE' ? '新建账户' : '编辑账户'}
      formRef={formRef}
      onFinish={async (values) => {
        if (mode === 'CREATE') {
          dispatch(createAccountThunk(values));
        } else {
          const payload = { ...account, ...values };
          dispatch(updateAccountThunk(payload));
        }
        formRef.current?.resetFields();
        return true;
      }}
      initialValues={account}
      trigger={
        trigger ? (
          trigger
        ) : (
          <Button type="primary">{mode === 'CREATE' ? '新建账户' : '编辑账户'}</Button>
        )
      }
      modalProps={{
        destroyOnClose: true,
        onCancel: () => {
          formRef.current?.resetFields();
        },
      }}
    >
      <ProFormText
        name="title"
        label="名称"
        rules={[{ required: true, message: '名称是必填项' }]}
      />
      <ProFormTextArea name="description" label="描述" />
    </ModalForm>
  );
};

export default AccountForm;
