import { Card, Col, Row } from 'antd';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import THSClassifyTable from 'classify/components/THSClassifyTable';
import { THSClassifyType } from 'classify/models';
import React, { FunctionComponent } from 'react';

const ClassifyList: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName="板块列表">
      <Card title="同花顺板块">
        <Row gutter={16}>
          {Object.keys(THSClassifyType).map((key) => {
            const type = key as keyof typeof THSClassifyType;
            return (
              <Col span={12}>
                <Card title={THSClassifyType[type]}>
                  <THSClassifyTable key={key} type={type} />
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
    </WorkdeskLayout>
  );
};

export default ClassifyList;
