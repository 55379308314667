import { useCallback, useReducer } from "react";
import produce, { Draft } from 'immer';
import _ from 'lodash';

interface Action {
  type: string,
  payload: {
    path: string | Array<string>,
    value?: any,
    idx?: number,
  },
}

export type Store = Record<string, any>;

const reducer = produce((state: Draft<Store>, action: Action) => {
  const payload = action.payload;
  const path = payload.path;

  let currentItems;

  switch (action.type) {
    case 'setValue':
      _.set(
        state,
        path,
        payload.value
      );
      return state;
    case 'addItem':
      currentItems = (path as Array<string>).reduce((state, k: string) => state[k], state);
      if (!currentItems) {
        _.set(state, path, [{}]);
      } else {
        currentItems.push({});
      }
      return state;
    case 'removeItem':
      currentItems = (path as Array<string>).reduce((state, k: string) => state[k], state);
      currentItems.splice(payload.idx, 1);
      return state;
    default:
      return state;
  }
});

const INITAL_STORE: Store = {}; // 修改时，是从服务器拉取的数据或者从本地恢复的

export const useStore = () => {
  const [store, dispatch] = useReducer(reducer, INITAL_STORE);

  const setValue = useCallback((path, value) => dispatch({
    type: 'setValue',
    payload: {
      path,
      value,
    }
  }), []);

  const addItem = useCallback(
    (path) => dispatch({ type: 'addItem', payload: { path } }),
    []
  );

  const removeItem = useCallback((path, idx) => dispatch({ type: 'removeItem', payload: { path, idx } }), []);

  return {
    store,
    actions: {
      setValue,
      addItem,
      removeItem,
    }
  }
};
