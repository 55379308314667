import React, { useState } from 'react';

import './index.scss';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import PageHeader from 'app/components/PageHeader';
import AIModelPredictAggTable from '../PredictAggregation/AggregationTable';
import { ColumnProps } from 'app/components/Table';
import { PredictAggregation } from 'ai/models';
import SearchInput from 'stock/components/SearchInput';
import { Link } from 'react-router-dom';
import { StockLimitTag } from 'stock/components/LimitTag';
import ClassifyTag from 'classify/components/ClassifyTag';
import { sortPredicts } from 'ai/utils';
import MobileModelPredictCard from 'ai/components/MobileModelPredictCard';
import { Tabs } from 'antd';
import MQCharts from 'app/components/MQCharts';
import { predictsConceptChartOptions, predictsIndustryChartOptions, predictsLimitChartOptions } from '../PredictAggregation/options';
import moment from 'moment';
import { StockDailyColumns } from 'stock/components/StockTableComponents';
import RangeStatistic from '../PredictAggregation/RangeStatistic';
import ConceptSectors from 'classify/components/ConceptSectors';

const COLUMNS: ColumnProps<PredictAggregation>[] = [
  {
    title: '股票',
    dataIndex: 'code',
    key: 'code',
    width: 80,
    fixed: 'left',
    renderFormItem(item, config, form) {
      return (
        <SearchInput
          onSelect={(value) => {
            form.setFieldsValue({ [item.dataIndex as string]: value.split(' ')[0] });
          }}
          search={false}
        />
      );
    },
    render: (_, record) => (
      <Link to={`/stocks/${record.info.code}`} target="_blank">
        {record.info.name}
        <br />
        {record.info.code}
        <StockLimitTag stock={record} />
        <br />
        <ClassifyTag {...record.industry_sector} />
        <ConceptSectors sectors={record.concept_sectors} />
      </Link>
    ),
    sorter: false,
  },
  {
    title: '推荐',
    search: false,
    dataIndex: 'confidence',
    render: (_, r) => r.confidence,
    sorter: (a, b) => a.confidence - b.confidence,
    defaultSortOrder: 'descend',
  },
  {
    title: '预测',
    dataIndex: 'prob',
    render: (_, r) => {
      const sortedProbs = sortPredicts(r.probs);
      return (
        <div className="predicts">
          {sortedProbs.map((prob) => (
            <MobileModelPredictCard predict={prob} />
          ))}
        </div>
      );
    },
    sorter: (a, b) => {
      // 首先比较大于0.9的预测数
      // 然后比较大于0.8的预测数
      // 然后比较平均日均收益
      // 实际上0.8-0.8之间的预测只是作为一个参考，不应该作为排序的依据
      // 0.8-0.9之间的预测，因为精确度实际上都是0.9以上的，所以也不纳入平均收益的计算
      const aHighProb = a.probs.filter((prob) => prob.prob >= 0.9);
      const bHighProb = b.probs.filter((prob) => prob.prob >= 0.9);
      if (aHighProb.length !== bHighProb.length) {
        return aHighProb.length - bHighProb.length;
      } else {
        const aMidProb = a.probs.filter((prob) => prob.prob >= 0.845);
        const bMidProb = b.probs.filter((prob) => prob.prob >= 0.845);
        if (aMidProb.length !== bMidProb.length) {
          return aMidProb.length - bMidProb.length;
        } else {
          return a.confidence - b.confidence;
        }
      }
    },
  },
];
const PredictMobile = () => {
  const [predicts, setPredicts] = useState<PredictAggregation[]>([]);

  const predictDate = predicts.length > 0 ? moment(predicts[0]?.date).format('YYYY-MM-DD') : '';

  const title = `模型预测${predictDate ? `(${predictDate})` : ''}`;
  return (
    <SinglePageLayout pageName="模型预测" className="predict-mobile">
      <PageHeader title={title} />
      <Tabs defaultActiveKey="table">
        <Tabs.TabPane tab="详情" key="table">
          <AIModelPredictAggTable
            setData={setPredicts}
            tableProps={{
              search: false,
              pagination: false,
              paginationConfig: {
                per_page: 500,
              },
              columns: COLUMNS.concat(StockDailyColumns as any),
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="概念" key="concept">
          <MQCharts
            options={predictsConceptChartOptions(predicts, 10)}
            className="predicts-classify-chart"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="行业" key="industry">
          <MQCharts
            options={predictsIndustryChartOptions(predicts, 10)}
            className="predicts-classify-chart"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="周期" key="limit">
          <MQCharts
            options={predictsLimitChartOptions(predicts)}
            className="predicts-limit-chart"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="趋势" key="range-statistic">
          <RangeStatistic count={50} />
        </Tabs.TabPane>
      </Tabs>
    </SinglePageLayout>
  );
}

export default PredictMobile;