import { BrowserHistoryState } from "./slice";

const KEY = 'browser_history';

export const getBrowserHistory = (): BrowserHistoryState => {
  const history = localStorage.getItem(KEY);
  if (history) {
    return JSON.parse(history);
  } else {
    return [];
  }
};

export const setBrowserHistory = (history: BrowserHistoryState) => {
  localStorage.setItem(KEY, JSON.stringify(history));
};