import React, { useEffect } from 'react';

import Routes from 'app/routes';
import { useAutoLogin } from 'auth/hooks';

import AppContext from 'app/context';

import './App.scss';
import { fetchLatestTradeDateThunk } from 'market/slice';
import { useAppDispatch } from 'app/hooks';

function App() {
  useAutoLogin();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // 获取最近交易日
    dispatch(fetchLatestTradeDateThunk());
  }, [dispatch]);

  return (
    <div className="app">
      <AppContext.Provider value={{ dispatch }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;