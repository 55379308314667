import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import MQCharts from 'app/components/MQCharts';
import { CandleType, Stock } from 'stock/models';
import { RootState } from 'app/store';

import { useCandleDataApi } from './hooks';
import { getOptions } from './utils';

import './index.scss';
import { ECharts } from 'echarts';

interface CandleChartProps {
  code: Stock['code'],
}

export function CandleChart(props: CandleChartProps) {
  const { code } = props;

  useCandleDataApi({ code, type: CandleType.DAILY });

  const data = useSelector((state: RootState) => state.stock[code]);
  const options = useMemo(() => getOptions(data), [data]);

  if (!options) {
    return null;
  }

  const eventHandler = (chart: ECharts) => {
    chart.off('click');
    chart.on('click', (params) => {
      if (params.componentType === 'markPoint') {
        // 如果params.data有modelId, 则跳转到模型详情页
        if ((params.data as any).modelId) {
          window.open(`/ai/model/${(params.data as any).modelId}`, '_blank');
        }
      }
    });
  }

  return (
    <div className="candle-chart">
      <MQCharts options={options} className='candle-chart' eventHandler={eventHandler} />
    </div>
  )
}
