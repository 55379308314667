import React, { FunctionComponent } from 'react';

import { Classify } from 'classify/models';
import Tag from 'antd/es/tag';
import { Link } from 'react-router-dom';

interface ClassifyTagProps {
  code: Classify['code'];
  name: Classify['name'];
  source: Classify['source'];
  type: Classify['type'];
  level?: Classify['level'];
  is_watching: Classify['is_watching'];
}

const ClassifyTag: FunctionComponent<ClassifyTagProps> = ({ code, name, source, type, level, is_watching}) => {
  let label;
  if (source === 'THS' && type === 'I') {
    label = `${name} - ${level}级`;
  } else {
    label = name;
  }

  const watching = is_watching?.signal_type && is_watching.signal_type === 'BUY';

  return (
    <Tag key={code} color={watching ? '#F50' : '#87d068'} className="stock-classify-tag">
      <Link to={`/classify/${source.toLocaleLowerCase()}/${code}`} target="_blank">
        {label}
        {/* <br />
        {is_watching.signal_date && `起始${watching ? '关注' : '取关'}: ${is_watching.signal_date}`} */}
      </Link>
    </Tag>
  );
};

export default ClassifyTag;
