import React, { FunctionComponent } from "react";
import Table, { ColumnProps } from "app/components/Table";
import moment from "moment";
import { AnalysisResult } from "../CrossComparison/models";

import "./index.scss";
import { useResultTableActions } from "./hooks";
import PageHeader from "app/components/PageHeader";
import { Link } from "react-router-dom";

const COLUMNS: ColumnProps<AnalysisResult>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    search: false,
    width: '20%',
  },
  {
    title: '描述',
    dataIndex: 'description',
    search: false,
  },
  {
    title: '保存方式',
    dataIndex: 'auto',
    render: (_, record) => record.auto ? '自动' : '手动',
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    search: false,
    width: '10%',
    render: (_, record) => moment(record.created_at).format('YYYY-MM-DD HH:mm:SS'),
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'x',
    width: '10%',
    render: (_, record) => <Link to={`/analysis/result/${record.id}`}>分析</Link>,
  },
];

const ResultsTable: FunctionComponent<{}> = () => {
  const { request } = useResultTableActions();

  return (
    <div className="analysis-results-table">
      <PageHeader title="分析结果列表" />
      <Table columns={COLUMNS} rowKey="id" request={request} options={false} search={false} />
    </div>
  );
};

export default ResultsTable;
