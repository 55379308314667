import { Account, AccountAmountRecord } from 'account/models';
import React from 'react';
import AmountRecordForm from './AmountRecordForm';
import Table, { ColumnProps } from 'app/components/Table';
import moment from 'moment';
import { Button } from 'antd';
import Modal from 'antd/lib/modal';
import { dispatch } from 'app/store';
import { deleteAccountAmountRecordThunk, fetchAccountDetailThunk } from 'account/slice';
import { AnyAction } from '@reduxjs/toolkit';

interface Props {
  accountID: Account['id'];
  records: Account['amount_records'];
}

const TYPETEXT = {
  D: '存入',
  W: '取出',
};

const COLUMNS: ColumnProps<AccountAmountRecord>[] = [
  {
    title: '时间',
    dataIndex: 'datatime',
    render: (_, r) => moment(r.datetime).format('YY/MM/DD HH:mm:ss'),
  },
  {
    title: '类型',
    dataIndex: 'type',
    render: (_, r) => TYPETEXT[r.type],
  },
  {
    title: '金额',
    dataIndex: 'amount',
  },
  {
    title: '操作',
    render: (_, r) => {
      return (
        <Button danger size="small" onClick={() => {
          Modal.confirm({
            title: '确认删除',
            content: '确认删除该记录吗？',
            onOk: () => {
              dispatch(
                deleteAccountAmountRecordThunk({
                  accountID: r.account_id,
                  recordID: r.id,
                }) as unknown as AnyAction
              ).then(() => {
                dispatch(fetchAccountDetailThunk(r.account_id) as unknown as AnyAction);
              });
            }
          });
        }}>
          删除
        </Button>
      );
    }
  }
];

const AmountRecords: React.FC<Props> = ({ accountID, records }) => {
  return (
    <div className="amount-records">
      <AmountRecordForm mode="CREATE" accountID={accountID} />
      <Table columns={COLUMNS} dataSource={records} toolBarRender={false} search={false} />
    </div>
  );
};

export default AmountRecords;
