import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Statistic, StatisticProps } from 'antd';
import { FunctionComponent } from 'react';

export enum AmountUnit {
  Yuan = '元',
  Thounsand = '千',
  Wan = '万',
  Million = '百万',
  HundredMillion = '亿',
}

export class AmountFormatter {
  raw_value: number;
  unit: AmountUnit;

  constructor(value: number, unit: AmountUnit) {
    this.raw_value = value;
    this.unit = unit;
  }

  getBase = (unit: AmountUnit) => {
    switch (unit) {
      case AmountUnit.Yuan:
        return 1;
      case AmountUnit.Thounsand:
        return 1000;
      case AmountUnit.Wan:
        return 10000;
      case AmountUnit.Million:
        return 1000000;
      case AmountUnit.HundredMillion:
        return 100000000;
    }
  };

  parseTo = (unit: AmountUnit, withUnit: boolean = true): string => {
    const division = this.getBase(unit) / this.getBase(this.unit);

    const value = this.raw_value / division;

    if (withUnit) {
      return `${value.toFixed(2)} ${unit}`;
    } else {
      return value.toString();
    }
  };

  toString = (): string => {
    return this.parseTo(this.unit);
  };
}

export const formatPctChange = (start: number, end: number): number => {
  return ((start - end) * 100) / end;
};

interface CompareStatisticProps extends StatisticProps {
  a: number;
  b: number;
}

export const CompareStatistic: FunctionComponent<CompareStatisticProps> = ({ a, b, ...props }) => {
  return (
    <Statistic
      prefix={a > b ? <ArrowUpOutlined /> : a < b ? <ArrowDownOutlined /> : null}
      value={`${a}(${b})`}
      className={a > b ? 'value-up' : a < b ? 'value-down' : ''}
      {...props}
    />
  );
};
