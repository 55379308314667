import { Card, Skeleton } from 'antd';
import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import ClassifyTag from 'classify/components/ClassifyTag';
import { ClassifySource, ClassifySourceText, THSClassifyType } from 'classify/models';
import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stock } from 'stock/models';
import { fetchStockClassifiesThunk } from 'stock/slice';

interface StockClassifiesProps {
  code: Stock['code'];
}

const StockClassifies: FunctionComponent<StockClassifiesProps> = ({ code }) => {
  const stockData = useSelector((state: RootState) => state.stock[code]);
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchStockClassifiesThunk(code));
  }, [dispatch, code]);

  if (!stockData || !stockData.classifies) {
    return <Skeleton active />;
  }

  return (
    <Card size="small" className='stock-classifies'>
      {Object.keys(ClassifySource).map((source) => {
        const classifies =
          stockData.classifies[source.toLowerCase() as keyof typeof ClassifySource];

        if (!classifies) return null;

        if (source === 'THS') {
          return (
            <div key={source} className="classify-tags">
              {
                Object.keys(THSClassifyType).map((type) => {
                return (
                  <div key={source} className="classify-tags">
                    {THSClassifyType[type as keyof typeof THSClassifyType]} :
                    {classifies.filter(c => c.type === type).map((c) => (
                      <ClassifyTag
                        key={c.code}
                        code={c.code}
                        name={c.name}
                        source={ClassifySource[source as keyof typeof ClassifySource]}
                        type={c.type}
                        level={c.level}
                        is_watching={c.is_watching}
                      />
                    ))}
                  </div>
                )
                })
              }
            </div>
          )
        } else {
          return (
            <div key={source} className="classify-tags">
              {ClassifySourceText[source as keyof typeof ClassifySource]}} :
              {classifies.map((c) => (
                <ClassifyTag
                  key={c.code}
                  code={c.code}
                  name={c.name}
                  source={ClassifySource[source as keyof typeof ClassifySource]}
                  type={c.type}
                  level={c.level}
                  is_watching={c.is_watching}
                />
              ))}
            </div>
          );
        }
      })}
    </Card>
  );
};

export default StockClassifies;
