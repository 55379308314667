import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CountByStrategy, Signal } from './models';
import { DataWithPagination } from 'app/services/api';
import * as signalApi from './api';

export interface SignalState {
  list: DataWithPagination<Signal>;
  countByStrategy?: {
    date: string;
    detail: CountByStrategy[];
  };
}

const initialState: SignalState = {
  list: { items: [] },
};

export const fetchSignalListThunk = createAsyncThunk(
  'signal/fetchList',
  async (params: signalApi.ListParams) => {
    const res = await signalApi.fetchSignalList(params);

    return {
      code: params.code,
      data: res.data as DataWithPagination<Signal>,
    };
  }
);

export const fetchSignalCountByStrategyThunk = createAsyncThunk(
  'signal/fetchCountByStrategy',
  async (date?: string) => {
    return (await signalApi.fetchSignalCountByStrategy(date)).data;
  }
);

export const signalSlice = createSlice({
  name: 'signal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignalListThunk.fulfilled, (state, { payload }) => {
      state.list = payload.data;
    });
    builder.addCase(fetchSignalCountByStrategyThunk.fulfilled, (state, { payload }) => {
      state.countByStrategy = {
        date: payload.date,
        detail: payload.detail.map(s => ({
          ...s,
          classifyDistribution: s.classify_distribution,
        }))
      };
    });
  },
});

export default signalSlice.reducer;
