import React, { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Tabs } from 'antd';

import { CandleChart } from 'stock/components/CandleChart';

import FundPieChart from 'stock/components/FundPieChart';
import { history } from 'app/routes';
import AddToCollection from 'collection/components/AddToCollection';
import PageHeader from 'app/components/PageHeader';
import { useStockDetail } from 'stock/hooks';

import './index.scss';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import StockClassifies from 'stock/components/Classifies';
import StockDailyData from 'stock/components/DailyData';
import StockPredictTable from 'ai/components/StockPredictTable';
import DailyFeatureTable from 'feature/components/DailyFeatuerTable';
import AIModelPredictAggTable from 'ai/pages/PredictAggregation/AggregationTable';
import { fetchStockModelPredicts } from 'ai/api';

const { TabPane } = Tabs;

export enum StockPageTab {
  current = 'current',
  fund = 'fund',
  features = 'features',
  predicts = 'predicts',
}

export default function Stock() {
  const { code } = useParams<{code: string }>();
  const location = useLocation();
  const activeTab = (location.hash && location.hash.split('#')[1]) || StockPageTab.current;

  const stock = useStockDetail(code);

  const setTab = useCallback(
    (tabKey) => {
      history.push(`/stocks/${code}#${tabKey}`);
    },
    [code]
  );

  if (!stock) {
    return null;
  }

  return (
    <SinglePageLayout pageName="signal-stock">
      <PageHeader
        title={`${stock?.info.name}(${stock?.info.code})`}
        extra={<AddToCollection code={code} />}
      />
      <CandleChart code={code} />
      <Tabs type="card" activeKey={activeTab} onChange={setTab}>
        <TabPane tab='当期' key='current'>
          <StockDailyData code={code} />
          <AIModelPredictAggTable
            apiFunction={() => fetchStockModelPredicts(code)}
            tableProps={{
              search: false,
            }}
          />
        </TabPane>
        <TabPane tab='板块' key='classify'>
          <StockClassifies code={code} />
        </TabPane>
        <TabPane tab="预测" key="predicts">
          <StockPredictTable code={code} />
        </TabPane>
        <TabPane tab="特征" key="features">
          <DailyFeatureTable code={code} target="STOCK" />
        </TabPane>
        <TabPane tab="资金" key="fund">
          <FundPieChart code={code} config={[1, 5, 13]} />
        </TabPane>
      </Tabs>
    </SinglePageLayout>
  );
}