import { Card, Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import React, { useEffect, useState } from 'react';
import { fetchZhangtingTrenders } from 'stock/api';
import { ZhangtingTrender } from 'stock/models';
import { buildEChartsOption } from './options';

import './index.scss';

interface Props {
  count: number;
}

const StockLimitTrender: React.FC<Props> = ({ count }) => {
  const [trender, setTrender] = useState<ZhangtingTrender>();

  useEffect(() => {
    fetchZhangtingTrenders(count).then((res) => {
      setTrender(res.data);
    })
  }, [count]);

  if (!trender) {
    return <Skeleton active />;
  }

  return (
    <Card title="涨停板趋势" size="small" className='stock-limit-trender'>
      <MQCharts options={buildEChartsOption(trender, 'count', [1])} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'count', [2])} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'ratios', [2])} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'count', [3], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'ratios', [3], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'count', [4], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'ratios', [4], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'count', [5], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'ratios', [5], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'count', [6], 'bar')} className='stock-limit-trender-count-chart' />
      <MQCharts options={buildEChartsOption(trender, 'ratios', [6], 'bar')} className='stock-limit-trender-count-chart' />
    </Card>
  );
};

export default StockLimitTrender;