import React from 'react';
import { AIModelPredict } from 'ai/models';
import classNames from 'classnames';
import './index.scss';
import { AlertOutlined } from '@ant-design/icons';

interface Props {
  predict: AIModelPredict;
}

const MobileModelPredictCard: React.FC<Props> = ({ predict }) => {
  const className = classNames('mobile-model-predict-card', {
    success: predict.max_chg && predict.label,
    fail: predict.max_chg && !predict.label,
    unknown: predict.max_chg === null,
    inactive: predict.prob < 0.795,
  });


  let statsDetail;
  const stats = predict.ai_model.spec.stats;

  if (predict.prob >= 0.9) {
    statsDetail = stats.detail[0.9];
  } else {
    statsDetail = stats.detail[0.8];
  }


  let modelDetail;

  const details = predict.ai_model.detail;

  if (Array.isArray(details)) {
    if (predict.prob >= 0.9) {
      modelDetail = details.filter((d) => d.threshold === 0.9)[0];
    } else {
      modelDetail = details.filter((d) => d.threshold === 0.8)[0];
    }
  } else {
    modelDetail = details;
  }

  if (modelDetail.val_pre <= 50) {
    return null;
  }

  return (
    <div className={className}>
      <div className="row">
        <div
          className={classNames('prob', {
            alert: predict.ai_model.spec.target.chg_pct <= 0,
          })}
        >
          {predict.ai_model.spec.target.chg_pct <= 0 && <AlertOutlined />}
          {predict.prob.toFixed(3)}({predict.ai_model.name})
        </div>
      </div>
      {/* <div>
        {statsDetail.positive_count === 0 ? (
          ' -'
        ) : (
          <span>
            {(statsDetail.tp_pct * 100).toFixed(2)}%({statsDetail.positive_count})
          </span>
        )}
      </div> */}
      <div>
        {modelDetail.positive_count === 0 ? (
          ' -'
        ) : (
          <span>
            {modelDetail.val_pre.toFixed(2)}%
          </span>
        )}
      </div>
    </div>
  );
};

export default MobileModelPredictCard;
