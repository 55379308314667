import React, { FunctionComponent } from 'react';
import MQCharts from 'app/components/MQCharts';
import { RootState } from 'app/store';
import { ECharts, EChartsOption, LineSeriesOption, SeriesOption } from 'echarts';
import { range } from 'lodash';
import { useSelector } from 'react-redux';
import { CrossComparisonState } from '../../slice';
import { useGroupCompareActions } from '../hooks';

/**
 * 格式化数据
 * 信号发生日的值记为1，这样更好看到形态出现之后，价格的走势
 */
const formatValue = (
  key: keyof CrossComparisonState['group']['dataset'],
  data: CrossComparisonState['group']['dataset']
): Array<string> => {
  const endDate = key.split('_')[1];

  const benchmarkValue = data[key].find(d => d[0] === endDate)?.[1];

  if (!benchmarkValue) {
    return [];
  }

  return data[key].map(x => (x[1] / benchmarkValue).toFixed(2));
};

const getOptions = (data: CrossComparisonState['group']['dataset']): EChartsOption => {
  const makeSeries = (): SeriesOption[] => {
    const series: LineSeriesOption[] =  Object.keys(data).map(key => {
      return {
        name: key,
        type: "line",
        data: formatValue(key, data),
        symbol: "circle",
        symbolSize: 2.5,
        emphasis: {
          focus: "series",
        },
        lineStyle: {
          width: 1,
        },
      };
    });

    series[0]['markLine'] = {
      data: [{ name: "基准线", yAxis: 1 }],
      lineStyle: {
        type: 'solid',
        color: 'red',
      },
      symbol: 'none',
    };

    return series;
  };

  return {
    animation: false,
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: range(Object.values(data)[0].length),
    },
    yAxis: {
      type: "value",
      min: (value) => (value.min - 0.2).toFixed(1),
      max: (value) => (value.max + 0.2).toFixed(1),
    },
    series: makeSeries(),
  };
};

const ComparisonChart: FunctionComponent<{}> = () => {
  const data = useSelector((state: RootState) => state.analysis.crossComparison.group.dataset);
  const { checkStockDetail } = useGroupCompareActions();
  if (Object.keys(data).length === 0) return null;

  const eventHandler = (chart: ECharts) => {
    chart.off('click');
    chart.on('click', 'series', (params) => {
      if (params.seriesName) {
        checkStockDetail(params.seriesName);
      }
    });
  }
  return (
    <div>
      <MQCharts
        options={getOptions(data)}
        className="group-comparison-chart"
        eventHandler={eventHandler}
      />
    </div>
  );
}

export default ComparisonChart;