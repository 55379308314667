import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React, { FunctionComponent } from 'react';
import MLModelPredictPane from 'strategy/components/MLModelPredictPane';

const Predict: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName='stratege-predict' title='模型预测'>
      <MLModelPredictPane />
    </WorkdeskLayout>
  );
};

export default Predict;
