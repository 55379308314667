import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateRangeParams } from "app/services/api";
import { DataWithMetaState, RootState } from "app/store";
import { MarginDaily, Turnover, MarginData, HSGT, Exchange } from "./models";
import * as marketApi from './api';

import { groupBy, sumBy } from 'lodash';
import { createAppAsyncThunk } from "app/redux/createAppAsyncThunk";

export type TurnoverState = DataWithMetaState<Turnover[]>;
export type MarginState = DataWithMetaState<MarginDaily[]>;
export type HSGTState = DataWithMetaState<HSGT[]>;

export interface MarketState {
  turnover?: TurnoverState,
  margin?: MarginState,
  hsgt?: HSGTState,
  latestTradeDate?: string,
}

interface MarketThunkParams extends DateRangeParams {
  count?: number;
}

const initialState: MarketState = {}

export const fetchTurnoverThunk = createAsyncThunk('turnover/fetch', async (params?: MarketThunkParams) => {
  const result = await marketApi.fetchTurnoverData(params);
  return {
    data: result.data,
    meta: result.meta,
  }
});

export const fetchMarginTunk = createAsyncThunk('margin/fetch', async (params?: MarketThunkParams) => {
  const { data, meta } = await marketApi.fetchMarginData(params);

  const grouped = groupBy(data, _data => _data.date);

  const sumKeys = meta?.map(field => field.dataIndex).filter(k => !['exchange', 'date'].includes(k));

  Object.keys(Exchange).forEach(exchange => {
    meta.push({
      dataIndex: `${exchange}-rzrqye`,
      title: `${Exchange[exchange as keyof typeof Exchange]}-融资融券余额`
    })
  });

  const formatted: MarginDaily[] = Object.entries(grouped).map(([date, components]) => {
    const sumData = sumKeys?.map(k => {
      return [k, sumBy(components, c => c[k])];
    });

    const flatData = components.map(c => {
      return [`${c.exchange}-rzrqye`, c.rzrqye];
    })

    return {
      date,
      components,
      ...(Object.fromEntries((sumData as [])) as MarginData),
      ...(Object.fromEntries(flatData)),
    };
  });


  return {
    data: formatted,
    meta: meta,
  }
});

export const fetchHSGTThunk = createAsyncThunk('hsgt/fetch', async (params?: MarketThunkParams) => {
  const result = await marketApi.fetchHSGTData(params);

  return {
    data: result.data,
    meta: result.meta,
  }
});

export const fetchLatestTradeDateThunk = createAppAsyncThunk(
  'market/fetchLatestTradeDate',
  async () => {
    return (await marketApi.fetchLatestTradeDate()).data;
  },
  {
    condition(_, { getState }) {
      const date = (getState() as RootState).market.latestTradeDate;
      if (date) {
        return false;
      }
    },
  }
);

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTurnoverThunk.fulfilled, (state, action) => {
      state['turnover'] = action.payload;
    });
    builder.addCase(fetchMarginTunk.fulfilled, (state, action) => {
      state['margin'] = action.payload;
    });
    builder.addCase(fetchHSGTThunk.fulfilled, (state, action) => {
      state['hsgt'] = action.payload;
    });
    builder.addCase(fetchLatestTradeDateThunk.fulfilled, (state, { payload }) => {
      state['latestTradeDate'] = payload.date;
    });
  }
})

export default marketSlice.reducer;