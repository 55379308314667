import { fetchAccountDetailThunk, updateAccountHistoriesThunk } from 'account/slice';
import { Modal, Skeleton } from 'antd';
import PageHeader from 'app/components/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import SinglePageLayout from 'app/layouts/SinglePageLayout';
import React, { FunctionComponent, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import AccountHistories from './AccountHistories';
import Tabs from 'antd/lib/tabs';
import AmountRecords from './AmountRecords';

import './index.scss';
import Card from 'antd/es/card/Card';
import { AmountFormatter, AmountUnit } from 'market/utils';
import TradeRecords from './TradeRecords';
import Table, { ColumnProps } from 'app/components/Table';
import { AccountPosition } from 'account/models';
import Button from 'antd/lib/button';
import { SyncOutlined } from '@ant-design/icons';
import AccountSignalsTable from 'account/components/AccountSignalsTable';

const PostionTableColumns: ColumnProps<AccountPosition>[] = [
  {
    title: '股票/市值',
    dataIndex: 'code',
    render: (_, r) => (
      <>
        <Link to={`/stocks/${r.ts_code}`} target="_blank">
          <div>{r.name}</div>
        </Link>
        <div>{new AmountFormatter(r.amount, AmountUnit.Yuan).parseTo(AmountUnit.Wan)}</div>
      </>
    ),
  },
  {
    title: '持仓盈亏',
    dataIndex: 'pl',
    render: (_, r) => {
      const className = r.pl_amount > 0 ? 'value-up' : 'value-down';
      return (
        <div className={className}>
          <div>{r.pl_amount.toFixed(2)}</div>
          <div>{r.pl_pct} %</div>
        </div>
      );
    },
  },
  {
    title: '持股天数',
    dataIndex: 'days'
  },
  {
    title: '持仓股数',
    dataIndex: 'shares',
  },
  {
    title: '现价/成本',
    dataIndex: 'price',
    render: (_, r) => `${r.price} / ${r.cost_price}`,
  },
  {
    title: '仓位占比',
    dataIndex: 'position_pct',
    render: (_, r) => `${r.position_pct} %`,
  },
];

const Detail: FunctionComponent = () => {
  const accountID = useParams<{ id: string }>().id;
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.details[accountID]);

  useEffect(() => {
    dispatch(fetchAccountDetailThunk(accountID));
  }, [dispatch, accountID]);

  if (!account) {
    return <Skeleton active />;
  }

  return (
    <SinglePageLayout pageName="account-detail">
      <PageHeader
        title={account.title}
        extra={
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={() => {
              Modal.confirm({
                title: `确认更新所有历史-${account.title}`,
                content: '确认更新所有历史吗？可能会耗时较长',
                onOk: () => {
                  dispatch(updateAccountHistoriesThunk(accountID) as unknown as any);
                },
              });
            }}
          >
            更新历史
          </Button>
        }
      />
      {account.description && (
        <Card title="说明" className="account-info" size="small">
          <span className="value">{account.description}</span>
        </Card>
      )}
      <Card title="持仓" className="account-info" size="small">
        <div className="account-info-item">
          <span className="label">日期</span>
          <span className="value">{account.status.date}</span>
        </div>
        <div className="account-info-item">
          <span className="label">净值</span>
          <span className="value">{account.status.net_value}</span>
        </div>
        <div className="account-info-item">
          <span className="label">总额</span>
          <span className="value">
            {new AmountFormatter(account.status.amount, AmountUnit.Yuan).parseTo(AmountUnit.Wan)}
          </span>
        </div>
        <Table
          dataSource={account.status.position}
          columns={PostionTableColumns}
          search={false}
          toolBarRender={false}
        />
      </Card>
      <Tabs defaultActiveKey="signals">
        <Tabs.TabPane tab="持仓信号" key="signals">
          <AccountSignalsTable accountID={accountID} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="交易记录" key="trade">
          <TradeRecords accountID={accountID} records={account.trade_records} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="转账记录" key="amount">
          <AmountRecords accountID={accountID} records={account.amount_records} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="净值历史" key="history">
          <AccountHistories histories={account.histories} />
        </Tabs.TabPane>
      </Tabs>
    </SinglePageLayout>
  );
};

export default Detail;
