import { ActionType } from '@ant-design/pro-components';
import Table from 'app/components/Table';
import { deleteDailyFeatureSpec, fetchDailyFeatureSpecs } from 'feature/api';
import { DailyFeatureSpec, FeatureCategory } from 'feature/models';
import React, { FunctionComponent, useEffect } from 'react';
import SpecForm from '../SpecForm';
import { Button, Modal, Tag } from 'antd';

import './index.scss';
import { DeleteOutlined } from '@ant-design/icons';
import { getParents } from 'app/components/Tree/utils';

interface SpecTableProps {
  category: Pick<FeatureCategory, 'id' | 'name'>;
  allCategories: FeatureCategory[];
}

const SpecTable: FunctionComponent<SpecTableProps> = ({ category, allCategories }) => {
  const tableRef = React.useRef<ActionType>();
  const categoryId = category.id;

  useEffect(() => {
    tableRef.current?.reload();
  }, [categoryId]);

  return (
    <Table<DailyFeatureSpec>
      className="feature-spec-table"
      fetchAction={(params) => {
        if (params.filter) {
          params.filter.category_id = categoryId;
        } else {
          params.filter = { category_id: categoryId };
        }
        return fetchDailyFeatureSpecs(params);
      }}
      rowKey={(record) => record.id}
      useRedux={false}
      sortInLocal={true}
      actionRef={tableRef}
      options={false}
      pagination={{ pageSize: 10 }}
      toolBarRender={() => [
        <SpecForm
          category={category}
          onFinish={() => {
            tableRef.current?.reloadAndRest?.();
          }}
        />,
      ]}
      columns={[
        {
          title: '序号',
          render: (text, record, index) => index + 1,
          search: false,
          width: 50,
        },
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: '标签',
          dataIndex: 'label',
          key: 'label',
          sorter: (a, b) => a.label.localeCompare(b.label),
        },
        {
          title: '描述',
          dataIndex: 'description',
          key: 'description',
          search: false,
        },
        {
          title: '类别',
          dataIndex: 'category_id',
          key: 'category_id',
          search: false,
          width: '15%',
          render: (_, r) => {
            const parents = getParents(allCategories, r.category_id);
            return parents.map((p) => p.name).join('->');
          },
        },
        {
          title: '计算代码',
          dataIndex: 'transformer',
          key: 'transformer',
          search: false,
          width: '35%',
          render: (_, r) => <pre>{r.transformer}</pre>,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          render: (_, r) => {
            if (r.status === 'ACTIVE') {
              return <Tag color="green">启用</Tag>;
            } else {
              return <Tag color="red">禁用</Tag>;
            }
          },
          valueEnum: {
            ACTIVE: { text: '启用' },
            INACTIVE: { text: '禁用' },
            ALL: { text: '全部' },
          },
        },
        {
          title: '操作',
          key: 'operations',
          search: false,
          className: 'operations',
          render: (_, r) => {
            return (
              <>
                <SpecForm
                  mode="EDIT"
                  spec={r}
                  onFinish={() => {
                    tableRef.current?.reloadAndRest?.();
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: `确认删除-${r.name}`,
                      content: '删除后不可恢复',
                      onOk: async () => {
                        const response = await deleteDailyFeatureSpec(r);
                        if (response.isOK) {
                          tableRef.current?.reloadAndRest?.();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default SpecTable;
