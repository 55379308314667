import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CandleDataParams } from 'stock/api';

import { fetchSignalListThunk } from 'signal/slice';
import { DEFAULT_PAGINATION } from 'app/components/Table/utils';
import { fetchCandleDataThunk, fetchStockAIModelPredicts, fetchTickAnalysisThunk } from 'stock/slice';
import moment from 'moment';

export const useCandleDataApi = ({ code }: CandleDataParams) => {
  const dispatch = useDispatch();

  const startDate = moment().subtract(5, 'years').format('YYYYMMDD');

  useEffect(() => {
    dispatch(fetchCandleDataThunk({ code, start: startDate }));
    dispatch(fetchTickAnalysisThunk({ code, start: startDate }));
    dispatch(fetchStockAIModelPredicts(code));
    dispatch(
      fetchSignalListThunk({
        code,
        pagination: { page: DEFAULT_PAGINATION.current, per_page: DEFAULT_PAGINATION.pageSize },
      })
    );
  }, [dispatch, code]);
};
