import { StockGroupItem } from "stock/components/GroupViewer/models";
import { StockCandleData } from "stock/models";

export type ResultSet = Array<ResultItem>;

export type DailyData = Array<[StockCandleData["date"], StockCandleData["close"]]>;

export interface ResultItem extends StockGroupItem {
  positive?: boolean;
}


export interface Group {
  groupIdx: number;
  groupSize: number;
  selectedKeys: string[];
  hasPrev: boolean;
  hasNext: boolean;
  dataset: Record<string, DailyData>;
}

export enum ActiveStep {
  ComparsionData,
  StockDetail
}

export enum RangeType {
  RESULT_ID,
  RANGE,
}

export interface AnalysisResult {
  id: string;
  description: string;
  url: string;
  auto: boolean;
  created_at: number;
}