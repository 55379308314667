import Tree, { TreeProps } from 'app/components/Tree';
import {
  createFeatureCategory,
  deleteFeatureCategory,
  fetchFeatureCategoryTree,
  moveFeatureCategory,
  updateFeatureCategory,
} from 'feature/api';
import React, { FunctionComponent, useMemo } from 'react';

type CategoryTreeProps = Pick<TreeProps, 'mode' | 'onSelect'>;

const CategoryTree: FunctionComponent<CategoryTreeProps> = ({ mode, onSelect }) => {
  const treeApi = useMemo(() => {
    return {
      fetchTree: fetchFeatureCategoryTree,
      createNode: createFeatureCategory,
      updateNode: updateFeatureCategory,
      deleteNode: deleteFeatureCategory,
      moveNode: moveFeatureCategory,
    };
  }, []);
  return <Tree name="feature-category" treeApi={treeApi} mode={mode} onSelect={onSelect} />;
};

export default CategoryTree;
