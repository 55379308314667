import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchStockLimitDailyStat } from './api';
import { StockLimitDailyStat } from './models';

export const fetchStockLimitDailyStatThunk = createAsyncThunk(
  'stock/fetchLimitDailyStat',
  async (date?: string) => {
    return (await fetchStockLimitDailyStat(date)).data;
  }
);

const initialState: Record<string, StockLimitDailyStat> = {};

export const stockLimitDailyStatSlice = createSlice({
  name: 'stocks/limitDailyStat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStockLimitDailyStatThunk.fulfilled, (state, { payload, meta }) => {
      if (!meta.arg) {
        state['latest'] = payload;
      }
      state[payload.date] = payload;
    });
  },
});

export default stockLimitDailyStatSlice.reducer;
