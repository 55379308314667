import React, { useCallback, useState } from 'react';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import CategoryTree from 'feature/components/CategoryTree';
import SpecTable from 'feature/components/SpecTable';
import Card from 'antd/es/card/Card';

import './index.scss';
import { FeatureCategory } from 'feature/models';

const Management = () => {
  const [selectedCategory, setSelectedCategory] = useState<{
    node: Pick<FeatureCategory, 'id' | 'name'>;
    allNodes: FeatureCategory[];
  }>();

  const onSelectCategory = useCallback((node, allNodes) => {
    setSelectedCategory({ node, allNodes });
  }, []);
  return (
    <WorkdeskLayout pageName="feature-store" title="特征管理">
      <CategoryTree mode="view" onSelect={onSelectCategory} />
      <Card className="feature-specs-container" size="small">
        {selectedCategory && (
          <SpecTable
            category={selectedCategory['node']}
            allCategories={selectedCategory['allNodes']}
          />
        )}
      </Card>
    </WorkdeskLayout>
  );
};

export default Management;
