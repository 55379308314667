export type BaseModel = Record<string, any>;

export interface ModelField {
  dataIndex: string,
  title: string,
  type?: string,
}

export class ModelMeta {
  fields: ModelField[]

  constructor(fields: ModelField[]) {
    this.fields = fields;
  }

  getTitle = (index: ModelField['dataIndex']) => {
    return this.fields.find(field => field.dataIndex === index)?.title || 'UNKNOWN';
  }
}

export type LoadingStatus = 'idle' | 'pending';

export type DataWithState<T> = {
  loaded: boolean;
  data?: T;
};