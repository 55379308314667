import React, { FunctionComponent, useMemo } from "react";
import _ from "lodash";

import MQCharts from "app/components/MQCharts";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { StockTickAnalysis, TA_META } from "stock/models";
import { EChartsOption, TitleComponentOption, TooltipComponentOption } from "echarts";

interface FundPieChartProps {
  code: string;
  config: number[];
}

const caculateAvgValue = (data: StockTickAnalysis[], key: string, range: number) => {
  return _.meanBy(
    data.slice(data.length - range).map((d) => d["components"]),
    key
  ).toFixed(2);
};

const makeSinglePie = (data: StockTickAnalysis[], days: number, idx: number) => {
  const pieData = [];
  const metas = _.pick(TA_META.components, ["major_pct", "big_pct", "medium_pct", "small_pct"]);

  let k: keyof typeof metas;

  for (k in metas) {
    const meta = metas[k];

    pieData.push({
      name: meta?.label,
      value: caculateAvgValue(data, k, days),
    });
  }

  return {
    name: `近${days}日资金分布`,
    type: "pie",
    data: pieData,
    center: ["50%", "50%"],
    label: {},
  };
};

const getOptions = (data: StockTickAnalysis[], config: FundPieChartProps["config"]) => {
  if (!data) return null;

  const width = 100 / config.length;

  const series = config.map((days, idx) => makeSinglePie(data, days, idx));
  series.forEach((pie, idx) => {
    pie.center = [`${width * (idx + 0.5)}%`, "50%"];
    pie.label = {
      formatter: "{b}: {c}%",
    };
  });

  const title: TitleComponentOption[] = [
    {
      text: "资金分布",
    },
  ];

  config.forEach((days, idx) => {
    title.push({
      subtext: `近${days}日资金分布`,
      left: `${width * (idx + 0.5)}%`,
      textAlign: "center",
      bottom: "2%",
      subtextStyle: {
        color: "#000",
        fontWeight: "bolder",
        fontSize: 16,
      },
    });
  });

  return {
    title,
    tooltip: {
      trigger: "item" as TooltipComponentOption['trigger'],
      formatter: "{a} <br/>{b} : {d}%",
    },
    series,
  };
};

const FundPieChart: FunctionComponent<FundPieChartProps> = (props) => {
  const { code, config } = props;

  const taData = useSelector((state: RootState) => state.stock[code]?.tickAnalysis);

  const options = useMemo(() => getOptions(taData, config), [taData, config]);

  if (!options) {
    return null;
  }

  return <MQCharts options={options as EChartsOption} className="fund-chart" />;
};

export default FundPieChart;
