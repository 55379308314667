import { Skeleton } from 'antd';
import Card from 'antd/es/card/Card';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { fetchZhangtingStocks } from 'stock/api';
import { ZhangtingStock } from 'stock/models';
import { StockWithDailyDataColumns } from './StockTable';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { StockLimitTag } from 'stock/components/LimitTag';
import ClassifyTag from 'classify/components/ClassifyTag';
import ConceptSectors from 'classify/components/ConceptSectors';
import { StockDailyColumns } from './StockTableComponents';
import Tabs from 'antd/lib/tabs';
import { Classify } from 'classify/models';
import classNames from 'classnames';
import _ from 'lodash';
import Table from 'app/components/Table';

const COLUMNS: ColumnsType<ZhangtingStock> = [
  {
    title: '序号',
    key: 'idx',
    render: (_, __, idx) => idx + 1,
    width: '5%',
    fixed: 'left',
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render: (_, record) => (
      <Link to={`/stocks/${record.info.code}`} target="_blank">
        {record.info.name}
        <br />
        {record.info.code}
        <StockLimitTag stock={record} />
        <br />
        <ClassifyTag {...record.industry_sector} />
        <ConceptSectors sectors={record.concept_sectors} />
      </Link>
    ),
  },
  {
    title: '日期',
    dataIndex: 'date',
    render: (_, record) => new Date(record.detail.date).toLocaleDateString(),
  },
];


const ZhangtingTabs: FunctionComponent<{
  stocks: ZhangtingStock[];
}> = ({ stocks }) => {

  const Groups: FunctionComponent<{ sectorType: string}> = ({ sectorType }) => {
    let groups: Array<{
      code: Classify['code'];
      name: Classify['name'];
      rsSignal: Classify['rs_signal'];
      rs: Classify['rs'];
      stocks: ZhangtingStock[];
      isWatching: boolean;
    }> = [];

    stocks.forEach((stock) => {
      let sectors = [];
      if (sectorType === 'INDUSTRY') {
        sectors.push(stock.industry_sector);
      } else {
        sectors = stock.concept_sectors;
      }
      sectors.forEach((sector) => {
        const group = groups.find((g) => g.code === sector.code);
        if (group) {
          group.stocks.push(stock);
        } else {
          groups.push({
            code: sector.code,
            name: sector.name,
            rsSignal: sector.rs_signal,
            rs: sector.rs,
            isWatching: sector.is_watching && sector.is_watching.signal_type === 'BUY',
            stocks: [stock],
          });
        }
      });
    });

    // groups按照stocks数量排序
    groups = _.sortBy(groups, (group) => [group.stocks.length, group.rs]).reverse();

    return (
      <Tabs>
        {groups.map((group) => (
          <Tabs.TabPane
            tab={
              <span className={classNames({ 'value-up': group.isWatching })}>
                {group.name}({group.stocks.length})
              </span>
            }
            style={{ marginBottom: 20 }}
            key={group.code}
          >
            <Table
              className="stock-table"
              columns={[...COLUMNS, ...StockDailyColumns]}
              dataSource={group.stocks}
              pagination={false}
              search={false}
              toolBarRender={false}
              rowKey={(record) => record.info.code}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  };

  return (
    <>
      共计{stocks.length}只股票
      <Groups sectorType="INDUSTRY" />
      <Groups sectorType="CONCEPT" />
    </>
  );
};

const StockLimitDetail = () => {
  const [stocks, setStocks] = useState<ZhangtingStock[]>([]);

  useEffect(() => {
    fetchZhangtingStocks().then((res) => {
      setStocks(res.data);
    });
  }, []);

  if (!stocks) {
    return <Skeleton active />;
  }

  const zhangtingCounts = Array.from(
    new Set(stocks.map((stock) => stock.detail.zhangting_count).sort((a, b) => b - a))
  );

  return (
    <Card title="涨停详情" className="stock-zhangting-detail">
      {zhangtingCounts.map((count, idx) => {
        const _data = stocks.filter((stock) => stock.detail.zhangting_count === count);
        if (count > 1) {
          return (
            <Table
              key={idx}
              className="stock-table"
              style={{ marginBottom: 30 }}
              columns={[...COLUMNS, ...StockDailyColumns]}
              dataSource={_data}
              pagination={false}
              search={false}
              toolBarRender={false}
              rowKey={(record) => record.info.code}
            />
          );
        } else {
          return (
            <ZhangtingTabs stocks={_data} />
          );
        }
      })}
    </Card>
  );
};

export default StockLimitDetail;
