import React, { FunctionComponent } from 'react';

import { Modal, Form } from 'antd';

import { useCollectionActions } from 'collection/hooks';
import CollectionForm from '../CollectionForm';

interface NewCollectionModalProps {
  visible: boolean;
  close: () => void;
}

const NewCollectionModal: FunctionComponent<NewCollectionModalProps> = (props: NewCollectionModalProps) => {
  const { visible, close } = props;
  const [form] = Form.useForm();
  const actions = useCollectionActions();

  return (
    <Modal
      title='创建自选分组'
      visible={visible}
      okText='创建'
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            actions.createCollection({
              name: values.name,
              items: values.items && values.items.split('\n'),
            });
            close();
          })
      }}
      cancelText='取消'
      onCancel={close}
    >
      <CollectionForm instance={form} />
    </Modal>
  );
};

export default NewCollectionModal;