import React, { FunctionComponent, ReactElement } from 'react';
import { Redirect, RouteComponentProps, Router } from 'react-router';
import {
  Route,
  Switch,
  RouteProps as RawRouteProps,
} from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { useSelector } from 'react-redux';

import { RootState } from './store';

import * as appPages from 'app/pages';
import * as authPages from 'auth/pages';
import * as signalPages from 'signal/pages';
import * as stockPages from 'stock/pages';
import * as collectionPages from 'collection/pages';
import * as analysisPages from 'analysis/pages';
import * as classifyPages from 'classify/pages';
import * as marketPages from 'market/pages';
import * as strategyPages from 'strategy/pages';
import * as featurePages from 'feature/pages';
import * as aiPages from 'ai/pages';
import * as accountPages from 'account/pages';

export const history = createHistory();

export interface RouteProps extends RawRouteProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>,
  breadcrumbName?: string,
  exact?: boolean,
  defaultPath?: string,
  routeComponent?: FunctionComponent<RouteProps>,
}

// 需要登录的页面
const AuthRoute = ({ component: Component, children, ...rest }: RouteProps): ReactElement => {
  const authState = useSelector((state: RootState) => state.auth);

  const render = (props: RouteComponentProps) => authState.isLogin ?
    ((Component && <Component {...props} />) || children) :
    <Redirect to={{ pathname: '/login', state: { from: props.location.pathname } }} />;

  return (<Route {...rest} render={render} />);
}

// 需要未登录的页面
const UnauthRoute = ({ component: Component, children, ...rest }: RouteProps): ReactElement => {
  const authState = useSelector((state: RootState) => state.auth);
  const defaultPath = rest.defaultPath || '/dashboard';

  const render = (props: RouteComponentProps) => authState.isLogin ?
    <Redirect to={{ pathname: defaultPath, state: { from: props.location.pathname } }} /> :
    ((Component && <Component {...props} />) || children);

  return (<Route {...rest} render={render} />);
}

export const routeConfig: RouteProps[] = [
  {
    path: '/login',
    exact: true,
    breadcrumbName: '登录',
    defaultPath: '/dashboard',
    component: authPages.Login,
    routeComponent: UnauthRoute,
  },
  {
    path: '/dashboard',
    exact: true,
    component: appPages.Dashboard,
    breadcrumbName: '每日概览',
  },
  {
    path: '/market/dashboard',
    exact: true,
    component: marketPages.Dashboard,
    breadcrumbName: '市场概览',
  },
  {
    path: '/signals',
    exact: true,
    component: signalPages.List,
    breadcrumbName: '信号列表',
    routeComponent: AuthRoute,
  },
  {
    path: '/strategy/predict',
    exact: true,
    component: strategyPages.Predict,
    breadcrumbName: '模型预测',
  },
  {
    path: '/signals/group_viewer',
    exact: true,
    component: signalPages.GroupViewer,
    breadcrumbName: '信号查看器',
  },
  {
    path: '/stocks',
    exact: true,
    component: stockPages.Dashboard,
    breadcrumbName: '股票首页',
    // routeComponent: AuthRoute,
  },
  {
    path: '/stocks/:code/:tab?',
    component: stockPages.Stock,
    breadcrumbName: '股票详情',
  },
  {
    path: '/collections/management',
    component: collectionPages.Management,
    breadcrumbName: '自选管理',
    routeComponent: AuthRoute,
  },
  {
    path: '/collections/dashboard',
    component: collectionPages.Dashboard,
    breadcrumbName: '自选首页',
    routeComponent: AuthRoute,
  },
  {
    path: '/collections',
    children: <Redirect to="/collections/dashboard" />,
    breadcrumbName: '自选首页',
    routeComponent: AuthRoute,
  },
  {
    path: '/infinity_form',
    component: appPages.InfinityForm,
    breadcrumbName: '无限表单',
  },
  {
    path: '/features/management',
    exact: true,
    component: featurePages.Management,
    breadcrumbName: '特征管理',
    routeComponent: AuthRoute,
  },
  {
    path: '/features/category',
    exact: true,
    component: featurePages.Category,
    breadcrumbName: '特征分类',
    routeComponent: AuthRoute,
  },
  {
    path: '/features/dashboard',
    exact: true,
    component: featurePages.Dashboard,
    breadcrumbName: '特征库',
    routeComponent: AuthRoute,
  },
  {
    path: '/features',
    children: <Redirect to="/features/dashboard" />,
    component: featurePages.Dashboard,
    breadcrumbName: '特征库',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/model_specs/create',
    exact: true,
    component: aiPages.CreateModelSpec,
    breadcrumbName: '创建模型定义',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/model_specs/:id/edit',
    exact: true,
    component: aiPages.EditModelSpec,
    breadcrumbName: '编辑模型定义',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/trainsets',
    exact: true,
    component: aiPages.TrainsetList,
    breadcrumbName: '训练集',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/trainsets/create',
    exact: true,
    component: aiPages.CreateTrainset,
    breadcrumbName: '创建训练集',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/trainsets/:id/edit',
    exact: true,
    component: aiPages.EditTrainset,
    breadcrumbName: '编辑训练集',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/trainsets/:id/detail',
    exact: true,
    component: aiPages.TrainsetDetail,
    breadcrumbName: '查看训练集',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/predict',
    exact: true,
    component: aiPages.ModelPredictAggregation,
    breadcrumbName: '预测',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/model/:id',
    exact: true,
    component: aiPages.ModelDetail,
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/model_specs/:id/detail',
    exact: true,
    component: aiPages.ModelSpecDetail,
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/model_specs',
    exact: true,
    component: aiPages.ModelSpecList,
    breadcrumbName: '模型定义',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai/predict_mobile',
    exact: true,
    component: aiPages.PredictMobile,
    breadcrumbName: '预测',
    routeComponent: AuthRoute,
  },
  {
    path: '/ai',
    children: <Redirect to="/ai/model_specs" />,
    routeComponent: AuthRoute,
  },
  {
    path: '/analysis/results',
    exact: true,
    component: analysisPages.ResultList,
    breadcrumbName: '分析结果列表',
  },
  {
    path: '/analysis/result/:resultID',
    exact: true,
    component: analysisPages.CrossComparison,
    breadcrumbName: '分析结果',
  },
  {
    path: '/analysis',
    children: <Redirect to="/analysis/cross-comparsion" />,
  },
  {
    path: '/classify/dashboard',
    exact: true,
    component: classifyPages.ClassifyDashboard,
    breadcrumbName: '板块概览',
  },
  {
    path: '/classify/list',
    exact: true,
    component: classifyPages.List,
    breadcrumbName: '板块列表',
  },
  {
    path: '/classify/:source/:code/:tab?',
    component: classifyPages.Detail,
    breadcrumbName: '板块详情'
  },
  {
    path: '/account/:id',
    exact: true,
    component: accountPages.AccountDetail
  },
  {
    path: '/accounts',
    exact: true,
    component: accountPages.AccountList
  }
];


export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routeConfig.map(route => {
          const RouteComponent = route.routeComponent || Route;

          return <RouteComponent key={(route.path as string)} {...route} />
        })}
        <Redirect exact from="/" to="/dashboard" />
        <Route component={appPages.NotFound} />
      </Switch>
    </Router>
  );
}