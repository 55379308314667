import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ClassifyTopType, THSTopListData } from 'classify/models';
import * as classifyApi from 'classify/api';

export type THSLatestTopState = {
  [key in keyof typeof ClassifyTopType]: THSTopListData;
};

const initialState: THSLatestTopState = {
  HIT: {
    N: [],
    I: [],
  },
  SCORE: {
    N: [],
    I: [],
  },
};

export const fetchLatestTHSTopListThunk = createAsyncThunk(
  'classify/fetchLatestTHSTopList',
  async (params: classifyApi.ClassifyTopListParams) => {
    const _params = {
      type: params.type,
      count: 1,
    };
    return (await classifyApi.fetchTHSTopList(_params)).data;
  }
);

const slice = createSlice({
  name: 'thsLatestTop',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLatestTHSTopListThunk.fulfilled, (state, { payload, meta }) => {
      const key = meta.arg?.type || ClassifyTopType.HIT;

      const grouped: THSTopListData = {
        N: [],
        I: [],
      };

      payload.forEach((classifyHit) => {
        grouped[classifyHit.type].push(classifyHit);
      });

      state[key] = grouped;
    });
  },
});

export default slice.reducer;
