import React, { FunctionComponent } from 'react';
import _ from 'lodash';
import { Row, Col, Card } from 'antd';
import ReactJson from 'react-json-view';

import StructSection from './StructSection';
import BizSection from './BizSection';

import { useStore } from './hooks';

const InfinityForm: FunctionComponent = (props) => {
  const { children } = props;

  const { store, actions } = useStore();

  return (
    <div className='infinity-form'>
      <h1>InfinityForm</h1>
      <Row>
        <Col span={14} offset={1}>
        {React.Children.map(children, child => {

          if (React.isValidElement(child)) {
            const path = child.props.formKey;
            return React.cloneElement(child, { value: _.get(store, path), actions });
          }
          return child;
        })}
        </Col>
        <Col span={6} offset={1}>
          <Card>
            <ReactJson src={store} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export {
  StructSection,
  BizSection
}

export default InfinityForm;