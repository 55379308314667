import { TreeNode } from 'app/components/Tree/models';
import { fetchFeatureCategoryTree } from './api';
import { FeatureCategoryTree } from './models';
import { TreeProps } from 'antd';

// 把node数组转换成tree结构
export const generateTree = (
  nodes: FeatureCategoryTree,
  parent_id: TreeNode['parent_id'] = null
): TreeProps['treeData'] => {
  return nodes
    .filter((node) => node.parent_id === parent_id)
    .map((node) => ({
      key: node.id,
      title: node.name,
      value: node.id,
      idx: node.idx,
      children: generateTree(nodes, node.id),
    }))
    .sort((a, b) => a.idx - b.idx);
};
// 给TreeSelector组件使用的工具函数
export const featureCategoryTreeRequest = async () => {
  const categoryList = (await fetchFeatureCategoryTree()).data;
  return generateTree(categoryList) as [];
};
