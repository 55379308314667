import React, { ReactNode } from "react";
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import {
  DashboardOutlined,
  CarryOutOutlined,
  AimOutlined,
  StockOutlined,
  FolderOutlined,
  UserOutlined,
  PoweroffOutlined,
  ExperimentOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  CrownOutlined,
  HeartOutlined,
  DatabaseOutlined,
  BarcodeOutlined,
  BarChartOutlined,
  AndroidOutlined,
  ZoomInOutlined,
  AccountBookOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { logout } from 'auth/slice';
import { RootState } from "app/store";

const { SubMenu } = Menu;

export interface MenuProps {
  key: string;
  icon: ReactNode;
  url: string;
  title: string;
  subMenus?: Array<{ key: string; url: string; title: string; external?: boolean }>;
  admin?: boolean;
}

const MENUS: MenuProps[] = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined />,
    url: '/dashboard',
    title: '总览',
  },
  {
    key: 'market',
    icon: <GlobalOutlined />,
    url: '/market/dashboard',
    admin: true,
    title: '市场',
  },
  {
    key: 'classify',
    icon: <AppstoreOutlined />,
    url: '/classify',
    title: '板块',
    admin: true,
    subMenus: [{
      key: 'classify-dashboard',
      url: '/dashboard',
      title: '概览'
    },{
      key: 'classify-list',
      url: '/list',
      title: '列表'
    }]
  },
  {
    key: 'collection',
    icon: <HeartOutlined />,
    url: '/collections',
    title: '自选',
    admin: true,
    subMenus: [{
      key: 'collection-dashboard',
      url: '/dashboard',
      title: '概览',
    }, {
      key: 'collection-management',
      url: '/management',
      title: '管理'
    }]
  },
  {
    key: 'feature',
    icon: <ZoomInOutlined />,
    url: '/features',
    title: '特征',
    admin: true,
    subMenus: [{
      key: 'feature-dashboard',
      url: '/dashboard',
      title: '总览',
    },{
      key: 'feature-management',
      url: '/management',
      title: '特征管理',
    }, {
      key: 'feature-category',
      url: '/category',
      title: '类别管理'
    }]
  },
  {
    key: 'ai',
    icon: <AndroidOutlined />,
    url: '/ai',
    title: 'AI模型',
    admin: true,
    subMenus: [{
      key: 'ai-trainsets',
      url: '/trainsets',
      title: '训练集',
    }, {
      key: 'ai-models',
      url: '/model_specs',
      title: '模型定义'
    }, {
      key: 'ai-predict',
      url: '/predict',
      title: '预测结果',
    }]
  },
  {
    key: 'ai-predict',
    url: '/ai/predict',
    title: '预测',
    icon: <CrownOutlined />,
  },
  {
    key: 'signal',
    icon: <AimOutlined />,
    url: '/signals',
    title: '信号'
  },
  {
    key: 'stock',
    icon: <StockOutlined />,
    url: '/stocks',
    admin: true,
    title: '行情'
  },
  // {
  //   key: 'analysis',
  //   icon: <ExperimentOutlined />,
  //   url: '/analysis',
  //   title: '分析',
  //   admin: true,
  //   subMenus: [{
  //     key: 'jupyter',
  //     url: 'http://jupyter.morequant.com',
  //     title: 'JupyterLab',
  //     external: true,
  //   },{
  //     key: 'cross-comparison',
  //     url: '/results',
  //     title: '结果集',
  //   }]
  // },
  {
    key: 'account',
    icon: <AccountBookOutlined />,
    url: '/accounts',
    title: '账户',
    admin: true,
  }
  // {
  //   key: 'portfolio',
  //   icon: <FolderOutlined />,
  //   url: '/portfolio',
  //   admin: true,
  //   title: '组合'
  // },
  // {
  //   key: 'profile',
  //   icon: <UserOutlined />,
  //   url: '/profile',
  //   admin: true,
  //   title: '账户'
  // },
];

const useCurrentKey = () => {
  const location = useLocation();
  const pathname = location.pathname;

  for (const menu of MENUS) {
    if (pathname.startsWith(menu.url)) {
      // 因为收起来了，所以不需要选中子菜单
      // if (menu.subMenus) {
      //   const selectedMenu = menu.subMenus.find(subMenu => pathname.startsWith(menu.url + subMenu.url));

      //   if (selectedMenu) {
      //     return [selectedMenu.key, menu.key];
      //   }
      //   return ['', menu.key];
      // }

      return [menu.key, ''];
    }
  }

  return ['', ''];
}

export default function SideMenu() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.auth.isLogin);
  const user = useSelector((state: RootState) => state.auth.user);

  const [selectKey, openKey] = useCurrentKey();

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[selectKey]} defaultOpenKeys={[openKey]}>
      {MENUS.map(menu => {
        const { key, icon, url, title, subMenus, admin }: MenuProps = menu;

        if (admin && !user?.is_admin) {
          return null;
        }

        if (subMenus) {
          const subItems = subMenus.map(({ key, url: subUrl, title, external }) => {
            const _url = url + subUrl;

            // 如果是外链
            if (external) {
              return (
                <Menu.Item key={key}>
                  <Link to={{ pathname: subUrl }} target="_blank">
                    {title}
                  </Link>
                </Menu.Item>
              );
            }

            return (
              <Menu.Item key={key}>
                <Link to={_url}>{title}</Link>
              </Menu.Item>
            );
          });
          return (
            <SubMenu key={key} icon={icon} title={title}>
              {subItems}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={key} icon={icon}>
            <Link to={url}>{title}</Link>
          </Menu.Item>
        )
      })}
      {
        isLogin && (
          <>
            <Menu.Divider />
            <Menu.Item key='logout' icon={<PoweroffOutlined />} onClick={() => dispatch(logout())}>
              退出
            </Menu.Item>
          </>
        )}
    </Menu>
  );
}