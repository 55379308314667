import {
  ProForm,
  ProFormDependency,
  ProFormGroup,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import {
  createModelSpec,
  fetchModelSepc,
  fetchModelSpecFormSpec,
  updateModelSpec,
} from 'ai/api';
import { ModelSpecFormSpec } from 'ai/models';
import { Button, FormInstance, Skeleton } from 'antd';
import { renderFormItem } from 'app/components/DaynamicForm';
import _ from 'lodash';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface ModelFormProps {
  mode: 'CREATE' | 'EDIT';
  id?: string;
}

const ModelSpecForm: FunctionComponent<ModelFormProps> = ({ mode, id }) => {
  const history = useHistory();
  const formRef = useRef<FormInstance>();
  const [initialValues, setInitialValues] = React.useState();
  const [formSpec, setFormSpec] = useState<ModelSpecFormSpec>();

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }
      const result = (await fetchModelSepc(id)).data;
      const initialValues = {
        name: result.name,
        description: result.description,
        trainset_id: result.trainset.id,
        base: result.params.base,
        status: result.status,
        ...result.params.hyper_params,
        ...result.target,
      };
      setInitialValues(initialValues as any);
    };
    if (mode === 'EDIT' && id) {
      init();
    }
    fetchModelSpecFormSpec().then((result) => {
      setFormSpec(result.data);
    });
  }, [mode, id]);

  if (mode === 'EDIT' && initialValues === undefined) {
    return <Skeleton />;
  }

  if (formSpec === undefined) {
    return <Skeleton />;
  }

  return (
    <ProForm
      initialValues={initialValues}
      formRef={formRef}
      submitter={{
        render: (_, dom) => {
          return [
            <Button
              type="primary"
              key="submit"
              onClick={() => {
                formRef.current?.submit();
              }}
            >
              提交
            </Button>,
            <Button key="cancel">
              <Link to="/ai/model_specs">取消</Link>
            </Button>,
          ];
        },
      }}
      onFinish={async (values) => {
        // 构建hyper_params, hyper_params是从values中找到除去name, description, trainset_id, target，base的所有字段
        // 找出target下面的所有字段
        const hyper_params = _.omit(values, [
          'name',
          'description',
          'trainset_id',
          'base',
          'target',
          'time_limit',
          'chg_pct',
          'status',
        ]);
        const formatedValues = {
          name: values.name,
          description: values.description,
          trainset_id: values.trainset_id,
          status: values.status,
          target: {
            time_limit: values.time_limit,
            chg_pct: values.chg_pct,
          },
          params: {
            base: values.base,
            hyper_params,
          },
        };

        let result = null;
        if (mode === 'CREATE') {
          result = await createModelSpec(formatedValues);
        }
        if (mode === 'EDIT' && id) {
          result = await updateModelSpec(id, formatedValues);
        }
        if (result) {
          history.push('/ai/model_specs');
        }
      }}
    >
      <ProFormText
        name="name"
        label="名称"
        rules={[{ required: true, message: '请输入模型名称' }]}
      />
      <ProFormTextArea
        name="description"
        label="描述"
        rules={[{ required: true, message: '请输入模型描述' }]}
      />
      <ProFormSelect
        name="status"
        label="状态"
        rules={[{ required: true, message: '请选择模型状态' }]}
        options={[
          { label: '启用', value: 'ACTIVE' },
          { label: '禁用', value: 'INACTIVE' },
        ]}
      />
      <ProFormSelect
        name="trainset_id"
        label="数据集"
        rules={[{ required: true, message: '请选择数据集' }]}
        request={async () => {
          return formSpec.trainsets.map((t) => ({
            label: t.name,
            value: t.id,
          }));
        }}
      />
      <ProFormGroup title="目标">{formSpec?.target.map((t) => renderFormItem(t))}</ProFormGroup>
      <ProFormSelect
        name="base"
        label="模型基础"
        rules={[{ required: true, message: '请选择模型基础' }]}
        request={async () => {
          return formSpec.params.map((p) => ({
            label: p.base,
            value: p.base,
          }));
        }}
      />
      <ProFormDependency name={['base']}>
        {({ base }) => {
          const param = formSpec?.params.find((p) => p.base === base);
          if (!param) {
            return null;
          }
          return param.hyper_params.map((p) => renderFormItem(p));
        }}
      </ProFormDependency>
    </ProForm>
  );
};

export default ModelSpecForm;
