import React, { FunctionComponent } from 'react';
import { Table } from 'antd';

import { StockWithCollection } from 'collection/models';
import { ColumnsType } from 'antd/lib/table';
import { useStockWithCollection } from 'collection/hooks';
import CollectionTag from '../CollectionTag';
import { openNewStockPage } from 'stock/utils';
import { StockWithDailyDataColumns } from 'stock/components/StockTable';

const columns: ColumnsType<StockWithCollection> = [
  ...(StockWithDailyDataColumns as ColumnsType<StockWithCollection>),
  {
    title: '所属组合',
    key: 'collections',
    render: (_, record) => {
      return (
        <>
          {record.collections.map(c => (
            <CollectionTag {...c} />
          ))}
        </>
      )
    }
  }
];

const StockTable: FunctionComponent<{}> = () => {
  const data = useStockWithCollection();

  return (
    <Table<StockWithCollection>
      className='stock-table'
      columns={columns}
      dataSource={data}
      pagination={false}
      onRow={record => {
        return {
          onClick: event => {
            openNewStockPage(record.info.code);
          }, // 点击行
          onDoubleClick: event => {},
          onContextMenu: event => {},
          onMouseEnter: event => {}, // 鼠标移入行
          onMouseLeave: event => {},
        };
      }}
    />
  );
};

export default StockTable;