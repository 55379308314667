import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "app/store";
import * as authService from './service';
import { history } from 'app/routes';
import * as authApi from './api';

export interface AuthState {
  isLogin: boolean,
  user: authService.AuthUserProps | null,
}

const FALLBACK_AUTH_STATE = {
  isLogin: false,
  user: null,
};

const setUpAuthState = () => {
  const isLogin = authService.isRemember();
  if (!isLogin) return FALLBACK_AUTH_STATE;

  return {
    isLogin: true,
    user: authService.getAuthUser()
  };
}

const initialState: AuthState = setUpAuthState();


export type LoginProps = {
  username: string,
  password: string,
  remember: boolean,
  from?: string,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLogin = true;
      state.user = action.payload;
    },
    logout: state => {
      authService.logout();
      state.isLogin = false;
      state.user = null;
      history.push('/');
    }
  }
});

export const { loginSuccess, logout } = authSlice.actions;

export const login = ({ username, password, remember, from} : LoginProps): AppThunk => async dispatch => {
  const res = await authApi.login(username, password);

  if (res.isOK && res.data) {
    const userData = res.data as authService.AuthUserProps;
    dispatch(loginSuccess(userData));
    if (remember) {
      authService.remember(userData);
    }

    if (from) {
      history.push(from);
    }
  }
};

export const autoLogin = (): AppThunk => async dispatch => {
  const isLogin = authService.isRemember();
  if (!isLogin) return;

  const res = await authApi.getCurrentUser();

  if (res.isOK) {
    dispatch(loginSuccess(res.data));
    authService.remember(res.data as authService.AuthUserProps);
  } else {
    dispatch(logout());
  }
};

export default authSlice.reducer;
