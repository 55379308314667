import { Skeleton } from 'antd';
import MQCharts from 'app/components/MQCharts';
import { EChartsOption } from 'echarts';
import React, { FunctionComponent, useMemo } from 'react';
import { DOWN_COLOR, UP_COLOR } from '../CandleChart/constants';
import { StockLimitRangeStat } from './model';

const formatData = (data: StockLimitRangeStat['records']) => {
  const dataset = data.slice().sort((a, b) => a.date > b.date ? 1 : -1);

  return {
    indexes: dataset.map(_data => _data.date),
    total: dataset.map((_data, idx) => [_data.date, _data.count]),
    up: dataset.map(_data => [_data.date, _data.up]),
    down: dataset.map(_data => [_data.date, _data.down]),
  }
};

const getOptions = (data: StockLimitRangeStat['records']): EChartsOption => {
  const formatedData = formatData(data);
  const result: EChartsOption = {
    legend: {
      data: ['总个数', '涨停数', '跌停数'],
    },
    xAxis: {
      type: 'category',
      data: formatedData.indexes,
      axisPointer: {
        type: 'shadow',
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        symbol: 'none',
        name: '总个数',
        type: 'line',
        data: formatedData.total,
        smooth: true,
      },
      {
        name: '涨停数',
        type: 'bar',
        stack: 'total',
        data: formatedData.up,
        itemStyle: {
          color: UP_COLOR,
        },
        label: {
          show: true,
        },
      },
      {
        name: '跌停数',
        type: 'bar',
        stack: 'total',
        data: formatedData.down,
        itemStyle: {
          color: DOWN_COLOR,
        },
        label: {
          show: true,
        },
      },
    ],
  };
  return result;
};

const StockLimitRangeStatChart: FunctionComponent<{
  data: StockLimitRangeStat['records'];
}> = ({ data }) => {
  const options = useMemo(() => getOptions(data), [data]);

  if (!options) {
    return <Skeleton active />;
  }

  return <MQCharts options={options} className="stock-limit-range-stat-chart" />;
};

export default StockLimitRangeStatChart;
