import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  fetchKChartData,
  fetchKChartIndData,
  fetchKChartOptions,
  fetchKChartRefData,
  KChartLoadDataParams,
  KChartLoadIndDataParams,
  KChartLoadRefDataParams,
} from './api';
import { KChartConfig, KChartData, KChartOption } from './models';

export interface KChartState {
  option?: KChartOption;
  charts: {
    [chartID: string]: {
      config: KChartConfig;
      data?: KChartData;
    };
  };
}

const initialState: KChartState = {
  charts: {},
};

export const fetchKChartOptionThunk = createAsyncThunk(
  'kChart/fetchOption',
  async () => {
    return (await fetchKChartOptions()).data;
  },
  {
    condition: (_, { getState }) => {
      const { kChart } = getState() as RootState;
      if (kChart.option) {
        return false;
      }
    },
  }
);

export const fetchKChartDataThunk = createAsyncThunk(
  'kChart/fetchData',
  async ({ id, params }: { id: string; params: KChartLoadDataParams }) => {
    return (await fetchKChartData(params)).data;
  }
);

export const fetchKChartRefDataThunk = createAsyncThunk(
  'kChart/fetchRefData',
  async ({ id, params }: { id: string; params: KChartLoadRefDataParams }) => {
    return (await fetchKChartRefData(params)).data;
  }
);

export const fetchKChartIndDataThunk = createAsyncThunk(
  'kChart/fetchIndData',
  async ({ id, params }: { id: string; params: KChartLoadIndDataParams }) => {
    return (await fetchKChartIndData(params)).data;
  }
);

const slice = createSlice({
  name: 'kChart',
  initialState,
  reducers: {
    setConfig: (
      state,
      { payload: { id, config } }: PayloadAction<{ id: string; config: KChartConfig }>
    ) => {
      if (!(id in state.charts)) {
        state.charts[id] = {
          config
        };
      } else {
        state.charts[id].config = config;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKChartOptionThunk.fulfilled, (state, action) => {
      state.option = action.payload;
    });
    builder.addCase(fetchKChartDataThunk.fulfilled, (state, action) => {
      state.charts[action.meta.arg.id].data = action.payload;
    });
    builder.addCase(fetchKChartRefDataThunk.fulfilled, (state, action) => {
      const data = state.charts[action.meta.arg.id].data;
      if (data) {
        data.refs = action.payload;
      }
    });
    builder.addCase(fetchKChartIndDataThunk.fulfilled, (state, action) => {
      const data = state.charts[action.meta.arg.id].data;
      if (data) {
        data.target.indicators = action.payload;
      }
    });
  },
});

export const { setConfig } = slice.actions;

export default slice.reducer;
