import callApi, { DataWithPagination, DateRangeParams } from 'app/services/api';
import {
  AIModel,
  AIModelParams,
  AIModelPredict,
  AIModelPredictRangeCount,
  AIModelSpec,
  AIModelSpecDetail,
  AIModelSpecStats,
  AIModelTarget,
  ModelSpecFormSpec,
  PredictAggregation,
  Trainset,
} from './models';
import { TableState } from 'app/components/Table/hooks';
import { StockAIModelPredict } from 'stock/models';

export const fetchTrainsets = async () => {
  return callApi<Trainset[]>({
    endpoint: '/ai/trainsets',
    method: 'GET',
  });
};

export interface CreateTrainsetParams {
  name: string;
  description: string;
  feature_ids: string[];
}

export const createTrainset = async (params: CreateTrainsetParams) => {
  return callApi<Trainset>({
    endpoint: '/ai/trainsets',
    method: 'POST',
    data: params,
  });
};

export const deleteTrainset = async (id: string) => {
  return callApi({
    endpoint: `/ai/trainsets/${id}`,
    method: 'DELETE',
  });
};

export const fetchTrainset = async (id: string) => {
  return callApi<Trainset>({
    endpoint: `/ai/trainsets/${id}`,
    method: 'GET',
  });
};

export const updateTrainset = async (id: string, params: CreateTrainsetParams) => {
  return callApi<Trainset>({
    endpoint: `/ai/trainsets/${id}`,
    method: 'PUT',
    data: params,
  });
};

// 获取模型定义列表
export const fetchModelSepcs = async () => {
  return callApi<AIModelSpec[]>({
    endpoint: '/ai/model_specs',
    method: 'GET',
  });
};

// 获取模型定义
export const fetchModelSepc = async (id: string) => {
  return callApi<AIModelSpec>({
    endpoint: `/ai/model_specs/${id}`,
    method: 'GET',
  });
};

// 创建模型定义
export interface CreateModelSpecParams {
  name: string;
  description: string;
  trainset_id: string;
  target: AIModelTarget;
  params: AIModelParams;
}

export const createModelSpec = async (params: CreateModelSpecParams) => {
  return callApi<AIModelSpec>({
    endpoint: '/ai/model_specs',
    method: 'POST',
    data: params,
  });
};

// 更新模型定义
export const updateModelSpec = async (id: string, params: CreateModelSpecParams) => {
  return callApi<AIModelSpec>({
    endpoint: `/ai/model_specs/${id}`,
    method: 'PUT',
    data: params,
  });
};

// 删除模型定义
export const deleteModelSpec = async (id: string) => {
  return callApi({
    endpoint: `/ai/model_specs/${id}`,
    method: 'DELETE',
  });
};

// 训练模型
export const trainModel = async (id: string) => {
  return callApi({
    endpoint: `/ai/model_spec/${id}/toggle_train`,
    method: 'POST',
  });
};

// 获取模型定义表单参数
export const fetchModelSpecFormSpec = async () => {
  return callApi<ModelSpecFormSpec>({
    endpoint: '/ai/model_specs/form_spec',
    method: 'GET',
  });
};

// 获取模型预测聚合结果
export const fetchModelPredictsAggregation = async (params?: TableState) => {
  return callApi<PredictAggregation[]>({
    endpoint: '/ai/model_predicts/aggregation',
    method: 'GET',
    data: params,
  });
};

// 获取模型定义的详情(包含所有历史版本)
export const fetchModelSpecDetail = async (id: string) => {
  return callApi<AIModelSpecDetail>({
    endpoint: `/ai/model_specs/${id}/detail`,
    method: 'GET',
  });
};

// 获取某版本模型详情
export const fetchModelDetail = async (id: string) => {
  return callApi<AIModel>({
    endpoint: `/ai/model/${id}/detail`,
    method: 'GET',
  });
};

// 获取某版本模型预测结果
export const fetchModelPredicts = async (id: string, params?: TableState) => {
  return callApi<DataWithPagination<AIModelPredict>>({
    endpoint: `/ai/model/${id}/predicts`,
    method: 'GET',
    data: params,
  });
};

// 获取个股AI模型预测结果_日K使用
export const fetchStockCandleModelPredicts = async (
  code: string,
  start?: string,
  prob?: number
) => {
  return callApi<StockAIModelPredict[]>({
    endpoint: `/ai/model_predicts/stock_candle/${code}`,
    method: 'GET',
    data: {
      start,
      prob,
    },
  });
};

// 获取某个ModelSpec的区间统计信息
export const fetchModelSpecStats = async (id: string) => {
  return callApi<AIModelSpecStats>({
    endpoint: `/ai/model_specs/${id}/stats`,
    method: 'GET',
  });
};

// 获取板块个股预测聚合结果
export const fetchClassifyModelPredictAggregation = async (classifyCode: string) => {
  return callApi<PredictAggregation[]>({
    endpoint: `/ai/model_predicts/classify_aggregation/${classifyCode}`,
    method: 'GET',
  });
};

// 获取个股还没有结果的预测记录
export const fetchStockModelPredicts = async (code: string) => {
  return callApi<PredictAggregation[]>({
    endpoint: `/ai/model_predicts/stock_aggregation/${code}`,
    method: 'GET',
  });
};

// 获取模型预测时间区间的个数统计
export const fetchPredictsRangeStatistic = async (range: DateRangeParams) => {
  return callApi<AIModelPredictRangeCount>({
    endpoint: `/ai/model_predicts/range_statistic`,
    method: 'GET',
    data: range,
  });
};

// 删除模型
export const deleteModel = async (id: string) => {
  return callApi({
    endpoint: `/ai/model/${id}`,
    method: 'DELETE',
  });
};
