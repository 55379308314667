import React, { FunctionComponent } from 'react';

import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import THSLatestSignals from 'classify/components/THSLatestSignals';

const ClassifyDashboard: FunctionComponent<{}> = () => {
  return (
    <WorkdeskLayout pageName="板块概览">
      <THSLatestSignals type="I" level={3} />
    </WorkdeskLayout>
  );
};

export default ClassifyDashboard;
