import { useContextDispatch } from 'app/hooks';
import { RootState } from 'app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchStockDetailThunk, fetchStockListThunk, StockData } from 'stock/slice';
import { Stock, StockWithDailyDetail } from './models';
import { StockTableData } from 'stock/components/StockTable';
import moment from 'moment';

export const useStockList = () => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    dispatch(fetchStockListThunk());
  }, [dispatch]);
};

type FilterType = 'OnlyLimit' | 'OnlySignal' | 'Latest';

export type FilterInfo = Array<FilterType> | FilterType;

export const useStockData = (
  codes: Array<Stock['code']>,
  filterInfo?: FilterInfo
): Array<StockTableData> => {
  const stockData = useSelector((state: RootState) =>
    (({ ...codes }) => ({ ...codes }))(state.stock)
  );
  const latestTradeDate = useSelector((state: RootState) => state.market.latestTradeDate);

  if (codes.length === 0) {
    return [];
  }

  let result: StockTableData[] = [];
  const filterStock = (stock: StockWithDailyDetail, type: FilterType) => {
    switch (type) {
      case 'OnlyLimit':
        return stock.detail.is_zhangting || stock.detail.is_dieting;
      case 'OnlySignal':
        return stock.detail.signals.length > 0;
      case 'Latest':
        return moment(stock.detail.date).format('YYYY/MM/DD') === latestTradeDate;
      default:
        return true;
    }
  };

  codes.forEach((code) => {
    const stock = stockData[code];

    if (stock && stock.latest) {
      result.push({
        key: code,
        info: stock.info,
        detail: stock.latest,
        industry_sector: stock.industrySector,
      });
    }
  });

  if (filterInfo) {
    result = result.filter((stock) => {
      if (Array.isArray(filterInfo)) {
        return filterInfo.every((filter) => filterStock(stock, filter));
      } else {
        return filterStock(stock, filterInfo);
      }
    });
  }

  return result;
};

export const useStockDetail = (code: Stock['code']): StockData => {
  const dispatch = useContextDispatch();
  const stock = useSelector((state: RootState) => state.stock[code]);

  useEffect(() => {
    if (!stock) {
      dispatch(fetchStockDetailThunk(code));
    }
  }, [dispatch, stock, code]);

  return stock;
};
