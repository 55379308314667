import React, { FunctionComponent } from 'react';

import { Modal, Form } from 'antd';

import CollectionForm from '../CollectionForm';
import { Collection } from 'collection/models';
import { useCollectionActions } from 'collection/hooks';

interface EditCollectionModalProps {
  collection: Collection,
  visible: boolean;
  close: () => void;
}

const EditCollectionModal: FunctionComponent<EditCollectionModalProps> = (props) => {
  const { visible, close, collection } = props;
  const [form] = Form.useForm();

  const actions = useCollectionActions();

  return (
    <Modal
      title={`编辑${collection.name}`}
      visible={visible}
      okText='保存'
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields();
            actions.editCollection(
              collection.id,
              {
                name: values.name,
                items: values.items && values.items.split('\n'),
              });
            close();
          })
      }}
      cancelText='取消'
      onCancel={close}
    >
      <CollectionForm
        instance={form}
        initialValues={{ name: collection.name, items: collection.items && collection.items.join('\n') }}
      />
    </Modal>
  );
};

export default EditCollectionModal;