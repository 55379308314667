import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTHSSignals, THSLatestSignals, THSSignalsParams } from "./api";

export type THSLatestSignalState = Record<string, THSLatestSignals>;

const initialState: THSLatestSignalState = {};

export const fetchLatestTHSSignalsThunk = createAsyncThunk(
  'classify/ths/fetchLatestSignals',
  async (params: THSSignalsParams, { rejectWithValue }) => {
    try {
      const data = (await fetchTHSSignals(params)).data;
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const makeStateKey = (params: THSSignalsParams) => {
  return `${params.type}_${params.level}`;
};

const slice = createSlice({
  name: 'thsLatestSignals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLatestTHSSignalsThunk.fulfilled, (state, { payload, meta }) => {
      const key = makeStateKey(meta.arg);
      state[key] = payload;
    });
  },
});

export default slice.reducer;