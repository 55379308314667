import React, { FunctionComponent } from 'react';

import { Form, Input } from 'antd'
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/es/form/interface';

interface CollectionFormPropps {
  instance: FormInstance<any>,
  initialValues?: Store
}

const CollectionForm: FunctionComponent<CollectionFormPropps> = (props) => {
  const { instance, initialValues } = props;

  return (
    <Form
      form={instance}
      layout="vertical"
      name="collection-form"
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="名称"
        rules={[{ required: true, message: '请输入自选分组名称' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="items"
        label="股票"
        help={<span>一行一个股票代码，例如<br/>000001<br/>000002</span>}
      >
        <Input.TextArea placeholder="一行一个股票代码" />
      </Form.Item>
    </Form>
  )
};

export default CollectionForm;