import React from 'react';
import ModelSpecForm from 'ai/components/ModelSpecForm';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';

const CreateModelSpec = () => {
  return (
    <WorkdeskLayout pageName="create-model-spec" title="创建模型定义">
      <ModelSpecForm mode="CREATE" />
    </WorkdeskLayout>
  );
};

export default CreateModelSpec;
