import React from 'react';
import ReactDomServer from 'react-dom/server';
import { ClassifyDailyIndicator, ClassifyStrategyData } from 'classify/models';
import {
  EChartsOption,
  GridComponentOption,
  LegendComponentOption,
  SeriesOption,
  TitleComponentOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { DOWN_COLOR, UP_COLOR } from 'stock/components/CandleChart/constants';
import { SMA } from 'technicalindicators';
import { AmountFormatter, AmountUnit } from 'market/utils';
import classNames from 'classnames';
import { RSDailyData } from 'strategy/models';
import moment from 'moment';
import { SignalType } from 'signal/models';

export type TimeSeriesData = number[][];

interface FormatedData {
  indexes: ClassifyDailyIndicator['date'][];
  score: TimeSeriesData;
  hit: TimeSeriesData;
  turnover: TimeSeriesData;
  candle: TimeSeriesData;
  stockLimit: TimeSeriesData;
  scoreRank: TimeSeriesData;
  rs: RSDailyData[];
}

type SeriesConfig = SeriesOption & {
  ma?: number[];
  visualMap?: VisualMapComponentOption;
  marks?: Array<{ date: string; type: SignalType }>;
};

type SubChartConfig = {
  label: string;
  series: SeriesConfig[];
  grid: GridComponentOption;
};

type ChartConfig = Array<SubChartConfig>;

const formatData = (
  data: ClassifyDailyIndicator[],
  strategy: ClassifyStrategyData
): FormatedData => {
  const dataset = data.slice().sort((a, b) => (a.date > b.date ? 1 : -1));

  const rsData = strategy.rs.slice().sort((a, b) => (a.date > b.date ? 1 : -1));

  return {
    indexes: dataset.map((_data) => _data.date),
    candle: dataset.map((_data) => [_data.open, _data.close, _data.low, _data.high, _data.pct_chg]),
    score: dataset.map((_data, idx) => [idx, _data.score, _data.pct_chg > 0 ? 1 : -1]),
    scoreRank: dataset.map((d, idx)=> [idx, d.score, d.score_rank]),
    hit: dataset.map((_data, idx) => [idx, _data.hit, _data.pct_chg > 0 ? 1 : -1]),
    turnover: dataset.map((_data) => [_data.turnover_rate_f, _data.pct_chg > 0 ? 1 : -1]),
    stockLimit: dataset.map((_data, idx) => [
      idx,
      Math.round(_data.zhangting_count),
      Math.round(_data.dieting_count),
    ]),
    rs: rsData,
  };
};

const getChartConfig = (formatedData: FormatedData): ChartConfig => {
  const config: ChartConfig = [
    {
      label: '行情',
      series: [
        {
          name: '日K',
          data: formatedData['candle'],
          type: 'candlestick',
          ma: [5, 20, 60],
          itemStyle: {
            color: UP_COLOR,
            color0: DOWN_COLOR,
          },
          marks: formatedData.rs
            .filter((d) => d.buy || d.sell)
            .map((rs) => {
              if (rs.buy) {
                return {
                  date: rs.date,
                  type: SignalType.BUY,
                };
              } else {
                return {
                  date: rs.date,
                  type: SignalType.SELL,
                };
              }
            }),
        },
      ],
      grid: {
        height: '30%',
      },
    },
    {
      label: '相对强度策略',
      series: [
        {
          name: 'rs',
          data: formatedData.rs.map((r) => r.rs),
          type: 'bar',
        },
        {
          symbol: 'none',
          name: '信号线',
          data: formatedData.rs.map((r) => r.signal),
          type: 'line',
        },
        {
          symbol: 'none',
          name: '上界',
          data: formatedData.rs.map((r) => r.up_bound),
          type: 'line',
        },
        {
          symbol: 'none',
          name: '下界',
          data: formatedData.rs.map((r) => r.down_bound),
          type: 'line',
        },
      ],
      grid: {
        height: '30%',
      },
    },
    // {
    //   label: '整体表现',
    //   series: [
    //     {
    //       name: '整体表现',
    //       data: formatedData.score,
    //       type: 'bar',
    //       markPoint: {
    //         symbol: 'pin',
    //         symbolSize: 18,
    //         data: formatedData.scoreRank
    //           .filter((r) => r[2] <= 10)
    //           .map((d) => ({
    //             name: '前十',
    //             xAxis: d[0],
    //             yAxis: d[1],
    //           })),
    //       },
    //       visualMap: {
    //         show: false,
    //         dimension: 2,
    //         pieces: [
    //           {
    //             value: 1,
    //             color: UP_COLOR,
    //           },
    //           {
    //             value: -1,
    //             color: DOWN_COLOR,
    //           },
    //         ],
    //       },
    //     },
    //   ],
    //   grid: {
    //     height: '12%',
    //   },
    // },
    // {
    //   label: '涨跌停统计',
    //   series: [
    //     {
    //       name: '涨停数',
    //       data: formatedData.stockLimit.map((l) => l[1]),
    //       type: 'bar',
    //       stack: 'total',
    //       itemStyle: {
    //         color: UP_COLOR,
    //       },
    //     },
    //     {
    //       name: '跌停数',
    //       type: 'bar',
    //       stack: 'total',
    //       data: formatedData.stockLimit.map((l) => l[2]),
    //       itemStyle: {
    //         color: DOWN_COLOR,
    //       },
    //     },
    //   ],
    //   grid: {
    //     height: '12%',
    //   },
    // },
  ];

  return config;
};

export const getOptions = (
  data?: ClassifyDailyIndicator[],
  strategy?: ClassifyStrategyData
): EChartsOption | null => {
  if (!data || !strategy) return null;

  const formatedData = formatData(data, strategy);

  let result: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      position: (pos, params, el, elRect, size) => {
        if (pos[0] < size.viewSize[0] / 2) {
          return {
            right: '10%',
            top: 10,
          };
        } else {
          return {
            left: '10%',
            top: '5%',
          };
        }
      },
      formatter: (params: any) => {
        return ReactDomServer.renderToStaticMarkup(
          <div className="candlechart-tooltip">
            <div className="tooltip-date">{params[0].axisValueLabel}</div>
            {params.map((series: any) => {
              let value;
              if (series.seriesName === '成交额') {
                value = (
                  <div className="tooltip-series-value">
                    {new AmountFormatter(series.value[1], AmountUnit.Thounsand).parseTo(
                      AmountUnit.HundredMillion
                    )}
                  </div>
                );
              } else if (series.seriesType === 'candlestick') {
                const valueType = ['开盘', '收盘', '最低', '最高', '涨跌幅'];
                value = (
                  <div className="tooltip-series-values">
                    {series.data.slice(1).map((v: number, idx: number) => {
                      return (
                        <div className="value-item" key={idx}>
                          <span className="name">{valueType[idx]}:</span>
                          {valueType[idx] === '涨跌幅' && (
                            <span
                              className={classNames('value', {
                                up: v >= 0,
                                down: v < 0,
                              })}
                            >
                              {v.toFixed(2)}%
                            </span>
                          )}
                          {valueType[idx] !== '涨跌幅' && <span className="value">{v}</span>}
                        </div>
                      );
                    })}
                  </div>
                );
              } else if (series.seriesName.includes('占比')) {
                value = (
                  <div className="tooltip-series-value">
                    {Array.isArray(series.value) ? series.value[1] : series.value}%
                  </div>
                );
              } else {
                value = (
                  <div className="tooltip-series-value">
                    {Array.isArray(series.value) ? series.value[1] : series.value}
                  </div>
                );
              }
              return (
                <div className="tooltip-series" key={series.seriesIndex}>
                  <div
                    className="tooltip-series-marker"
                    style={{ backgroundColor: series.color }}
                  />
                  <div className="tooltip-series-name">{series.seriesName}:</div>
                  {value}
                </div>
              );
            })}
          </div>
        );
      },
    },
    axisPointer: {
      link: [{ xAxisIndex: 'all' }],
      label: {
        backgroundColor: '#777',
      },
    },
  };

  let series: SeriesOption[] = [];
  let legend: LegendComponentOption[] = [];
  let xAxis: XAXisComponentOption[] = [];
  let yAxis: YAXisComponentOption[] = [];
  let grid: GridComponentOption[] = [];
  let title: TitleComponentOption[] = [];
  let visualMap: VisualMapComponentOption[] = [];

  const config = getChartConfig(formatedData);

  let top: number = 3;
  let seriesIdx: number = 0;
  config.forEach((c, idx) => {
    const legendNames: string[] = [];
    c.series.forEach((s) => {
      const _series =
        s.type !== 'pie'
          ? {
              ...s,
              xAxisIndex: idx,
              yAxisIndex: idx,
            }
          : s;

      if (s.marks) {
        _series.markPoint = {
          data: s.marks.map(signal => {
              const x = signal.date;
              const idx = formatedData.indexes.findIndex((v) => v === x);
              const symbolSize = 20;
              const labelSize = 10;

              if (signal.type === SignalType.BUY) {
                const y = (_series.data as Array<any>)[idx][2];
                return {
                  symbol: 'arrow',
                  symbolSize,
                  symbolOffset: [0, '20%'],
                  coord: [x, y],
                  label: {
                    fontSize: labelSize,
                    color: '#FFF',
                  },
                  itemStyle: {
                    color: UP_COLOR,
                  }
                }
              } else {
                const y = (_series.data as Array<any>)[idx][3];

                return {
                  symbol: 'arrow',
                  symbolSize,
                  symbolOffset: [0, '-20%'],
                  coord: [x, y],
                  symbolRotate: 180,
                  label: {
                    fontSize: labelSize,
                    color: '#FFF',
                  },
                  itemStyle: {
                    color: DOWN_COLOR,
                  }
                }
              }
          })
        }

      }
      series.push(_series);

      if (s.ma) {
        const values = (s.data as any[]).map((d) => d[1]) as Array<number>;

        s.ma.forEach((ma) => {
          const maData = SMA.calculate({ period: ma, values }).map((v) => v.toFixed(2));
          const fillData = Array(values.length - maData.length).fill('-');

          series.push({
            symbol: 'none',
            name: `MA ${ma}`,
            type: 'line',
            data: fillData.concat(maData),
            smooth: true,
            xAxisIndex: idx,
            yAxisIndex: idx,
            lineStyle: {
              width: 1,
            },
          });
          seriesIdx += 1;
        });
      }


      legendNames.push(s.name as string);
      if (s.visualMap) {
        visualMap.push({
          ...s.visualMap,
          seriesIndex: seriesIdx,
        });
      }
      seriesIdx += 1;
    });

    xAxis.push({
      gridIndex: idx,
      type: 'category',
      data: formatedData.indexes,
      boundaryGap: false,
      axisLine: { onZero: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: true },
    });
    yAxis.push({
      scale: true,
      gridIndex: idx,
    });
    legend.push({
      data: legendNames,
      top: `${top - 3} %`,
    });
    grid.push({
      ...c.grid,
      top: `${top}%`,
    });
    title.push({
      top: `${top}%`,
      text: c.label,
    })
    top += parseInt((c.grid.height as string).split('%')[0]) + 5;
  });

  result.xAxis = xAxis;
  result.yAxis = yAxis;
  result.series = series;
  result.legend = legend;
  result.grid = grid;
  result.title = title;
  result.visualMap = visualMap;

  return result;
};
