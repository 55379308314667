import { ProTableParams } from 'app/components/Table';
import callApi, { DataWithPagination, DateRangeParams } from 'app/services/api';
import { StockWithDailyDetail } from 'stock/models';
import { RSDailyData } from 'strategy/models';
import {
  Classify,
  ClassifyDailyIndicator,
  ClassifyTopType,
  THSClassifyType,
  THSTopList,
} from './models';

export interface ClassifyTopListParams {
  date?: string;
  type: ClassifyTopType;
  start_date?: string;
  end_date?:  string;
  count?: number;
}

export const fetchTHSTopList = (params?: ClassifyTopListParams) => {
  return callApi<THSTopList>({
    endpoint: `/classify/ths_top_list`,
    data: params
  });
};

export const fetchTHSClassifyInfo = (code: string) => {
  return callApi<{ info: Classify; latest: ClassifyDailyIndicator }>({
    endpoint: `/classify/ths/${code}/info`,
  });
};

export interface fetchTHSDailyIndicatorsResp {
  indicators: ClassifyDailyIndicator[];
  strategy?: {
    rs: RSDailyData[];
  };
}

export const fetchTHSDailyIndicators = (code: string, dateRange?: DateRangeParams, includeStrategy: boolean = false) => {
  return callApi<fetchTHSDailyIndicatorsResp>({
    endpoint: `/classify/ths/${code}/daily_indicators`,
    data: {
      ...dateRange,
      include_strategy: includeStrategy,
    }
  });
};

export type fetchTHSClassifyListParams = ProTableParams & { type: keyof typeof THSClassifyType };
export const fetchTHSClassifyList = (params: fetchTHSClassifyListParams) => {
  return callApi<DataWithPagination<Classify>>({
    endpoint: '/classify/ths/list',
    data: params,
  });
};

export const fetchTHSMemberLatestData = (code: string) => {
  return callApi<StockWithDailyDetail[]>({
    endpoint: `/classify/ths/${code}/member_latest_data`,
  });
};
