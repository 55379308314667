import { Classify } from 'classify/models';
import React, { FunctionComponent } from 'react';
import ClassifyTag from 'classify/components/ClassifyTag';
import _ from 'lodash';
import { Button } from 'antd';
import './index.scss';

interface Props {
  sectors: Classify[];
}

const ConceptSectors: FunctionComponent<Props> = ({ sectors }) => {
  const [expand, setExpand] = React.useState(false);

  // sectors按照rs降序排列
  // const sortedSectors = _.sortBy(sectors, (sector) => sector.rs).reverse();
  const sortedSectors = sectors;

  const displaySectors = expand ? sortedSectors : sortedSectors.slice(0, 3);

  return (
    <div className="concept-sectors">
      {displaySectors.map((sector) => (
        <ClassifyTag {...sector} key={sector.code} />
      ))}
      {expand && (
        <Button
          type="link"
          onClick={(e) => {
            setExpand(false);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          收起
        </Button>
      )}
      {!expand && sortedSectors.length > 3 && (
        <Button
          type="link"
          onClick={(e) => {
            setExpand(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          展开
        </Button>
      )}
    </div>
  );
};

export default ConceptSectors;
