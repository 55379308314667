import React from 'react';
import { TreeApi, TreeNode } from './models';
import { Tree as AntdTree, Button, Modal, Skeleton } from 'antd';
import { useTree } from './hooks';
import TreeForm from './form';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import './index.scss';
import { DataNode } from 'antd/es/tree';
import classNames from 'classnames';

export interface TreeProps {
  name: string;
  treeApi: TreeApi;
  mode: 'manage' | 'view';
  onSelect?: (node: TreeNode, allNodes: TreeNode[]) => void;
}

export type NodeState = DataNode & { idx: number; parentId: TreeNode['parent_id'] };

const Tree = ({ treeApi, name, mode, onSelect }: TreeProps) => {
  const { state, handlers } = useTree(treeApi);
  const isManageMode = mode === 'manage';

  // 管理节点
  const ManageNode = (node: NodeState) => (
    <div className="tree-node-title">
      <span>{node.title}</span>
      <Button
        size="small"
        onClick={() => {
          handlers.startOperate({
            mode: 'CREATE',
            selectedNode: node.key as TreeNode['parent_id'],
            formOpen: true,
          });
        }}
        icon={<PlusOutlined />}
      />
      <Button
        size="small"
        onClick={() => {
          handlers.startOperate({
            mode: 'UPDATE',
            selectedNode: node.key as TreeNode['id'],
            formOpen: true,
          });
        }}
        icon={<EditOutlined />}
      />
      <Button
        size="small"
        danger
        onClick={() => {
          Modal.confirm({
            title: `确认删除-${node.title}？`,
            content: '删除后不可恢复，确认删除？',
            onOk: async () => {
              await handlers.deleteNode(node.key as TreeNode['id']);
            },
            okButtonProps: { danger: true },
            okText: '确认删除',
            cancelText: '取消',
          });
        }}
        icon={<DeleteOutlined />}
      />
    </div>
  );

  return (
    <div className={classNames('tree-container', name)}>
      {isManageMode && (
        <Button
          type="primary"
          onClick={() =>
            handlers.startOperate({
              mode: 'CREATE',
              selectedNode: null,
              formOpen: true,
            })
          }
        >
          新建根节点
        </Button>
      )}
      {state.nodes.length === 0 && <Skeleton active />}
      {state.nodes.length > 0 && (
        <AntdTree<DataNode & { idx: number; parentId: TreeNode['parent_id'] }>
          showLine
          treeData={state.treeData}
          defaultExpandAll
          onSelect={(_, info) => {
            const selectedNode = info.selectedNodes[0];
            if (!selectedNode) {
              return;
            }
            const currentNodeId = info.selectedNodes[0].key;
            const currentNode = state.nodes.find((node) => node.id === currentNodeId);
            if (currentNode) {
              onSelect && onSelect(currentNode, state.nodes);
            }
          }}
          titleRender={(node) => {
            return isManageMode ? ManageNode(node) : node.title;
          }}
          onDrop={(info) => {
            const dragKey = info.dragNode.key as TreeNode['id'];
            const dropKey = info.node.key as TreeNode['id'];
            const dropToGap = info.dropToGap;
            // 如果dropToGap，就是移动到目标节点的前面或者后面，否则就是移动到目标节点的子节点
            if (dropToGap) {
              handlers.moveNode(
                dragKey,
                info.node.parentId,
                info.node.dragOverGapBottom ? info.node.idx + 1 : info.node.idx
              );
            } else {
              handlers.moveNode(dragKey, dropKey, 0);
            }
          }}
        />
      )}
      {state.operateConfig.formOpen && <TreeForm state={state} handlers={handlers} />}
    </div>
  );
};

export default Tree;
