import { AIModelSpecStats } from "ai/models";
import { EChartsOption } from "echarts";

export const makeModelSpecStatsChartOptions = (detail: AIModelSpecStats['detail']): EChartsOption => {
  const records = detail[0.8].records;
  const indexes = records.map((r) => r.trade_date);
  const aucData = records.map((r) => r.auc);

  const positiveCountData_0_85 = records.map((r) => r.positive_count);
  const tpCountData_0_85 = records.map((r) => r.tp_count ? r.tp_count : 0);
  const positiveCountData_0_9 = detail[0.9].records.map((r) => r.positive_count);
  const tpCountData_0_9 = detail[0.9].records.map((r) => r.tp_count ? r.tp_count : 0);

  // aucData使用折线图，positiveCountData和tpCountData使用柱状图
  return {
    title: {
      text: '模型表现',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    legend: {
      data: ['AUC', 'Positive Count', 'TP Count'],
      top: 30,
    },
    xAxis: [
      {
        type: 'category',
        data: indexes,
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'AUC',
        min: 0,
        max: 1,
        interval: 0.1,
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        name: 'Positive Count',
        axisLabel: {
          formatter: '{value}',
        },
      },
    ],
    series: [
      {
        name: 'AUC',
        type: 'line',
        data: aucData,
        markPoint: {
          data: [
            { type: 'max', name: '最大值' },
            { type: 'min', name: '最小值' },
          ],
        },
      },
      {
        name: 'Positive Count(0.8)',
        type: 'bar',
        yAxisIndex: 1,
        data: positiveCountData_0_85,
      },
      {
        name: 'TP Count(0.8)',
        type: 'bar',
        yAxisIndex: 1,
        data: tpCountData_0_85,
      },
      {
        name: 'Positive Count(0.9)',
        type: 'bar',
        yAxisIndex: 1,
        data: positiveCountData_0_9,
      },
      {
        name: 'TP Count(0.9)',
        type: 'bar',
        yAxisIndex: 1,
        data: tpCountData_0_9,
      }
    ],
  };
}