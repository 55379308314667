import { Tag } from 'antd';
import { WatchingSignal } from 'classify/models';
import React from 'react';

interface WatchingTagProps {
  item?: WatchingSignal;
}

const WatchingTag: React.FC<WatchingTagProps> = ({ item }) => {
  if (!item) {
    return null;
  }
  if (item.signal_type === 'BUY') {
    return <Tag color="red">{item.signal_date.slice(5)}</Tag>;
  } else {
    return <Tag color="green">{item.signal_date.slice(5)}</Tag>;
  }
};

export default WatchingTag;
