import Table, {ColumnProps, TableProps} from 'app/components/Table';
import React, { FunctionComponent, useCallback } from 'react';
import { Classify, THSClassifyType } from 'classify/models';
import { fetchTHSClassifyList, fetchTHSClassifyListParams } from 'classify/api';
import { Link } from 'react-router-dom';

const COLUMNS: ColumnProps<Partial<Classify>>[] = [
  {
    title: '代码',
    dataIndex: 'code',
    render: (_, r) => (
      <Link to={`/classify/ths/${r['code']}`} target="_blank">
        {r['code']}
      </Link>
    ),
  },
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '成分股数',
    dataIndex: 'count',
    search: false,
  },
  {
    title: '设置日期',
    dataIndex: 'list_date',
    search: false,
  },
];

const THSClassifyTable: FunctionComponent<{type: keyof typeof THSClassifyType}> = ({ type }) => {
  const request: TableProps<Classify>['request'] = useCallback(
    async (params, ...rest) => {
      const { current, pageSize, ...filter } = params;
      const _params: fetchTHSClassifyListParams = {
        page: current,
        per_page: pageSize,
        type,
        ...filter,
      };

      const payload = await fetchTHSClassifyList(_params);

      return {
        data: payload.data.items,
        success: payload.isOK,
        total: payload.data.pagination?.total_count,
      };
    },
    [type]
  );
  return (
    <Table
      columns={COLUMNS}
      request={request}
      options={false}
      paginationConfig={{per_page: 10}}
    />
  );
}

export default THSClassifyTable;