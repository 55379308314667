import React from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { Route } from 'antd/lib/breadcrumb/Breadcrumb';


function itemRender(route: Route, params: any, routes: Route[], paths: string[]) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  );
}

export default function BreadcrumbComponent({ routes }: { routes?: Route[] }) {
  if (!routes) return null;

  return (
    <Breadcrumb itemRender={itemRender} routes={routes} />
  )
}