import { v4 as uuidv4 } from 'uuid';

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useContextDispatch } from 'app/hooks';
import {
  fetchKChartDataThunk,
  fetchKChartIndDataThunk,
  fetchKChartOptionThunk,
  fetchKChartRefDataThunk,
  setConfig as setConfigAction,
} from './slice';
import { KChartLoadDataParams, KChartLoadIndDataParams, KChartLoadRefDataParams } from './api';
import { KChartConfig } from './models';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { getOptions } from './utils';

export const useKChartHook = (config?: KChartConfig) => {
  const chartID = useRef<string>(uuidv4());
  const state = useSelector((state: RootState) => state.kChart.charts[chartID.current]);

  const options = useMemo(() => getOptions(state), [state]);

  const dispatch = useContextDispatch();

  const loadOption = useCallback(() => {
    dispatch(fetchKChartOptionThunk());
  }, [dispatch]);

  const loadData = useCallback(
    (params: KChartLoadDataParams) => {
      dispatch(fetchKChartDataThunk({ id: chartID.current as string, params }));
    },
    [dispatch]
  );

  const loadRefData = useCallback(
    (params: KChartLoadRefDataParams) => {
      dispatch(fetchKChartRefDataThunk({ id: chartID.current as string, params }));
    },
    [dispatch]
  );

  const loadIndicatorData = useCallback(
    (params: KChartLoadIndDataParams) => {
      dispatch(fetchKChartIndDataThunk({ id: chartID.current as string, params }));
    },
    [dispatch]
  );

  /**
   * 设定配置
   * 1. 更新配置信息
   * 2. 根据配置信息抓取数据
   */
  const setConfig = useCallback(
    (config: KChartConfig) => {
      dispatch(setConfigAction({ id: chartID.current, config }));
      const loadDataParams: KChartLoadDataParams = {
        start: config.range.start,
        end: config.range.end,
        count: config.range.count,
        target: config.target.code,
        refs: config.refs && config.refs.map((ref) => ref.code).join(','),
        indicators: config.target.indicators && config.target.indicators.join(','),
      };
      loadData(loadDataParams);
    },
    [dispatch, loadData]
  );

  useEffect(() => {
    if (config) {
      setConfig(config);
    }
  }, [config]);

  return {
    id: chartID.current,
    options,
    state,
    actions: {
      // loadOption,
      // loadRefData,
      // loadIndicatorData,
      // loadData,
      // setConfig,
    },
  };
};;
