import callApi, { ApiResult, DataWithPagination, DateRangeParams, FetchApiFunc, PaginationParams } from 'app/services/api';
import { Collection, SignalWithCollection } from './models';
import { Stock } from 'stock/models';
import { TableState } from 'app/components/Table/hooks';

export const fetchCollections = async function () {
  return await callApi({ endpoint: '/collections' }) as ApiResult<Collection[]>;
}

export type createCollectionParams = Pick<Collection, 'name' | 'items'>;

export const createCollection = async function(params: createCollectionParams) {
  return await callApi({ endpoint: '/collections', method: 'POST', data: params }) as ApiResult<Collection>;
}

export const deleteCollection = async function (id: Collection['id']) {
  return await callApi({ endpoint: `/collections/${id}`, method: 'DELETE' });
}

export const updateCollection = async function (id: Collection['id'], params: createCollectionParams) {
  return await callApi({ endpoint: `/collections/${id}`, method: 'PUT', data: params }) as ApiResult<Collection>;
}

export const reorderCollection = async function (id: Collection['id'], idx: number) {
  return await callApi({ endpoint: `/collections/${id}`, method: 'PUT', data: { idx } });
}

export interface addItemParams {
  item: Stock['code'],
  collections?: Array<Collection['id']>,
}

export const addItem = async function (params: addItemParams) {
  return await callApi({ endpoint: '/collections/add_item', method: 'PUT', data: params }) as ApiResult<Collection[]>;
};

export interface removeCollectionItemParams {
  item: Stock['code'],
  collections?: Array<Collection['id']>,
}

export const removeItem = async (params: removeCollectionItemParams) => {
  return await callApi({ endpoint: '/collections/remove_item', method: 'DELETE', data: params }) as ApiResult<Collection[]>;
}

export type fetchCollectionSignalParams = TableState | DateRangeParams;

export const fetchSignals: FetchApiFunc<DataWithPagination<SignalWithCollection>> = (params: fetchCollectionSignalParams) => {
  return callApi({ endpoint: '/signals/collection', data: params });
};
