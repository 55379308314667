import { EChartsOption } from 'echarts';
import { ZhangtingTrender } from 'stock/models';

type DataType = 'count' | 'ratios';

export const buildEChartsOption = (
    rawData: ZhangtingTrender,
    dataType: DataType,
    filterKeys?: number[],
    seriesType: 'line' | 'bar' = 'line',
): EChartsOption => {
    // 提取日期和指定类型的数据
    const dates = Object.keys(rawData);
    const data = dates.map(date => rawData[date][dataType] || {});

    let allKeys: number[] = [];

    if (filterKeys) {
        allKeys = filterKeys;
    } else {
        const keySet: Set<number> = new Set();
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                keySet.add(Number(key));
            });
        });
        allKeys = Array.from(keySet);
    }

    // 配置选项
    const option: EChartsOption = {
        title: {
            text: `${allKeys}${dataType === 'count' ? '连板数' : '连板比率'}`,
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: allKeys.map(key => `${key}${dataType === 'count' ? '板' : ''}`),
        },
        xAxis: {
            type: 'category',
            data: dates,
        },
        yAxis: {
            type: 'value',
        },
        series: [],
    };

    // 构建series数据
    allKeys.forEach(key => {
        const seriesData = data.map(item => item[key]);

        option.series.push({
            name: `${key}${dataType === 'count' ? '板' : ''}`,
            type: seriesType,
            data: seriesData,
            symbol: 'none',
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
            }
        });
    });

    return option;
};