import React from 'react';

import { Input } from 'antd';

import InfinityForm, { StructSection, BizSection } from 'app/components/InfinityForm';

export default function InfinityFormPage() {
  return (
    <InfinityForm>
      <StructSection formKey='userRull' label='User Rull'>
        <BizSection formKey='uid' label='UserID'>
          <Input />
        </BizSection>
        <BizSection formKey='rule' label='Rule'>
          <Input />
        </BizSection>
      </StructSection>
      <BizSection formKey='format' label='Format'>
        <Input />
      </BizSection>
      <StructSection formKey='deepRull' label='Depp Rull'>
        <StructSection formKey='userRull' label='User Rull'>
          <BizSection formKey='uid' label='UserID'>
            <Input />
          </BizSection>
          <BizSection formKey='rule' label='Rule'>
            <Input />
          </BizSection>
        </StructSection>
        <BizSection formKey='foo' label='Foo'>
          <Input />
        </BizSection>
      </StructSection>
    </InfinityForm>
  );
}