import { configureStore, ThunkAction, Action, ThunkDispatch } from '@reduxjs/toolkit';

import authReducer from 'auth/slice';
import signalReducer from 'signal/slice';
import stockReducer from 'stock/slice';
import browserHistoryReducer from 'stock/components/BrowserHistory/slice';
import collectionReducer from 'collection/slice';
import marketReducer from 'market/slice';
import analysisReducer from 'analysis/reducer';
import strategyReducer from 'strategy/slice';
import classifyReducer from 'classify/slice';
import stocksReducer from 'stock/stocksSlice';
import kChartReducer from 'app/components/KChart/slice';
import featureReducer from 'feature/slice';
import accountReducer from 'account/slice';
import { ModelField } from './models';

export interface DataWithMetaState<T> {
  meta: ModelField[];
  data: T;
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    signal: signalReducer,
    stock: stockReducer,
    browserHistory: browserHistoryReducer,
    collection: collectionReducer,
    market: marketReducer,
    analysis: analysisReducer,
    strategy: strategyReducer,
    classify: classifyReducer,
    stocks: stocksReducer,
    kChart: kChartReducer,
    feature: featureReducer,
    account: accountReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const dispatch = store.dispatch;
