import React, { FunctionComponent } from 'react';

import Button, { ButtonProps } from 'antd/es/button';
import { Stock } from 'stock/models';
import { useInCollections, useSelectCollectionModal } from './hooks';
import { useCollectionActions } from 'collection/hooks';
import SelectCollectionsModal from './SelectCollectionModal';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

interface AddToCollectionProps {
  code: Stock['code'],
  size?: ButtonProps['size'],
}

const AddToCollection: FunctionComponent<AddToCollectionProps> = ({ code, size = 'middle' }) => {
  const { inCollections, collections } = useInCollections(code);
  const collectionActions = useCollectionActions();
  const selectCollectionsModal = useSelectCollectionModal(code, collections);

  const isLogin = useSelector((state: RootState) => state.auth.isLogin);

  if (!isLogin) {
    return null;
  }

  if (collections.length === 0) {
    return null;
  }

  if (inCollections.length > 0) {
    return (
      <Button
        danger
        type='primary'
        size={size}
        onClick={() => {collectionActions.removeItem({ item: code }); }}
        className='add-to-collection-btn'
      >
        移除自选
      </Button>
    )
  }

  return (
    <>
      <Button
        type='primary'
        onClick={selectCollectionsModal.actions.onAddClick}
        size={size}
        className='add-to-collection-btn'
      >
        加自选
      </Button>
      <SelectCollectionsModal
        visible={selectCollectionsModal.state.visiable}
        actions={selectCollectionsModal.actions}
      />
    </>
  )
};

export default AddToCollection;