import Tag from 'antd/es/tag';
import { Collection } from 'collection/models';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import './index.scss';

type CollectionTagProps = Pick<Collection, 'id' | 'name'>;

const CollectionTag: FunctionComponent<CollectionTagProps> = ({ id, name }) => {
  const history = useHistory();

  return (
    <Tag
      className='collection-tag'
      onClick={(e) => {
        history.push(`/collections/dashboard#${name}`);
        e.stopPropagation();
      }}>
      {name}
    </Tag>
  );
};

export default CollectionTag;