import { BaseModel } from "app/models";

// 指数信息
export interface IndexInfo {
  code: string,
  name: string,
}

// 指数日线
export interface IndexDailyData {
  low: number,
  high: number,
  open: number,
  close: number,
  date: string,
  vol: number,
  amount: number,
  pct_chg: number,
  price_chg: number,
}

// 成交量数据
export interface Turnover extends BaseModel {
  date: string,
  total: number,
  components: Array<IndexInfo & Pick<IndexDailyData, 'amount'>>
}

// 交易所信息
export enum Exchange {
  SSE = '上交所',
  SZSE = '深交所'
}

// 两融数据
export interface MarginData {
  rzye: number,
  rzmre: number,
  rzche: number,
  rqye: number,
  rqmcl: number,
  rzrqye: number,
  rqyl: number,
}

export interface Margin extends BaseModel, MarginData {
  exchange: keyof typeof Exchange,
  date: string,
}

export interface MarginDaily extends BaseModel, MarginData {
  date: string,
  components: Margin[]
}

// 沪深港通数据
export interface HSGT {
  date: string,
  ggt_sh: number,
  ggt_sz: number,
  south_money: number,
  hgt: number,
  sgt: number,
  north_money: number,
}

export interface Indicator {
  code: string;
  date: string;
  [key: string]: number | string;
}
