import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-components';
import { deleteTrainset, fetchTrainsets } from 'ai/api';
import { Button, Modal } from 'antd';
import Table from 'app/components/Table';
import WorkdeskLayout from 'app/layouts/WorkdeskLayout';
import React from 'react';
import { Link } from 'react-router-dom';

const TrainsetList = () => {
  const tableRef = React.useRef<ActionType>();

  return (
    <WorkdeskLayout pageName="trainset-list" title="训练集管理">
      <Table
        actionRef={tableRef}
        className="trainset-table"
        fetchAction={() => {
          return fetchTrainsets();
        }}
        rowKey={(record) => record.id}
        useRedux={false}
        sortInLocal={true}
        options={false}
        pagination={{ pageSize: 10 }}
        search={false}
        toolBarRender={() => [
          <Button type="primary" href="/ai/trainsets/create">
            创建训练集
          </Button>,
        ]}
        columns={[
          {
            title: '序号',
            render: (text, record, index) => index + 1,
            search: false,
            width: 50,
          },
          {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            search: false,
            render: (_, r) => (
              <Link to={`/ai/trainsets/${r.id}/detail`}>
                {r.name}
              </Link>
            ),
          },
          {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
            search: false,
          },
          {
            title: '特征数量',
            dataIndex: 'feature_count',
            key: 'feature_count',
            search: false,
          },
          {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            search: false,
            render: (_, r) => new Date(r.created_at).toLocaleString(),
          },
          {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            search: false,
            render: (_, r) => (
              <>
                <Link to={`/ai/trainsets/${r.id}/edit`}>
                  <Button type="primary" size="small" >
                    <EditOutlined />
                  </Button>
                </Link>
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: `确认删除-${r.name}`,
                      content: '删除后不可恢复',
                      onOk: async () => {
                        const response = await deleteTrainset(r.id);
                        if (response.isOK) {
                          tableRef.current?.reloadAndRest?.();
                        }
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </>
            ),
          },
        ]}
      />
    </WorkdeskLayout>
  );
};

export default TrainsetList;
