import React, { FunctionComponent, useMemo } from 'react';
import MQCharts from 'app/components/MQCharts';
import { CountByClassify } from 'signal/models';
import { Skeleton } from 'antd';
import { ECharts, EChartsOption, SeriesOption, YAXisComponentOption } from 'echarts';
import { DOWN_COLOR, UP_COLOR } from 'stock/components/CandleChart/constants';
import { Classify, THSClassifyType } from 'classify/models';

const formatData = (data: CountByClassify[], valueKey: 'buy_count' | 'sell_count') => {
  // 只显示前十，后面加总后用other表示
  const sorted = [...data].sort((a, b) => (a.count + a.buy_count > b.count + b.buy_count ? -1 : 1));
  // const result = [...sorted.slice(0, 10), {
  //   'code': '其他',
  //   'name': '其他',
  //   'type': data[0].type,
  //   'count': sorted.slice(11).reduce((pre, c) => pre + c.count, 0),
  // }];
  const result = sorted.slice(0, 30);

  return result.map((c) => ({ name: c.name, value: c[valueKey], code: c.code })).reverse();
};
const getOptions = (
  data: CountByClassify[],
  classifyType: Classify['type'],
  classifyLevel: Classify['level']
): EChartsOption | null => {
  if (!data) return null;
  let title: string;
  if (classifyType === 'N') {
    title = `${THSClassifyType[classifyType]}(前30)`;
  } else {
    title = `${classifyLevel}级行业(前30)`;
  }
  const result: EChartsOption = {
    title: {
      text: title,
      left: '45%',
    },
    xAxis: {},
    grid: {
      left: '25%',
    },
  };

  const series: SeriesOption[] = [];
  const yAxis: YAXisComponentOption[] = [];

  ['buy_count', 'sell_count'].forEach((countType, _idx) => {
    const _data = formatData(
      classifyType === 'N'
        ? data.filter((c) => c.type === classifyType)
        : data.filter((c) => c.type === classifyType && c.level === classifyLevel),
      countType as 'buy_count' | 'sell_count'
    );
    if (_idx === 0) {
      yAxis.push({
        type: 'category',
        data: _data.map((item) => item['name']),
        axisLabel: {
          interval: 0,
        },
      });
    }
    series.push({
      name: _idx === 0 ? '买入' : '卖出',
      type: 'bar',
      data: _data,
      stack: 'total',
      label: {
        show: true,
      },
      itemStyle: {
        color: _idx === 0 ? UP_COLOR : DOWN_COLOR,
      },
    });
  });

  result.series = series;
  result.yAxis = yAxis;

  return result;
};


const ClassifyDistributionPie: FunctionComponent<{
  data: CountByClassify[];
  classifyType: Classify['type'];
  classifyLevel?: Classify['level']
}> = ({ data, classifyType, classifyLevel }) => {
  const options = useMemo(
    () => getOptions(data, classifyType, classifyLevel),
    [data, classifyType, classifyLevel]
  );

  if (!options) {
    return <Skeleton active />;
  }

  const eventHandler = (chart: ECharts) => {
    chart.off('click');
    chart.on('click', 'series', (params) => {
      if (params.seriesName) {
        const code = (params.data as { code: string })['code'];
        if (code !== '其他') {
          window.open(`/classify/ths/${code}/latest`, 'blank');
        }
      }
    });
  };
  return (
    <MQCharts
      options={options}
      className="signal-classify-dist-pie-chart"
      eventHandler={eventHandler}
    />
  );
};

export default ClassifyDistributionPie;