import { FormatedData } from './utils';
import * as constants from './constants';
import _ from 'lodash';
import { EMA } from 'technicalindicators';
import { ChartComp } from 'app/components/MQCharts';
import { SeriesOption } from 'echarts';

export enum Indicator {
  VOLUME = 'volume',
  AMOUNT = 'amount',
  MAJOR_TREND = 'major_trend',
}

const volumeMaker = (data: FormatedData, type: Indicator, idx: number): ChartComp => {
  const xAxis = {
    gridIndex: idx,
    data: data.indexes,
    scale: true,
    boundaryGap: false,
    axisLine: { onZero: false },
    axisTick: { show: false },
    splitLine: { show: false },
    axisLabel: { show: false },
    splitNumber: 20,
    min: 'dataMin',
    max: 'dataMax',
  };

  const yAxis = {
    scale: true,
    gridIndex: idx,
    splitNumber: 2,
    axisLabel: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    splitLine: { show: false }
  };

  const series: SeriesOption = {
    name: type === Indicator.VOLUME ? '成交量' : '成交额',
    type: 'bar',
    xAxisIndex: idx,
    yAxisIndex: idx,
    data: type === 'volume' ? data.volumes : data.amount,
  };

  const visualMap = [{
    show: false,
    seriesIndex: idx + 5,
    dimension: 2,
    pieces: [{
      value: 1,
      color: constants.UP_COLOR,
    }, {
      value: -1,
      color: constants.DOWN_COLOR,
    }]
  }];

  return {
    xAxis,
    yAxis,
    series: [series],
    visualMap,
  }
};

const majorTrendMaker = (data: FormatedData, idx: number): ChartComp => {
  const xAxis = {
    gridIndex: idx,
    data: data.indexes,
    scale: true,
    boundaryGap: false,
    axisLine: { onZero: false },
    axisTick: { show: false },
    splitLine: { show: false },
    axisLabel: { show: false },
    splitNumber: 20,
    min: 'dataMin',
    max: 'dataMax',
  };

  const yAxis = {
    scale: true,
    gridIndex: idx,
    splitNumber: 2,
    axisLabel: { formatter: (v: string) => `${v}%` },
    // axisLine: { show: false },
    // axisTick: { show: false },
    // splitLine: { show: false }
  };

  const majorData = data.majorPct.map(d => d[1]);

  const maLines = [12, 26].map(ma => {
    const maData = EMA.calculate({period: ma, values : majorData}).map(v => v.toFixed(2));
    const fillData = Array(majorData.length - maData.length).fill('-');

    return {
      symbol: 'none',
      name: `主力占比MA-${ma}`,
      type: 'line',
      xAxisIndex: idx,
      yAxisIndex: idx,
      data: fillData.concat(maData),
      smooth: true,
      lineStyle: {
        opacity: 0.8,
        width: 1,
      },
    }
  });

  const series = [...maLines, {
    name: '主力占比',
    type: 'bar',
    xAxisIndex: idx,
    yAxisIndex: idx,
    data: data.majorPct,
  }] as SeriesOption[];

  const visualMap = [{
    show: false,
    seriesIndex: idx + 7,
    dimension: 2,
    pieces: [{
      value: 1,
      color: constants.UP_COLOR,
    }, {
      value: -1,
      color: constants.DOWN_COLOR,
    }]
  }];

  return {
    xAxis,
    yAxis,
    series,
    visualMap,
  }
}

export const makeIndicators = (data: FormatedData, indicators: Indicator[]): ChartComp[] | null => {
  if (!indicators || indicators.length === 0) {
    return null;
  }

  const result: ChartComp[] = indicators.map((indicator, idx) => {
    if ([Indicator.AMOUNT, Indicator.VOLUME].includes(indicator)) {
      return volumeMaker(data, indicator, idx + 1);
    } else {
      return eval(`${_.camelCase(indicator)}Maker`)(data, idx + 1);
    }
  });

  return result;
};