import { TableState } from 'app/components/Table/hooks';
import callApi, { DataWithPagination } from 'app/services/api';
import { MLModelPredict } from 'strategy/models';

export const fetchMLModelPredictLatest = () => {
  return callApi<MLModelPredict[]>({
    endpoint: '/strategies/ml_model_predicts/latest',
  });
};

export const fetchMLModelPredictList = (params?: TableState) => {
  return callApi<MLModelPredict[]>({
    endpoint: '/strategies/ml_model_predicts/list',
    data: params,
  });
};