import React from 'react';
import { Account, AccountTradeRecord } from 'account/models';
import Table, { ColumnProps } from 'app/components/Table';
import TradeRecordForm from './TradeRecordForm';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { dispatch } from 'app/store';
import { deleteAccountTradeRecordThunk, fetchAccountDetailThunk } from 'account/slice';
import { AnyAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AmountFormatter, AmountUnit } from 'market/utils';

interface Props {
  accountID: Account['id'];
  records: Account['trade_records'];
}

const TYPETEXT = {
  B: '买入',
  S: '卖出',
};

const COLUMNS: ColumnProps<AccountTradeRecord>[] = [
  {
    title: '时间',
    dataIndex: 'datatime',
    render: (_, r) => moment(r.datetime).format('YY/MM/DD HH:mm:ss'),
  },
  {
    title: '类型',
    dataIndex: 'type',
    render: (_, r) => TYPETEXT[r.type],
  },
  {
    title: '名称',
    dataIndex: 'name',
    render: (_, r) => <Link to={`/stocks/${r.ts_code}`} target="_blank">{r.name}</Link>,
  },
  {
    title: '价格',
    dataIndex: 'price',
  },
  {
    title: '数量',
    dataIndex: 'shares',
  },
  {
    title: '成本',
    dataIndex: 'cost',
    render: (_, r) => new AmountFormatter(r.cost, AmountUnit.Yuan).parseTo(AmountUnit.Wan)
  },
  {
    title: '操作',
    render: (_, r) => {
      return (
        <Button danger size="small" onClick={() => {
          Modal.confirm({
            title: '确认删除',
            content: '确认删除该记录吗？',
            onOk: () => {
              dispatch(
                deleteAccountTradeRecordThunk({
                  accountID: r.account_id,
                  recordID: r.id,
                }) as unknown as AnyAction
              ).then((res) => {
                dispatch(fetchAccountDetailThunk(r.account_id) as unknown as AnyAction);
              });
            }
          });
        }}>
          删除
        </Button>
      );
    }
  }
];

const TradeRecords: React.FC<Props> = ({ accountID, records }) => {
  return (
    <div className="trade-records">
      <TradeRecordForm mode="CREATE" accountID={accountID} />
      <Table<AccountTradeRecord>
        columns={COLUMNS}
        dataSource={records}
        rowKey="id"
        search={false}
        toolBarRender={false}
      />
    </div>
  );
};

export default TradeRecords;