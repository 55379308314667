import callApi, { DateRangeParams } from 'app/services/api';
import { KChartData, KChartIndData, KChartOption, KChartRefData, Target } from './models';

export const fetchKChartOptions = () => {
  return callApi<KChartOption>({
    endpoint: '/k_chart/load_config',
  });
};

export type KChartLoadDataParams = DateRangeParams & {
  target: Target['code'];
  refs?: string; // 逗号分割
  indicators?: string; // 逗号分割
};

// 抓取完整数据
export const fetchKChartData = (params: KChartLoadDataParams) => {
  return callApi<KChartData>({
    endpoint: '/k_chart/load_full_data',
    data: params,
  });
};

export type KChartLoadRefDataParams = DateRangeParams & {
  codes: string; // 逗号分割
};

// 抓取对比标的数据
export const fetchKChartRefData = (params: KChartLoadRefDataParams) => {
  return callApi<KChartRefData[]>({
    endpoint: '/k_chart/load_ref_data',
    data: params,
  });
};

export type KChartLoadIndDataParams = DateRangeParams & {
  code: Target['code'];
  indicators: string; // 逗号分割
};

// 抓取指标数据
export const fetchKChartIndData = (params: KChartLoadIndDataParams) => {
  return callApi<KChartIndData[]>({
    endpoint: '/k_chart/load_indicator_data',
    data: params,
  });
};
